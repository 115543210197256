import React from 'react';
import styled from '@emotion/styled';
import { Body } from 'imdui';

const DataRowColumnStyled = styled(Body)`
  flex: 1;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const DataRowColumn = ({ text, ...props }) => (
  <DataRowColumnStyled secondary empty={!text} {...props}>
    {text}
  </DataRowColumnStyled>
);

DataRowColumn.DataRowColumnStyled = DataRowColumnStyled;

export default DataRowColumn;
