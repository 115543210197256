import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { BaseReleaseFields } from 'imdshared';

import {
  Body,
  BodySmall,
  AppTitle,
  Button,
  Option,
  OptionHeader,
  OptionDescription,
} from 'imdui';
import {
  ArtistSideEditor,
  ReleaseEditor,
  useProductPage,
  useProductPageActions,
} from 'components';
import { useTranslation } from 'react-i18next';
import { sectionStyle } from 'imdshared/src/Next/ReleaseEditor/styles';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router';

const requiredFields: never[] = [];

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
  position: relative;
  transition: background-color 0.1s;
  padding: 24px 0px 0px;
`;

const Options = styled.div<{ count: number }>`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-flow: row;
  @media (min-width: 1600px) {
    grid-template-columns: repeat(${({ count }) => count}, 1fr) minmax(
        20%,
        144px
      );
  }
`;

export const SimpleReleaseForm = ({ id }: { id: number | string }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { changeProduct } = useProductPageActions();
  const {
    state: { data: { nextProduct } = {} },
  } = useProductPage();

  const handleRedirect = useCallback(() => {
    if (nextProduct) {
      history.push({
        pathname: `/order/${nextProduct}/new`,
        state: {
          releaseId: id,
        },
      });
    } else {
      history.push(`/library/releases/${id}`);
    }
  }, [nextProduct]);
  return (
    <>
      <div>
        <Wrapper>
          <ReleaseEditor
            hideCoverUploader={true}
            // @ts-ignore
            fieldsComponent={
              <BaseReleaseFields>
                <section css={sectionStyle}>
                  <AppTitle style={{ margin: '8px 0 16px' }}>
                    {t('select-next-step')}
                  </AppTitle>
                  <Options count={2}>
                    <Option
                      selected={nextProduct === 'md'}
                      onClick={() => {
                        changeProduct({
                          nextProduct: 'md',
                        });
                      }}
                    >
                      <OptionHeader>
                        <AppTitle>
                          {t('music-distribution', { context: 'new-release' })}
                        </AppTitle>
                      </OptionHeader>
                      <OptionDescription>
                        <Body secondary>
                          {t('music-distribution-description', {
                            context: 'new-release',
                          })}
                        </Body>
                      </OptionDescription>
                    </Option>
                    <Option
                      selected={nextProduct === 'release-page'}
                      onClick={() => {
                        changeProduct({
                          nextProduct: 'release-page',
                        });
                      }}
                    >
                      <OptionHeader>
                        <AppTitle>
                          {t('release-page', { context: 'new-release' })}
                        </AppTitle>
                      </OptionHeader>
                      <OptionDescription>
                        <Body secondary>
                          {t('release-page-description', {
                            context: 'new-release',
                          })}
                        </Body>
                      </OptionDescription>
                    </Option>
                    <Option
                      onClick={() => {
                        changeProduct({
                          nextProduct: undefined,
                        });
                      }}
                      selected={!nextProduct}
                    >
                      <OptionHeader>
                        <AppTitle>{t('library')}</AppTitle>
                        <BodySmall secondary>
                          {t('edit-release-and-start-products-on-library')}
                        </BodySmall>
                      </OptionHeader>
                    </Option>
                  </Options>
                </section>
              </BaseReleaseFields>
            }
            artworkCopyrightFields={false}
            requiredFields={requiredFields}
            releaseId={id}
          ></ReleaseEditor>
        </Wrapper>
      </div>
      <ArtistSideEditor isAdaptive={false} />
      <Portal node={document && document.getElementById('next-product-step')}>
        <Button
          primary={true}
          onClick={handleRedirect}
          text={t(`continue-to-${nextProduct || 'library'}`)}
        />
      </Portal>
    </>
  );
};
