//
import { schema } from 'normalizr';
import config from 'imdconfig';
import { decamelize } from 'humps';
import { put, call } from 'redux-saga/effects';
import { omit } from 'ramda';
import {
  createS3FileUploader,
  requestSagaCreator,
  factoryEntityApi,
} from '../base';
import callApi from '../callApi';
import * as schemas from '../schemas';
import {
  entities,
  filesManager as filesManagerActionTypes,
} from '../actionTypes/entities';
import { actions as pricesActions } from '../actionTypes/prices';
import { actions as deliveryDatesActions } from '../actionTypes/deliveryDates';
import { covers } from './releases';

export { default as releases, covers } from './releases';
export { default as users } from './users';
export { default as tracks } from './tracks';

// TODO:
// selectData causes schema not to be automatic, how can we break the connection?

export const fieldUpdateRequests = {
  ...factoryEntityApi({
    endpoint: 'field-update-requests/update-requests',
    // base: 'http://localhost:8888',
    entityKey: 'fieldUpdateRequests',
    schema: schemas.fieldUpdateRequest,
    actions: entities.fieldUpdateRequests.actions,
  }),
};

export const trackActions = {
  create: requestSagaCreator(
    entities.trackActions.actions.create,
    ({ data: { action, ...data } }, impersonate) => {
      const endpoint = `tracks/${action}`;
      return callApi({
        impersonate,
        endpoint,
        options: { method: 'POST', data },
      });
    }
  ),
};
export const releaseActions = {
  create: requestSagaCreator(
    entities.releaseActions.actions.create,
    ({ data: { action, ...data } }, impersonate) => {
      const endpoint = `releases/${action}`;
      return callApi({
        impersonate,
        endpoint,
        options: { method: 'POST', data },
      });
    }
  ),
};

export const fieldUpdateRequestItems = {
  ...factoryEntityApi({
    formatEndpoint: ({ query, id }) =>
      id
        ? `field-update-requests/update-requests/${query.requestId}/requested-fields/${id}`
        : `field-update-requests/update-requests/${query.requestId}/requested-fields`,
    entityKey: 'fieldUpdateRequests',
    schema: schemas.fieldUpdateRequestItem,
    actions: entities.fieldUpdateRequestItems.actions,
  }),
};

export const revenueSplitTargets = {
  ...factoryEntityApi({
    endpoint: 'revenue_split_targets',
    entityKey: 'revenueSplitTargets',
    schema: schemas.revenueSplitTarget,
    actions: entities.revenueSplitTargets.actions,
  }),
};

export const dateFormats = {
  ...factoryEntityApi({
    endpoint: 'date_formats',
    entityKey: 'dateFormats',
    schema: schemas.dateFormat,
    actions: entities.dateFormats.actions,
  }),
};

export const trackAudioFingerprints = {
  ...factoryEntityApi({
    endpoint: 'track_audio_fingerprints',
    entityKey: 'trackAudioFingerprints',
    schema: schemas.trackAudioFingerprint,
    actions: entities.trackAudioFingerprints.actions,
  }),
};

export const trackAudioFingerprintMatches = {
  ...factoryEntityApi({
    endpoint: 'track_audio_fingerprint_matches',
    entityKey: 'trackAudioFingerprintMatches',
    schema: schemas.trackAudioFingerprintMatch,
    actions: entities.trackAudioFingerprintMatches.actions,
  }),
};

export const platformTips = {
  ...factoryEntityApi({
    formatEndpoint: ({ query }) =>
      `csh/content/tips/${query?.locale || 'en'}.json`,
    entityKey: 'platformTips',
    formatQuery: () => '',
    base: config.assetsUrl,
    schema: [schemas.platformTip],
    selectData: (json) => json.data,
    actions: entities.platformTips.actions,
    credentials: 'omit',
  }),
};

export const releaseNotes = {
  ...factoryEntityApi({
    formatEndpoint: ({ query }) =>
      `csh/content/release_notes/${query?.locale || 'en'}.json`,
    entityKey: 'newsExceprts',
    formatQuery: () => '',
    base: config.assetsUrl,
    schema: [schemas.releaseNote],
    selectData: (json) => json.data,
    actions: entities.releaseNotes.actions,
    credentials: 'omit',
  }),
};

export const newsExceprts = {
  ...factoryEntityApi({
    formatEndpoint: ({ query }) =>
      `csh/content/news/${query?.locale || 'en'}.json`,
    entityKey: 'newsExceprts',
    base: config.assetsUrl,
    formatQuery: () => '',
    schema: [schemas.newsExceprt],
    selectData: (json) => json.data,
    actions: entities.newsExceprts.actions,
    credentials: 'omit',
  }),
};

export const coverArtworkTemplates = {
  ...factoryEntityApi({
    endpoint: 'fetch',
    entityKey: 'coverArtworkTemplates',
    base: config.coverArtworkServiceUrl,
    schema: [schemas.coverArtworkTemplate],
    selectData: (json) => {
      return json?.results ? json.results || [] : json;
    },
    actions: entities.coverArtworkTemplates.actions,
  }),
};

export const contributors = {
  ...factoryEntityApi({
    endpoint: 'contributors',
    entityKey: 'contributors',
    schema: schemas.contributor,
    actions: entities.contributors.actions,
  }),
};

export const releaseDeliveryStatus = {
  fetch: requestSagaCreator(
    entities.releaseDeliveryStatus.actions.fetch,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        endpoint: `releases/${payload.id}/delivery_status`,
        options: { method: 'GET' },
      });
    }
  ),
};

export const deliveryBundleUpdates = {
  ...factoryEntityApi({
    endpoint: 'delivery_bundle_updates',
    entityKey: 'deliveryBundleUpdates',
    schema: schemas.deliveryBundleUpdate,
    actions: entities.deliveryBundleUpdates.actions,
  }),
};

export const countries = {
  ...factoryEntityApi({
    endpoint: 'countries',
    entityKey: 'countries',
    schema: schemas.country,
    actions: entities.countries.actions,
  }),
};

export const invoicesOverview = {
  create: requestSagaCreator(
    entities.invoicesOverview.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.orderOverview,
        endpoint: payload.id
          ? `invoices/${payload.id}/overview`
          : `invoices/overview`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const deliveryBundleUpdateOverview = {
  create: requestSagaCreator(
    entities.deliveryBundleUpdateOverview.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.orderOverview,
        endpoint: payload.id
          ? `delivery_bundle_updates/${payload.id}/overview`
          : `delivery_bundle_updates/overview`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const instantMasteringPreviews = {
  ...factoryEntityApi({
    endpoint: 'instant_mastering_previews',
    entityKey: 'instantMasteringPreviews',
    schema: schemas.instantMasteringPreview,
    actions: entities.instantMasteringPreviews.actions,
  }),
};

export const instantMasteringTracks = {
  ...factoryEntityApi({
    endpoint: 'instant_mastering_tracks',
    entityKey: 'instantMasteringTracks',
    schema: schemas.instantMasteringTrack,
    actions: entities.instantMasteringTracks.actions,
  }),
};

export const instantMasterings = {
  ...factoryEntityApi({
    endpoint: 'instant_masterings',
    entityKey: 'instantMasterings',
    schema: schemas.instantMastering,
    actions: entities.instantMasterings.actions,
  }),
};

export const instantMasteringTrackActions = {
  create: requestSagaCreator(
    entities.instantMasteringTrackActions.actions.create,
    ({ id, data: { action } }) => {
      const endpoint = `instant_mastering_tracks/${id}/${action}`;
      return callApi({
        schema: schemas.instantMastering,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};

export const instantMasteringOverview = {
  create: requestSagaCreator(
    entities.instantMasteringOverview.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.orderOverview,
        endpoint: `instant_masterings/${payload.id}/overview`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const deliveryBundleOverview = {
  create: requestSagaCreator(
    entities.deliveryBundleOverview.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.orderOverview,
        endpoint: `delivery_bundles/${payload.id}/overview`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const deliveryBundleUpgradeOverview = {
  create: requestSagaCreator(
    entities.deliveryBundleUpgradeOverview.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.orderOverview,
        endpoint: payload.id
          ? `delivery_bundle_upgrade/${payload.id}/overview`
          : `delivery_bundle_upgrade/overview`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const productsOverview = {
  create: requestSagaCreator(
    entities.productsOverview.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.orderOverview,
        endpoint: payload.id
          ? `products/${payload.id}/overview`
          : `products/overview`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const registerAction = {
  create: requestSagaCreator(
    entities.registerAction.actions.create,
    (payload) => {
      return callApi({
        endpoint: 'register',
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};
export const releasePagesOverview = {
  create: requestSagaCreator(
    entities.releasePagesOverview.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.orderOverview,
        endpoint: payload.id
          ? `release_pages/${payload.id}/overview`
          : `release_pages/overview`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const subscriptionsOverview = {
  create: requestSagaCreator(
    entities.subscriptionsOverview.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.orderOverview,
        endpoint: payload.id
          ? `subscriptions/${payload.id}/overview`
          : `subscriptions/overview`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const subscriptions = {
  ...factoryEntityApi({
    endpoint: 'subscriptions',
    entityKey: 'subscriptions',
    schema: schemas.subscription,
    actions: entities.subscriptions.actions,
  }),
};

export const deliveryBundleTracks = {
  ...factoryEntityApi({
    endpoint: 'delivery_bundle_tracks',
    entityKey: 'deliveryBundleTracks',
    schema: schemas.deliveryBundleTrack,
    actions: entities.deliveryBundleTracks.actions,
  }),
};

export const deliveryBundleDeliveries = {
  create: requestSagaCreator(
    entities.deliveryBundleDeliveries.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.deliveryBundle,
        endpoint: `delivery_bundles/${payload?.query?.deliveryBundleId}/deliveries`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const deliveryRegions = {
  ...factoryEntityApi({
    endpoint: 'delivery_regions',
    entityKey: 'deliveryRegions',
    schema: schemas.deliveryRegion,
    actions: entities.deliveryRegions.actions,
  }),
};

export const prices = {
  create: requestSagaCreator(
    entities.prices.actions.create,
    (
      { query: { productId, productType, ...query }, ...payload },
      impersonate
    ) => {
      if (!productType) return { error: true };
      return callApi({
        impersonate,
        schema: schemas.price,
        query,
        endpoint: `${decamelize(
          productType === 'buyableProducts' ? 'products' : productType
        )}/${productId}/prices`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
  update: requestSagaCreator(
    entities.prices.actions.update,
    (
      { query: { productId, productType, ...query }, ...payload },
      impersonate
    ) => {
      if (!productType) return { error: true };
      return callApi({
        impersonate,
        schema: schemas.price,
        query,
        endpoint: `${decamelize(productType)}/${productId}/prices/${payload.id
          }`,
        options: { method: 'PUT', data: payload.data },
      });
    }
  ),
  delete: requestSagaCreator(
    entities.prices.actions.delete,
    (
      { query: { productId, productType, ...query }, ...payload },
      impersonate
    ) => {
      if (!productType) return { error: true };
      return callApi({
        impersonate,
        schema: schemas.price,
        query,
        endpoint: `${decamelize(productType)}/${productId}/prices/${payload.id
          }`,
        options: { method: 'DELETE', data: payload.data },
      });
    }
  ),
  fetch: requestSagaCreator(
    entities.prices.actions.fetch,
    (
      { query: { productId, productType, regionId, ...query }, ...payload },
      impersonate
    ) => {
      if (!productType || !regionId) return { error: true };
      return callApi({
        impersonate,
        schema: schemas.price,
        query,
        endpoint:
          regionId && !payload.id
            ? `${decamelize(
              productType
            )}/${productId}/prices/region/${regionId}`
            : `${decamelize(productType)}/${productId}/prices/${payload.id}`,
        options: { method: 'GET', data: payload.data },
      });
    }
  ),
  overviewByRegion: requestSagaCreator(
    pricesActions.overviewByRegion,
    ({ id }) => {
      return callApi({
        schema: schemas.pricesOverview,
        endpoint: `prices/price_region/${id}`,
        options: { method: 'GET' },
      });
    }
  ),
};

export const pricesOverview = {
  fetch: requestSagaCreator(
    entities.pricesOverview.actions.fetch,
    ({ query: { priceRegionId, ...query }, ...payload }, impersonate) => {
      return callApi({
        impersonate,
        schema: schemas.newPricesOverview,
        query,
        endpoint: `/prices/price_region/${priceRegionId}`,
        options: { method: 'GET', data: payload.data },
      });
    }
  ),
};

export const priceRegions = {
  ...factoryEntityApi({
    endpoint: 'price_regions',
    entityKey: 'priceRegions',
    schema: schemas.priceRegion,
    actions: entities.priceRegions.actions,
  }),
};

export const pricePlans = {
  ...factoryEntityApi({
    endpoint: 'price_plans',
    entityKey: 'pricePlans',
    schema: schemas.pricePlan,
    actions: entities.pricePlans.actions,
  }),
};

export const customers = {
  ...factoryEntityApi({
    endpoint: 'customers',
    entityKey: 'customers',
    schema: schemas.customer,
    actions: entities.customers.actions,
  }),
};

export const customerActions = {
  delete: requestSagaCreator(
    entities.customerActions.actions.delete,
    ({ id, data: { action } }) => {
      const endpoint = `customers/${id}/${action}`;
      return callApi({
        endpoint,
        options: { method: 'DELETE' },
      });
    }
  ),
  create: requestSagaCreator(
    entities.customerActions.actions.create,
    ({ id, data: { action, ...data } }) => {
      const endpoint = `customers/${id}/${action}`;
      return callApi({
        endpoint,
        options: { method: 'POST', data },
      });
    }
  ),
};

export const contactRequests = {
  ...factoryEntityApi({
    endpoint: 'contact',
    entityKey: 'contactRequests',
    actions: entities.contactRequests.actions,
  }),
};

export const pricesCustomer = {
  fetch: requestSagaCreator(
    pricesActions.pricesCustomer,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        endpoint: `prices/customer`,
        schema: schemas.pricesCustomer,
        options: { method: 'GET' },
      });
    }
  ),
};

export const deliveryDates = {
  fetch: requestSagaCreator(
    deliveryDatesActions.fetch,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        endpoint: `calendar`,
        options: { method: 'GET' },
      });
    }
  ),
};

export const payoutRequest = {
  fetch: requestSagaCreator(
    entities.payoutRequest.actions.fetch,
    (payload, impersonate) => {
      return callApi({
        ...payload,
        impersonate,
        schema: schemas.payoutRequest,
        endpoint: `payout_request`,
        options: { method: 'GET' },
      });
    }
  ),
  create: requestSagaCreator(
    entities.payoutRequest.actions.create,
    (payload, impersonate) => {
      return callApi({
        ...payload,
        impersonate,
        schema: schemas.payout,
        endpoint: `payout_request`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const trendsBasic = {
  fetch: requestSagaCreator(
    entities.trendsBasic.actions.fetch,
    ({ query }, impersonate) => {
      return callApi({
        impersonate,
        query,
        endpoint: `trends/charts/free`,
        schema: new schema.Object({}),
        options: { method: 'GET' },
      });
    }
  ),
};
export const trendsSummary = {
  fetch: requestSagaCreator(
    entities.trendsSummary.actions.fetch,
    ({ query }, impersonate) => {
      return callApi({
        impersonate,
        query,
        endpoint: `trends/charts/details`,
        schema: schemas.trendsSummary,
        options: { method: 'GET' },
      });
    }
  ),
};

export const trendsDevices = {
  fetch: requestSagaCreator(
    entities.trendsDevices.actions.fetch,
    ({ query }, impersonate) => {
      return callApi({
        impersonate,
        query,
        endpoint: `trends/charts/devices`,
        schema: new schema.Object({}),
        options: { method: 'GET' },
      });
    }
  ),
};
export const trendsSources = {
  fetch: requestSagaCreator(
    entities.trendsSources.actions.fetch,
    ({ query }, impersonate) => {
      return callApi({
        impersonate,
        query,
        endpoint: `trends/charts/sources`,
        schema: new schema.Object({}),
        options: { method: 'GET' },
      });
    }
  ),
};
export const trendsCountries = {
  fetch: requestSagaCreator(
    entities.trendsCountries.actions.fetch,
    ({ query }, impersonate) => {
      return callApi({
        impersonate,
        query,
        endpoint: `trends/charts/countries`,
        schema: new schema.Object({}),
        options: { method: 'GET' },
      });
    }
  ),
};

export const trendsGenders = {
  fetch: requestSagaCreator(
    entities.trendsGenders.actions.fetch,
    ({ query }, impersonate) => {
      return callApi({
        impersonate,
        query,
        endpoint: `trends/charts/genders`,
        schema: new schema.Object({}),
        options: { method: 'GET' },
      });
    }
  ),
};
export const trendsShops = {
  fetch: requestSagaCreator(
    entities.trendsShops.actions.fetch,
    ({ query }, impersonate) => {
      return callApi({
        impersonate,
        query,
        endpoint: `trends/charts/shops`,
        schema: new schema.Object({}),
        options: { method: 'GET' },
      });
    }
  ),
};

export const trendsTopTracks = {
  fetch: requestSagaCreator(
    entities.trendsTopTracks.actions.fetch,
    ({ query }, impersonate) => {
      return callApi({
        impersonate,
        query,
        endpoint: `trends/charts/tracks`,
        schema: new schema.Object({}),
        options: { method: 'GET' },
      });
    }
  ),
};

export const invoices = {
  ...factoryEntityApi({
    endpoint: 'invoices',
    entityKey: 'invoices',
    schema: schemas.invoice,
    actions: entities.invoices.actions,
  }),
};
export const invoicePayments = {
  create: requestSagaCreator(
    entities.invoicePayments.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        selectData: (json) => json,
        schema: new schema.Object({
          item: schemas.invoice,
          transaction: schemas.transaction,
        }),
        endpoint: `invoices/${payload.id}/payment`,
        query: { with: 'items' },
        options: {
          method: 'POST',
          data: payload.data,
        },
      });
    }
  ),
};

export const invoiceItems = {
  ...factoryEntityApi({
    endpoint: 'invoice_items',
    entityKey: 'invoiceItems',
    schema: schemas.invoiceItem,
    actions: entities.invoiceItems.actions,
  }),
};
export const invoiceDiscounts = {
  ...factoryEntityApi({
    endpoint: 'invoice_discounts',
    entityKey: 'invoiceDiscounts',
    schema: schemas.invoiceDiscount,
    actions: entities.invoiceDiscounts.actions,
  }),
};
export const invoiceTransactions = {
  ...factoryEntityApi({
    endpoint: 'invoice_transactions',
    entityKey: 'invoiceTransactions',
    schema: schemas.invoiceTransaction,
    actions: entities.invoiceTransactions.actions,
  }),
};

export const downloads = {
  ...factoryEntityApi({
    endpoint: 'downloads',
    entityKey: 'downloads',
    schema: schemas.download,
    actions: entities.downloads.actions,
  }),
  create: requestSagaCreator(
    entities.downloads.actions.create,
    (payload, impersonate) => {
      const { entity, action, ...data } = payload.data;
      return callApi({
        impersonate,
        schema: schemas.download,
        endpoint: action
          ? `downloads/${payload.id}/${action}`
          : `downloads/${decamelize(entity)}`,
        options: { method: 'POST', data },
      });
    }
  ),
};

export const salesItems = {
  ...factoryEntityApi({
    endpoint: 'sales_items',
    entityKey: 'salesItems',
    schema: schemas.salesItem,
    actions: entities.salesItems.actions,
  }),
};
export const salesCustomerRevenues = {
  ...factoryEntityApi({
    endpoint: 'sales_customer_revenues',
    entityKey: 'salesCustomerRevenues',
    schema: schemas.salesCustomerRevenue,
    actions: entities.salesCustomerRevenues.actions,
  }),
};
export const salesItemRevenues = {
  ...factoryEntityApi({
    endpoint: 'sales_item_revenues',
    entityKey: 'salesItemRevenues',
    schema: schemas.salesItemRevenue,
    actions: entities.salesItemRevenues.actions,
  }),
};

export const salesDistributions = {
  ...factoryEntityApi({
    endpoint: 'sales_distributions',
    entityKey: 'salesDistributions',
    schema: schemas.salesDistribution,
    actions: entities.salesDistributions.actions,
  }),
};

export const salesDistributionActions = {
  create: requestSagaCreator(
    entities.salesDistributionActions.actions.create,
    ({ id, data: { action } }) => {
      const endpoint = `sales_distributions/${id}/${action}`;
      return callApi({
        schema: schemas.salesDistribution,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};
export const salesImports = {
  ...factoryEntityApi({
    endpoint: 'sales_imports',
    entityKey: 'salesImports',
    schema: schemas.salesImport,
    actions: entities.salesImports.actions,
  }),
};
export const salesImportActions = {
  create: requestSagaCreator(
    entities.salesImportActions.actions.create,
    ({ id, data: { action } }) => {
      const endpoint = `sales_imports/${id}/${action}`;
      return callApi({
        schema: schemas.salesImport,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};

export const payoutAddresses = {
  ...factoryEntityApi({
    endpoint: 'payout_addresses',
    entityKey: 'payoutAddresses',
    schema: schemas.bank,
    actions: entities.payoutAddresses.actions,
  }),
};

export const payoutAddressesActions = {
  create: requestSagaCreator(
    entities.payoutAddressesActions.actions.create,
    ({ id, data: { action, token } }) => {
      if (action === 'confirm') {
        return callApi({
          schema: schemas.bank,
          endpoint: `payout_addresses/${id}/confirm${token ? `/${token}` : ''}`,
          options: { method: 'POST' },
        });
      }
      const endpoint = `payout_addresses/${id}/${action}`;
      return callApi({
        schema: schemas.bank,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};

export const salesShopsGroups = {
  ...factoryEntityApi({
    endpoint: 'sales_shop_groups',
    entityKey: 'salesShopsGroups',
    schema: schemas.salesShopsGroup,
    actions: entities.salesShopsGroups.actions,
  }),
};

export const salesShops = {
  ...factoryEntityApi({
    endpoint: 'sales_shops',
    entityKey: 'salesShops',
    schema: schemas.salesShop,
    actions: entities.salesShops.actions,
  }),
};

export const salesStatements = {
  ...factoryEntityApi({
    endpoint: 'sales/statements',
    entityKey: 'salesStatements',
    schema: schemas.salesStatements,
    actions: entities.salesStatements.actions,
  }),
};

export const salesStatementsSplits = {
  fetch: requestSagaCreator(
    entities.salesStatementsSplits.actions.fetch,
    ({ query: { signature } }, impersonate) => {
      const endpoint = `sales/customer/statement/${signature}/splits`;
      return callApi({
        impersonate,
        schema: schemas.salesStatementsSplit,
        endpoint,
        options: { method: 'GET' },
      });
    }
  ),
};

export const deliveries = {
  ...factoryEntityApi({
    endpoint: 'deliveries',
    entityKey: 'deliveries',
    schema: schemas.delivery,
    actions: entities.deliveries.actions,
  }),
};

export const deliveryBundleActions = {
  create: requestSagaCreator(
    entities.deliveryBundleActions.actions.create,
    ({ id, data: { action } }) => {
      const endpoint = `delivery_bundles/${id}/${action}`;
      return callApi({
        schema: schemas.deliveryBundle,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};

export const deliveryBundles = {
  ...factoryEntityApi({
    endpoint: 'delivery_bundles',
    entityKey: 'deliveryBundles',
    schema: schemas.deliveryBundle,
    actions: entities.deliveryBundles.actions,
  }),
};

export const deliveryBundleShops = {
  ...factoryEntityApi({
    formatEndpoint: ({ query }) => `delivery_bundles/${query.bundleId}/shops`,
    entityKey: 'deliveryBundleShops',
    schema: schemas.deliveryBundleShop,
    actions: entities.deliveryBundleShops.actions,
  }),
};

export const shops = {
  ...factoryEntityApi({
    endpoint: 'shops',
    entityKey: 'shops',
    schema: schemas.shop,
    actions: entities.shops.actions,
  }),
};

export const admins = {
  ...factoryEntityApi({
    endpoint: 'admins',
    entityKey: 'admins',
    schema: schemas.admin,
    actions: entities.admins.actions,
  }),
};
export const barcodes = {
  ...factoryEntityApi({
    endpoint: 'barcodes',
    entityKey: 'barcodes',
    schema: schemas.barcode,
    actions: entities.barcodes.actions,
  }),
};
export const videos = {
  ...factoryEntityApi({
    endpoint: 'videos',
    entityKey: 'videos',
    schema: schemas.video,
    actions: entities.videos.actions,
  }),
};
export const channels = {
  ...factoryEntityApi({
    endpoint: 'channels',
    entityKey: 'channels',
    schema: schemas.channel,
    actions: entities.channels.actions,
  }),
};

export const isrcs = {
  ...factoryEntityApi({
    endpoint: 'isrcs',
    entityKey: 'isrcs',
    schema: schemas.isrc,
    actions: entities.isrcs.actions,
  }),
};
export const currencyRates = {
  ...factoryEntityApi({
    endpoint: 'currency_rates',
    entityKey: 'currencyRates',
    schema: schemas.currencyRate,
    actions: entities.currencyRates.actions,
  }),
};
export const currencies = {
  ...factoryEntityApi({
    formatEndpoint: () => 'currencies',
    entityKey: 'currencies',
    schema: schemas.currency,
    actions: entities.currencies.actions,
  }),
};

export const transactions = {
  ...factoryEntityApi({
    formatEndpoint: ({ id, query }) => {
      return query?.type
        ? `transactions/${query.type.join('/')}`
        : id
          ? `transactions/${id}`
          : 'transactions';
    },

    entityKey: 'transactions',
    schema: schemas.transaction,
    actions: entities.transactions.actions,
  }),
};

export const labels = {
  ...factoryEntityApi({
    endpoint: 'labels',
    entityKey: 'labels',
    schema: schemas.label,
    actions: entities.labels.actions,
  }),
};

export const labelActions = {
  create: requestSagaCreator(
    entities.labelActions.actions.create,
    ({ id, data: { action, shopAction, shopId } }) => {
      if (action === 'shopRegistration') {
        const endpoint = `labels/${id}/${shopId}/${shopAction}`;
        return callApi({
          schema: schemas.label,
          endpoint,
          options: { method: 'PUT' },
        });
      }
      const endpoint = `labels/${id}/${action}`;
      return callApi({
        schema: schemas.label,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};

export const publishers = {
  ...factoryEntityApi({
    endpoint: 'publishers',
    entityKey: 'publishers',
    schema: schemas.publisher,
    actions: entities.publishers.actions,
  }),
};

export const trackIsrcs = {
  ...factoryEntityApi({
    formatEndpoint: ({ id }) => {
      return `tracks/${id}/isrc`;
    },
    entityKey: 'trackIsrcs',
    schema: schemas.track,
    actions: entities.trackIsrcs.actions,
  }),
};

export const trackNames = {
  ...factoryEntityApi({
    endpoint: 'track_names',
    entityKey: 'trackNames',
    schema: schemas.trackName,
    actions: entities.trackNames.actions,
  }),
};
export const releaseNames = {
  ...factoryEntityApi({
    endpoint: 'release_names',
    entityKey: 'releaseNames',
    schema: schemas.releaseName,
    actions: entities.releaseNames.actions,
  }),
};

export const releaseBarcodes = {
  ...factoryEntityApi({
    formatEndpoint: ({ id }) => {
      return `releases/${id}/barcodes`;
    },
    entityKey: 'releaseBarcodes',
    schema: schemas.release,
    actions: entities.releaseBarcodes.actions,
  }),
};

export const contributorNames = {
  ...factoryEntityApi({
    endpoint: 'contributor_names',
    entityKey: 'contributorNames',
    schema: schemas.contributorName,
    actions: entities.contributorNames.actions,
  }),
};

export const artistNames = {
  ...factoryEntityApi({
    endpoint: 'artist_names',
    entityKey: 'artistNames',
    schema: schemas.artistName,
    actions: entities.artistNames.actions,
  }),
};

export const orders = {
  ...factoryEntityApi({
    endpoint: 'orders',
    entityKey: 'orders',
    schema: schemas.order,
    actions: entities.orders.actions,
  }),
};

export const ordersOverview = {
  create: requestSagaCreator(
    entities.ordersOverview.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        selectData: (json) => json,
        schema: new schema.Object({
          data: schemas.orderOverview,
          orders: [schemas.order],
        }),
        endpoint: `orders/overview${payload.id ? `/${payload.id}` : ''}`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const batchProcesses = {
  ...factoryEntityApi({
    endpoint: 'batch_processes',
    entityKey: 'batchProcesses',
    schema: schemas.batchProcess,
    actions: entities.batchProcesses.actions,
  }),
};

export const batchProcessActions = {
  create: requestSagaCreator(
    entities.batchProcessActions.actions.create,
    ({ id, data: { action } }) => {
      const endpoint = `batch_processes/${id}/${action}`;
      return callApi({
        schema: schemas.batchProcess,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};

export const indexConfigurators = {
  ...factoryEntityApi({
    endpoint: 'index-configurators',
    entityKey: 'indexConfigurators',
    schema: schemas.indexConfigurator,
    actions: entities.indexConfigurators.actions,
  }),
};

export const indexConfiguratorActions = {
  create: requestSagaCreator(
    entities.indexConfiguratorActions.actions.create,
    ({ id, data: { action, ...data } }) => {
      const endpoint = `index-configurators/${id}/${action}`;
      return callApi({
        schema: schemas.indexConfigurator,
        endpoint,
        options: { method: 'POST', data },
      });
    }
  ),
};

export const orderItems = {
  ...factoryEntityApi({
    endpoint: 'order_items',
    entityKey: 'orderItems',
    schema: schemas.orderItem,
    actions: entities.orderItems.actions,
  }),
};

export const orderItemActions = {
  create: requestSagaCreator(
    entities.orderItemActions.actions.create,
    ({ id, data: { action, type } }) => {
      const endpoint =
        type === 'restartHandler'
          ? `order_items/${id}/actions/${action}/restart`
          : `order_items/${id}/${action}`;
      return callApi({
        schema: schemas.orderItem,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};

export const holidays = {
  ...factoryEntityApi({
    endpoint: 'holidays',
    entityKey: 'holidays',
    schema: schemas.holiday,
    actions: entities.holidays.actions,
  }),
};
export const languages = {
  ...factoryEntityApi({
    endpoint: 'languages',
    entityKey: 'languages',
    schema: schemas.language,
    actions: entities.languages.actions,
  }),
};

export const genres = {
  ...factoryEntityApi({
    endpoint: 'genres',
    entityKey: 'genres',
    schema: schemas.genre,
    actions: entities.genres.actions,
  }),
};

export const artists = {
  ...factoryEntityApi({
    endpoint: 'artists',
    entityKey: 'artists',
    schema: schemas.artist,
    actions: entities.artists.actions,
  }),
};

export const artistFiles = {
  ...factoryEntityApi({
    formatQuery: omit(['artistId']),
    formatEndpoint: ({ query, id }) => `artists/${query?.artistId}/files/${id}`,
    entityKey: 'artistFiles',
    schema: schemas.artistFile,
    actions: entities.artistFiles.actions,
  }),
};

export const artistImages = {
  ...factoryEntityApi({
    endpoint: 'artist_images',
    entityKey: 'artistImages',
    schema: schemas.artistImage,
    actions: entities.artistImages.actions,
  }),
};

export const artistGalleries = {
  ...factoryEntityApi({
    endpoint: 'artist_galleries',
    entityKey: 'artistGalleries',
    schema: schemas.artistGallery,
    actions: entities.artistGalleries.actions,
  }),
};

export const artistPages = {
  ...factoryEntityApi({
    endpoint: 'artist_pages',
    entityKey: 'artistPages',
    schema: schemas.artistPage,
    actions: entities.artistPages.actions,
  }),
};

export const artistPageActions = {
  create: requestSagaCreator(
    entities.artistPageActions.actions.create,
    ({ id, data: { action, ...data } }, impersonate) => {
      const endpoint = `artist_pages/${id}/${action}`;
      return callApi({
        impersonate,
        schema: schemas.artistPage,
        endpoint,
        options: { method: 'POST', data },
      });
    }
  ),
};

export const artistsMetadata = {
  ...factoryEntityApi({
    base: config.smsUrl,
    endpoint: 'artists',
    entityKey: 'artistsMetadata',
    actions: entities.artistsMetadata.actions,
    schema: schemas.artistsMetadata,
  }),
};

export const vouchers = {
  ...factoryEntityApi({
    endpoint: 'vouchers',
    entityKey: 'vouchers',
    schema: schemas.voucher,
    actions: entities.vouchers.actions,
  }),
};

export const voucherValues = {
  ...factoryEntityApi({
    endpoint: 'voucher_values',
    entityKey: 'voucherValues',
    schema: schemas.voucherValue,
    actions: entities.voucherValues.actions,
  }),
};

export const releaseTypes = {
  ...factoryEntityApi({
    endpoint: 'release_types',
    entityKey: 'releaseTypes',
    schema: schemas.releaseType,
    actions: entities.releaseTypes.actions,
  }),
};

export const payoutMethods = {
  ...factoryEntityApi({
    endpoint: 'payout_methods',
    entityKey: 'payoutMethods',
    schema: schemas.payoutMethod,
    actions: entities.payoutMethods.actions,
  }),
};

export const promotions = {
  ...factoryEntityApi({
    endpoint: 'promotions',
    entityKey: 'promotions',
    schema: schemas.promotion,
    actions: entities.promotions.actions,
  }),
};

export const paymentMethods = {
  ...factoryEntityApi({
    endpoint: 'payment_methods',
    entityKey: 'paymentMethods',
    schema: schemas.paymentMethod,
    actions: entities.paymentMethods.actions,
  }),
};

export const products = {
  ...factoryEntityApi({
    endpoint: 'products',
    entityKey: 'products',
    schema: schemas.product,
    actions: entities.products.actions,
  }),
};

export const createTakedown = {
  create: requestSagaCreator(
    entities.createTakedown.actions.create,
    (payload, impersonate) => {
      const { handler, entity } = payload?.query || {};
      if (!handler) {
        return {
          error: 'Provide Handler',
        };
      }
      if (!entity) {
        return {
          error: 'Provide Entity Takedown',
        };
      }
      const { id } = payload;
      const finalHandler = handler === 'all' ? '' : handler;
      return callApi({
        impersonate,
        selectData: (json) => json,
        endpoint: `${decamelize(entity)}/${id}/deliveries/takedown/${decamelize(
          finalHandler
        )}`,
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const createOrder = {
  create: requestSagaCreator(
    entities.createOrder.actions.create,
    (payload, impersonate) => {
      if (!payload.query || !payload.query.productType) {
        return {
          error: 'Provide Product Type',
        };
      }
      return callApi({
        impersonate,
        selectData: (json) => json,
        schema: {
          item: schemas.order,
          orderOverview: schemas.orderOverview,
          handlerData: {
            label: schemas.label,
          },
        },
        endpoint: `${decamelize(payload.query.productType)}/${payload.id ? `${payload.id}/` : ''
          }order`,
        query: { with: 'items' },
        options: { method: 'POST', data: payload.data },
      });
    }
  ),
};

export const payouts = {
  ...factoryEntityApi({
    endpoint: 'payouts',
    entityKey: 'payouts',
    schema: schemas.payout,
    actions: entities.payouts.actions,
  }),
};

export const customerSubscriptions = {
  ...factoryEntityApi({
    endpoint: 'customer_subscriptions',
    entityKey: 'customerSubscriptions',
    schema: schemas.customerSubscription,
    actions: entities.customerSubscriptions.actions,
  }),
};

export const customerSubscriptionState = {
  ...factoryEntityApi({
    endpoint: 'customer_subscription',
    entityKey: 'customerSubscriptionState',
    schema: schemas.customerSubscriptionState,
    actions: entities.customerSubscriptionState.actions,
  }),
};

export const customerSubscriptionStateAction = {
  create: requestSagaCreator(
    entities.customerSubscriptionStateAction.actions.create,
    ({ data: { action, ...data } }, impersonate) => {
      const endpoint = `customer_subscription/${action}`;
      return callApi({
        impersonate,
        schema: schemas.customerSubscriptionState,
        endpoint,
        options: {
          method: 'POST',

          data,
        },
      });
    }
  ),
};
export const userSocialAccounts = {
  ...factoryEntityApi({
    endpoint: 'user_social_accounts',
    entityKey: 'userSocialAccounts',
    schema: schemas.userSocialAccount,
    actions: entities.userSocialAccounts.actions,
  }),
};
export const customerVouchers = {
  ...factoryEntityApi({
    endpoint: 'customer_vouchers',
    entityKey: 'customerVouchers',
    schema: schemas.customerVoucher,
    actions: entities.customerVouchers.actions,
  }),
};

export const logs = {
  ...factoryEntityApi({
    endpoint: 'logs',
    entityKey: 'logs',
    schema: schemas.log,
    actions: entities.logs.actions,
  }),
};

export const settings = {
  ...factoryEntityApi({
    endpoint: 'settings',
    entityKey: 'settings',
    actions: entities.settings.actions,
  }),
};

export const releaseTracks = {
  ...factoryEntityApi({
    endpoint: 'release_tracks',
    entityKey: 'releaseTracks',
    schema: schemas.releaseTrack,
    actions: entities.releaseTracks.actions,
  }),
};
export const releaseCovers = {
  ...factoryEntityApi({
    endpoint: 'release_covers',
    entityKey: 'releaseCovers',
    schema: schemas.releaseCover,
    actions: entities.releaseCovers.actions,
  }),
  ...covers,
};

export const userNotifications = {
  ...factoryEntityApi({
    endpoint: 'user_notifications',
    entityKey: 'userNotifications',
    schema: schemas.userNotification,
    actions: entities.userNotifications.actions,
  }),
};
export const notifications = {
  ...factoryEntityApi({
    endpoint: 'notifications',
    entityKey: 'notifications',
    schema: schemas.notification,
    actions: entities.notifications.actions,
  }),
};
export const trackAudios = {
  ...factoryEntityApi({
    endpoint: 'track_audios',
    entityKey: 'trackAudios',
    schema: schemas.trackAudio,
    actions: entities.trackAudios.actions,
  }),
};
export const deliveryFiles = {
  ...factoryEntityApi({
    endpoint: 'delivery_files',
    entityKey: 'deliveryFiles',
    schema: schemas.deliveryFile,
    actions: entities.deliveryFiles.actions,
  }),
};
export const disks = {
  ...factoryEntityApi({
    endpoint: 'disks',
    entityKey: 'disks',
    schema: schemas.disk,
    actions: entities.disks.actions,
  }),
};
export const files = {
  ...factoryEntityApi({
    endpoint: 'files',
    entityKey: 'files',
    schema: schemas.file,
    actions: entities.files.actions,
  }),
};

export const fileActions = {
  create: requestSagaCreator(
    entities.fileActions.actions.create,
    ({ id, data: { action } }) => {
      const endpoint = `files/${id}/${action}`;
      return callApi({
        schema: schemas.file,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};
export const blacklistItems = {
  ...factoryEntityApi({
    endpoint: 'blacklist_items',
    entityKey: 'blacklistItems',
    schema: schemas.blacklistItem,
    actions: entities.blacklistItems.actions,
  }),
};
export const fileLocations = {
  ...factoryEntityApi({
    endpoint: 'file_locations',
    entityKey: 'fileLocations',
    schema: schemas.fileLocation,
    actions: entities.fileLocations.actions,
  }),
};

export const failedJobs = {
  ...factoryEntityApi({
    endpoint: 'failed-jobs',
    entityKey: 'failedJobs',
    schema: schemas.failedJob,
    actions: entities.failedJobs.actions,
  }),
};
export const trendFiles = {
  ...factoryEntityApi({
    entityKey: 'trendFiles',
    schema: schemas.trendFile,
    actions: entities.trendFiles.actions,
    formatEndpoint: () => 'trend_files',
  }),
};
export const trendItems = {
  ...factoryEntityApi({
    formatEndpoint: () => 'trend_items',
    entityKey: 'trendItems',
    schema: schemas.trendItem,
    actions: entities.trendItems.actions,
  }),
};
export const handlers = {
  ...factoryEntityApi({
    endpoint: 'handlers',
    entityKey: 'handlers',
    schema: schemas.handler,
    actions: entities.handlers.actions,
  }),
};

export const copyrightClaims = {
  ...factoryEntityApi({
    endpoint: 'copyright_claims',
    entityKey: 'copyrightClaims',
    schema: schemas.copyrightClaim,
    actions: entities.copyrightClaims.actions,
  }),
};

export const shortLinks = {
  ...factoryEntityApi({
    formatEndpoint: ({ id }) => {
      const [linkId] = id ? id.split(':') : '';
      return linkId ? `links/${linkId}` : `links`;
    },
    base: config.shortLinksUrl,
    entityKey: 'shortLinks',
    schema: schemas.shortLink,
    actions: entities.shortLinks.actions,
  }),
};

export const customerPaymentMethods = {
  ...factoryEntityApi({
    endpoint: 'customer-payment-methods',
    entityKey: 'customerPaymentMethods',
    schema: schemas.customerPaymentMethod,
    actions: entities.customerPaymentMethods.actions,
  }),

  // Overwrite to work as invoicePayments
  create: requestSagaCreator(
    entities.customerPaymentMethods.actions.create,
    (payload, impersonate) => {
      return callApi({
        impersonate,
        selectData: (json) => json,
        schema: new schema.Object({
          item: schemas.customerPaymentMethod,
          transaction: schemas.transaction,
        }),
        endpoint: `customer-payment-methods/create/${payload.id}`,
        query: { with: 'items' },
        options: {
          method: 'POST',
          data: payload.data,
        },
      });
    }
  ),
};

export const customerPaymentMethodSetDefault = {
  update: requestSagaCreator(
    entities.customerPaymentMethods.actions.update,
    ({ id }) => {
      const endpoint = `customer-payment-methods/${id}/default`;
      return callApi({
        schema: schemas.customerPaymentMethod,
        endpoint,
        options: { method: 'PUT' },
      });
    }
  ),
};

export const pitching = {
  ...factoryEntityApi({
    endpoint: 'pitching',
    entityKey: 'pitching',
    schema: schemas.pitching,
    actions: entities.pitching.actions,
  }),
};

export const referralRewards = {
  ...factoryEntityApi({
    endpoint: 'referral/reward',
    entityKey: 'referralRewards',
    schema: schemas.referralReward,
    actions: entities.referralRewards.actions,
  }),
};

export const releasePages = {
  ...factoryEntityApi({
    endpoint: 'release_pages',
    entityKey: 'releasePages',
    schema: schemas.releasePage,
    actions: entities.releasePages.actions,
  }),
};

export const releasePagesShops = {
  ...factoryEntityApi({
    formatQuery: omit(['releasePageId']),
    formatEndpoint: ({ query }) =>
      `release_pages/${query?.releasePageId}/shops`,
    entityKey: 'releasePagesShops',
    schema: schemas.releasePagesShop,
    actions: entities.releasePagesShops.actions,
  }),
};

export const translations = {
  ...factoryEntityApi({
    formatQuery: omit(['resource', 'resourceId']),
    formatEndpoint: ({ query, id }) =>
      `${query?.resource}/${query?.resourceId}/translations${id ? `/${id}` : ''
      }`,
    entityKey: 'translations',
    schema: schemas.translation,
    actions: entities.translations.actions,
  }),
};

export const releasePagesPressFiles = {
  ...factoryEntityApi({
    formatQuery: omit(['releasePageId']),
    formatEndpoint: ({ query, id }) =>
      `release_pages/${query?.releasePageId}/press_files${id ? `/${id}` : ''}`,
    entityKey: 'releasePagesPressFiles',
    schema: schemas.releasePagesPressFile,
    actions: entities.releasePagesPressFiles.actions,
  }),
};

export const releasePageActions = {
  create: requestSagaCreator(
    entities.releasePageActions.actions.create,
    ({ id, data: { action } }) => {
      const endpoint = `release_pages/${id}/${action}`;
      return callApi({
        schema: schemas.releasePage,
        endpoint,
        options: { method: 'POST' },
      });
    }
  ),
};

const getFileHandlerEndpoints = ({ handler, handlerData }) => {
  switch (handler) {
    case 'artistImage': {
      return {
        upload: {
          url: `artist_images/${handlerData.id}/s3`,
          method: 'POST',
        },
        delete: {
          url: `artist_images/${handlerData.id}`,
          method: 'DELETE',
        },
        confirm: {
          url: `artist_images/${handlerData.id}/s3/confirm`,
          method: 'POST',
        },
      };
    }
    case 'artistFile': {
      return {
        upload: {
          url: `artists/${handlerData.artistId}/files/s3`,
          method: 'POST',
        },
        delete: {
          url: `artists/${handlerData.artistId}/files/${handlerData.artistFileId}`,
          method: 'DELETE',
        },
        confirm: {
          url: (file) =>
            `artists/${handlerData.artistId}/files/${file?.fileId}`,
          schema: schemas.artistFile,
          method: 'POST',
        },
      };
    }
    case 'artistPageTourSplashImage': {
      return {
        upload: {
          url: `artist_pages/${handlerData.id}/tour_page_splash_file/s3`,
          method: 'POST',
        },
        delete: {
          url: `artist_pages/${handlerData.id}/tour_page_splash_file`,
          method: 'DELETE',
        },
        confirm: {
          url: `artist_pages/${handlerData.id}/tour_page_splash_file`,
          method: 'POST',
        },
      };
    }
    case 'artistProfileImage': {
      return {
        upload: {
          url: `artists/${handlerData.id}/profile_image/s3`,
          method: 'POST',
        },
        delete: {
          url: `artists/${handlerData.id}/profile_image`,
          method: 'DELETE',
        },
        confirm: {
          url: `artists/${handlerData.id}/profile_image`,
          method: 'POST',
        },
      };
    }
    case 'releasePagesPressFile': {
      return {
        upload: {
          url: `release_pages/${handlerData.releasePageId}/press_files/${handlerData.id}/file`,
          method: 'POST',
        },
        delete: {
          url: `release_pages/${handlerData.releasePageId}/press_files/${handlerData.id}/file`,
          method: 'DELETE',
        },
        confirm: {
          url: `release_pages/${handlerData.releasePageId}/press_files/${handlerData.id}/file`,
          method: 'PUT',
        },
      };
    }
    case 'releasePageFile': {
      return {
        upload: {
          url: `release_pages/${handlerData.id}/files/${handlerData.fileType}`,
          method: 'POST',
        },
        delete: {
          url: `release_pages/${handlerData.id}/files/${handlerData.fileType}`,
          method: 'DELETE',
        },
        confirm: {
          url: `release_pages/${handlerData.id}/files/${handlerData.fileType}`,
          method: 'PUT',
        },
      };
    }
    case 'salesImportFile': {
      return {
        upload: {
          url: `sales_imports/${handlerData.id}/file/s3`,
          method: 'POST',
        },
        delete: {
          url: `sales_imports/${handlerData.id}/file`,
          method: 'DELETE',
        },
        confirm: {
          url: `sales_imports/${handlerData.id}/file`,
          method: 'POST',
        },
      };
    }
    default: {
      console.error('ATTEMPTING TO UPLOAD/DELETE NON SUPPORTED FILE TYPE');
      return {
        upload: {},
        delete: {},
        confirm: {},
      };
    }
  }
};

export const filesManager = {
  create: function* handleFileUpload(payload, meta) {
    const {
      id,
      data: { handlerData, handler, file, ...data },
    } = payload;
    const endpoints = getFileHandlerEndpoints({ handlerData, handler });
    const requestHandlers = {
      confirm: requestSagaCreator(
        filesManagerActionTypes.actions.confirm,
        ({ data, file: f }, impersonate) => {
          return callApi({
            impersonate,
            schema: endpoints.confirm.schema,
            endpoint:
              typeof endpoints.confirm.url === 'function'
                ? endpoints.confirm.url(f)
                : endpoints.confirm.url,
            options: { method: endpoints.confirm.method, data },
          });
        }
      ),
      upload: requestSagaCreator(
        filesManagerActionTypes.actions.upload,
        ({ data: uploadData, file }, impersonate) => {
          return callApi({
            impersonate,
            camelize: false,
            endpoint:
              typeof endpoints.upload.url === 'function'
                ? endpoints.upload.url(file)
                : endpoints.upload.url,
            options: { method: endpoints.upload.method, data: uploadData },
          });
        }
      ),
    };

    const uploadAndConfirm = createS3FileUploader({
      uploadApiSaga: requestHandlers.upload,
      confirmApiSaga: requestHandlers.confirm,
      actions: filesManagerActionTypes.actions,
      types: filesManagerActionTypes.types,
    });

    try {
      yield call(uploadAndConfirm, {
        payload: {
          id,
          file,
          data,
          confirmPayload: handlerData?.confirmPayload,
        },
        meta,
      });
    } catch (error) {
      console.log(error);
    }
  },
  delete: function* handleFileDelete(payload, meta) {
    const {
      id,
      data: { handler, handlerData, ...data },
    } = payload;
    yield put(filesManagerActionTypes.actions.abort({ id }));
    const endpoints = getFileHandlerEndpoints({ handlerData, handler });

    const deleteHandler = requestSagaCreator(
      filesManagerActionTypes.actions.delete,
      (_, impersonate) => {
        return callApi({
          impersonate,
          endpoint: endpoints.delete.url,
          options: { method: endpoints.delete.method },
        });
      }
    );

    yield call(
      deleteHandler,
      {
        id,
        data,
      },
      meta
    );
  },
};
