import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ApplicationTheme, EntityModels } from 'imddata';
import {
  useCurrentCustomer,
  useCurrentUser,
  useUpdateEntity,
  useEntries,
  useSettings,
} from 'imddata';
import type { DropdownMenuBaseProps } from 'imdshared';
import {
  DropdownMenu,
  LanguageSelectorField,
  LanguageSwitcher,
  SelectField,
  EnabledField,
} from 'imdshared';
import type { WrappedFieldProps } from 'redux-form';
import { Field, FormSection, reduxForm } from 'redux-form';
import moment from 'moment';

type DateFormatProps = DropdownMenuBaseProps<
  { label: string; value: string },
  false
> & {
  valueOverride?: string;
  input: WrappedFieldProps['input'];
  meta: WrappedFieldProps['meta'];
};

const DateFormatSelector = (props: DateFormatProps) => {
  const { i18n } = useTranslation();

  const { entries } = useEntries<EntityModels.DateFormat>({
    entity: 'dateFormats',
  });

  const data = useMemo(() => {
    return entries.map(({ short, long, id }) => ({
      value: id,
      label: `${moment.utc('1973-10-20').format(long)} (${moment
        .utc('1973-10-20')
        .format(short)})`,
    }));
  }, [entries, i18n.language]);

  return <SelectField {...props} data={data} />;
};

const timezoneOptions = moment.tz
  .names()
  .reduce<{ offset: number; name: string }[]>((memo, tz) => {
    memo.push({
      name: tz,
      offset: moment.tz(tz).utcOffset(),
    });

    return memo;
  }, [])
  .sort((a, b) => {
    return a.offset - b.offset;
  })
  .reduce<{ label: string; value: string }[]>((memo, tz) => {
    const timezone = tz.offset ? moment.tz(tz.name).format('Z') : '';

    return [
      ...memo,
      {
        value: tz.name,
        label: `(GMT${timezone}) ${tz.name}`,
      },
    ];
  }, []);

type TimezoneSelectorProps = DropdownMenuBaseProps<
  { label: string; value: string },
  false
> & {
  valueOverride?: string;
  input: WrappedFieldProps['input'];
  meta: WrappedFieldProps['meta'];
};

type FormData = {
  user: {
    defaultLanguageId: string;
    dateFormatId: number;
    timezone: string;
    id: number;
  };
  customer: {
    cashCreditAutoDeduction: boolean;
  };
};

const TimezoneSelector = (props: TimezoneSelectorProps) => {
  return <SelectField {...props} data={timezoneOptions} />;
};

const SettingsForm = reduxForm<
  FormData,
  { customerId: number; userId: number }
>({ form: 'redux-form' })(({ userId, customerId }) => {
  const { t } = useTranslation();
  const { updateSettings } = useSettings();
  const { updateEntry: updateUser } = useUpdateEntity({ entity: 'users' });
  const { updateEntry: updateCustomer } = useUpdateEntity({
    entity: 'customers',
  });
  const themeOptions = useMemo<
    Array<{ value: ApplicationTheme; label: string }>
  >(
    () => [
      {
        label: t('system'),
        value: 'system',
      },
      {
        label: t('light'),
        value: 'light',
      },
      {
        label: t('dark'),
        value: 'dark',
      },
    ],
    [t]
  );
  return (
    <section>
      <FormSection component={Fragment} name="settings">
        <Field
          name="applicationTheme"
          label={t('application-theme')}
          data={themeOptions}
          onChange={(_e, value) => {
            updateSettings({ applicationTheme: value });
          }}
          // @ts-ignore
          component={SelectField}
        />
      </FormSection>
      <FormSection component={Fragment} name="user">
        <LanguageSwitcher
          // @ts-ignore
          label={t('ui-language')}
          component={DropdownMenu}
        />

        <Field
          name="timezone"
          floatingLabelText={t('user-timezone')}
          acceptValidation={false}
          component={TimezoneSelector}
          onChange={(e: any, value?: string) => {
            updateUser({ id: userId, data: { timezone: value } });
          }}
          style={{ marginBottom: 24 }}
        />

        <Field
          name="dateFormatId"
          floatingLabelText={t('date-format')}
          acceptValidation={false}
          component={DateFormatSelector}
          onChange={(e: any, value?: string) => {
            updateUser({ id: userId, data: { dateFormatId: value } });
          }}
          style={{ marginBottom: 24 }}
        />

        <Field
          name="defaultLanguageId"
          label={t('default-metadata-language')}
          acceptValidation={false}
          component={LanguageSelectorField}
          onChange={(e: any, value?: string) => {
            updateUser({
              id: userId,
              data: { defaultLanguageId: value },
            });
          }}
          style={{ marginBottom: 24 }}
        />
      </FormSection>
      <FormSection component={Fragment} name="customer">
        <Field
          name="cashCreditAutoDeduction"
          label={t('cash-credit-auto-deduction')}
          component={EnabledField}
          onChange={(e: any, value?: string) => {
            updateCustomer({
              id: customerId,
              data: { cashCreditAutoDeduction: value },
            });
          }}
        />
      </FormSection>
    </section>
  );
});
export const Settings = () => {
  const { entry: user } = useCurrentUser();
  const { entry: customer } = useCurrentCustomer();
  const { settings } = useSettings();

  const initialValues = useMemo<Partial<FormData>>(
    () =>
      user && customer
        ? {
          settings: {
            applicationTheme: settings.applicationTheme,
          },
          user: {
            id: user.id,
            timezone: user.timezone,
            dateFormatId: user.dateFormatId,
            defaultLanguageId: user.defaultLanguageId,
          },
          customer: {
            cashCreditAutoDeduction: customer.cashCreditAutoDeduction,
          },
        }
        : {},
    [user, customer]
  );

  if (!customer || !user) return null;

  return (
    <SettingsForm
      initialValues={initialValues}
      customerId={customer.id}
      userId={user.id}
    />
  );
};
