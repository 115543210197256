import { getSubscriptionCadence } from 'components';
import {
  useCurrentCustomer,
  useCurrentCustomerSubscription,
  useCustomerPrices,
} from 'imddata';
import { useSubscriptionUpsell } from 'imdshared';
import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';

type PromoType = 'free' | 'monthly' | 'none';

export const useSubsPromo = (): [boolean, MouseEventHandler, PromoType] => {
  const { entry: customer } = useCurrentCustomer();
  const prices = useCustomerPrices();
  const promotions = prices.data?.result?.promotions;
  const promoKeys = Object.values(promotions || {}).map((p) => p.id);

  const subscription = useCurrentCustomerSubscription();
  const { open } = useSubscriptionUpsell();

  const cadence = subscription
    ? getSubscriptionCadence(subscription.subscriptionId)
    : null;

  const futureCadence = subscription?.renewToId
    ? getSubscriptionCadence(subscription.renewToId)
    : null;

  let type: PromoType = 'none';

  if (customer) {
    if (promoKeys.length > 0) {
      type = 'free';
    }
    if (
      subscription &&
      cadence === 'monthly' &&
      promoKeys.includes('monthly-subs') &&
      (!futureCadence || futureCadence === 'monthly')
    ) {
      type = 'monthly';
    }
    if (promoKeys.includes('win-back-yearly')) {
      type = 'none';
    }
  }

  const handleClick = useCallback<MouseEventHandler>(() => {
    open({ analytics: { detail: 'promo-banner' } });
  }, []);

  return [type !== 'none', handleClick, type];
};
