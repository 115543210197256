import React, { useCallback } from 'react';
import { hooks, PageTemplate } from 'components';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import type { ErrorHandler, SuccessHandler } from './components/PaymentFlow';
import { PaymentFlow } from './components/PaymentFlow';
import { css } from '@emotion/react';

export default function PaymentGate() {
  // TODO: redirect on failed overview

  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const invoiceId = Number(params.id);
  hooks.tracking.useInvoicePaymentInitiated(invoiceId);
  const history = useHistory();

  const handleComplete = useCallback<SuccessHandler>((id) => {
    history.push('/order/complete', { invoiceId: id });
  }, []);

  const handleError = useCallback<ErrorHandler>((type, id, message) => {
    if (type === 'unpayable') {
      history.push('/account/invoices/' + id);
    }
    if (type === 'failed_payment') {
      history.push('/order/failed', {
        invoiceId: id,
        message,
      });
    }
  }, []);

  /* eslint-disable react/no-array-index-key */

  return (
    <>
      <Helmet>
        <title>{t('page-title-payment')}</title>
      </Helmet>

      <PageTemplate title={t('secure-checkout')}>
        <PaymentFlow
          css={css`
            max-width: 100%;
            margin-top: 32px;
            align-self: flex-start;
            justify-self: flex-start;
          `}
          id={invoiceId}
          entity="invoices"
          onError={handleError}
          onPayment={(products) => {
            if (window && window.analytics) {
              window.analytics.track('FT Clicked pay basket checkout', {
                products,
              });
            }
          }}
          onComplete={handleComplete}
          terms={
            <Trans
              i18nKey="product-terms-and-conditions"
              components={[
                <a
                  key={1}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t('terms-link-for-order') || '#'}
                >
                  terms
                </a>,
              ]}
              defaults={
                'Paying this order you are accepting <0>terms and conditions</0> for products'
              }
            />
          }
        />
      </PageTemplate>
    </>
  );
}
