//
import React, { useCallback } from 'react';
import {
  useEntryProvider,
  useCurrentCustomerHasAddress,
  useStartDownload,
} from 'imddata';
import { Button, Icons, IconButton } from 'imdui';

const DownloadEntityButton = ({
  onRequestDownload,
  loading,
  type = 'icon',
  icon,
  text,
  disabled,
}) => {
  if (type === 'icon') {
    return (
      <IconButton
        disabled={disabled || loading}
        showLoading={loading}
        onClick={onRequestDownload}
        icon={Icons.actions.download}
      />
    );
  }

  return (
    <Button
      disabled={disabled || loading}
      showLoading={loading}
      text={text}
      icon={icon}
      onClick={onRequestDownload}
    />
  );
};

export const DownloadInvoiceButton = ({ id, text, type }) => {
  const invoice = useEntryProvider({ entity: 'invoices', id });

  const hasAddressSet = useCurrentCustomerHasAddress();
  const {
    startDownload,
    request: { loading },
  } = useStartDownload({ entity: 'invoices', id });
  const onClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      startDownload({ invoiceId: id });
    },
    [startDownload, id]
  );

  if (!invoice || invoice.status !== 'paid') return null;

  return (
    <DownloadEntityButton
      text={text}
      type={type}
      icon={Icons.categories.invoice}
      loading={loading}
      onRequestDownload={onClick}
      disabled={!hasAddressSet}
    />
  );
};

export const DownloadPayoutReceiptButton = ({ id, text, type }) => {
  const payout = useEntryProvider({ entity: 'payouts', id });
  const hasAddressSet = useCurrentCustomerHasAddress();
  const {
    startDownload,
    request: { loading },
  } = useStartDownload({ entity: 'payout_receipt', id });
  const onClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      startDownload({ payoutId: id });
    },
    [startDownload, id]
  );

  return (
    <DownloadEntityButton
      text={text}
      type={type}
      loading={loading}
      onRequestDownload={onClick}
      disabled={payout?.status !== 'paid' || !hasAddressSet}
    />
  );
};
