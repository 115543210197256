import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Content, Caption, LoadingArtwork } from 'imdshared';
import { getTranslatableName } from 'imddata/selectors';
import BundleStatusButton from '../../../BundleStatusButton';
import type { ReleasesNested } from 'imddata';
import {
  StyledCell,
  smallBundleStatusButtonStyle,
  linkStyle,
  HoverOverlay,
} from './styles';

type Props = {
  item?: ReleasesNested;
};

const ArtworkHolder = styled.div`
  position: relative;
`;

const GridItem: React.FC<Props> = ({ item }) => {
  const title = item ? getTranslatableName(item, '', 'title') : '';
  const displayArtist = item
    ? getTranslatableName(item, '', 'displayArtist')
    : '';

  const isTitleEmpty = !title;
  const hasCurrentDeliveryBundle = !!item?.currentDeliveryBundleId;

  const isFieldUpdateRequested = !!item?.states?.updateRequest?.find(
    (s) => s.status === 'requested'
  );

  return (
    <StyledCell>
      <Link
        onClick={(event) => {
          if (!item) {
            event.preventDefault();
          }
        }}
        to={`/library/releases/${item?.id}`}
        css={linkStyle}
      >
        <ArtworkHolder>
          <LoadingArtwork
            releaseId={item?.id}
            hideStatusText={true}
            shadow="medium"
          />
          <HoverOverlay />
        </ArtworkHolder>

        <Content empty={isTitleEmpty}>
          {title}
          {item?.version ? ` (${item.version})` : ''}
        </Content>

        <Caption>{displayArtist}</Caption>
      </Link>

      {hasCurrentDeliveryBundle && item && (
        <BundleStatusButton
          css={smallBundleStatusButtonStyle}
          status={item.currentDeliveryBundleStatus}
          deliveryStatus={item.deliveryStatus}
          isFeedbackRequired={isFieldUpdateRequested}
        />
      )}
    </StyledCell>
  );
};

export default GridItem;
