import styled from '@emotion/styled';

export const Title = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;

  text-align: center;
  letter-spacing: -0.02em;

  color: rgba(0, 0, 0, 0.8);
`;

export const Divider = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const Container = styled.div`
  margin-top: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
