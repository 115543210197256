import styled from '@emotion/styled';
import { Level, LevelBadge } from './LevelBadge';
import { ApplicationSettingsContext } from 'imdshared';
import { useContext } from 'react';

const LevelWrapper = styled.div<{ levelCount: number }>`
  width: 100%;
  display: grid;
  gap: 4px;
  grid-template-columns: 24px repeat(${({ levelCount }) => levelCount - 1}, 1fr);
  text-align: right;
`;

const Progress = styled.div`
  display: flex;
  height: 2px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  gap: 10px;
  flex: 1;
  flex: 1 0 0;
  background: var(--state-active-on-surface, rgba(27, 28, 27, 0.08));
`;

const ActiveProgress = styled(Progress)<{ value: number }>`
  width: ${({ value }) => value}%;
  background: var(--accent);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
`;

const TopRow = styled.div`
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
`;

const LevelSection = styled.div`
  display: grid;
  gap: 8px;

  & > ${Level} {
    margin-left: auto;
    width: fit-content;
    min-width: 24px;
    text-align: center;
    color: var(--accent);
  }
`;

export const LevelProgress = ({
  levels,
  value,
}: {
  levels: Array<number>;
  value: number;
}) => {
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const formatter = new Intl.NumberFormat(numberFormatLocale);
  return (
    <LevelWrapper levelCount={levels.length}>
      {levels.map((level, index) => (
        <LevelSection key={level}>
          <TopRow>
            {index > 0 && (
              <Progress>
                <ActiveProgress
                  value={
                    value >= level
                      ? 100
                      : levels[index - 1] > value
                        ? 0
                        : (value / level) * 100
                  }
                />
              </Progress>
            )}
            <LevelBadge value={index + 1} active={value >= level} />
          </TopRow>
          <Level>{formatter.format(level)}</Level>
        </LevelSection>
      ))}
    </LevelWrapper>
  );
};
