import React from 'react';
import { Content, BodySmall } from 'imdui';
import styled from '@emotion/styled';

const LegendDot = ({
  fill = '#333',
  className,
  style,
  r = 5,
}: {
  fill?: string;
  className?: string;
  style?: React.CSSProperties;
  r?: number;
}) => {
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r={r} fill={fill} />
    </svg>
  );
};

const LegendDash = ({
  fill = '#333',
  className,
  style,
}: {
  fill?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 12H17"
        stroke={fill}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export type LegendType = 'circle' | 'dot' | 'line';

const LegendIconBase = ({
  fill,
  type,
  className,
  style,
}: {
  type?: LegendType;
  fill?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  if (type === 'circle') {
    return <LegendDot fill={fill} className={className} style={style} />;
  }
  if (type === 'dot') {
    return <LegendDot fill={fill} className={className} style={style} r={2} />;
  }
  if (type === 'line') {
    return <LegendDash fill={fill} className={className} style={style} />;
  }
  return null;
};

export const LegendIcon = styled(LegendIconBase)`
  display: inline-block;
  margin-left: 4px;
`;

const LegendItemStyled = styled.span`
  display: flex;
  align-items: center;
  & > ${LegendIcon} {
    margin-right: 8px;
  }
`;

export const LegendItem = ({
  label,
  selected = false,
  type = 'circle',
  fill,
}: {
  type?: LegendType;
  fill?: string;
  label?: string;
  selected?: boolean;
}) => {
  return (
    <LegendItemStyled>
      <LegendIcon type={type} fill={fill} />
      {selected ? <Content>{label}</Content> : <BodySmall>{label}</BodySmall>}
    </LegendItemStyled>
  );
};
