import styled from '@emotion/styled';
import { Content } from '../../../text';

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  white-space: pre;
  flex-wrap: wrap;
  min-height: 56px;
  overflow: hidden;
  gap: 8px;
  padding: 8px;
  justify-content: center;

  background-color: var(--on-surface-semi);
  border-radius: 50vh;
 }

  * {
    user-select: none;
  }
`;

export const ItemWrapper = styled.div<{
  selected: boolean;
}>`
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  ${({ selected }) =>
    `

  padding: 0 16px;
  gap: 8px;
  word-break: none;
  background-color: ${selected ? 'var(--on-surface-semi, #FFF)' : 'none'};
  border-radius: 50vh;

  ${Content} {
    color: ${selected ? 'var(--on-surface)' : 'var(--on-surface-var)'};
  }

`}

  :last-child {
    margin-right: 4px;
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50vh;
    transition: all 0.1s ease-in-out;
  }

  :hover::before,
  :focus::before {
    background: ${({ selected }) => (selected ? 'none' : 'rgba(0, 0, 0, 0.1)')};
  }
`;
