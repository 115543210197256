import styled from '@emotion/styled';
import { Card, Content } from 'imdui';
import React from 'react';

const StatusContainer = styled(Card)`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 420px;
  margin: 64px auto 32px auto;
  gap: 12px;
`;
export const PaymentStatusMessage = ({
  message,
  success,
}: {
  message: React.ReactNode;
  success: boolean;
}) => {
  return (
    <StatusContainer>
      {success ? (
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9571 10.2929C24.3476 10.6834 24.3476 11.3166 23.9571 11.7071L13.9571 21.7071C13.5666 22.0976 12.9334 22.0976 12.5429 21.7071L8.04289 17.2071C7.65237 16.8166 7.65237 16.1834 8.04289 15.7929C8.43342 15.4024 9.06658 15.4024 9.45711 15.7929L13.25 19.5858L22.5429 10.2929C22.9334 9.90237 23.5666 9.90237 23.9571 10.2929Z"
            fill="var(--accent)"
          />
          <rect
            x="1"
            y="1"
            width="30"
            height="30"
            rx="15"
            stroke="var(--accent)"
            strokeWidth="2"
          />
        </svg>
      ) : (
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2929 20.7929C9.90237 21.1834 9.90237 21.8166 10.2929 22.2071C10.6834 22.5976 11.3166 22.5976 11.7071 22.2071L10.2929 20.7929ZM22.7071 11.2071C23.0976 10.8166 23.0976 10.1834 22.7071 9.79289C22.3166 9.40237 21.6834 9.40237 21.2929 9.79289L22.7071 11.2071ZM21.2929 22.2071C21.6834 22.5976 22.3166 22.5976 22.7071 22.2071C23.0976 21.8166 23.0976 21.1834 22.7071 20.7929L21.2929 22.2071ZM11.7071 9.79289C11.3166 9.40237 10.6834 9.40237 10.2929 9.79289C9.90237 10.1834 9.90237 10.8166 10.2929 11.2071L11.7071 9.79289ZM11.7071 22.2071L22.7071 11.2071L21.2929 9.79289L10.2929 20.7929L11.7071 22.2071ZM22.7071 20.7929L11.7071 9.79289L10.2929 11.2071L21.2929 22.2071L22.7071 20.7929Z"
            fill="var(--error-error)"
          />
          <rect
            x="1.5"
            y="1"
            width="30"
            height="30"
            rx="15"
            stroke="var(--error-error)"
            strokeWidth="2"
          />
        </svg>
      )}
      <Content>{message}</Content>
    </StatusContainer>
  );
};
