//
import io from 'socket.io-client';
import config from 'imdconfig';

export const channels = {
  general: 'general',
  admins: 'general_admins',
};

export default function connect(channel = channels.admins) {
  const socket = io(config.socketUrl, {
    path: config.socketPath,
    withCredentials: true,
    upgrade: false,
    transports: ['websocket'],
    protocol: 'https',
  });

  return new Promise((resolve) => {
    socket.on('connect', () => {
      socket.emit(
        'subscribe-user',
        {
          user: {
            broadcastChannel: channel,
          },
        },
        () => {
          // callback
        }
      );
      resolve(socket);
    });
  });
}
