import React, { useMemo, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Block,
  Caption,
  Body,
  Subheading,
  SVGIcon,
  Icons,
  palette,
} from 'imdshared';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  useQuery,
  useBundle,
  defaultBundleQuery,
  useBundles,
  useDeleteEntity,
} from 'imddata';
import moment from 'moment';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const ActionsWrapper = styled.div`
  padding: 8px 0 8px 40px;
  & > button {
    width: 100%;
  }

  & > button:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const LinkStyled = styled(Link)``;

const SVGIconStyled = styled(SVGIcon)`
  margin: 5px 20px 0 0;
  flex-shrink: 0;
`;

const ExpandDivider = styled(Caption)`
  margin: 0 5px;
`;

const DateCaption = styled(Caption)`
  margin-left: auto;
`;

const Footer = styled.div`
  display: flex;
  margin-left: 40px;

  ${DateCaption}, ${ExpandDivider}, ${Caption} {
    color: #666;
  }
`;

const NotificationCardContainer = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px;
  ${Subheading},
  ${LinkStyled}
  ${Body} {
    flex: 1;
    overflow: hidden;
  }

  ${({ onClick }) =>
    onClick &&
    `
  cursor: pointer;
  &:hover {
  background-color: rgba(0, 0, 0, 0.05);
  }
  &:active {
  background-color: rgba(0, 0, 0, 0.1);

  }
  `}
`;
const NotificationBody = styled.div`
  margin: 20px 0 10px 40px;
  word-break: break-word;
`;

const Heading = styled.div`
  display: flex;
  flex: 1;

  ${Subheading} {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const bodyStyle = css`
  margin: 12px 0 10px 0px;
  word-break: break-word;
`;

type Props = {
  id: string | number;
  title: string;
  body: string;
  data?: Record<string, unknown>;
  date: string;
  wasRead: boolean;
  isExpanded?: boolean;
  isImportant: boolean;
  isPinned: boolean;
  type: string;
  onClick?: (
    e: React.MouseEvent,
    d: { id: string | number; hasDetailView: boolean }
  ) => void;
};

const SHORT_NOTIFICATION_BODY_LENGTH = 130;

const FinishUpdateLink = () => {
  const { t } = useTranslation();
  return <Link to="/order/update">{t('review-changes')}</Link>;
};

const DismissDeliveryButton = ({ id }: { id: number }) => {
  const { t } = useTranslation();
  const {
    deleteEntry,
    request: { deleting, deleted },
  } = useDeleteEntity({ entity: 'deliveryBundles', id });
  const text = deleted ? t('removed') : deleting ? t('removing') : t('remove');
  return (
    <button type="button" onClick={() => deleteEntry()}>
      {text}
    </button>
  );
};

const FinishDeliveryEntry = ({ id }: { id: number }) => {
  const { entry } = useBundle({ id, passive: true });
  const { t } = useTranslation();
  if (!entry) return null;
  if (
    entry.type === 'releases' &&
    entry.releases &&
    entry.releases[0] &&
    entry.releases[0].release
  ) {
    const { title, tracksCount } = entry.releases[0].release;
    return (
      <div>
        {title || t('untitled')}{' '}
        {`(${tracksCount} ${t('track', {
          count: tracksCount,
        })})`}{' '}
        <Link to={`/order/md/${id}`}>{t('continue')}</Link>{' '}
        <DismissDeliveryButton id={id} />
      </div>
    );
  }
  if (entry.type === 'tracks') {
    // const tracksCount = entry.tracks?.length || 0;
    return null;
    // <div>
    //   {t('ycid')}{' '}
    //   {`(${tracksCount} ${t('track', {
    //     count: tracksCount,
    //   })})`}{' '}
    //   <Link to={`/order/ycid/${id}`}>{t('continue')}</Link>{' '}
    //   <DismissDeliveryButton id={id} />
    // </div>
  }
  return null;
};

const FinishMasteringEntry = ({ id }: { id: number }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Link to={`/order/mastering/${id}`}>{t('continue')}</Link>
    </div>
  );
};

const FetchDeliveryBundles = <D extends { deliveryBundleId?: number[] }>({
  data,
}: {
  data?: D;
}) => {
  const query = useMemo(
    () => ({
      ...defaultBundleQuery,
      'filter.ids': data?.deliveryBundleId?.join(','),
    }),
    [data]
  );
  const { queryHash } = useQuery({ query });
  useBundles({ query, queryHash });
  return null;
};

const UnpaidInvoiceEntry = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Link to="/account/invoices">{t('click-here-to-view')}</Link>
    </div>
  );
};

const renderActions = ({
  type,
  data,
}: {
  type: string;
  data?: Record<string, unknown>;
}) => {
  switch (type) {
    case 'unpaid-invoice': {
      return <UnpaidInvoiceEntry />;
    }
    case 'delivery-bundle-outdated-resource': {
      return <FinishUpdateLink />;
    }
    case 'instant-mastering-previews-done': {
      return <FinishMasteringEntry id={data?.instantMasteringId as number} />;
    }
    case 'finish-delivery': {
      return (
        <>
          <FetchDeliveryBundles data={data} />
          {data?.deliveryBundleId &&
            // @ts-ignore
            data.deliveryBundleId.map((id) => (
              <FinishDeliveryEntry key={id} id={id} />
            ))}
        </>
      );
    }
    default:
      return null;
  }
};

const renderBody = ({
  t,
  data,
  finalText,
  type,
}: {
  t: TFunction;
  data?: Record<string, unknown>;
  finalText: string;
  type: string;
}) => {
  switch (type) {
    case 'download-ready': {
      return (
        <LinkStyled to="/downloads" css={bodyStyle}>
          {t('click-here-to-view')}
        </LinkStyled>
      );
    }
    case 'delivery-bundle-accepted': {
      if (!data) return null;
      const count = (
        data[
          data.deliveryBundleType === 'releases' ? 'releaseIds' : 'trackIds'
        ] as unknown[] | undefined
      )?.length;
      const to =
        data.deliveryBundleType === 'releases'
          ? // @ts-ignore
            `/library/releases/${data.releaseIds[0]}`
          : '/library/tracks';
      return (
        <Body css={bodyStyle}>
          {t('delivery-bundle-accepted-body', {
            ...data,
            releaseTitle:
              'releaseTitle' in data && Array.isArray(data.releaseTitle)
                ? data.releaseTitle?.[0]
                : '',
            context: data.deliveryBundleType,
            count,
          })}

          <br />
          <Link to={to}>{t('click-here-to-view')}</Link>
        </Body>
      );
    }
    case 'release-page-generated': {
      if (!data) return null;
      return (
        <Body css={bodyStyle}>
          {t('release-page-ready', { title: data.title })}
          <br />
          <a target="_blank" rel="noreferrer" href={data.url as string}>
            {t('click-here-to-view')}
          </a>
        </Body>
      );
    }
    case 'customer-subscription-renewal-upcoming-and-missing-payment-method': {
      return (
        <Body css={bodyStyle}>
          {t(
            'customer-subscription-renewal-upcoming-and-missing-payment-method-body'
          )}
          <br />
          <Link to="/account/payment-methods">{t('click-here-to-view')}</Link>
        </Body>
      );
    }
    case 'pro-unlimited-downgrade-notification': {
      return (
        <Body css={bodyStyle}>
          {finalText} <br />
          <Link to="/account">{t('cancel-downgrade')}</Link>
        </Body>
      );
    }
    case 'pro-unlimited-ended-notification': {
      return (
        <Body css={bodyStyle}>
          {finalText} <br />
          <Link to="/account?openSubscribe=true">{t('resubscribe-now')}</Link>
        </Body>
      );
    }
    default:
      return <Body css={bodyStyle}>{finalText}</Body>;
  }
};
const getTitle = ({
  t,
  data,
  type,
  title,
}: {
  t: TFunction;
  type: string;
  data: Record<string, any>;
  title: string;
}) => {
  switch (type) {
    case 'delivery-bundle-accepted': {
      if (!data) return null;
      const count =
        // TODO: add typedef for notifications
        // @ts-ignore
        data[data.deliveryBundleType === 'releases' ? 'releaseIds' : 'trackIds']
          ?.length;
      return t('delivery-bundle-accepted-title', {
        context: data.deliveryBundleType,
        count,
      });
    }
    default:
      return t(title);
  }
};
const NotificationCard = ({
  id,
  title,
  body: bodyText = '',
  date,
  wasRead,
  isExpanded = false,
  isImportant,
  data = {},
  isPinned,
  type,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  // TODO: Figure out better way to pass translated parameters
  const body = t(bodyText, {
    ...(data || {}),
    subscription: t(data?.subscriptionId as string),
  });
  const shouldCut = body && body.length > SHORT_NOTIFICATION_BODY_LENGTH;

  const actions = renderActions({ data, type });

  const hasDetailView = actions || shouldCut;

  const finalText =
    !isExpanded && shouldCut
      ? body.slice(0, SHORT_NOTIFICATION_BODY_LENGTH).trim().concat('...')
      : body;

  const notificationBody = renderBody({ finalText, type, data, t });
  const finalTitle = getTitle({ t, data, type, title });

  return (
    <NotificationCardContainer
      onClick={
        onClick
          ? (e) => {
              onClick(e, { id, hasDetailView: !!hasDetailView });
            }
          : undefined
      }
    >
      <Block width="100%">
        <Heading>
          {isImportant && (
            <SVGIconStyled
              d={Icons.states.error}
              color={wasRead ? palette.light.xDark : palette.accent}
            />
          )}

          {!isImportant && isPinned && <Block padding={[0, 40, 0, 0]} />}

          {!isImportant && !isPinned && (
            <SVGIconStyled
              d={Icons.categories.dotFat}
              color={wasRead ? palette.light.xDark : palette.main.normal}
            />
          )}

          <Subheading>{finalTitle}</Subheading>
        </Heading>

        <NotificationBody>{notificationBody}</NotificationBody>

        {isExpanded && actions && <ActionsWrapper>{actions}</ActionsWrapper>}

        <Footer>
          {!isExpanded && hasDetailView && <Caption>{t('open')}</Caption>}

          {!isExpanded && !hasDetailView && !wasRead && (
            <Caption>{t('mark-as-read')}</Caption>
          )}

          {isImportant && (
            <>
              {!isExpanded && <ExpandDivider>•</ExpandDivider>}

              <Caption
                style={{
                  color: wasRead ? palette.dark.xLight : palette.accent,
                }}
              >
                {t('requires-action')}
              </Caption>
            </>
          )}

          <DateCaption>{moment(date).format('LLL')}</DateCaption>
        </Footer>
      </Block>
    </NotificationCardContainer>
  );
};

export default NotificationCard;
