/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { Theme } from '@emotion/react';
import styled from '@emotion/styled';

type Tag = keyof JSX.IntrinsicElements;

// TODO: Unify backgrounds with FieldCard
const getStyles = ({
  disabled,
  status,
  focused,
}: {
  status?: 'error' | 'warning';
  disabled?: boolean;
  focused?: boolean; // For SecuredFormField
  theme: Theme;
}) => {
  return `
  position: relative;
  border-radius: 8px;
    color: var(--on-surface);
  min-width: 0px;
  min-height: 48px;
  padding: 12px 16px;
  ${(() => {
      switch (status) {
        case 'error':
          return `
  box-shadow: inset 0 0 0 1px var(--error, #F00);
  background: var(--error-container);
  `;
        case 'warning':
          return `
  box-shadow: inset 0 0 0 1px var(--accent-orange, #F00);
background: var(--surface-container-medium, #F4F4F4);
&:before {
content: '';
position: absolute;
width: 100%;
height: 100%;
z-index: 1;
opacity: 0.03;
pointer-events: none;
background: var(--accent-orange, #FB9013);
}
  `;
        default:
          return `
  background: var(--bg-3);
  box-shadow: inset 0 0 0 1px var(--fg-4, #f4f4f4);`;
      }
    })()}
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  resize: vertical;
  overflow-y: scroll;
  transition: box-shadow 0.1s ease-in-out;


${disabled
      ? `
          opacity: 0.4;
          pointer-events: none;
          user-select: none;
          cursor: default;`
      : ''
    }



  ${focused
      ? `
    box-shadow: inset 0 0 0 1px var(--fg-3, #f4f4f4);
  `
      : ''
    }


  :focus {
    background-color: var(--bg-4);
    box-shadow: inset 0 0 0 1px var(--fg-3, #f4f4f4);

  }

  ::placeholder {
    font-weight: 400;
    color: var(--on-surface);
    opacity: 0.5;
  }

  ::-webkit-resizer {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' stroke-width='1.5'%3E%3Cpath d='M3.353 10.425l7.071-7.071M7.95 11.485l3.536-3.535'/%3E%3C/g%3E%3C/svg%3E");
  }

  `;
};

// TODO: Fix this to move base input styles from component definition?
const createDomInputWithStyles = (
  inputComponent: 'div' | 'input' = 'input'
) => styled(inputComponent) <{
  as?: Tag;
  // TODO remove as & rows and add textarea comp def
  rows?: number;
  focused?: boolean; // For SecuredFormField
  status?: 'error' | 'warning';
  disabled?: boolean;
}>`
  ${({ theme, status, disabled, focused }) =>
      getStyles({ theme, disabled, status, focused })}
`;

const createComponentInputWithStyles = <
  C extends React.ComponentClass<React.ComponentProps<C>>,
>(
  inputComponent: C
) => styled(inputComponent) <{
  as?: Tag;
  rows?: number;
  focused?: boolean; // For SecuredFormField
  status?: 'error' | 'warning';
  disabled?: boolean;
}>`
  ${({ theme, status, focused, disabled }) =>
      getStyles({ theme, status, disabled, focused })}
`;

const createInputWithStyles = <
  C extends React.ComponentClass<React.ComponentProps<C>>,
>(
  component?: C | 'div' | 'input'
) => {
  if (typeof component === 'object' || typeof component === 'function')
    return createComponentInputWithStyles(component);

  return createDomInputWithStyles(component);
};

export default createInputWithStyles;
