//
import { actionTypes as userActionTypes } from './users';

export const INVALIDATE_AUTH = '@auth/INVALIDATE';

export const actionTypes = {
  signOut: {
    SUCCESS: '@auth/SIGNOUT_SUCCESS',
  },
  optimizelyUpdate: '@auth/OPTIMIZELY_UPDATE',
};

export const constants = {
  LOGGED_USER_SUCCESS: userActionTypes.loggedUser.fetch.SUCCESS,
  LOGGED_USER_REQUEST: userActionTypes.loggedUser.fetch.REQUEST,
  LOGGED_USER_FAILURE: userActionTypes.loggedUser.fetch.FAILURE,
  RESET_LOGGED_USER: actionTypes.signOut.SUCCESS,
};
