//
import React from 'react';
import styled from '@emotion/styled';
import { ImusicianIcon as AppIcon, ChevronLeftIcon, H1 } from '@imus/base-ui';
import { ToastContainer } from 'react-toastify';
import ToastStyles from './ToastStyles';
import { css } from '@emotion/react';
import { BackgroundA, BackgroundB } from './Background';
import { Button } from '../button';

export const SimpleLayoutContentWrapper = styled.div`
  width: 100%;
  gap: 48px;
  display: flex;
  flex-direction: column;
  max-width: 320px;
`;

const StyledCard = styled.div`
  position: relative;
  max-width: 480px;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px;
  align-items: center;
  gap: 48px;

  @media (min-width: 640px) {
    padding: 48px;
    margin: 32px auto 32px auto;

    overflow: hidden;
    box-sizing: border-box;

    border-radius: 20px;
    background: var(--surface-container-medium);

    /* Shadow/Card */
    box-shadow:
      0px 2px 6px -2px rgba(0, 0, 0, 0.05),
      0 0 0 1px var(--outline-var);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0 48px;
  ${H1} {
    line-height: 32px;
  }
`;

export const SimpleLayoutFooter = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;
  & > div {
    justify-content: center;
    display: flex;
    width: 100%;
    text-align: center;
  }
`;
const TopBar = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  padding: 0px 32px;
  justify-content: center;
  align-items: center;
  gap: 64px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--outline-var, #cdcbc9);
  border-bottom: 1px solid
    var(--outline-var, color(display-p3 0.8039 0.7961 0.7882));
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SimpleLayout = ({
  children,
  title,
  size = 'narrow',
  topBarChildren = <AppIcon />,
  onClickBack,
  className,
  style,
}: {
  size?: 'wide' | 'narrow';
  onClickBack?: () => void;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  topBarChildren?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Container className={className} style={style}>
      <TopBar>{topBarChildren}</TopBar>
      <ToastStyles />
      <StyledCard
        style={{
          maxWidth: size === 'wide' ? 640 : 560,
        }}
      >
        {onClickBack && (
          <Button
            css={css`
              position: absolute;
              left: 24px;
              top: 24px;
              @media (min-width: 640px) {
                left: 48px;
                top: 48px;
              }
            `}
            icon={ChevronLeftIcon}
            onClick={onClickBack}
            appearance="fill"
          />
        )}
        {title && (
          <Header>
            <H1>{title}</H1>
          </Header>
        )}

        <SimpleLayoutContentWrapper>{children}</SimpleLayoutContentWrapper>
      </StyledCard>
      <ToastContainer />

      {size === 'narrow' ? <BackgroundA /> : <BackgroundB />}
    </Container>
  );
};
