//
import React, { useMemo, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Body, Button, TextFormatted } from 'imdshared';
import { useOverview } from 'imddata';
import { Card, LoadingIndicator } from 'imdui';
import { ProductScreen, ProductPriceContext } from '../../shared';
import UnitsNumber from './components/UnitsNumber';
import { useInitFastPayment } from 'components/hooks';
import { ShoppingCartIcon } from '@imus/base-ui';

const Description = styled(Body)`
  display: block;
  padding: 0px 0 32px 0;
`;

const MultipleOrdersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 auto;
`;

const StyledOrderButton = styled(Button)``;

const ProductCard = styled(Card)`
  display: flex;
  flex-direction: column;
`;

export default function ProductView() {
  const history = useHistory();
  const { productId } = useParams<{ productId: string }>();
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(1);
  const { loaded, product, init } = useInitFastPayment({
    productId,
    successPath: '/account/invoices',
  });
  const data = useMemo(
    () => ({
      quantity,
    }),
    [quantity]
  );

  const {
    overview,
    refresh,
    request: { loading: loadingOverview },
  } = useOverview({
    entity: 'productsOverview',
    id: productId,
    data,
  });

  const priceContext = useMemo(
    () =>
      overview
        ? {
          recalculate: refresh,
          price: overview.subTotal,
          priceFormat: overview.currency.format,
          priceCurrency: overview.currency.id,
          calculating: !!loadingOverview,
        }
        : {
          recalculate: refresh,
          calculating: !!loadingOverview,
        },
    [overview]
  );
  useEffect(() => {
    if (!product && loaded) {
      history.push('/dashboard');
    }
  }, [!!product, loaded]);

  if (!product) {
    return <LoadingIndicator />;
  }
  const { name, allowMultipleItemsInOrder } = product;
  return (
    <ProductPriceContext.Provider value={priceContext}>
      <ProductScreen title={name}>
        <ProductCard
          style={{ padding: '24px', margin: '24px 0', maxWidth: '640px' }}
        >
          <Description>
            <TextFormatted text={t(`product-description-${productId}`)} />
          </Description>

          <MultipleOrdersWrapper>
            {allowMultipleItemsInOrder && (
              <UnitsNumber
                value={quantity}
                onChange={(event) => {
                  const { value } = event.target;
                  const re = /^[0-9\b]+$/;
                  if (re.test(value)) {
                    if (parseInt(value, 10) < 1) {
                      setQuantity(1);
                      return;
                    }
                    setQuantity(Number(value));
                    return;
                  }
                  setQuantity(1);
                }}
              />
            )}

            <StyledOrderButton
              text={t('add-to-cart')}
              icon={ShoppingCartIcon}
              size="medium"
              primary="paid"
              onClick={() => {
                init({
                  quantity,
                });
              }}
              disabled={!quantity}
            />
          </MultipleOrdersWrapper>
        </ProductCard>
      </ProductScreen>
    </ProductPriceContext.Provider>
  );
}
