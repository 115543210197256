import styled from '@emotion/styled';
import MilestoneImage from './milestone-indicator.svg';
import MilestoneRankBackground from './milestone-rank-bg.svg';
import { LockIcon } from '@imus/base-ui';
import { css } from '@emotion/react';
import { LevelBadge } from '../LevelBadge';

const inactiveLockStyle = css`
  border-radius: 32px;
  background: var(--state-active-on-surface, rgba(27, 28, 27, 0.08));
  --on-surface: var(--on-surface-var-2);
`;

const LockWrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  background: var(--state-active-accent, rgba(0, 81, 103, 0.1));
  background: var(
    --state-active-accent,
    color(display-p3 0 0.302 0.3882 / 0.1)
  );

  --on-surface: var(--accent);
`;

export const MilestoneIndicator = ({ size = 64 }: { size?: number }) => {
  return <MilestoneImage width={size} height={size} />;
};
export const MilestoneLevel = ({
  value,
  locked,
  size = 64,
}: {
  value: number;
  size?: number;
  locked?: boolean;
}) => {
  if (locked) {
    return (
      <LockWrapper
        style={{
          width: size,
          height: size,
        }}
        css={inactiveLockStyle}
      >
        <LockIcon
          style={{
            width: size / 2,
            height: size / 2,
          }}
        />
      </LockWrapper>
    );
  }
  return (
    <LockWrapper
      style={{
        width: size,
        height: size,
      }}
    >
      <MilestoneRankBackground style={{ position: 'absolute' }} />
      <LevelBadge
        style={{ marginBottom: '-3px' }}
        active={true}
        value={value}
      />
    </LockWrapper>
  );
};
