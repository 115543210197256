import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import type { EntityModels, ReduxState } from 'imddata';
import {
  ProcessFieldMeta,
  EntityFormContext,
  useQuery,
  useDeleteEntity,
  useUpdateEntity,
} from 'imddata';
import { actions } from 'imddata/actionTypes/releases';
import { useSelector, useDispatch } from 'react-redux';
import type { WrappedFieldProps, InjectedFormProps } from 'redux-form';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  Caption,
  Button,
  HelpWindowContext,
  HelperText,
  OverlineText,
  Card,
} from 'imdui';
import memoize from 'memoize-one';
import { useFormDisplayArtist } from '../../ArtistJoin';
import ConfirmationWindow from '../../ConfirmationWindow';
import ArtworkEditor from '../../ArtworkEditor';
import {
  useFieldUpdatable,
  FieldUpdatable,
  NewInputField,
} from '../../../fields';
import LoadingArtwork from '../../LoadingArtwork';
import { ReleaseFormContext } from '../ReleaseFormContext';
import { fieldStyle, artworkSection } from '../styles';
import { ProductTwoColumnsWrapper } from '../../ProductTwoColumnsWrapper';

/*
 * TODO:
 * 1: Better support for Various Artist display
 * 2: Return dimensions validation
 *
      const isDimensionsOk =
        img.naturalWidth >= minCoverWidth &&
        img.naturalHeight >= minCoverHeight;

      const error = isDimensionsOk
        ? null
        : this.props.t('image-dimensions-not-met');
 */

const CoverOverlineText = ({
  meta,
  input,
  required,
  ...props
}: WrappedFieldProps & { required: boolean } & React.ComponentProps<
  typeof OverlineText
>) => {
  const { t } = useTranslation();
  return (
    <OverlineText
      error={required && meta.touched && t(meta.error)}
      {...props}
    />
  );
};

const CoverHelperText = ({
  meta,
  input,
  ...props
}: WrappedFieldProps & {
  components?: { OverlineActions?: React.FC };
} & React.ComponentProps<typeof HelperText>) => (
  <>
    <ProcessFieldMeta
      hasValue={input && !!input.value}
      meta={{
        ...meta,
        error: meta.error,
      }}
    >
      {({ error, warning }) => (
        <HelperText warningText={warning} errorText={error} {...props} />
      )}
    </ProcessFieldMeta>
  </>
);

export type Props = {
  showCoverRequirementText?: boolean;
  hideCoverUploader?: boolean;
  alwaysShowCoverFields?: boolean;
  fieldsComponent: React.ReactNode;
  artworkCopyrightFields: boolean;
  requiredFields?: Array<string>;
  uploadStatus: EntityModels.UploadStatus;
  additionalValidation?: (v: FormData, p: Props) => Record<string, string>;
  form: string;
  children?: React.ReactNode;
};

function DeleteCoverButton({
  releaseId,
  onClick,
  ...props
}: Omit<React.ComponentProps<typeof Button>, 'text'> & {
  text: string;
  releaseId: string | number;
}) {
  const query = useMemo(() => ({ releaseId }), [releaseId]);
  const { queryHash } = useQuery({ query });
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const {
    deleteEntry,
    request: { deleting },
  } = useDeleteEntity({
    queryHash,
    entity: 'releaseCovers',
    query,
  });
  const { editable } = useFieldUpdatable({ fieldName: 'covers' });

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }
      deleteEntry();
      setOpenConfirmation(false);
    },
    [deleteEntry, onClick]
  );
  return (
    <>
      <ConfirmationWindow
        isOpen={openConfirmation}
        title={props.text}
        onRequestClose={() => {
          setOpenConfirmation(false);
        }}
        onConfirm={handleClick}
      />
      <Button
        {...props}
        style={{
          width: '100%',
        }}
        testId="ReleaseForm-DeleteCoverButton"
        disabled={editable ? false : props.disabled || deleting}
        showLoading={deleting}
        onClick={() => {
          setOpenConfirmation(true);
        }}
      />
    </>
  );
}

const isLoadedOrLoading = (v: EntityModels.UploadStatus) =>
  ['initialized', 'confirmed', 'finished'].indexOf(v) >= 0;

const isUploading = (v: EntityModels.UploadStatus) =>
  ['initialized', 'confirmed'].indexOf(v) >= 0;

type FormData = any;

function ConnectedArtworkEditor({
  form,
  displayArtist,
  onUpload,
  onSelect,
  showCoverRequirementText,
  children,
}: {
  form: string;
  displayArtist: string;
  showCoverRequirementText: boolean;
  onUpload: React.ComponentProps<typeof ArtworkEditor>['onUpload'];
  onSelect: React.ComponentProps<typeof ArtworkEditor>['onSelect'];
  children: React.ComponentProps<typeof ArtworkEditor>['children'];
}) {
  const { t } = useTranslation();

  const albumPlaceholder = t('album-name');
  const artistPlaceholder = t('artist-name');

  const album = useSelector((state: ReduxState) => {
    const selector = formValueSelector(form);
    const title = selector(state, 'title') || albumPlaceholder;
    const version = selector(state, 'version');
    return `${title}${version ? ` (${version})` : ''}`;
  });

  return (
    <ArtworkEditor
      showCoverRequirementText={showCoverRequirementText}
      onSelect={onSelect}
      displayArtist={displayArtist || artistPlaceholder}
      title={album}
      onUpload={onUpload}
    >
      {children}
    </ArtworkEditor>
  );
}

export function ReleaseForm({
  touch,
  change,
  uploadStatus,
  fieldsComponent,
  artworkCopyrightFields,
  hideCoverUploader,
  children,
  showCoverRequirementText,
  alwaysShowCoverFields = false,
}: Props & InjectedFormProps<FormData, Props>) {
  const { releaseId, form, editableFields, requiredFields } =
    useContext(ReleaseFormContext);

  const artworkCopyright = useSelector((state: ReduxState) => {
    const selector = formValueSelector(form);
    const coverYear = selector(state, 'coverCopyrightYear');
    const coverText = selector(state, 'coverCopyrightText');
    const text = selector(state, 'copyrightText');
    const year = selector(state, 'copyrightYear');
    const releaseAt = selector(state, 'releaseAt');
    const releaseAtYear = releaseAt ? moment(releaseAt).year() : '';
    return `© ${coverText || text}, ${coverYear || year || releaseAtYear}`;
  });

  const { updateEntry } = useUpdateEntity({
    id: releaseId,
    entity: 'releases',
  });

  const dispatch = useDispatch();

  const uploadCover = useCallback<typeof actions.uploadCover>(
    (...args) => dispatch(actions.uploadCover(...args)),
    []
  );

  const displayArtist = useFormDisplayArtist(form);
  const { t } = useTranslation();
  const handleUploadCover = useCallback(({ fullsize, thumbnail }) => {
    if (fullsize) {
      uploadCover({
        id: releaseId,
        fullsize,
        thumbnail,
      });
    }
  }, []);

  useEffect(() => {
    if (uploadStatus === 'finished') {
      change('pendingCover', true);
    }
  }, [uploadStatus]);

  const handleField = useCallback(
    memoize((key) => (_event: any, value: any) => {
      updateEntry(
        {
          formId: form,
          query: key === 'artists' ? { with: 'artists' } : null,
          data: { [key]: value },
        },
        { debounce: true }
      );
    }),
    [releaseId]
  );
  const handleImageDeletion = useCallback(() => {
    touch('pendingCover');
    change('coverCopyrightText', '');
    handleField('coverCopyrightText')(null, '');
  }, []);

  const showHelpWindow = useContext(HelpWindowContext);
  const entityFormValue = useMemo(
    () => ({ entity: 'releases', id: releaseId }),
    [releaseId]
  );

  const [artworkType, setArtworkType] = useState<'gallery' | 'custom'>(
    'gallery'
  );

  const coverFields = (
    <>
      <FieldUpdatable
        key="text"
        testId="coverCopyrightText"
        name="coverCopyrightText"
        disabled={editableFields ? !editableFields.coverCopyrightText : false}
        // @ts-ignore
        onChange={handleField('coverCopyrightText')}
        label={t('cover-copyright-text')}
        onClickHelp={() => {
          showHelpWindow(
            t('cover-copyright-text'),
            t('release-helptext-cover-copyright-text')
          );
        }}
        component={NewInputField}
      />
    </>
  );

  return (
    <EntityFormContext.Provider
      // @ts-ignore
      value={entityFormValue}
    >
      <ProductTwoColumnsWrapper>
        {!hideCoverUploader && (
          <section>
            <Card secondary={true}>
              <div>
                <div css={artworkSection}>
                  <FieldUpdatable
                    name="pendingCover"
                    entityFieldName="covers"
                    required={requiredFields.includes('pendingCover')}
                    component={CoverOverlineText}
                    size="large"
                    label={t('cover-artwork')}
                    onClickHelp={() => {
                      showHelpWindow(
                        t('cover-artwork'),
                        t('cropper-cover-text')
                      );
                    }}
                  />

                  {isLoadedOrLoading(uploadStatus) ? (
                    <>
                      <div css={fieldStyle}>
                        <LoadingArtwork
                          expandable={true}
                          releaseId={releaseId}
                        />
                        <Caption
                          secondary
                          style={{
                            wordBreak: 'break-all',
                          }}
                        >
                          {artworkCopyright}
                        </Caption>
                      </div>
                      {alwaysShowCoverFields ? coverFields : null}

                      <DeleteCoverButton
                        position="center"
                        size="small"
                        releaseId={releaseId}
                        text={t('delete-image')}
                        css={fieldStyle}
                        disabled={
                          editableFields
                            ? !editableFields.coverUpload
                            : isUploading(uploadStatus)
                        }
                        onClick={handleImageDeletion}
                      />
                    </>
                  ) : (
                    <ConnectedArtworkEditor
                      onSelect={setArtworkType}
                      form={form}
                      displayArtist={displayArtist}
                      showCoverRequirementText={!!showCoverRequirementText}
                      onUpload={handleUploadCover}
                    >
                      {alwaysShowCoverFields ||
                        (artworkCopyrightFields && artworkType === 'custom')
                        ? coverFields
                        : null}
                    </ConnectedArtworkEditor>
                  )}
                  <FieldUpdatable
                    name="pendingCover"
                    entityFieldName="covers"
                    component={CoverHelperText}
                  />
                </div>
              </div>
            </Card>
          </section>
        )}
        <section>
          <Card data-test-id="release-form">
            {fieldsComponent}

            {children}
          </Card>
        </section>
      </ProductTwoColumnsWrapper>
    </EntityFormContext.Provider>
  );
}

const ConnectedReleaseForm = reduxForm<FormData, Props>({
  submitAsSideEffect: true,
})(ReleaseForm);

export default ConnectedReleaseForm;
