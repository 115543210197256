import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type QueryBuilderInput from '../../atoms/QueryBuilderInput';

type QueryBuilderInputProps = React.ComponentProps<typeof QueryBuilderInput>;
// TODO: fix any and partially copy from future QueryBuilder types
export type LibraryHeaderToolsProps = {
  value: QueryBuilderInputProps['value'];
  onChangeFiltering: QueryBuilderInputProps['onChange'];
  components?: {
    Left?: React.ReactNode;
    Bottom?: React.ReactNode;
  };
  children?: React.ReactNode;
  stickyTopThreshold?: number;
  respectTopConstraint?: boolean;
  renderQueryBuilder: React.FC<{
    onChange: QueryBuilderInputProps['onChange'];
    value: QueryBuilderInputProps['value'];
  }>;
  className?: string;
  style?: React.CSSProperties;
};

const Container = styled.div`
  padding: 32px;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid var(--outline);
`;

const wrapperStyle = css`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-right: -16px;
  align-items: end;

  > * {
    width: 100%;
    justify-content: center;
  }

  @media (min-width: 512px) {
    > * {
      width: auto;
    }
  }
`;

const QueryBuilderWrapper = styled.div`
  flex-grow: 1;
  max-height: unset;
`;

const LibraryHeaderTools: React.FC<LibraryHeaderToolsProps> = ({
  value,
  renderQueryBuilder: QueryBuilder,
  onChangeFiltering,
  components,
  children,
  className,
  style,
}) => (
  <Container className={className} style={style}>
    <div css={wrapperStyle} className={className}>
      {components?.Left}

      {QueryBuilder && (
        <QueryBuilderWrapper>
          <QueryBuilder onChange={onChangeFiltering} value={value} />
        </QueryBuilderWrapper>
      )}

      {children}
    </div>

    {components?.Bottom}
  </Container>
);

export default LibraryHeaderTools;
