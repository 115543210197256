import type { EntityModels } from 'imddata';

type RequiredInvoice = {
  status: EntityModels.Invoice['status'];
  paymentMethodId: EntityModels.Invoice['paymentMethodId'];
};

export const isInvoicePaid = <T extends RequiredInvoice>(invoice: T) => {
  if (
    invoice.status === 'pending' &&
    invoice.paymentMethodId === 'sepa_direct_debit'
  ) {
    return true;
  }
  return invoice.status === 'paid' || invoice.status === 'finalized';
};
