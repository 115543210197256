import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { AutosizeGrid } from '@imus/base-ui';
import type { Theme } from '../../../theme';

export const OptionGrid = styled(AutosizeGrid)`
  margin-bottom: 12px;
`;

const focus = keyframes`
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
`;

type Props = {
  disabled?: boolean;
  selected?: boolean;
  error?: boolean | string;
};

export const optionClickStyle = ({
  theme,
  selected,
  disabled,
  error,
  onClick,
}: Props & { theme: Theme; onClick: any }) => css`
  border-radius: ${theme.component.radius};
  cursor: ${!disabled ? 'pointer' : 'default'};
  opacity: ${disabled ? '0.4' : 1};
  box-shadow: ${error // force to make customer deselect
    ? `0 0 0 2px var(--error-error)`
    : selected
      ? `0 0 0 2px var(--accent), 0px 1px 4px rgba(95, 148, 41, 0.24)`
      : '0 0 0 1px var(--outline)'};

  &:after {
    top: 0;
    left: 0;
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    transition: opacity 0.1s;
    background-color: rgba(0, 0, 0);
    opacity: 0;
    will-change: opacity;
    height: 100%;
    width: 100%;
  }
  :hover::after {
    opacity: ${onClick ? '0.1' : '0'};
  }

  :active::after {
    opacity: ${onClick ? '0.2' : '0'};
    background-color: ${onClick ? 'rgba(50, 50, 50, 0.4)' : 'rgba(50, 50, 50)'};
  }

  &:focus::after {
    animation: ${focus} 1.25s forwards infinite;
  }

  &:active::after {
    animation: none;
  }
`;

// @ts-ignore
export const OptionGridItem = styled.button<Props>`
  position: relative;
  width: 100%;
  height: 100%;

  padding: 12px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  transition: box-shadow 0.15s ease-in-out;

  color: var(--on-surface);
  background: var(--surface-container-high);
  ${optionClickStyle as any}
`;
