//
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import DateController from '../../../DateController';
import DayRenderer from '../../../DateController/DayRenderer';
import DatePickerLegend from './DatePickerLegend';

function getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
  if (start.isAfter(end)) {
    throw new Error('start must precede end');
  }

  const next = moment(start).add(1, key).startOf(key);

  if (next.isAfter(end, key)) return arr;

  return getRangeOfDates(next, end, key, arr.concat(next));
}

function usePickerSize() {
  const [size, setSize] = useState(window.innerWidth > 640 ? 2 : 1);

  function handleResize() {
    setSize(window.innerWidth > 640 ? 2 : 1);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
}

const createDayRenderer = (dateRanges) => (day, modifiers) => {
  const deliveryOption = dateRanges[day.format('YYYY-MM-DD')];

  const isProcessing = dateRanges.processingRange[day.format('YYYY-MM-DD')];

  return (
    <DayRenderer
      testId={`ReleaseDateSelectorOption-${
        deliveryOption ? deliveryOption.id : 'free'
      }-${modifiers.has('blocked') ? 'blocked' : 'unblocked'}`}
      testValue={day.format('x')}
      day={day}
      modifiers={modifiers}
      deliveryOption={deliveryOption}
      isProcessing={isProcessing}
    />
  );
};

const DatePicker = ({
  dateRanges,
  noOptions,
  isDayBlocked,
  onDateChange,
  date,
}) => {
  const initialVisibleMonth = useCallback(() => {
    const currentDay = moment();
    const lastDay = moment().endOf('month');

    const hasUnblockedDay = getRangeOfDates(currentDay, lastDay, 'day').find(
      (day) => !isDayBlocked(day)
    );

    const nextMonth = moment().add({ months: 1 });

    return hasUnblockedDay ? moment() : nextMonth;
  }, []);

  const numberOfMonths = usePickerSize();

  const renderCalendarInfo = useCallback(() => {
    if (noOptions) {
      return null;
    }
    return <DatePickerLegend dateRanges={dateRanges} />;
  }, [noOptions, dateRanges]);

  const renderDayContents = useMemo(() => {
    if (noOptions) {
      return undefined;
    }

    return createDayRenderer(dateRanges);
  }, [noOptions, dateRanges]);

  return (
    <DateController
      key={dateRanges ? dateRanges.key : 'datecontroller'}
      isDayBlocked={noOptions ? undefined : isDayBlocked}
      initialVisibleMonth={noOptions ? undefined : initialVisibleMonth}
      focused={true}
      renderDayContents={renderDayContents}
      renderMonthElement={null}
      renderCalendarInfo={renderCalendarInfo}
      value={date}
      date={date}
      onDateChange={onDateChange}
      numberOfMonths={numberOfMonths}
    />
  );
};

export default DatePicker;
