import React, { useMemo } from 'react';
import { identity } from 'ramda';
import { useDrag, useDrop } from 'react-dnd';
import styled from '@emotion/styled';
import type { Props as TrackProps } from '..';
import Track from '..';
import DragHandler from '../../DragHandler';

const DragHandlerStyled = styled(DragHandler) <{ draggable: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 12px;
  right: 10px;
  cursor: move;
  visibility: ${({ draggable }) => (draggable ? 'visible' : 'hidden')};
`;

const TrackDraggableStyled = styled.div<{ isOver?: boolean }>`
  position: relative;
  border-radius: 3px;
  &:hover > ${DragHandlerStyled} {
    opacity: 1;
  }
  ${({ isOver }) =>
    isOver &&
    `
    background-color: var(--surface-container-high);
    border: dashed 2px var(--outline-var);
    & > * {
    display: none;
    }
  `}
`;

const TrackStyled = styled(Track) <{ draggable: boolean }>`
  ${({ draggable }) =>
    draggable &&
    `
  padding-right: 48px;
`}
`;
type Props = TrackProps & {
  onMove?: (from: number, to: number) => void;
  onDrop?: (changed: boolean, newPosition: number) => void;
  index: number;
};

const DRAG_TYPE = 'track-item';

type DragObject = {
  index: number;
  id: string | number;
};

export default function TrackDraggable({
  onMove,
  onDrop,
  style,
  ...props
}: Props) {
  const [, dragHandler, dragPreview] = useDrag<
    DragObject,
    DragObject,
    { isDragging: boolean }
  >(
    () => ({
      type: DRAG_TYPE,
      item: {
        index: props.index,
        id: props.trackId,
      },
      end: (item, monitor) => {
        const dropIndex = monitor.getItem().index;
        const result = monitor.getDropResult();

        if (onDrop && monitor.didDrop() && result) {
          const isPositionChanged = dropIndex !== result.index;

          onDrop(isPositionChanged, dropIndex);
        }
      },
    }),
    [props.index, props.trackId]
  );

  const [{ isOver }, dropHander] = useDrop<
    DragObject,
    void,
    { isOver: boolean }
  >(
    () => ({
      accept: DRAG_TYPE,
      collect: (monitor) => ({ isOver: monitor.isOver() }),
      hover: (item, monitor) => {
        const dragIndex = item.index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (!onMove || dragIndex === hoverIndex || !monitor.canDrop()) {
          return;
        }

        // Perform the move action
        onMove(dragIndex, hoverIndex);

        // eslint-disable-next-line no-param-reassign
        item.index = hoverIndex;
      },
    }),

    [props.index, props.trackId]
  );

  const draggable = !!onMove && !!onDrop;

  const handler = useMemo<React.RefCallback<HTMLDivElement>>(
    () =>
      draggable
        ? (el) => {
          dragHandler(el);
          dragPreview(el);
          dropHander(el);
        }
        : identity,
    [draggable]
  );

  return (
    <TrackDraggableStyled
      draggable={draggable}
      isOver={isOver}
      ref={handler}
      style={style}
    >
      <TrackStyled
        {...props}
        draggable={draggable}
        className={props.className}
      />

      <DragHandlerStyled draggable={draggable} />
    </TrackDraggableStyled>
  );
}
