import styled from '@emotion/styled';
import React, { Suspense, useMemo, useState } from 'react';
import { ReleasePageOptionIcon } from './ReleasePageOptionIcon';
import {
  Body,
  BodySmall,
  Button,
  Option,
  OptionDescription,
  OptionHeader,
  OptionTabs,
  OverlineText,
  Subheading,
  Window,
} from 'imdui';
import { useCustomerFeatureRollout } from 'imddata';
import { useTranslation } from 'react-i18next';
import { ReleasePageTemplateScaler } from './Scaler';

const Options = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-auto-flow: row;
`;

const Header = styled.div`
  width: 100%;
  background: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const CustomizeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`;

const SubscribeText = styled(BodySmall)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.accent.subscription};
  font-weight: 500;
  margin-left: auto;
`;

const OptionHeaderStyled = styled(OptionHeader)`
  display: flex;
`;

const BaseDescription = () => {
  const { t } = useTranslation();
  return (
    <>
      <OptionHeaderStyled>
        <Subheading>{t('release-page-free')}</Subheading>
      </OptionHeaderStyled>
      <OptionDescription>
        <Body>{t('smartlink-with-select-shops')}</Body>
        <Body>{t('single-page')}</Body>
        <Body>{t('imusician-branding')}</Body>
      </OptionDescription>
    </>
  );
};

const ArtistHubProDescription = ({ upsellText }: { upsellText?: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <OptionHeaderStyled>
        <Subheading>{t('release-page-pro')}</Subheading>
        {upsellText && <SubscribeText>{upsellText}</SubscribeText>}
      </OptionHeaderStyled>
      <OptionDescription>
        <Body>{t('smartlink-with-all-shops')}</Body>
        <Body>{t('imusician-branding')}</Body>
      </OptionDescription>
    </>
  );
};

const ArtistHubProPlusDescription = ({
  upsellText,
}: {
  upsellText?: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <OptionHeaderStyled>
        <Subheading>{t('release-page-pro-plus')}</Subheading>
        {upsellText && <SubscribeText>{upsellText}</SubscribeText>}
      </OptionHeaderStyled>
      <OptionDescription>
        <Body>{t('presave-included')}</Body>
        <Body>
          {t('fully-customizable', { context: 'artist-hub-pro-plus' })}
        </Body>
        <Body>{t('no-imusician-branding')}</Body>
      </OptionDescription>
    </>
  );
};

type Mode = 'base' | 'pro' | 'pro-plus';

type GridProps = {
  mode: Mode;
  onCustomize?: () => void;
  disabledCustomize?: boolean;
  onSelect: (mode: Mode) => void;
};

const ReleasePageOptionsGrid = ({
  mode,
  disabledCustomize,
  onCustomize,
  onSelect,
}: GridProps) => {
  const { t } = useTranslation();
  switch (mode) {
    case 'base':
      return (
        <Options>
          <Option
            selected={true}
            header={
              <Header>
                <ReleasePageOptionIcon branding={true} customizable={false} />
              </Header>
            }
          >
            <BaseDescription />
          </Option>
          <Option
            onClick={() => {
              onSelect('pro-plus');
            }}
            header={
              <Header>
                <ReleasePageOptionIcon branding={false} customizable={true} />
              </Header>
            }
          >
            <ArtistHubProPlusDescription upsellText={t('upgrade')} />
          </Option>
        </Options>
      );
    case 'pro':
      return (
        <>
          <Options>
            <Option
              selected={true}
              header={
                <Header>
                  <ReleasePageOptionIcon branding={true} customizable={true} />
                </Header>
              }
            >
              <ArtistHubProDescription />
            </Option>
            <Option
              onClick={() => {
                onSelect('pro-plus');
              }}
              header={
                <Header>
                  <ReleasePageOptionIcon branding={false} customizable={true} />
                </Header>
              }
            >
              <ArtistHubProPlusDescription upsellText={t('upgrade')} />
            </Option>
          </Options>
          {onCustomize && (
            <Options style={{ marginTop: '12px' }}>
              <CustomizeWrapper>
                <Button
                  text={t('customize')}
                  size="small"
                  primary={true}
                  onClick={onCustomize}
                />
              </CustomizeWrapper>
              <div />
            </Options>
          )}
        </>
      );
    case 'pro-plus':
      return (
        <>
          <Options>
            <Option
              selected={true}
              header={
                <Header>
                  <ReleasePageOptionIcon branding={false} customizable={true} />
                </Header>
              }
            >
              <ArtistHubProPlusDescription />
            </Option>
          </Options>
          {onCustomize && (
            <Options style={{ marginTop: '12px' }}>
              <CustomizeWrapper>
                <Button
                  disabled={disabledCustomize}
                  text={t('customize')}
                  size="small"
                  primary={true}
                  onClick={onCustomize}
                />
              </CustomizeWrapper>
            </Options>
          )}
        </>
      );
  }
};

const WindowStyled = styled(Window)`
  width: 100%;
  max-width: 1280px;
`;

const WindowScaler = styled(ReleasePageTemplateScaler)`
  border: none;
  margin: 0;
`;

const Align = styled.div`
  width: 100%;
  background: var(--surface-container-low);
  display: grid;
  justify-items: center;
  align-items: center;
`;

const Wrapper = styled.div`
  max-width: 960px;
  height: 100%;
  background: var(--background);
  border: 1px solid var(--outline-var);
  width: 100%;
  padding: 32px;
`;

const HeaderAlign = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  & > * {
    margin-left: 32px;
  }
`;

export const ReleasePageOptionsTemplateContext =
  React.createContext<Mode>('base');

export const ReleasePageOptions = ({
  legacyPremium,
  onCustomize,
  onSelect,
  disabledTemplate,
  template,
  label,
}: {
  label?: string;
  disabledTemplate?: boolean;
  legacyPremium?: boolean;
  onCustomize?: GridProps['onCustomize'];
  onSelect: GridProps['onSelect'];
  template: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const [isOpenExample, setOpenExample] = useState(false);
  const artistHubPro = useCustomerFeatureRollout({
    feature: 'artist-hub-pro',
    rolloutKey: 'tiered-subs',
    fallback: false,
  });
  const artistHubProUnbranded = useCustomerFeatureRollout({
    feature: 'artist-hub-pro-unbranded',
    rolloutKey: 'tiered-subs',
    fallback: false,
  });
  const mode = legacyPremium
    ? 'pro-plus'
    : !artistHubPro && !artistHubProUnbranded
      ? 'base'
      : artistHubPro && !artistHubProUnbranded
        ? 'pro'
        : 'pro-plus';

  const [previewMode, setPreviewMode] = useState<Mode>(() => mode);
  const previewModes = useMemo<{ value: Mode; label: string }[]>(() => {
    switch (mode) {
      case 'base':
        return [
          { value: 'base', label: t('free') },
          // { value: 'pro', label: t('pro-legacy') },
          { value: 'pro-plus', label: t('pro-plus') },
        ];
      case 'pro':
        return [
          { value: 'pro', label: t('pro-legacy') },
          { value: 'pro-plus', label: t('pro-plus') },
        ];
      default:
        return [{ value: 'pro-plus', label: t('pro-plus') }];
    }
  }, [mode]);
  return (
    <div>
      <OverlineText
        size="large"
        style={{ marginBottom: '24px' }}
        label={label}
        components={{
          OverlineActions: () => (
            <Button
              disabled={disabledTemplate}
              size="small"
              text={t('preview')}
              onClick={() => {
                setOpenExample(true);
              }}
            />
          ),
        }}
      />
      <ReleasePageOptionsGrid
        mode={mode}
        disabledCustomize={disabledTemplate}
        onCustomize={onCustomize}
        onSelect={onSelect}
      />
      <WindowStyled
        key="window"
        title={
          <HeaderAlign>
            <OptionTabs
              options={previewModes}
              value={previewMode}
              onChange={(m) => {
                setPreviewMode(m);
              }}
            />
          </HeaderAlign>
        }
        isOpen={isOpenExample}
        close={() => {
          setOpenExample(false);
        }}
      >
        <ReleasePageOptionsTemplateContext.Provider value={previewMode}>
          <Align>
            <Wrapper>
              <WindowScaler>
                <Suspense fallback={null}>{template}</Suspense>
              </WindowScaler>
            </Wrapper>
          </Align>
        </ReleasePageOptionsTemplateContext.Provider>
      </WindowStyled>
    </div>
  );
};
