import styled from '@emotion/styled';
import { BodyXS, ContentS, H4 } from '@imus/base-ui';
import {
  LevelProgress,
  MilestoneIndicator,
  MilestoneLevel,
} from 'components/atoms';
import { Window } from 'imdui';

const MilestonesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const MilestonesHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;

const MilestoneHeaderDescription = styled.div`
  max-width: 228px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MilestonesList = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  flex: 1;
`;

const MilestoneTitle = styled(H4)`
  line-height: 16px;
  text-transform: uppercase;
`;
const MilestoneDate = styled(BodyXS)`
  color: var(--on-surface-var-2);
`;
const MilestoneTextBlock = styled.div`
  flex: 1;
  display: grid;
  gap: 8px;
`;

const MilestoneBase = ({
  value,
  locked,
  title,
  description,
  className,
  style,
  date,
}: {
  value: number;
  locked?: boolean;
  title?: string;
  date?: string;
  description: string;
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <div className={className} style={style}>
      <MilestoneLevel locked={locked} value={value} />
      <MilestoneTextBlock>
        {title && <MilestoneTitle>{title}</MilestoneTitle>}
        {description && <BodyXS secondary>{description}</BodyXS>}
      </MilestoneTextBlock>
      {date && <MilestoneDate>{date}</MilestoneDate>}
    </div>
  );
};

const Milestone = styled(MilestoneBase)`
  flex: 1;
  display: flex;
  padding: 16px;
  align-items: center;

  gap: 24px;
  border-radius: 8px;
  background: var(--surface-container-low, #e9e7e5);
  background: var(
    --surface-container-low,
    color(display-p3 0.9137 0.9059 0.898)
  );
`;

export const Milestones = () => {
  return (
    <MilestonesContainer>
      <MilestonesHeader>
        <MilestoneHeaderDescription>
          <MilestoneIndicator />
          <ContentS secondary>
            Gain streams to get to the next level. Each level gives you a prize
          </ContentS>
        </MilestoneHeaderDescription>
        <LevelProgress levels={[10, 100, 1000, 5000, 10000]} value={200} />
      </MilestonesHeader>
      <MilestonesList>
        <Milestone
          value={1}
          title="Beginner"
          description="You gained this achievement for 10 streams"
          date="10 mar 2025"
        />
        <Milestone
          value={2}
          title="Beginner"
          description="10 streams"
          date="10 mar 2025"
        />
        <Milestone
          value={3}
          title="Beginner"
          description="10 streams"
          date="10 mar 2025"
        />
        <Milestone value={4} locked={true} description="100000 streams" />
        <Milestone value={5} locked={true} description="10000000000 streams" />
      </MilestonesList>
    </MilestonesContainer>
  );
};

export const MilestonesWindow = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Window
      headerAlign="center"
      style={{ maxWidth: '480px' }}
      title="Milestone Progress & Rewards"
      isOpen={isOpen}
      close={onClose}
    >
      <div style={{ padding: '0 32px 32px' }}>
        <Milestones />
      </div>
    </Window>
  );
};
