//
import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { Body, Content, SVGIcon } from 'imdui';

const ChildrenWrapper = styled.div`
  z-index: 1;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: 40px;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 32px;
  text-align: left;
  transition: all 0.1s ease-in-out;

  :not(:last-child) {
    box-shadow: inset 0 -1px 0 0 var(--outline-var);
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Thumbnail = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 24px;
`;

const TypeIcon = styled(SVGIcon)`
  width: 24px;
  height: 24px;
  margin-right: 24px;
`;

const TitleWrapper = styled.div`
  flex: 2;

  > * {
    display: block;
  }

  > ${Body} {
    margin-top: 8px;
  }
`;

const FieldsWrapper = styled.div`
  flex: 1;

  @media screen and (max-width: 600px) {
    display: none;
  }

  > * {
    display: flex;
  }

  > ${Content}:nth-of-type(2) {
    margin-top: 8px;
  }
`;

const NavListItem = ({
  style: { position, top, left } = {},
  testId,
  icon,
  title,
  subtitle = '',
  price,
  status,
  createdAt,
  children,
  onClick,
  thumbnailUrl,
  to,
}: {
  style?: React.CSSProperties;
  testId?: string;
  icon?: string;
  title: string;
  subtitle?: string;
  price?: string;
  createdAt?: string;
  thumbnailUrl?: string;
  to?: string;
  children?: React.ReactNode;
  status?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const history = useHistory();
  return (
    <Wrapper
      role="button"
      data-test-id={testId}
      style={{
        position,
        top,
        left,
      }}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        if (to) {
          history.push(to);
        }
      }}
    >
      {thumbnailUrl && <Thumbnail src={thumbnailUrl} />}

      {icon && <TypeIcon d={icon} />}

      <TitleWrapper>
        <Content>{title}</Content>

        {subtitle && <Body>{subtitle}</Body>}
      </TitleWrapper>

      <FieldsWrapper>
        {!!price && <Content>{price}</Content>}

        <Content style={{ color: status === 'Paid' ? '#628C35' : undefined }}>
          {status}
        </Content>
      </FieldsWrapper>

      <FieldsWrapper>
        <Body>{createdAt}</Body>
      </FieldsWrapper>

      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </Wrapper>
  );
};

export default NavListItem;
