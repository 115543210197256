import { css } from '@emotion/react';
import styled from '@emotion/styled';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence } from 'framer-motion';
import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from 'react';
import { Portal } from 'react-portal';
import { useLocation } from 'react-router-dom';
import { DOMNodeContext, OverlayContext } from '../../context';
import { CloseButton } from '../button';
import {
  AnimatedDim,
  WindowCard,
  WindowChildren,
  WindowHeader as WindowHeaderWrapper,
} from './styles';
import { ModalWindowTitle } from '@imus/base-ui';

export { AnimatedDim } from './styles';

type Props = {
  headerAlign?: 'left' | 'center';
  children: React.ReactNode;
  isOpen?: boolean;
  title?: React.ReactNode;
  hideTitleBar?: boolean;
  close?: (
    e?: React.MouseEvent<HTMLElement>,
    type?: 'x-button' | 'outside' | 'event'
  ) => void;
  closeOnLocation?: boolean;
  onClose?: () => void;
  className?: string;
  style?: any;
  lockClickOutside?: boolean;
  contextComponent?: typeof OverlayContext;
};

const dimBefore = { opacity: 0 };
const dimAfter = { opacity: 1 };

const windowBefore = { scale: 0.9 };
const windowAfter = { scale: 1 };

const animConfig = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
};

const windowContentStyle = css`
  overflow: auto;
  container-name: template-sizer;
  container-type: inline-size;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
`;
const stopBubbleClickOutside: React.MouseEventHandler<HTMLDivElement> = (e) => {
  e.stopPropagation();
};

const WindowHeaderContext = createContext<null | HTMLDivElement>(null);

const Window: React.FC<Props> = ({
  children,
  isOpen = false,
  title,
  closeOnLocation = true,
  hideTitleBar,
  lockClickOutside = false,
  headerAlign = 'left',
  close,
  onClose,
  className,
  style,
}) => {
  const node = useRef<HTMLDivElement>(null);
  const focusNode = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const locationRef = useRef(location);

  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen]);

  const handleClose = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (close) {
        close(e as React.MouseEvent<HTMLElement>, 'x-button');
      }
    },
    [close]
  );

  const onClickInside = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onClickOutside = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (
        !lockClickOutside &&
        !focusNode?.current?.contains(e.target as Node)
      ) {
        e.stopPropagation();
        if (close) {
          close(e as React.MouseEvent<HTMLElement>, 'outside');
        }
      }
    },
    [lockClickOutside, close]
  );
  const handleKeyDown = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const { dropdownNode } = useContext(DOMNodeContext);

  const headerRef = useRef<HTMLDivElement>(null);

  const domValue = useMemo(
    () => ({
      sideWindowNode: node,
      contentNode: node,
      dropdownNode: node,
    }),
    [node]
  );

  useEffect(() => {
    if (
      isOpen &&
      close &&
      closeOnLocation &&
      location.pathname !== locationRef.current.pathname
    ) {
      close();
    }
  }, [location, closeOnLocation]);

  return (
    <Portal
      node={
        dropdownNode?.current ||
        document.getElementById('app') ||
        document.getElementById('root')
      }
    >
      <AnimatePresence>
        {isOpen && (
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
              fallbackFocus: 'body',
            }}
          >
            <div onKeyDown={handleKeyDown} data-test-id="window" ref={node}>
              <AnimatedDim
                tabIndex={0}
                initial={dimBefore}
                animate={dimAfter}
                exit={dimBefore}
                transition={animConfig}
                onClick={stopBubbleClickOutside}
                onMouseDown={onClickOutside}
              >
                <WindowCard
                  ref={focusNode}
                  initial={windowBefore}
                  animate={windowAfter}
                  exit={windowBefore}
                  transition={animConfig}
                  className={className}
                  style={style}
                >
                  <div onClick={onClickInside} css={windowContentStyle}>
                    <WindowHeaderWrapper
                      headerAlign={headerAlign}
                      ref={headerRef}
                      style={hideTitleBar ? { display: 'none' } : {}}
                    >
                      {close && (
                        <CloseButton
                          style={{ position: 'absolute', right: '32px' }}
                          testId="Window-Close"
                          onClick={handleClose}
                        />
                      )}
                      {typeof title === 'string' ? (
                        <ModalWindowTitle>{title}</ModalWindowTitle>
                      ) : (
                        title
                      )}
                    </WindowHeaderWrapper>

                    <WindowHeaderContext.Provider value={headerRef.current}>
                      <DOMNodeContext.Provider value={domValue}>
                        <WindowChildren hideTitleBar={!!hideTitleBar}>
                          {children}
                        </WindowChildren>
                      </DOMNodeContext.Provider>
                    </WindowHeaderContext.Provider>
                  </div>
                </WindowCard>
              </AnimatedDim>
            </div>
          </FocusTrap>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export const WindowHeader = ({ children }: { children: React.ReactNode }) => {
  const node = useContext(WindowHeaderContext);
  return <Portal node={node}>{children}</Portal>;
};

export const PageLayer = styled(Window)`
  max-width: 1280px;
  margin: 16px 16px 0 16px;
  max-height: initial;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: calc(100% - 16px);
  background: var(--background);
  ${WindowHeaderWrapper} {
    position: relative;
    background: transparent;

    ${ModalWindowTitle} {
      flex: 1;
      text-align: center;
      align-self: center;
    }
  }
`;

const WindowConnected: React.FC<Props> = ({
  contextComponent: ContextComponent = OverlayContext,
  ...props
}) => (
  <ContextComponent.Consumer>
    {({ isOpen, close }) => (
      <Window
        isOpen={props.isOpen !== undefined ? props.isOpen : isOpen}
        close={props.close !== undefined ? props.close : close}
        {...props}
      />
    )}
  </ContextComponent.Consumer>
);

export default WindowConnected;
