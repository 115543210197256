import React from 'react';
import styled from '@emotion/styled';
import { OverlineText, createInputWithStyles } from 'imdui';
import { HelperText } from 'imdshared';

export type FieldProps = {
  isValid?: boolean;
  isFocused?: boolean;
  blurred?: boolean;
  hasError?: boolean;
  error?: string;
};

type Props = {
  id: string;
  field: FieldProps;
  className?: string;
  style?: React.CSSProperties;
};

// Idk why it is not liking this
// @ts-ignore
export const HostedField = styled(createInputWithStyles('div'))`
  resize: none;
  overflow: hidden;
  height: 40px;
  padding: 0 16px;
`;

// TODO: Do not duplicate this as it already in NewInput/styles
export const StyledOverlineText = styled(OverlineText)``;

export const HelperTextStyled = styled(HelperText)``;

export default function SecuredFormField({
  className,
  id,
  field,
  style,
}: Props) {
  return (
    <div style={style} className={className}>
      <HostedField
        status={field.error ? 'error' : undefined}
        id={id}
        data-cse={id}
        focused={!!field.isFocused}
      />
      <HelperTextStyled errorText={field.error} />
    </div>
  );
}
