//
import React, { Component, useContext } from 'react';
import { equals } from 'ramda';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import WaveSurfer from 'wavesurfer.js';
import { Block, Card, Icons, Caption, Content, IconButton } from 'imdui';
import { AppContext } from 'imddata';
import { keyframes } from '@emotion/react';
import { motion } from 'framer-motion';
import type { TFunction } from 'i18next';
import { formatOffset, palette } from '../../helpers';
import ErrorBoundary from '../ErrorBoundary';
import PlayPauseButton from '../PlayPauseButton';
import { usePlayer } from '../PlayerProvider';

const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Buffering = styled.div`
  background-color: var(--outline-var);
  position: absolute;
  margin-top: -2px;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  animation: ${opacityAnimation} 5s infinite linear;
  will-change: opacity;
`;

const TitleArtistLabel = styled(Content)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeBlockWrapper = styled.div`
  width: 64px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const TimeBlock = ({ children }: { children: React.ReactNode }) => (
  <TimeBlockWrapper>
    <Caption style={{ color: palette.dark.xLight, textAlign: 'center' }}>
      {children}
    </Caption>
  </TimeBlockWrapper>
);

const LabelBlock = styled.div`
  flex-direction: column;
  display: none;
  max-width: 300px;

  @media (min-width: 600px) {
    flex-grow: 0;
    flex: 300px;
    display: flex;
  }
`;

const PADDING = [10, 10, 10, 10];

const Container = styled(motion.div)`
  z-index: 101;
  width: 100%;
  margin: 0 auto;
  max-width: 1680px;
  padding: 8px 32px;
`;

const transition = {
  type: 'spring',
  stiffness: 800,
  damping: 60,
};

export type BaseProps = {
  position?: number;
  playerPosition: 'bottom' | 'top';
  offset?: number;
};

type ProviderProps = {
  trackId: number;
  t: TFunction;
  playing: boolean;
  audioFile: string | Blob | MediaSource;
  playState: string;
  position: number;
  play: (t: number, pos: number) => void;
  seek: number;
  sync: (v: { duration: number; currentPos: number }) => void;
  setBuffering: (v: boolean) => void;
  stop: () => void;
  app: 'front' | 'admin';
  artist?: string;
  offset?: number;
  title?: string;
};

type Props = BaseProps & ProviderProps;

type State = {
  error?: boolean;
  totalTime?: number;
  currentTime?: number;
  buffering?: boolean;
  trackId?: number;
  ready?: boolean;
};

class AudioPlayer extends Component<Props, State> {
  static defaultProps = {
    buffer: null,
    width: 1200,
    height: 50,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { playing } = nextProps;
    const newTrack =
      !prevState.trackId || nextProps.trackId !== prevState.trackId;
    if (newTrack || playing) {
      return {
        ...(newTrack
          ? {
            trackId: nextProps.trackId,
            error: false,
            totalTime: 0,
            currentTime: 0,
            buffering: true,
            ready: false,
          }
          : {}),
      };
    }
    return null;
  }

  state: State = {
    totalTime: 0,
  };

  lastPos?: number;

  wavesurfer?: WaveSurfer;

  audioContainer = React.createRef<HTMLDivElement>();

  localAudioFile: null | string = null;

  componentDidMount() {
    // const { audioFile } = this.props;
    // this.initWaveSurfer();
    // if (audioFile) {
    //   this.wavesurfer?.load(audioFile);
    // }
  }

  initWaveSurfer = () => {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
    if (this.audioContainer.current) {
      this.wavesurfer = WaveSurfer.create({
        container: this.audioContainer.current,
        waveColor: 'var(--accent-surface-container)',
        cursorColor: 'var(--accent)',
        cursorWidth: 2,
        hideScrollbar: true,
        height: 40,
        barWidth: 1,
        barGap: 0,
        progressColor: palette.main.normal,
      });

      this.wavesurfer.on('redraw', () => {
        this.setState({ buffering: false });
      });

      // this.wavesurfer.on('audioprocess', () => {
      //   this.setState({ error: true });
      // });

      this.wavesurfer.on('ready', () => {
        const { trackId, playState, position, play, setBuffering } = this.props;

        const finalPosition = Number.isFinite(position) ? position : 0;
        const duration = this.wavesurfer!.getDuration();

        const totalTime = Math.floor(duration);

        setBuffering(false);
        this.setState({ ready: true, buffering: true, totalTime });

        if (trackId && playState === 'play') {
          play(trackId, finalPosition);
          this.wavesurfer?.setTime(finalPosition);
          this.wavesurfer!.play();
        }
      });

      this.wavesurfer.on('audioprocess', this.handleSeekAndProcess);

      this.wavesurfer.on('seeking', this.handleSeekAndProcess);
    }
  };

  handleSeekAndProcess = () => {
    const { sync } = this.props;

    const totalTime = Math.floor(this.wavesurfer!.getDuration());
    const currentTime = this.wavesurfer!.getCurrentTime();
    const currentPos = Math.floor(currentTime);

    this.setState({ currentTime: currentPos, totalTime });

    if (currentPos !== this.lastPos) {
      this.lastPos = currentPos;
      sync({
        duration: totalTime,
        currentPos,
      });
    }
  };

  cleanupFile() {
    if (this.localAudioFile) {
      URL.revokeObjectURL(this.localAudioFile);
      this.localAudioFile = null;
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { trackId, setBuffering, audioFile, playState, position, seek } =
      this.props;

    const finalPosition = Number.isFinite(position) ? position : 0;
    if (!this.wavesurfer) {
      this.initWaveSurfer();
    }

    if (!equals(trackId, prevProps.trackId)) {
      this.wavesurfer!.destroy();
      this.cleanupFile();
      setBuffering(true);

      this.initWaveSurfer();
    }

    if (
      !equals(trackId, prevProps.trackId) ||
      audioFile !== prevProps.audioFile
    ) {
      if (typeof audioFile !== 'string' && audioFile) {
        this.localAudioFile = URL.createObjectURL(audioFile);
        this.wavesurfer!.load(this.localAudioFile);
      } else if (audioFile) {
        this.initWaveSurfer();
        this.wavesurfer!.load(audioFile);
        return;
      }
    }

    if (prevProps.playState !== playState && !this.state.error) {
      switch (playState) {
        case 'play': {
          this.wavesurfer?.setTime(finalPosition);
          this.wavesurfer!.play();
          break;
        }
        case 'stop': {
          this.cleanupFile();
          this.wavesurfer!.stop();
          return;
        }
        case 'pause': {
          this.wavesurfer!.pause();
          break;
        }
        default: {
          break;
        }
      }
    }

    if (this.state.error && playState !== 'pause') {
      this.wavesurfer!.pause();
      return;
    }

    if (seek !== prevProps.seek && Number.isFinite(seek)) {
      this.wavesurfer!.setTime(seek);
    }
  }

  componentWillUnmount() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
    this.cleanupFile();
    this.wavesurfer = undefined;
  }

  handleCloseClicked = (e: any) => {
    const { stop } = this.props;
    e.stopPropagation();
    // console.log(this.wavesurfer);
    // if (!this.state.error && this.wavesurfer.media) {
    //   this.wavesurfer.stop();
    // }
    stop();
  };

  render() {
    const {
      playerPosition,
      app,
      trackId,
      audioFile,
      artist,
      offset,
      title,
      t,
    } = this.props;

    const { currentTime, error, ready, totalTime, buffering } = this.state;

    return (
      <Container
        initial={{ y: playerPosition === 'bottom' ? 80 + (offset || 0) : -90 }}
        exit={{ y: playerPosition === 'bottom' ? 80 + (offset || 0) : -90 }}
        transition={transition}
        animate={
          audioFile
            ? {
              y: 0,
            }
            : { y: playerPosition === 'bottom' ? 80 + (offset || 0) : -90 }
        }
      >
        <Card>
          <Block
            height={app === 'admin' ? 48 : 64}
            direction="row"
            padding={app === 'admin' ? [4, 4, 4, 4] : PADDING}
          >
            <Block>
              <PlayPauseButton disabled={false} trackToBePlayed={trackId} />
            </Block>

            <TimeBlock>{formatOffset(currentTime)}</TimeBlock>

            <div id="player-controls" />

            <Block direction="row" flex={1}>
              {buffering && <Buffering />}

              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  pointerEvents: !ready ? 'none' : 'all',
                }}
              >
                <Block flex={1}>
                  {error ? (
                    <div
                      style={{
                        justifyItems: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        display: 'flex',
                        flex: 1,
                        pointerEvents: !ready ? 'none' : 'all',
                      }}
                    >
                      {t('audio-playback-error')}
                    </div>
                  ) : null}
                  <div
                    ref={this.audioContainer}
                    style={error ? { display: 'none' } : undefined}
                  />
                </Block>
              </div>
            </Block>

            <TimeBlock>{formatOffset(totalTime)}</TimeBlock>

            <LabelBlock>
              <TitleArtistLabel>{title || 'Untitled'}</TitleArtistLabel>

              <TitleArtistLabel>{artist || 'Unknown artist'}</TitleArtistLabel>
            </LabelBlock>

            <IconButton
              icon={Icons.actions.close}
              onClick={this.handleCloseClicked}
            />
          </Block>
        </Card>
      </Container>
    );
  }
}

export default function ConnectedAudioPlayer(props: BaseProps) {
  const { t } = useTranslation();
  const playerData = usePlayer();
  const app = useContext(AppContext);
  return (
    <ErrorBoundary componentName="AudioPlayer" extra={props}>
      <AudioPlayer {...props} app={app} {...playerData} t={t} />
    </ErrorBoundary>
  );
}
