import { useEffect } from 'react';
import qs from 'qs';
import { camelizeKeys } from 'humps';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSignupCodes } from 'imddata/actionTypes/ui/signup';
import type { ReduxState } from 'imddata';

const EMPTY_CODES = {
  referral: undefined,
  affiliate: undefined,
};

export const useSignupCodes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const { affiliatePartnerToken, referralToken } = camelizeKeys(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })
    ) as qs.ParsedQs;

    if (affiliatePartnerToken || referralToken) {
      dispatch(
        setSignupCodes({
          referral:
            typeof referralToken === 'string' ? referralToken : undefined,
          affiliate:
            typeof affiliatePartnerToken === 'string'
              ? affiliatePartnerToken
              : undefined,
        })
      );
    }
  }, []);

  const codes = useSelector((state: ReduxState) => {
    return state.ui.signup.codes
      ? {
          referral: state.ui.signup.codes.referral,
          affiliate: state.ui.signup.codes.affiliate,
        }
      : EMPTY_CODES;
  });

  return codes;
};
