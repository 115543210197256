import React, {
  useRef,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import config from 'imdconfig';
import { useTranslation } from 'react-i18next';
import { CheckboxField, useCurrentLocale } from 'imdshared';
import { createInputWithStyles, NewInput } from 'imdui';
import MaskedInput from 'react-text-mask';
import styled from '@emotion/styled';
import { CustomerPaymentMethod } from 'components';
import type { FieldProps } from './SecuredFormField';
import SecuredFormField from './SecuredFormField';
import { NewPaymentOption, PaymentOption } from '../PaymentOption';
import * as cpf from './cpf';
import type { GatewayProps } from '../types';

const SecuredFormFieldStyled = styled(SecuredFormField)``;
const Row = styled.div`
  ${SecuredFormFieldStyled} {
    margin-bottom: 0px;
    &:nth-child(2) {
      margin-left: 16px;
    }
  }
`;

const CardForm = styled.div<{ isHostedFieldsReady?: boolean }>`
  position: relative;
  pointer-events: ${({ isHostedFieldsReady }) =>
    !isHostedFieldsReady ? 'none' : 'initial'};
`;

const GatewayStyled = styled.form`
  ${Row} {
    display: flex;
    margin-bottom: 16px;
    ${SecuredFormFieldStyled} {
      margin-bottom: 0;
    }
  }
  ${SecuredFormFieldStyled} {
    flex: 1;
    margin-bottom: 16px;
  }
`;

const CPF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const StyledMaskedInput = createInputWithStyles(MaskedInput);

type SocialSecurityInputProps = Parameters<typeof NewInput>[0];

const renderSocialSecurityInput: SocialSecurityInputProps['renderInput'] = (
  props
) => (
  // @ts-ignore
  <StyledMaskedInput
    {...props}
    guide={false}
    placeholder="123.456.789-09"
    mask={CPF_MASK}
  />
);

const SocialSecurityInput = (props: SocialSecurityInputProps) => {
  return <NewInput {...props} renderInput={renderSocialSecurityInput} />;
};

type State = {
  values: {
    storePaymentMethod: boolean;
    encryptedCardNumber?: string;
    encryptedSecurityCode?: string;
    encryptedExpiryDate?: string;
    socialSecurityNumber?: string;
    holderName?: string;
  };
  isFormValid: boolean;
  isHostedFieldsReady: boolean;
  brand: undefined | string;
} & Record<FieldType, FieldProps>;

const getInitialCardState = (formData: {
  enforceStorePaymentMethod?: boolean;
  originKey?: string;
  allowSocialSecurityNumber?: boolean;
}): State => ({
  isHostedFieldsReady: false,
  isFormValid: false,
  holderName: { isValid: false },
  storePaymentMethod: { isValid: true },
  encryptedCardNumber: {},
  encryptedSecurityCode: {},
  encryptedExpiryDate: {},
  socialSecurityNumber: { isValid: true },
  brand: undefined,
  values: { storePaymentMethod: !!formData.enforceStorePaymentMethod },
});

const CHANGE_ENCRYPTED_FIELD = 'CHANGE_ENCRYPTED_FIELD';
const FOCUS_FIELD = 'FOCUS_FIELD';
const VALIDATE_FIELD = 'VALIDATE_FIELD';
const CHANGE_SOCIAL_SECURITY = 'CHANGE_SOCIAL_SECURITY';
const CHANGE_HOLDER_NAME = 'CHANGE_HOLDER_NAME';
const CHANGE_STORE_METHOD = 'CHANGE_STORE_METHOD';
const FIELDS_READY = 'FIELDS_READY';
const BRAND_DETECTED = 'BRAND_DETECTED';
// const AUTOCOMPLETE_ENCRYPTED_FIELDS = 'AUTOCOMPLETE_ENCRYPTED_FIELDS';

type FieldType =
  | 'holderName'
  | 'encryptedExpiryDate'
  | 'encryptedSecurityCode'
  | 'encryptedCardNumber'
  | 'storePaymentMethod'
  | 'socialSecurityNumber';

type FocusPayload = {
  focus: boolean;
  fieldType: FieldType;
};
type ValidatePayload = {
  errorI18n: string;
  error: string;
  fieldType: FieldType;
};

type Action =
  | {
    type: typeof CHANGE_ENCRYPTED_FIELD;
    payload: {
      isFormValid: boolean;
      values: Record<string, string>;
    };
  }
  | {
    type: typeof FIELDS_READY;
    payload: {
      isHostedFieldsReady: boolean;
    };
  }
  | {
    type: typeof FOCUS_FIELD;
    payload: FocusPayload;
  }
  | {
    type: typeof VALIDATE_FIELD;
    payload: ValidatePayload;
  }
  | {
    type: typeof CHANGE_STORE_METHOD;
    payload: {
      value: boolean;
    };
  }
  | {
    type: typeof BRAND_DETECTED;
    payload: {
      brand: string;
    };
  }
  | {
    type: typeof CHANGE_HOLDER_NAME;
    payload: {
      value: string;
    };
  }
  | {
    type: typeof CHANGE_SOCIAL_SECURITY;
    payload: {
      value: string;
    };
  };

type Reducer = (s: State, a: Action) => State;

const cardReducer: Reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_ENCRYPTED_FIELD: {
      const {
        payload: { isFormValid, values },
      } = action;
      return {
        ...state,
        values: { ...state.values, ...values },
        isFormValid,
      };
    }
    case BRAND_DETECTED: {
      return {
        ...state,
        brand: action.payload.brand,
      };
    }
    case FIELDS_READY: {
      return {
        ...state,
        isHostedFieldsReady: action.payload.isHostedFieldsReady,
      };
    }
    case FOCUS_FIELD: {
      const {
        payload: { focus, fieldType },
      } = action;
      return {
        ...state,
        [fieldType]: {
          ...state[fieldType],
          isFocused: focus,
          blurred: state[fieldType].isFocused && !focus,
        },
      };
    }
    case VALIDATE_FIELD: {
      const {
        payload: { errorI18n, error, fieldType },
      } = action;
      return {
        ...state,
        [fieldType]: {
          ...state[fieldType],
          hasError: !!error,
          error: errorI18n,
        },
      };
    }
    case CHANGE_STORE_METHOD: {
      const {
        payload: { value },
      } = action;
      return {
        ...state,
        values: {
          ...state.values,
          storePaymentMethod: value,
        },
      };
    }
    case CHANGE_HOLDER_NAME: {
      const {
        payload: { value },
      } = action;
      return {
        ...state,
        holderName: {
          ...state.holderName,
          isValid: !!value,
        },
        values: {
          ...state.values,
          holderName: value,
        },
      };
    }
    case CHANGE_SOCIAL_SECURITY: {
      const {
        payload: { value },
      } = action;
      return {
        ...state,
        socialSecurityNumber: {
          ...state.socialSecurityNumber,
          isValid: value ? cpf.isValid(value) : true,
        },
        values: {
          ...state.values,
          socialSecurityNumber: value,
        },
      };
    }
    default:
      return state;
  }
};

type Props = {
  checked: boolean;
  children?: React.ReactNode;
  formData: {
    fee?: string;
    enforceStorePaymentMethod?: boolean;
    originKey?: string;
    allowSocialSecurityNumber?: boolean;
  };
  onPaymentChange: GatewayProps['onPaymentChange'];
};

function AdyenCardForm({
  checked,
  children,
  onPaymentChange,
  formData,
}: Props): React.ReactElement {
  const clientKey = config?.imdfront?.publicPaymentKey || formData.originKey;
  const { enforceStorePaymentMethod } = formData;
  const displaySocialSecurityNumber = formData.allowSocialSecurityNumber;

  const locale = useCurrentLocale();
  const { t } = useTranslation();
  const rootNode = useRef<HTMLFormElement>(null);
  const initialCardState = useMemo(
    () => getInitialCardState(formData),
    [formData]
  );
  const [
    {
      brand,
      holderName,
      socialSecurityNumber,
      isHostedFieldsReady,
      isFormValid,
      values,
      encryptedSecurityCode,
      encryptedExpiryDate,
      encryptedCardNumber,
    },
    dispatchCardEvent,
  ] = useReducer<Reducer>(cardReducer, initialCardState);

  const handleChangeHolderNameField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatchCardEvent({
        type: CHANGE_HOLDER_NAME,
        payload: { value: event.target.value },
      });
    },
    []
  );

  const handleFocusHolderNameField = useCallback(() => {
    dispatchCardEvent({
      type: FOCUS_FIELD,
      payload: { fieldType: 'holderName', focus: true },
    });
  }, []);

  const handleBlurHolderNameField = useCallback(() => {
    dispatchCardEvent({
      type: FOCUS_FIELD,
      payload: { fieldType: 'holderName', focus: false },
    });
  }, []);

  const handleChangeSocialSecurityField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatchCardEvent({
        type: CHANGE_SOCIAL_SECURITY,
        payload: { value: event.target.value },
      });
    },
    []
  );

  const handleFocusSocialSecurityField = useCallback(() => {
    dispatchCardEvent({
      type: FOCUS_FIELD,
      payload: { fieldType: 'socialSecurityNumber', focus: true },
    });
  }, []);

  const handleBlurSocialSecurityField = useCallback(() => {
    dispatchCardEvent({
      type: FOCUS_FIELD,
      payload: { fieldType: 'socialSecurityNumber', focus: false },
    });
  }, []);

  const checkoutStyles = useMemo(() => {
    let onSurface = '#222';

    if (window) {
      onSurface = window
        .getComputedStyle(document.body)
        .getPropertyValue('--on-surface');
    }

    return {
      base: {
        background: 'transparent !important',
        color: onSurface,
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        opacity: 1,
        fontFamily:
          "Barlow -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      },
      error: {
        color: onSurface,
      },
      placeholder: {
        fontWeight: '400',
        color: onSurface,
        opacity: 0.7,
      },
      validated: {
        color: onSurface,
      },
    };
  }, []);

  const checkout = useMemo(() => {
    return new AdyenCheckout({
      styles: checkoutStyles,
      locale,
      environment: config.paymentEnv,
      translations: {
        [locale]: {
          'creditCard.numberField.placeholder': t('card-number'),
          'creditCard.expiryDateField.placeholder': t('expiration-date'),
          'creditCard.cvcField.placeholder': t('cvv'),
        },
      },
      clientKey,
      onChange: (payload: {
        isValid: boolean;
        data: { paymentMethod: any };
      }) => {
        const {
          isValid,
          data: {
            paymentMethod: { type, ...encryptedValues },
          },
        } = payload;
        dispatchCardEvent({
          type: CHANGE_ENCRYPTED_FIELD,
          payload: { values: encryptedValues, isFormValid: isValid },
        });
      },
      onConfigSuccess: ({
        iframesConfigured,
      }: {
        iframesConfigured: boolean;
      }) => {
        dispatchCardEvent({
          type: 'FIELDS_READY',
          payload: { isHostedFieldsReady: iframesConfigured },
        });
      },
      onError: (payload: ValidatePayload) =>
        dispatchCardEvent({ type: VALIDATE_FIELD, payload }),
      onFocus: (payload: FocusPayload) =>
        dispatchCardEvent({ type: FOCUS_FIELD, payload }),
      onBrand: (payload: { brand: string }) => {
        dispatchCardEvent({
          type: BRAND_DETECTED,
          payload: { brand: payload.brand },
        });
      },
      // onLoad: console.log,
      // onAutoComplete: console.log,
    });
  }, []);

  const mountedNode = useRef<null | unknown>(null);

  useEffect(() => {
    if (rootNode.current) {
      mountedNode.current = checkout
        .create('securedfields')
        .mount(rootNode.current);
    } else {
      throw new Error('No node found for credit card form');
    }
    return () => {
      checkout.remove(mountedNode.current);
      mountedNode.current = null;
    };
  }, []);

  const paymentNotReady =
    !socialSecurityNumber.isValid ||
    !isFormValid ||
    !isHostedFieldsReady ||
    !holderName.isValid;

  const handleChange = useCallback(() => {
    onPaymentChange({
      id: 'new-card',
      paymentMethod: 'card',
      isReady: !paymentNotReady,
      data: {
        paymentMethodId: 'card',
        data: values.socialSecurityNumber
          ? {
            ...values,
            socialSecurityNumber: cpf.strip(values.socialSecurityNumber),
          }
          : values,
      },
    });
  }, [paymentNotReady, values]);

  useEffect(() => {
    if (checked) handleChange();
  }, [paymentNotReady, values]);

  useEffect(() => {
    checkout.components[0].updateStyles(checkoutStyles);
  }, [isHostedFieldsReady]);

  console.log(checkout.modules.i18n.translations);
  return (
    <NewPaymentOption selected={checked} data-test-id="new-payment-option-card">
      <PaymentOption
        checked={checked}
        onCheck={checked ? undefined : handleChange}
        formChildren={
          <GatewayStyled ref={rootNode} key="form" id="adyen-card">
            <div>
              <div>
                <CardForm
                  isHostedFieldsReady={isHostedFieldsReady}
                  data-test-id={`HostedFields-${isHostedFieldsReady ? 'ready' : 'initializing'
                    }`}
                  className=".js-chckt-pm__pm-holder"
                >
                  <input type="hidden" name="txvariant" value="card" />
                  <SecuredFormFieldStyled
                    style={{ minWidth: '120px' }}
                    id="encryptedCardNumber"
                    field={encryptedCardNumber}
                  />
                  <Row>
                    <SecuredFormFieldStyled
                      id="encryptedExpiryDate"
                      field={encryptedExpiryDate}
                    />
                    <SecuredFormFieldStyled
                      id="encryptedSecurityCode"
                      field={encryptedSecurityCode}
                    />
                  </Row>

                  <NewInput
                    name="holderName"
                    testId="holderName"
                    style={{ marginBottom: '16px' }}
                    onBlur={handleBlurHolderNameField}
                    onFocus={handleFocusHolderNameField}
                    errorText={holderName.blurred && !holderName.isValid}
                    placeholder={
                      checkout.modules.i18n.translations[
                      'creditCard.holderName'
                      ]
                    }
                    value={values.holderName}
                    onChange={handleChangeHolderNameField}
                  />
                  {displaySocialSecurityNumber && (
                    <SocialSecurityInput
                      onBlur={handleBlurSocialSecurityField}
                      onFocus={handleFocusSocialSecurityField}
                      errorText={
                        socialSecurityNumber.blurred &&
                        !socialSecurityNumber.isValid
                      }
                      helperText={t('social-security-number-helpertext-short')}
                      label={t('social-security-number')}
                      value={values.socialSecurityNumber}
                      onChange={handleChangeSocialSecurityField}
                    />
                  )}
                  {!enforceStorePaymentMethod && (
                    <CheckboxField
                      // @ts-ignore
                      meta={{}}
                      name="save-method"
                      text={t('save-payment-method')}
                      // @ts-ignore
                      input={{
                        value: values.storePaymentMethod,
                        onChange: (value) => {
                          dispatchCardEvent({
                            type: CHANGE_STORE_METHOD,
                            payload: {
                              value,
                            },
                          });
                        },
                      }}
                    />
                  )}
                </CardForm>
              </div>
            </div>

            {children ? <div style={{ marginTop: 16 }}>{children}</div> : null}
          </GatewayStyled>
        }
      >
        <CustomerPaymentMethod
          scheme="new-card"
          caption={formData?.fee ? formData?.fee + ' ' + t('fee') : undefined}
          data={{
            name: brand,
          }}
        />
      </PaymentOption>
    </NewPaymentOption>
  );
}

export default function AdyenCardGateway({
  paymentData,
  onPaymentChange,
  formData,
  children,
}: GatewayProps) {
  if (!formData?.originKey) return null;

  return (
    <>
      <AdyenCardForm
        checked={!paymentData.id || paymentData.id === 'new-card'}
        onPaymentChange={onPaymentChange}
        formData={formData}
      >
        {children}
      </AdyenCardForm>
    </>
  );
}
