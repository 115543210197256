import styled from '@emotion/styled';
import React from 'react';
import { Clickable } from 'imdui';
import { useHistory } from 'react-router';
import { ChevronLeftIcon } from '@imus/base-ui';

const BackButton = styled(Clickable)`
  width: 48px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: var(--on-surface-semi);
  border-radius: 20px;
`;

const ReturnNavigationBase = (
  props:
    | {
      className?: string;
      style?: React.CSSProperties;
      text: string;
      to: string;
      children?: React.ReactNode;
    }
    | {
      className?: string;
      children?: React.ReactNode;
      style?: React.CSSProperties;
      text: string;
      onClick: () => void;
    }
) => {
  const history = useHistory();
  return (
    <div style={props.style} className={props.className}>
      <BackButton
        onClick={
          'to' in props
            ? () => {
              history.push(props.to);
            }
            : props.onClick
        }
      >
        <ChevronLeftIcon />
      </BackButton>
      <span>{props.text}</span>
      {props.children}
    </div>
  );
};

export const ReturnNavigation = styled(ReturnNavigationBase)`
  min-height: 40px;
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  gap: 16px;
  font-family: 'Barlow';
  color: var(--on-surface);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;
