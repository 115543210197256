//
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useRelease, getReleaseCover, useLatestOpenBundles } from 'imddata';
import {
  ArtworkImage,
  BodySmall,
  Card,
  ContentSmall,
  OverlineText,
} from 'imdui';
import { LinkButton, Content } from 'imdshared';
import { ErrorBoundary } from 'components';

const StyledArtworkImage = styled(ArtworkImage)`
  width: 96px;
  height: 96px;
`;

const TextGrid = styled.div`
  flex: 1;
  overflow: hidden;
  gap: 16px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  ${ContentSmall} {
    display: block;
    color: var(--on-surface-var-2, #5f5e5d);
    font-feature-settings: 'ss01' on;
  }

  > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: var(--outline-var, #c9c6c4);
`;

const Container = styled.div`
  display: grid;
  gap: 24px;
  padding: 24px;
`;

const StepText = styled.span`
  margin-bottom: 4px;
  display: block;
  color: var(--on-surface, #1c1c1b);
  font-feature-settings:
    'ss01' on,
    'cv11' on;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
  text-transform: uppercase;
`;

const DistributionInProgressCard = ({
  releaseId,
  bundleId,
}: {
  releaseId?: number;
  bundleId?: number;
  loading?: boolean;
}) => {
  const { t } = useTranslation();
  const { entry: release } = useRelease({ id: releaseId, passive: !releaseId });

  if (!release) {
    return null;
  }

  const artworkSrc = getReleaseCover(release);

  return (
    <Container>
      <OverlineText
        style={{ margin: '0' }}
        size="large"
        label={t('distribution-in-progress')}
      />
      <TextGrid>
        <StyledArtworkImage src={artworkSrc} shadow="medium" />

        <div>
          <Content>
            {release.defaultNameNormalized?.title || t('unknown-release')}
          </Content>

          <ContentSmall>{`${t(release.releaseTypeId)}`}</ContentSmall>
        </div>
      </TextGrid>

      <Separator />
      <div>
        <StepText>{t('release-details')}</StepText>
        <BodySmall>
          {t('complete-form-and-proceed-to-distribution', {
            defaultValue: 'Complete form and proceed to distribution',
          })}
        </BodySmall>
        <LinkButton
          style={{ width: '100%', marginTop: '24px' }}
          size="small"
          appearance="fill"
          to={`/order/md/${bundleId}`}
          text={t('resume')}
        />
      </div>
    </Container>
  );
};

const SelectDeliveryBundle = () => {
  const {
    request: { loading, loaded },
    entries,
    refresh,
  } = useLatestOpenBundles();

  // Only run refresh if already loaded on mount
  useEffect(() => {
    if (loaded) {
      refresh();
    }
  }, []);

  const bundle = entries[0];
  if (loading || !bundle) return null;

  return (
    <Card>
      <ErrorBoundary>
        <DistributionInProgressCard
          releaseId={bundle?.releases[0]?.releaseId}
          bundleId={bundle?.id}
          loading={loading}
        />
      </ErrorBoundary>
    </Card>
  );
};

export default SelectDeliveryBundle;
