export const staticRoles = { remixer: true };
export const roles = [
  {
    label: 'artist-role-main',
    value: 'main',
    joinString: '',
    displayArtist: true,
  },
  {
    label: 'artist-role-meets',
    value: 'meets',
    joinString: 'meets',
    displayArtist: true,
  },
  {
    label: 'artist-role-versus',
    value: 'versus',
    joinString: 'vs.',
    displayArtist: true,
  },
  {
    label: 'artist-role-with',
    value: 'with',
    joinString: 'with',
    displayArtist: true,
  },
  {
    label: 'artist-role-featuring',
    value: 'featuring',
    joinString: 'feat.',
    displayArtist: true,
  },
  {
    label: 'artist-role-presented-by',
    value: 'presented_by',
    joinString: 'presented by',
    displayArtist: true,
  },
  {
    label: 'artist-role-remixed-by',
    value: 'remixer',
    joinString: '',
    static: true,
    displayArtist: false,
  },
];

export const trackRoles = [...roles];

export const releaseRoles = [...roles];
