import React, { useMemo, useState, useContext, Component } from 'react';
import { connect } from 'react-redux';
import { useFeature } from 'imdfeature';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import type { TrackNested, EntityModels } from 'imddata';
import {
  EntityFormContext,
  useTracksProvider,
  AppContext,
  useIsAdmin,
} from 'imddata';
import { find, uniqWith, equals } from 'ramda';
import { updateEntity as updateEntityAction } from 'imddata/actionTypes/request';
import { FieldGroup, Button, ComponentIcons, HelpWindowContext } from 'imdui';
import type { TFunction } from 'i18next';
import moment from 'moment';
import { createSelector as createFormSelector } from '../../../helpers';
import LabelTextField from '../../LabelTextField';
import { TrackArtistJoinField } from '../../TrackArtistJoin';
import { PerformerJoinField } from '../../PerformerJoin';
import { PublisherJoinField } from '../../PublisherJoin';
import { ContributorJoinField } from '../../ContributorJoin';
import {
  FieldUpdatable,
  GenreSelectField,
  NewInputField,
  EnabledField,
  EnabledFieldGroup,
} from '../../../fields';
import AudioLanguageField from '../shared/AudioLanguageField';
import { validate } from '../shared';
import type { JoinTagValue } from '../../JoinTags/types';

type Props = {
  form?: string;
  editableFields: ReturnType<typeof useTracksProvider>['editableFields'];
  languageId?: string;
  disabled?: boolean;
  tracks: number[];
  shouldTouch?: boolean;
  versionDisabled: boolean | undefined;
  initialValues?: FormData;
  requiredFields?: string[];
  collidedFields?: Collided;
  showHelpWindow: React.ContextType<typeof HelpWindowContext>;
  t: TFunction;
  updateEntity: typeof updateEntityAction;
  setShowContributorOptions: (v: boolean) => void;
  setShowPublisherOptions: (v: boolean) => void;
  showPublisherOptions?: boolean;
  showContributorOptions?: boolean;
  traditonalTracksEnabled?: boolean;
  additionalValidation?: (...args: unknown[]) => Record<string, any>;
  displayedFields?: {
    copyrightText?: boolean;
    publishers?: boolean;
    performers?: boolean;
    audioLanguageId?: boolean;
  };
};

const SelectHasNoPublishers = createFormSelector('hasNoPublishers');
const SelectIsCoverVersion = createFormSelector('isCoverVersion');

class MultiEditForm extends Component<
  Props & InjectedFormProps<FormData, Props>,
  Record<string, never>,
  string
> {
  componentDidMount() {
    const { requiredFields, shouldTouch, touch } = this.props;
    if (requiredFields && shouldTouch) {
      requiredFields.forEach((field) => touch(field));
    }
    if (window && window.analytics) {
      const { tracks } = this.props;
      window.analytics.track('FT Multiediting Tracks', {
        tracks,
      });
    }
  }

  showHelpWindow = (title: string, text: string) => () => {
    const { showHelpWindow } = this.props;
    showHelpWindow(title, text);
  };

  // @ts-ignore
  // eslint-disable-next-line
  handleTrackField = (key: string) => (event: any, value?: any) => {
    const { updateEntity, form, tracks } = this.props;
    updateEntity(
      'tracks',
      {
        formId: form,
        query: { with: 'artists,publishers,contributors' },
        data: {
          id: tracks,
          payload: { [key]: value },
        },
      },
      { multiedit: true, debounce: true }
    );
  };

  handleNoPublishers = (event: any, value: any) => {
    const { change } = this.props;
    change('publishers', []);
    this.handleTrackField('publishers')(null, []);
    this.handleTrackField('hasNoPublishers')(null, value);
  };

  handleIsCoverVersion = (event: any, value: any) => {
    const { updateEntity, tracks, form, change } = this.props;
    change('publishers', []);

    updateEntity(
      'tracks',
      {
        formId: form,
        query: { with: 'artists,publishers,contributors' },
        data: {
          id: tracks,
          payload: {
            isCoverVersion: value,
            ...(value === true
              ? {
                hasTraditionalComposer: false,
                hasTraditionalLyricist: false,
              }
              : {}),
          },
        },
      },
      { multiedit: true, debounce: true }
    );

    if (value === true) {
      change('hasTraditionalLyricist', false);
      change('hasTraditionalComposer', false);
    }
  };

  handlePublishers = (event: any, value: JoinTagValue[]) => {
    const { change } = this.props;
    if (value && value.length) {
      change('hasNoPublishers', false);
      this.handleTrackField('publishers')(null, value);
      this.handleTrackField('hasNoPublishers')(null, false);
    }
    this.handleTrackField('publishers')(null, value);
  };

  handleContributors = (event: any, value: JoinTagValue[]) => {
    this.handleTrackField('nonPerformingContributors')(null, value);
  };

  componentDidUpdate(prevProps: Props) {
    const { tracks, initialValues, initialize } = this.props;
    if (!equals(tracks, prevProps.tracks)) {
      initialize(initialValues);
    }
  }

  render() {
    const {
      t,
      // disabled,
      editableFields,
      languageId,
      requiredFields,
      form,
      versionDisabled,
      setShowPublisherOptions,
      showPublisherOptions,
      displayedFields,
      showContributorOptions,
      traditonalTracksEnabled,
      setShowContributorOptions,
      collidedFields,
    } = this.props;

    const valueOverride = t('changing-field-will-override-previous-value');

    const performersSection = (
      <>
        <FieldUpdatable
          name="performers"
          valueOverride={collidedFields?.performers && valueOverride}
          label={t('performers-details')}
          component={LabelTextField}
          onClickHelp={this.showHelpWindow(
            t('track-helptext-short-performers'),
            t('track-helptext-performers')
          )}
        />

        <FieldUpdatable
          testId="performers"
          name="performers"
          disabled={!editableFields.performers}
          label={t('performers')}
          warningText={collidedFields?.performers && valueOverride}
          valueOverride={collidedFields?.performers && valueOverride}
          onChange={this.handleTrackField('performers')}
          languageId={languageId}
          component={PerformerJoinField}
        />
      </>
    );

    return (
      <>
        <FieldGroup data-test-id="TrackForms-Multi">
          <Field
            name="isInstrumental"
            disabled={!editableFields.contributors}
            label={t('instrumental')}
            // @ts-ignore
            component={EnabledField}
            style={{ marginBottom: '8px' }}
            onChange={this.handleTrackField('isInstrumental')}
            warningText={collidedFields?.isInstrumental && valueOverride}
          />
          <FieldUpdatable
            name="isCoverVersion"
            disabled={!editableFields.isCoverVersion}
            label={t('cover-version')}
            component={EnabledField}
            warningText={collidedFields?.isCoverVersion && valueOverride}
            style={{ marginBottom: '8px' }}
            onChange={this.handleIsCoverVersion}
          />
          <FieldUpdatable
            name="isLiveVersion"
            disabled={!editableFields.isLiveVersion}
            label={t('live-version')}
            component={EnabledField}
            warningText={collidedFields?.isLiveVersion && valueOverride}
            onChange={this.handleTrackField('isLiveVersion')}
          />
        </FieldGroup>
        <FieldGroup>
          {/*
           * Can not be Field Updatable, since this special case for the multiedit endpoint
           * TODO: add support for providing default track_names ids to be requested for FUR?
           */}
          <Field
            testId="version"
            name="version"
            disabled={!editableFields.names || versionDisabled}
            onChange={this.handleTrackField('version')}
            label={t('track-version')}
            warningText={collidedFields?.version && valueOverride}
            valueOverride={collidedFields?.version && valueOverride}
            helperText={t('track-helptext-short-version')}
            onClickHelp={this.showHelpWindow(
              t('track-version'),
              t('track-helptext-version')
            )}
            component={NewInputField}
          />
        </FieldGroup>

        {displayedFields?.audioLanguageId ? (
          <FieldGroup>
            <AudioLanguageField
              requiredFields={requiredFields}
              form={form}
              showHelpWindow={this.showHelpWindow}
              handleTrackField={this.handleTrackField}
            />
          </FieldGroup>
        ) : null}

        {displayedFields?.copyrightText ||
          requiredFields?.includes('copyrightText') ? (
          <FieldGroup>
            <FieldUpdatable
              name="copyrightText"
              // It exists
              // @ts-ignore
              testId="copyrightText"
              disabled={!editableFields.copyrightText}
              label={t('copyright-text')}
              onChange={this.handleTrackField('copyrightText')}
              valueOverride={collidedFields?.copyrightText && valueOverride}
              component={NewInputField}
            />
          </FieldGroup>
        ) : null}

        <FieldGroup>
          <Field
            name="genreId"
            // It exists
            // @ts-ignore
            testId="genreId"
            disabled={!editableFields.genreId}
            onChange={this.handleTrackField('genreId')}
            valueOverride={collidedFields?.genreId && valueOverride}
            component={GenreSelectField}
          />
        </FieldGroup>

        <FieldGroup>
          <Field
            name="artists"
            label={t('artists-details')}
            onClickHelp={this.showHelpWindow(
              t('artists'),
              t('track-helptext-artists')
            )}
            component={LabelTextField}
            valueOverride={collidedFields?.artists && valueOverride}
          />

          <FieldUpdatable
            name="artists"
            data-test-id="artists-jointag"
            testId="artists"
            disabled={!editableFields.artists}
            component={TrackArtistJoinField}
            onChange={this.handleTrackField('artists')}
            languageId={languageId}
            helperText={t('track-helptext-short-artists')}
            warningText={collidedFields?.artists && valueOverride}
            valueOverride={collidedFields?.artists && valueOverride}
          />
        </FieldGroup>

        <FieldGroup>
          <FieldUpdatable
            name="nonPerformingContributors"
            entityFieldName="nonPerformingContributors"
            label={t('contributors-details')}
            onClickHelp={this.showHelpWindow(
              t('contributors-details'),
              t('track-helptext-contributors')
            )}
            component={LabelTextField}
            valueOverride={
              collidedFields?.nonPerformingContributors && valueOverride
            }
          />

          <FieldUpdatable
            name="nonPerformingContributors"
            entityFieldName="nonPerformingContributors"
            data-test-id="contributors"
            component={ContributorJoinField}
            onChange={this.handleContributors}
            disabled={!editableFields.contributors}
            languageId={languageId}
            testId="contributors"
            helperText={t('track-helptext-short-contributors')}
            warningText={
              collidedFields?.nonPerformingContributors && valueOverride
            }
            valueOverride={
              collidedFields?.nonPerformingContributors && valueOverride
            }
            extraButton={
              <Button
                size="small"
                position="center"
                text={t('options')}
                style={{
                  flex: 'none',
                  display:
                    traditonalTracksEnabled || showContributorOptions
                      ? 'block'
                      : 'none',
                }}
                icon={
                  !showContributorOptions
                    ? ComponentIcons.ChevronDown
                    : ComponentIcons.ChevronUp
                }
                onClick={() => {
                  setShowContributorOptions(!showContributorOptions);
                }}
              />
            }
          >
            {showContributorOptions && (
              <SelectIsCoverVersion form={form}>
                {(isCoverVersion: boolean) => (
                  <EnabledFieldGroup style={{ marginTop: '16px' }}>
                    <FieldUpdatable
                      name="hasTraditionalComposer"
                      disabled={!editableFields.contributors || isCoverVersion}
                      label={t('traditional-composer')}
                      description={t('traditional-composer-description', {
                        context: isCoverVersion ? 'cover-version' : '',
                      })}
                      component={EnabledField}
                      onChange={this.handleTrackField('hasTraditionalComposer')}
                    />
                    <FieldUpdatable
                      name="hasTraditionalLyricist"
                      disabled={!editableFields.contributors || isCoverVersion}
                      label={t('traditional-lyricist')}
                      description={t('traditional-lyricist-description', {
                        context: isCoverVersion ? 'cover-version' : '',
                      })}
                      component={EnabledField}
                      onChange={this.handleTrackField('hasTraditionalLyricist')}
                    />
                  </EnabledFieldGroup>
                )}
              </SelectIsCoverVersion>
            )}
          </FieldUpdatable>
        </FieldGroup>

        {displayedFields?.performers ||
          requiredFields?.includes('performers') ? (
          <FieldGroup>{performersSection}</FieldGroup>
        ) : null}
        <SelectIsCoverVersion form={form}>
          {(isCoverVersion: boolean) =>
            isCoverVersion || displayedFields?.publishers ? (
              <FieldGroup>
                <FieldUpdatable
                  name="publishers"
                  label={t('publishers-details')}
                  onClickHelp={this.showHelpWindow(
                    t('publishers-details'),
                    t('track-helptext-publishers')
                  )}
                  component={LabelTextField}
                  valueOverride={collidedFields?.publishers && valueOverride}
                />

                <SelectHasNoPublishers form={form}>
                  {(checked: boolean) => (
                    <FieldUpdatable
                      name="publishers"
                      data-test-id="publishers-jointag"
                      component={PublisherJoinField}
                      valueOverride={
                        collidedFields?.publishers && valueOverride
                      }
                      onChange={this.handlePublishers}
                      disabled={!editableFields.publishers || checked}
                      languageId={languageId}
                      testId="publishers"
                      helperText={t('track-helptext-short-publishers')}
                      label={t('publishers')}
                      warningText={collidedFields?.publishers && valueOverride}
                      extraButton={
                        <Button
                          position="center"
                          text={t('optional')}
                          icon={
                            !showPublisherOptions
                              ? ComponentIcons.ChevronDown
                              : ComponentIcons.ChevronUp
                          }
                          onClick={() => {
                            setShowPublisherOptions(!showPublisherOptions);
                          }}
                        />
                      }
                    >
                      {showPublisherOptions && (
                        <FieldUpdatable
                          name="hasNoPublishers"
                          type="checkbox"
                          style={{ marginTop: '16px' }}
                          label={t('no-publisher-available')}
                          description={t('no-publisher-available-description')}
                          component={EnabledField}
                          disabled={!editableFields.publishers}
                          // @ts-ignore
                          onChange={this.handleNoPublishers}
                        />
                      )}
                    </FieldUpdatable>
                  )}
                </SelectHasNoPublishers>
              </FieldGroup>
            ) : null
          }
        </SelectIsCoverVersion>

        <FieldGroup>
          <FieldUpdatable
            name="hasExplicitContent"
            disabled={!editableFields.hasExplicitContent}
            label={t('explicit-content')}
            component={EnabledField}
            onChange={this.handleTrackField('hasExplicitContent')}
          />
        </FieldGroup>
      </>
    );
  }
}

type SupportedValues =
  | 'version'
  | 'copyrightYear'
  | 'genreId'
  | 'audioLanguageId'
  | 'isCoverVersion'
  | 'isLiveVersion'
  | 'hasExplicitContent'
  | 'isMedley'
  | 'isInstrumental'
  | 'copyrightText'
  | 'artists'
  | 'hasNoPublishers'
  | 'nonPerformingContributors'
  | 'publishers'
  | 'performers'
  | 'revenueSplits'
  | 'hasTraditionalComposer'
  | 'hasTraditionalLyricist';

const defaultValues: Record<SupportedValues, []> = {
  version: [],
  revenueSplits: [],
  copyrightYear: [],
  genreId: [],
  audioLanguageId: [],
  isCoverVersion: [],
  isLiveVersion: [],
  hasExplicitContent: [],
  isMedley: [],
  isInstrumental: [],
  copyrightText: [],
  artists: [],
  hasNoPublishers: [],
  nonPerformingContributors: [],
  publishers: [],
  performers: [],
  hasTraditionalComposer: [],
  hasTraditionalLyricist: [],
};

type Collided = Partial<Record<SupportedValues, boolean>>;
type FormData = Partial<Record<SupportedValues, any>>;

const valueKeysToMerge = Object.keys(defaultValues) as SupportedValues[];

const MultiEditFormConnected = connect(null, {
  updateEntity: updateEntityAction,
})(
  reduxForm<FormData, Props, string>({
    validate,
    getFormState: (state) => state.form,
  })(MultiEditForm)
);

type MappedTrack = Omit<
  TrackNested,
  | 'performers'
  | 'artists'
  | 'publishers'
  | 'nonPerformingContributors'
  | 'revenueSplits'
> & {
  publishers: {
    publisherId: EntityModels.TrackPublisher['publisherId'];
  }[];
  revenueSplits: {
    share: number;
    revenueSplitTargetId: string;
  }[];
  artists: {
    role: EntityModels.TrackArtist['role'];
    artistId: EntityModels.TrackArtist['artistId'];
  }[];
  performers: {
    role: EntityModels.TrackContributor['role'];
    contributorId: EntityModels.TrackContributor['contributorId'];
  }[];
  nonPerformingContributors: {
    role: EntityModels.TrackContributor['role'];
    contributorId: EntityModels.TrackContributor['contributorId'];
  }[];
};

export const useTrackFormValues = ({
  ids,
  disabled,
}: {
  ids: number[];
  disabled?: boolean;
}) => {
  const { entries, editableFields } = useTracksProvider({ ids });

  const app = useContext(AppContext);

  const shouldTouch =
    entries.reduce(
      (acc, track) =>
        Math.max(acc, moment(track.updatedAt).diff(track.createdAt)),
      0
    ) > 3000;

  const tracks: MappedTrack[] = useMemo(
    () =>
      entries.map((track) => {
        return {
          ...track,
          revenueSplits:
            track.revenueSplits &&
            track.revenueSplits.map((trs) => {
              return {
                share: trs.share,
                revenueSplitTargetId: trs.revenueSplitTargetId,
              };
            }),
          performers:
            track.performers &&
            track.performers.map(({ role, contributorId }) => ({
              role,
              contributorId,
            })),
          artists:
            track.artists &&
            track.artists.map(({ role, artistId }) => ({
              role,
              artistId,
            })),
          publishers:
            track.publishers &&
            track.publishers.map(({ role, publisherId }) => ({
              role,
              publisherId,
            })),
          nonPerformingContributors:
            track.nonPerformingContributors &&
            track.nonPerformingContributors.map(({ role, contributorId }) => ({
              role,
              contributorId,
            })),
        };
      }),
    [ids, entries]
  );
  return useMemo(() => {
    if (!tracks)
      return {
        entries,
        editableFields,
        initialValues: undefined,
        collidedFields: undefined,
        disabled: true,
      };
    const trackModules = valueKeysToMerge.reduce<
      Record<SupportedValues, any[]>
    >(
      (acc, valueKey) => ({
        ...acc,
        [valueKey]: tracks.reduce(
          (valAcc, track) =>
            track[valueKey] !== undefined
              ? [...valAcc, track[valueKey]]
              : valAcc,
          acc[valueKey]
        ),
      }),
      defaultValues
    );
    const collidedFields = valueKeysToMerge.reduce<Collided>(
      (acc, value: SupportedValues) => {
        if (uniqWith(equals, trackModules[value]).length > 1) {
          return { ...acc, [value]: true };
        }
        return acc;
      },
      {}
    );

    collidedFields.nonPerformingContributors =
      collidedFields?.nonPerformingContributors ||
      collidedFields?.hasTraditionalLyricist ||
      collidedFields?.hasTraditionalComposer;

    collidedFields.publishers =
      collidedFields?.publishers || collidedFields.hasNoPublishers;

    const finalDisabled =
      disabled ||
      (app === 'front' &&
        find(
          (track) => track && track.deliveryStatus !== 'not_delivered',
          tracks
        ));

    const versionDisabled = !!tracks.find(
      (track) => track.namesNormalized?.length !== 1
    );

    return {
      shouldTouch,
      entries,
      editableFields,
      versionDisabled,
      initialValues: {
        revenueSplits: collidedFields?.revenueSplits
          ? []
          : trackModules.revenueSplits[0],
        genreId: collidedFields?.genreId ? null : trackModules.genreId[0],
        version: collidedFields?.version ? null : trackModules.version[0],
        hasExplicitContent: collidedFields?.hasExplicitContent
          ? false
          : trackModules.hasExplicitContent[0],
        isMedley: collidedFields?.isMedley ? false : trackModules.isMedley[0],
        isInstrumental: collidedFields?.isInstrumental
          ? false
          : trackModules.isInstrumental[0],
        isCoverVersion: collidedFields?.isCoverVersion
          ? false
          : trackModules.isCoverVersion[0],
        isLiveVersion: collidedFields?.isLiveVersion
          ? false
          : trackModules.isLiveVersion[0],
        copyrightText: collidedFields?.copyrightText
          ? null
          : trackModules.copyrightText[0],
        artists: collidedFields?.artists ? [] : trackModules.artists[0],
        performers: collidedFields?.performers
          ? []
          : trackModules.performers[0],

        hasTraditionalComposer: collidedFields?.hasTraditionalComposer
          ? false
          : !!trackModules.hasTraditionalComposer[0],
        hasTraditionalLyricist: collidedFields?.hasTraditionalLyricist
          ? false
          : !!trackModules.hasTraditionalLyricist[0],
        nonPerformingContributors: collidedFields?.nonPerformingContributors
          ? []
          : trackModules.nonPerformingContributors[0],
        hasNoPublishers: collidedFields?.publishers
          ? false
          : trackModules.hasNoPublishers[0],
        publishers: collidedFields?.publishers
          ? []
          : trackModules.publishers[0],
      },
      collidedFields,
      disabled: !!finalDisabled,
    };
  }, [tracks, disabled]);
};

type RequiredProps = {
  form?: string;
  tracks: number[];
  disabled?: boolean;
  requiredFields?: string[];
  additionalValidation?: (...args: unknown[]) => Record<string, any>;
  displayedFields?: {
    copyrightText?: boolean;
    publishers?: boolean;
    performers?: boolean;
    audioLanguageId?: boolean;
  };
};

const MultiEditFormWithOverlay = ({ tracks, ...props }: RequiredProps) => {
  const { t } = useTranslation();

  const showHelpWindow = useContext(HelpWindowContext);
  const {
    entries,
    shouldTouch,
    editableFields,
    initialValues,
    collidedFields,
    versionDisabled,
    disabled,
  } = useTrackFormValues({
    ids: tracks,
    disabled: props.disabled,
  });
  const entityFormValue = useMemo<React.ContextType<typeof EntityFormContext>>(
    () => ({ entity: 'tracks', id: tracks }),
    [tracks]
  );

  const [showPublisherOptions, setShowPublisherOptions] = useState(
    () => initialValues?.hasNoPublishers
  );

  const isAdmin = useIsAdmin();
  const [traditonalTracksEnabledFeature] = useFeature({
    featureKey: 'tradional-enabled',
  });
  const traditonalTracksEnabled = traditonalTracksEnabledFeature || isAdmin;
  const [showContributorOptions, setShowContributorOptions] = useState(
    () =>
      initialValues?.hasTraditionalLyricist ||
      initialValues?.hasTraditionalComposer
  );
  return (
    <EntityFormContext.Provider value={entityFormValue}>
      <MultiEditFormConnected
        {...props}
        form={props.form || 'trackMultieditForm'}
        versionDisabled={versionDisabled}
        languageId={entries && entries[0] && entries[0].defaultLanguageId}
        tracks={tracks}
        showHelpWindow={showHelpWindow}
        disabled={disabled}
        shouldTouch={shouldTouch}
        collidedFields={collidedFields}
        editableFields={editableFields}
        initialValues={initialValues}
        traditonalTracksEnabled={traditonalTracksEnabled}
        showContributorOptions={showContributorOptions}
        setShowContributorOptions={setShowContributorOptions}
        showPublisherOptions={showPublisherOptions}
        setShowPublisherOptions={setShowPublisherOptions}
        t={t}
      />
    </EntityFormContext.Provider>
  );
};

export default MultiEditFormWithOverlay;
