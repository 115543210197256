import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import {
  useFetchEntity,
  useInvoice,
  useEntryProvider,
  useCreateEntity,
  useOverview,
  useEntries,
  nil,
} from 'imddata';
import { Button, Card, Centered, LoadingIndicator } from 'imdui';
import type { PaymentMethod, PaymentData } from './types';
import { PaymentMethodContainer } from './PaymentMethodContainer';
import { PaymentProcessor } from './PaymentProcessor';
import PaymentOverviewDetails from './PaymentOverviewDetails';
import config from 'imdconfig';
import { postPayment } from './postPayment';
import type { OverviewGroupItem } from 'imddata/types/entities';
import { BodyS, BodyXS, Content, H4, LockIcon } from '@imus/base-ui';
import { css } from '@emotion/react';
import { PaymentOption } from './PaymentOption';
import { ApplicationSettingsContext, CustomerPaymentMethod } from 'components';
import GooglePayButton from '@google-pay/button-react';
import { isInvoicePaid } from 'helpers/invoice';

const GooglePayAutoStyleButton = (
  props: React.ComponentProps<typeof GooglePayButton>
) => {
  const prefersDark =
    window && window.matchMedia('(prefers-color-scheme: dark)').matches;

  return (
    <GooglePayButton
      buttonRadius={24}
      buttonColor={prefersDark ? 'white' : 'black'}
      buttonSizeMode="fill"
      style={{ width: '100%', height: 48 }}
      {...props}
    />
  );
};

const TermsCaption = styled(BodyXS)`
  color: var(--accent);
  display: block;
  width: 100%;
  margin-top: 16px;
  line-height: 14px;
`;

const Container = styled.div`
  align-self: center;
  justify-self: center;
  display: flex;
  width: 100%;
  flex: 1;
  gap: 24px;
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  max-width: 1024px;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  gap: 32px;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1000px) {
    margin: 16px;
  }
`;

const Items = styled.div`
  display: grid;
  gap: 8px;
`;

const Footer = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;

  @media (max-width: 1000px) {
    padding: 16px;
  }
  & > div {
    display: grid;
    gap: 8px;
    flex: 1;
    min-width: 300px;
  }
`;

const SectionCaption = styled(Content)`
  font-weight: 700;
  line-height: 16px;
  display: block;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

export type SuccessHandler = (id: number) => void;

export type ErrorHandler = (
  type: 'unpayable' | 'failed_payment',
  id?: number,
  message?: string
) => void;

const defaultMakeReturnUrl = (id: number) =>
  config.imdfront.appUrl + '/order/complete?invoiceId=' + id;

const useMakePayment = (
  ids: number[] | undefined,
  entity: 'invoices' | 'orders',
  onComplete: SuccessHandler,
  onError: ErrorHandler,
  makeReturnUrl: (id: number) => string = defaultMakeReturnUrl
) => {
  const sessionId = useRef(Date.now());

  const [paymentInitData, setPaymentInitData] = useState<Record<string, any>>();
  const [invoiceId, setInvoiceId] = useState<number>();
  const { reload: reloadPaymentMethods } = useFetchEntity({
    entity: 'customerPaymentMethods',
  });

  const {
    createEntry: createInvoice,
    request: invoiceRequest,
    createdId: createdInvoiceId,
  } = useCreateEntity({
    entity: 'invoices',
    query: { with: 'items' },
  });

  useEffect(() => {
    if (entity === 'invoices' && ids) {
      setInvoiceId(ids[0]);
      return;
    }
    if (createdInvoiceId) {
      setInvoiceId(createdInvoiceId as number);
    }
  }, [createdInvoiceId, ids]);

  const {
    createEntry: makePayment,
    request,
    createdId,
  } = useCreateEntity({
    entity: 'invoicePayments',
    componentKey: `InvoicePayment-${invoiceId}-${sessionId.current}`,
  });

  // TODO: define result as an object return somehow and avoid using createdId field for this
  // @ts-ignore
  const transactionId = createdId?.transaction;

  const transaction = useEntryProvider<EntityModels.Transaction>({
    entity: 'transactions',
    id: transactionId,
  });

  const { entry: invoice } = useInvoice({
    id: invoiceId,
    passive: !invoiceId,
  });

  useEffect(() => {
    if (transaction) {
      const { redirectUrl, redirectMethod, redirectData } = transaction;
      if (redirectUrl) {
        if (redirectMethod.toUpperCase() === 'POST') {
          postPayment(redirectUrl, redirectData);
          return;
        }
        window.location.href = redirectUrl;
        return;
      }
      if (
        transaction.status === 'authorization_failed' ||
        transaction.status === 'settlement_failed' ||
        transaction.status === 'failed'
      ) {
        onError('failed_payment', invoiceId, transaction.statusText);
        return;
      }
    }

    if (invoice && invoiceId) {
      if (isInvoicePaid(invoice)) {
        reloadPaymentMethods();
        onComplete(invoiceId);
      }
    }
  }, [transaction, invoice]);

  const onPayment = useCallback(
    (data = {}) => {
      setPaymentInitData({ ...data });
    },
    [makePayment, invoiceId]
  );
  useEffect(() => {
    if (entity === 'orders' && !invoiceId && paymentInitData) {
      createInvoice({
        data: {
          orderIds: ids,
        },
      });
    }
    if (invoiceId && paymentInitData) {
      makePayment({
        id: invoiceId,
        data: {
          ...paymentInitData,
          returnUrl: makeReturnUrl(invoiceId),
        },
      });
    }
  }, [paymentInitData, invoiceId, ids]);

  return {
    onPayment,
    request,
    paying: request.creating || invoiceRequest.creating,
  };
};

const emptyTabs: Array<{
  label: string;
  value: PaymentMethod;
  testId?: string;
}> = [];

type PaymentOption = {
  label: string;
  value: PaymentMethod;
  testId?: string;
};

export const PaymentFlow = ({
  id,
  entity,
  style,
  className,
  onComplete,
  onError,
  onPayment,
  makeReturnUrl,
  loading,
  terms,
  action,
}: {
  loading?: boolean;
  id?: number;
  terms?: React.ReactNode;
  action?: React.ReactNode;
  entity: 'invoices' | 'orders';
  onPayment?: (products: string[]) => void;
  onComplete: SuccessHandler;
  onError: ErrorHandler;
  makeReturnUrl?: (id: number) => string;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const attempt = useMemo(() => Date.now(), []);
  const { t } = useTranslation();

  const overviewEntity =
    entity === 'invoices' ? 'invoicesOverview' : 'ordersOverview';

  const {
    entries: customerPaymentMethodsUnordered,
    request: { loaded: customerPaymentMethodsLoaded },
    refresh,
  } = useEntries<EntityModels.CustomerPaymentMethod>({
    entity: 'customerPaymentMethods',
  });
  useEffect(() => {
    refresh();
  }, []);

  const customerPaymentMethods = customerPaymentMethodsUnordered?.sort(
    (a, b) =>
      new Date(b.lastManualUseAt).getTime() -
      new Date(a.lastManualUseAt).getTime()
  );

  const defaultMethod = customerPaymentMethods?.[0] || null;

  const [paymentData, setPaymentData] = useState<PaymentData>({
    paymentMethod: 'card',
    id: null,
    isReady: false,
  });

  const overviewData = useMemo(() => {
    return {
      ...(entity === 'orders' && id ? { orderIds: [id] } : {}),
      ...(paymentData.paymentMethod
        ? { paymentMethodId: paymentData.paymentMethod }
        : {}),
    };
  }, [paymentData.paymentMethod, entity, id]);

  const orderData = useMemo(
    () => ({
      ...(entity === 'orders' && id ? { orderIds: [id] } : {}),
    }),
    []
  );
  const { overview: paymentOverview } = useOverview({
    entity: overviewEntity,
    id: id,
    data: overviewData,
    passive: !paymentData.paymentMethod,
    componentKey: `${id}-${paymentData.paymentMethod}-${attempt}`,
  });

  const { request: baseOverviewRequest, overview: baseOverview } = useOverview({
    entity: overviewEntity,
    id: id,
    data: orderData,
    passive: loading,
    componentKey: `${id}-${attempt}`,
  });

  const [paymentOptions, setPaymentOptions] = useState<Array<PaymentOption>>(
    () => emptyTabs
  );

  useEffect(() => {
    if (!baseOverview || paymentOptions.length > 0 || loading) return;
    const tabs: Array<{
      label: string;
      value: PaymentMethod;
      testId?: string;
    }> = [];

    if (baseOverview && !baseOverview.requiresExternalTransactions) {
      setPaymentOptions([{ value: nil, label: t('credit') }]);
      return;
    }
    if (baseOverview.availablePaymentMethods.card) {
      tabs.push({ value: 'card', label: t('credit-card') });
    }
    if (baseOverview.availablePaymentMethods.paypal) {
      tabs.push({
        value: 'paypal',
        label: t('paypal'),
        testId: 'PaymentGate-Paypal',
      });
    }
    if (baseOverview.availablePaymentMethods.googlePay) {
      tabs.push({
        value: 'google_pay',
        label: t('google-pay'),
        testId: 'PaymentGate-Google',
      });
    }
    if (baseOverview.availablePaymentMethods.applePay) {
      tabs.push({
        value: 'apple_pay',
        label: t('apple-pay'),
        testId: 'PaymentGate-ApplePay',
      });
    }
    if (baseOverview.availablePaymentMethods.sepaDirectDebit) {
      tabs.push({
        value: 'sepa_direct_debit',
        label: t('sepa-direct-debit'),
        testId: 'PaymentGate-DirectDebit',
      });
    }
    if (!tabs.length) {
      return;
    }
    if (defaultMethod) {
      setPaymentData({
        id: defaultMethod.id,
        paymentMethod: defaultMethod.paymentMethodId,
        isReady: true,
        data: {
          customerPaymentMethodId: defaultMethod.id,
        },
      });
    }
    setPaymentOptions(tabs);
  }, [baseOverview, paymentOptions, loading]);

  useEffect(() => {
    if (
      defaultMethod &&
      paymentOptions?.length &&
      paymentOptions.find((po) => po.value === defaultMethod.paymentMethodId)
    ) {
      setPaymentData({
        id: defaultMethod.id,
        paymentMethod: defaultMethod.paymentMethodId,
        isReady: true,
        data: {
          customerPaymentMethodId: defaultMethod.id,
        },
      });
    }
  }, [defaultMethod, paymentOptions]);

  useEffect(() => {
    if (
      baseOverviewRequest.failed &&
      !baseOverviewRequest.loading &&
      !loading
    ) {
      onError('unpayable', id, entity);
      return;
    }

    if (baseOverview && !baseOverview.canBePaid && !loading) {
      onError('unpayable', id, entity);
    }
  }, [baseOverview, loading]);

  const ids = useMemo(() => {
    if (!baseOverview) return undefined;

    return entity === 'orders' && !id
      ? baseOverview.includedOrders
      : id
        ? [id]
        : undefined;
  }, [entity, baseOverview?.includedOrders, id]);

  const orderedItems = Object.values(baseOverview?.groups || []).reduce<
    OverviewGroupItem[]
  >((acc, g) => [...acc, ...g.items], []);

  const { onPayment: handlePayment, paying } = useMakePayment(
    ids,
    entity,
    onComplete,
    onError,
    makeReturnUrl
  );

  const hasStoredMethods =
    customerPaymentMethods.length > 0 &&
    baseOverview?.requiresExternalTransactions;

  const disabledPayment = paying || !paymentOverview || !paymentData.isReady;

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const currencyId = baseOverview?.currency?.id;
  const priceFormatter = useMemo(() => {
    if (currencyId) {
      return new Intl.NumberFormat(numberFormatLocale, {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: currencyId,
      });
    }
    return {
      format: () => '',
    };
  }, [currencyId]);

  const cardFee = baseOverview?.availablePaymentMethods['card']?.fee
    ? priceFormatter.format(
      Number(baseOverview?.availablePaymentMethods['card'].fee)
    )
    : '';

  const googlePayFee = baseOverview?.availablePaymentMethods['googlePay']?.fee
    ? priceFormatter.format(
      Number(baseOverview?.availablePaymentMethods['googlePay'].fee)
    )
    : '';
  const paypalFee = baseOverview?.availablePaymentMethods['paypal']?.fee
    ? priceFormatter.format(
      Number(baseOverview?.availablePaymentMethods['paypal'].fee)
    )
    : '';
  const sepaFee = baseOverview?.availablePaymentMethods['sepaDirectDebit']?.fee
    ? priceFormatter.format(
      Number(baseOverview?.availablePaymentMethods['sepaDirectDebit'].fee)
    )
    : '';
  const enforceStorePaymentMethod = baseOverview?.storedPaymentMethodRequired;
  const termsComponent = (
    <>
      {(terms || enforceStorePaymentMethod) && (
        <TermsCaption>
          {terms}
          {enforceStorePaymentMethod &&
            !paymentData.data?.customerPaymentMethodId && (
              <>
                <br />
                {t('your-payment-details-will-be-stored-for-future-renewals')}
              </>
            )}
        </TermsCaption>
      )}
    </>
  );

  let payButton = null;

  const defaultPayButton = (
    <Button
      testId={`PayButton-${disabledPayment ? 'disabled' : 'enabled'}`}
      position="center"
      style={{ width: '100%' }}
      size="large"
      primary={!disabledPayment && !!paymentOverview}
      type="button"
      appearance="fill"
      iconLeft={() => <LockIcon />}
      showLoading={!paymentOverview}
      text={
        t('pay') + ' ' + priceFormatter.format(Number(paymentOverview?.toPay))
      }
      onClick={() => {
        if (onPayment && orderedItems)
          onPayment(orderedItems.map((oi) => oi.id));
        handlePayment(paymentData?.data);
      }}
      disabled={disabledPayment}
    />
  );

  switch (paymentData.paymentMethod) {
    case 'google_pay': {
      if (paymentData.id !== 'new-google-pay') {
        payButton = defaultPayButton;
        break;
      }
      payButton =
        paymentOverview &&
          paymentOverview.availablePaymentMethods.googlePay &&
          currencyId ? (
          <GooglePayAutoStyleButton
            environment={
              process.env.TARGET_ENV === 'production' ? 'PRODUCTION' : 'TEST'
            }
            paymentRequest={{
              apiVersion: 2,
              apiVersionMinor: 0,
              allowedPaymentMethods: [
                {
                  type: 'CARD',
                  parameters: {
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                    allowedCardNetworks: ['MASTERCARD', 'VISA', 'AMEX'],
                  },
                  tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                      gateway: 'adyen',
                      gatewayMerchantId: 'IMusicianDigitalAGCOM',
                    },
                  },
                },
              ],
              merchantInfo: {
                merchantId:
                  paymentOverview.availablePaymentMethods.googlePay.data
                    .googleMerchantId,
              },
              transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: Number(paymentOverview.toPay).toFixed(2),
                currencyCode: currencyId.toUpperCase(),
                countryCode: 'CH',
              },
            }}
            onLoadPaymentData={(paymentRequest) => {
              handlePayment({
                ...(paymentData.data || {}),
                data: {
                  ...(paymentData.data?.data || {}),
                  googlePayToken:
                    paymentRequest.paymentMethodData.tokenizationData.token,
                },
              });
            }}
          />
        ) : (
          <Button
            style={{ width: '100%' }}
            size="large"
            text=" "
            appearance="fill"
            disabled
            showLoading
          />
        );
      break;
    }
    default:
      payButton = defaultPayButton;
      break;
  }

  const isLoading = !baseOverview || !customerPaymentMethodsLoaded;
  console.log(baseOverview?.availablePaymentMethods, paymentOptions);
  return (
    <>
      {isLoading && (
        <Centered style={{ marginTop: '32px' }}>
          <LoadingIndicator />
        </Centered>
      )}
      <Wrapper
        style={{
          ...(style || {}),
          display: isLoading ? 'none' : 'flex',
        }}
        className={className}
      >
        <Container>
          <div
            css={css`
              flex: 500px;
              display: flex;
              flex-direction: column;
            `}
          >
            <Card css={css({ padding: '32px', display: 'grid', gap: '32px' })}>
              {hasStoredMethods && (
                <div>
                  <SectionCaption>{t('saved-payment-methods')}</SectionCaption>
                  <Items>
                    {customerPaymentMethods.map((data) => (
                      <PaymentOption
                        key={data.id}
                        checked={paymentData.id === data.id}
                        className={paying ? 'disabled' : ''}
                        formChildren={payButton}
                        onCheck={() =>
                          setPaymentData({
                            paymentMethod: data.paymentMethodId,
                            id: data.id,
                            isReady: true,
                            data: { customerPaymentMethodId: data.id },
                          })
                        }
                      >
                        {data.paymentMethodId === 'sepa_direct_debit' && (
                          <CustomerPaymentMethod
                            caption={sepaFee + ' ' + t('fee')}
                            scheme={'sepa_direct_debit'}
                            data={data.data}
                          />
                        )}
                        {data.paymentMethodId === 'google_pay' && (
                          <CustomerPaymentMethod
                            caption={googlePayFee + ' ' + t('fee')}
                            scheme={data.paymentMethodId}
                            data={data.data}
                          />
                        )}
                        {data.paymentMethodId === 'paypal' && (
                          <CustomerPaymentMethod
                            caption={paypalFee + ' ' + t('fee')}
                            scheme={data.paymentMethodId}
                            data={data.data}
                          />
                        )}
                        {data.paymentMethodId === 'card' && (
                          <CustomerPaymentMethod
                            caption={cardFee + ' ' + t('fee')}
                            scheme={data.paymentMethodId}
                            data={data.data}
                          />
                        )}
                      </PaymentOption>
                    ))}
                  </Items>
                </div>
              )}
              <div>
                {baseOverview?.requiresExternalTransactions && (
                  <SectionCaption>{t('new-payment-method')}</SectionCaption>
                )}
                <Items>
                  {paymentOptions.map((pm) => {
                    let fee = '';
                    let data = {};
                    switch (pm.value) {
                      case 'card':
                        fee = cardFee;
                        data =
                          baseOverview?.availablePaymentMethods.card?.data ||
                          {};
                        break;
                      case 'paypal':
                        fee = paypalFee;
                        data =
                          baseOverview?.availablePaymentMethods.paypal?.data ||
                          {};
                        break;
                      case 'apple_pay':
                        fee = googlePayFee;
                        data =
                          baseOverview?.availablePaymentMethods.applePay
                            ?.data || {};
                        break;
                      case 'sepa_direct_debit':
                        fee = sepaFee;
                        data =
                          baseOverview?.availablePaymentMethods.sepaDirectDebit
                            ?.data || {};
                        break;
                      case 'google_pay':
                        fee = googlePayFee;
                        data =
                          baseOverview?.availablePaymentMethods.googlePay
                            ?.data || {};
                        break;

                      case '@imddata/nil':
                        break;
                    }
                    return (
                      <PaymentMethodContainer
                        className={
                          paying && pm.value !== paymentData.id
                            ? 'disabled'
                            : ''
                        }
                        id={pm.value}
                        key={pm.value}
                      >
                        <PaymentProcessor
                          customerPaymentMethods={customerPaymentMethods}
                          paymentMethodData={{
                            fee,
                            data: {
                              ...data,
                              enforceStorePaymentMethod,
                            },
                          }}
                          paymentMethod={pm.value}
                          paymentData={paymentData}
                          onChange={(pd) => {
                            setPaymentData(pd);
                          }}
                        >
                          <>
                            {payButton}
                            {termsComponent}
                          </>
                        </PaymentProcessor>
                      </PaymentMethodContainer>
                    );
                  })}
                </Items>
              </div>
            </Card>
          </div>
          <div
            css={css`
              flex: 400px;
              @media (min-width: 1000px) {
                max-width: 400px;
              }
            `}
          >
            <Card
              secondary
              css={css`
                display: grid;
                padding: 32px;
                box-shadow: none;
              `}
            >
              <SectionCaption>{t('order-summary')}</SectionCaption>
              {baseOverview && (
                <>
                  <PaymentOverviewDetails overview={baseOverview}>
                    {action}
                  </PaymentOverviewDetails>
                </>
              )}
            </Card>
            <Footer>
              <div>
                <H4>
                  {t('secure-payment', { defaultValue: 'Secure Payment' })}
                </H4>
                <BodyS>
                  {t('secure-payment-description', {
                    defaultValue:
                      'We accept most credit cards, Paypal, Apple Pay, Afterpay and gift cards',
                  })}
                </BodyS>
              </div>
              <div>
                <H4>
                  {t('secure-payment-2', {
                    defaultValue: 'Your data belongs to you',
                  })}
                </H4>
                <BodyS>
                  {t('secure-payment-2-description', {
                    defaultValue:
                      'We don’t use any data you entered and don’t sell it to third parties. We only use that information to speed up the process of checking out.',
                  })}
                </BodyS>
              </div>
            </Footer>
          </div>
        </Container>
      </Wrapper>
    </>
  );
};
