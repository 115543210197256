import React, {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { splitAt } from 'ramda';
import type { Moment } from 'moment';
import moment from 'moment';
import {
  HelpWindowContext,
  Button,
  CenterWrapper,
  Headline,
  TabItem,
  IconButton,
  TouchTarget,
} from 'imdui';
import type { TrendLine, TrendEntry } from 'imddata';
import { useTrends, useCustomerFeature } from 'imddata';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import {
  MilestoneIndicatorLevelBadge,
  MilestonesWindow,
  TracksBrowserDialog,
} from 'components';
import { ButtonDropdown, DevComponent } from 'imdshared';
import styled from '@emotion/styled';
import { useResizeDetector } from 'react-resize-detector';
import { TrendChart } from './components/TrendChart';
import { ChartHeader } from './components/ChartHeader';
import type { Datum as BarChartDatum } from './components/BarChart';
import BarChart from './components/BarChart';
import DonutChart from './components/DonutChart';
import { StreamsChartCard } from './components/StreamsChartCard';
import { ChartsGridCard } from './components/ChartsGridCard';
import {
  ChartsContext,
  ChartsProvider,
} from './components/ChartsContextProvider';
import type { Range } from './components/DateRangeButton';
import DateRangeButton, {
  createLastDaysOptions,
} from './components/DateRangeButton';
import { EntriesTable } from './components/EntriesTable';
import { SubscribeCard } from './components/SubscribeCard';
import {
  CompassIcon,
  ContentS,
  TrendsFilteredIcon,
  TrendsIcon,
  UserIcon,
} from '@imus/base-ui';
import { css } from '@emotion/react';

const SubscribeCardStyled = styled(SubscribeCard)`
  grid-column: 1 / -1;
`;

const queryWithTrends = {
  IMD_FETCH_DOMAIN: 'trends',
};

const DAY_OFFSET = 2;
const DEFAULT_AVAILABLE_RANGE = {
  startDate: moment().subtract(90 + DAY_OFFSET, 'days'),
  endDate: moment().subtract(DAY_OFFSET, 'days'),
};
const DEFAULT_DATE_RANGE = createLastDaysOptions(
  DEFAULT_AVAILABLE_RANGE.endDate
)[2];

type ChartsFilterContextType = {
  dates: Range;
  entriesIds: Array<number>;
  onChangeEntries: (ids: Array<number>) => void;
  shops: Array<{ value: string | undefined; label: string }>;
  country: number | undefined;
};

const QuestionIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="9.5" stroke="#333333" strokeWidth="1.4px" />
    <path
      d="M10 9.5C10 8.98252 10.1643 8.65377 10.4085 8.44008C10.6702 8.21109 11.1557 8 12 8V6C10.8443 6 9.82979 6.28891 9.0915 6.93492C8.33572 7.59623 8 8.51748 8 9.5H10ZM12 8C12.8443 8 13.3298 8.21109 13.5915 8.44008C13.8357 8.65377 14 8.98252 14 9.5H16C16 8.51748 15.6643 7.59623 14.9085 6.93492C14.1702 6.28891 13.1557 6 12 6V8ZM14 9.5C14 9.89174 13.8953 10.0506 13.7964 10.1577C13.6409 10.3261 13.4263 10.4478 12.9961 10.6987C12.6202 10.918 12.0874 11.2361 11.6749 11.7747C11.2383 12.3446 11 13.0685 11 14H13C13 13.4315 13.1367 13.1554 13.2626 12.9909C13.4126 12.7951 13.6298 12.6445 14.0039 12.4263C14.3237 12.2397 14.8591 11.9551 15.2661 11.5142C15.7297 11.0119 16 10.3583 16 9.5H14Z"
      fill="#333333"
    />
    <circle cx="12" cy="16.5" r="1.5" fill="#333333" />
  </svg>
);

const ChartsFilterContext = createContext<ChartsFilterContextType>({
  country: undefined,
  entriesIds: [],
  shops: [],
  dates: DEFAULT_DATE_RANGE,
  onChangeEntries: () => {
    return;
  },
});

type TrackTrendData = {
  trackId: number;
  trackName: string;
  artistName: string;
};

type GenderTrendData = { gender: string };
type CountryTrendData = {
  countryName: string;
  countryId: number;
};
type ShopTrendData = {
  shop: string;
};

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  gap: 24px;
  padding: 8px 0 24px 0;

  @media (min-width: 1430px) {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-flow: row;
  }
`;

const TrendLineChartCard = styled(ChartsGridCard)`
  grid-column-start: span 2;
  @media (min-width: 1430px) {
    grid-column-start: span 6;
  }
  height: 512px;
`;

const EntriesTableCard = styled(ChartsGridCard)`
  height: 312px;
  grid-column-start: span 2;
  @media (min-width: 1430px) {
    grid-column-start: span 4;
  }
`;
const WideBarChartCard = styled(ChartsGridCard)`
  grid-column-start: span 2;

  @media (min-width: 1430px) {
    grid-column-start: span 4;
  }
  height: 312px;
`;
const DonutChartCard = styled(ChartsGridCard)`
  grid-column-start: span 2;

  @media (min-width: 900px) {
    grid-column-start: span 1;
  }
  @media (min-width: 1430px) {
    grid-column-start: span 2;
  }
  height: 312px;
`;
const BarChartCard = styled(ChartsGridCard)`
  grid-column-start: span 2;
  @media (min-width: 1430px) {
    grid-column-start: span 3;
  }
  height: 312px;
`;

const getFormattedDateTime = (dateTime: Date) =>
  dateTime.toLocaleDateString(moment.locale(), {
    month: 'short',
    day: 'numeric',
  });

// 48px taken from TopWrapper
const Header = styled(CenterWrapper)`
  & > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  padding-bottom: 24px;
`;

const HeaderFirstRow = styled.div`
  padding-top: 40px;
  grid-column-start: span 2;
  & ${Headline} ~ * {
    margin-top: 20px;
  }
`;

const HeaderFilters = styled.div`
  width: 100%;
  flex: 1;
  margin: 0 0 0 auto;
  gap: 24px;
  display: inline-grid;
  justify-content: space-between;

  @media (min-width: 550px) {
    grid-auto-flow: column;
  }

  @media (max-width: 700px) {
    margin: initial;
    justify-content: flex-start;
    grid-column-start: span 2;
  }
`;

const MilestoneSection = styled.div`
  display: flex;
  gap: 24px;
`;

const HeaderFilterSection = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  & > ${ContentS} {
    margin-right: 4px;
  }
`;

const selectByRange = <T extends { date: string | Moment }>(
  values: Array<T>,
  r: Range
): Array<T> => {
  if (!values) return [];

  return values.filter((d) =>
    moment(d.date).isBetween(r.startDate, r.endDate, 'days', '[]')
  );
};

const fillRange = (values: Array<TrendEntry>, r: Range): Array<TrendEntry> => {
  if (!r.startDate) return values;
  if (!r.endDate) return values;

  if (!values || !values.length)
    return Array(moment(r.endDate).diff(r.startDate, 'days'))
      .fill(null)
      .map((_v, idx) => ({
        value: null,
        date: moment(r.startDate).add(idx, 'days'),
      }));

  const firstValue = values[0];
  const lastValue = values[values.length - 1];

  const missingBefore = new Array(
    moment(firstValue.date).diff(r.startDate, 'days')
  )
    .fill(null)
    .map((_v, idx) => ({
      value: null,
      date: moment(r.startDate).add(idx, 'days'),
    }));

  const missingAfter = new Array(r.endDate.diff(moment(lastValue.date), 'days'))
    .fill(null)
    .map((_v, idx) => ({
      value: null,
      date: moment(lastValue.date).add(idx + 1, 'days'),
    }));

  return [...missingBefore, ...values, ...missingAfter];
};

type CardProps = { isPremium: boolean };

const aggregateTrendValues = (
  acc: null | number = 0,
  v: TrendEntry
): number | null =>
  v.value !== null && acc !== null
    ? acc + v.value
    : v.value !== null
      ? v.value
      : acc === null
        ? null
        : acc;

const sumTrendValues = (acc = 0, v: TrendEntry): number =>
  v.value !== null && acc !== null
    ? acc + v.value
    : v.value !== null
      ? v.value
      : acc;

const calculateChangeRatio = (
  allData: Array<TrendEntry> | undefined,
  rangedData: Array<TrendEntry> | undefined,
  range: Range
): [null, null] | [number, Range] => {
  if (!range || !range.endDate || !range.startDate || !allData || !rangedData)
    return [null, null];

  const rangeLength = rangedData.length + 1;
  const prevRange = {
    startDate: moment(range.startDate).subtract(rangeLength, 'days'),
    endDate: moment(range.endDate).subtract(rangeLength, 'days'),
  };

  // check if prevRange is out of scope
  if (
    !allData.find(
      (d) =>
        d.date.format('YYYY-MM-DD') === prevRange.startDate.format('YYYY-MM-DD')
    )
  ) {
    return [null, null];
  }

  const prevRangedData = selectByRange(allData, prevRange);

  const prevTotal = prevRangedData.reduce(sumTrendValues, 0);
  const currentTotal = rangedData.reduce(sumTrendValues, 0);
  if (prevTotal === 0 || currentTotal === 0) return [null, null];
  const ratio = (currentTotal - prevTotal) / prevTotal;

  return [ratio, prevRange];
};

const splitBarChartData = (
  d: Array<{ value: number; id: string; label: string }>,
  count: number,
  selected: number | undefined,
  otherLabel: string
): Array<BarChartDatum> => {
  const sorted = d.sort((a, b) => b.value - a.value);
  const [top, other] = splitAt(count, sorted);
  const selectedEntryIndex = selected
    ? sorted.findIndex((v) => v.id === `${selected}`)
    : -1;
  const otherGroup = other?.filter((v) => v.id !== `${selected}`);
  return [
    ...top,
    ...(selectedEntryIndex >= count ? [sorted[selectedEntryIndex]] : []),
    {
      hidden:
        !!(selected && selectedEntryIndex >= count) ||
        !otherGroup ||
        otherGroup.length === 0,
      label: otherLabel,
      collapsed: otherGroup,
      id: 'other',
      value: otherGroup.reduce((acc, v) => acc + v.value, 0),
    },
  ];
};

const formatStreamsCaption = (
  ratioRange: Range | null,
  t: TFunction
): React.ReactNode => {
  if (
    ratioRange !== null &&
    ratioRange?.endDate !== null &&
    ratioRange?.startDate !== null
  ) {
    return `${t('streams-compare', {
      startDate: getFormattedDateTime(ratioRange.startDate.toDate()),
      endDate: getFormattedDateTime(ratioRange.endDate.toDate()),
    })}`;
  }
  return null;
};

function makeTotalLine<T>(
  lines: Array<TrendLine<T> | undefined> | undefined,
  dates: Range,
  label: string
) {
  if (!lines) return [];
  const filtered = lines.filter(Boolean) as Array<TrendLine<T>>;
  if (!filtered.length) return [];
  return filtered
    .map((tl) => {
      return fillRange(selectByRange(tl.streams.accountable, dates), dates).map(
        (d) => ({ ...d, label })
      );
    })
    .reduce((acc, tl) => {
      // Reduce by using first element and add into it
      return tl.map((d, idx) => {
        return {
          ...d,
          value: aggregateTrendValues(acc[idx].value, d),
        };
      });
    });
}

const useAvailableRange = <T extends { streams: { fromTo: [Moment, Moment] } }>(
  isPremium: boolean,
  trendLine?: T,
  premiumShopLines?: T[]
) =>
  useMemo<Range | null>(() => {
    if (!isPremium && trendLine?.streams) {
      return {
        endDate: moment.utc(trendLine.streams.fromTo[1]),
        startDate: moment.utc(trendLine.streams.fromTo[0]),
      };
    }
    if (isPremium && premiumShopLines?.length) {
      const dates = premiumShopLines
        .reduce<
          number[]
        >((acc, line) => [...acc, moment.utc(line.streams.fromTo[0]).unix(), moment.utc(line.streams.fromTo[1]).unix()], [])
        .sort((a, b) => a - b);
      return {
        endDate: moment.unix(dates[dates.length - 1]),
        startDate: moment.unix(dates[0]),
      };
    }

    return null;
  }, [!!trendLine?.streams, isPremium, !!premiumShopLines]);

const useChartTracking = ({
  chart,
  selectedShop,
}: {
  chart: string;
  selectedShop?: string;
}) => {
  const { dates, country, entriesIds } = useContext(ChartsFilterContext);

  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.9 });

  useEffect(() => {
    if (window.analytics) {
      if (selectedShop) {
        window.analytics.track('FT Enhanced Analytics Refined Search', {
          scope: chart,
          shop: selectedShop,
          changed_field: 'shop',
          country,
          track_selection_active: entriesIds.length > 0,
          selected_days_length:
            dates && dates.endDate && dates.startDate
              ? dates.endDate?.diff(dates.startDate, 'days')
              : 'undef',
        });
      }
    }
  }, [selectedShop]);

  useEffect(() => {
    if (window.analytics && inView && window.scrollY !== 0) {
      window.analytics.track('FT Enhanced Analytics Scrolled to chart', {
        chart,
      });
    }
  }, [inView]);

  return [ref];
};

export const StreamsCard: React.FC<
  CardProps & {
    verticalTicksNum?: number;
    className?: string;
    style?: React.CSSProperties;
  }
> = ({ isPremium, verticalTicksNum = 4, className, style }) => {
  const { dates, onChangeEntries, shops, country, entriesIds } =
    useContext(ChartsFilterContext);

  const [selectedShop, setSelectedShop] = useState<undefined | string>(
    () => undefined
  );

  const { trendLine, request } = useTrends<Record<string, never>>({
    entity: 'trendsBasic',
    passive: isPremium,
  });

  useChartTracking({ selectedShop, chart: 'streams' });

  const { trendLines, request: premiumRequest } = useTrends<
    Record<string, never>
  >({
    entity: 'trendsShops',
    passive: !isPremium,
    query: {
      'filter.country_id': country,
      'filter.track_id': entriesIds?.join(','),
    },
  });

  const finalPremiumLines = useMemo(() => {
    if (!selectedShop) return trendLines;
    return [trendLines?.find((tl) => tl.shop === selectedShop)];
  }, [selectedShop, trendLines]);

  const { t } = useTranslation();

  const availableRange = useAvailableRange(isPremium, trendLine, trendLines);

  const {
    trendLines: trackLines,
    request: { loaded: tracksLoaded },
  } = useTrends<TrackTrendData>({
    passive: !isPremium || !entriesIds?.length,
    entity: 'trendsTopTracks',
    query: {
      'filter.provider': selectedShop,
      'filter.country_id': country,
      'filter.track_id': entriesIds?.join(','),
    },
  });

  const fullTotal = useMemo(() => {
    if (entriesIds.length || availableRange === null) {
      return [];
    }

    const total = makeTotalLine(
      isPremium ? finalPremiumLines : [trendLine],
      availableRange,
      t('total')
    );
    return total;
    // TODO: process trendlines
  }, [availableRange, finalPremiumLines]);

  const totalStreams = useMemo(() => {
    return { total: selectByRange(fullTotal, dates) };
  }, [fullTotal, dates]);

  const trackStreams = useMemo(() => {
    if (!trackLines || !trackLines.length) return [];

    return trackLines.reduce((acc, tl) => {
      return {
        ...acc,
        [tl.trackId]: fillRange(
          selectByRange(tl.streams.accountable, dates),
          dates
        ).map((d) => ({
          label: `${tl.trackName} - ${tl.artistName}`,
          ...d,
        })),
      };
    }, {});
  }, [trackLines, dates]);

  const [ratio, ratioRange] = useMemo(
    () =>
      totalStreams.total
        ? calculateChangeRatio(fullTotal, totalStreams.total, dates)
        : [null, null],
    [totalStreams]
  );

  const caption = useMemo(
    () => formatStreamsCaption(ratioRange, t),
    [ratioRange, t]
  );

  const onCloseAll = useCallback(() => {
    onChangeEntries([]);
  }, []);

  const onCloseEntry = useCallback(
    (id) => {
      onChangeEntries(entriesIds.filter((eid) => Number(eid) !== Number(id)));
    },
    [entriesIds]
  );

  const loading = isPremium
    ? entriesIds?.length
      ? !tracksLoaded
      : request.loading || premiumRequest.loading
    : request.loading;

  const loaded = isPremium
    ? entriesIds?.length
      ? !tracksLoaded
      : request.loaded || premiumRequest.loaded
    : request.loaded;

  const data = entriesIds?.length ? trackStreams : totalStreams;

  const disabled = loading || (loaded && !totalStreams.total.length);

  return (
    <StreamsChartCard
      onCloseEntry={onCloseEntry}
      onCloseAll={onCloseAll}
      displayLegend={!!entriesIds.length}
      data={data}
      verticalTicksNum={verticalTicksNum}
      loading={loading}
      disabled={disabled}
      title={t('streams')}
      caption={caption}
      changeRatio={ratio}
      shops={isPremium && shops?.length ? shops : undefined}
      selectedShop={selectedShop}
      onChangeShop={setSelectedShop}
      className={className}
      style={style}
    />
  );
};

const StreamsChartCardStyled = styled(StreamsCard)`
  grid-column-start: span 2;
  @media (min-width: 1430px) {
    grid-column-start: span 6;
  }
  ${ChartsGridCard} {
    height: 400px;
  }
`;

const GenderCard: React.FC<CardProps> = ({ isPremium }) => {
  const { t } = useTranslation();
  const { dates, shops, country, entriesIds } = useContext(ChartsFilterContext);
  const [selectedShop, setSelectedShop] = useState<undefined | string>(
    () => undefined
  );

  const [ref] = useChartTracking({ selectedShop, chart: 'gender' });

  const { trendLines, request } = useTrends<GenderTrendData>({
    passive: !isPremium,
    entity: 'trendsGenders',
    query: {
      'filter.provider': selectedShop,
      'filter.country_id': country,
      'filter.track_id': entriesIds?.join(','),
    },
  });

  const genderData = useMemo(() => {
    if (!trendLines) return [];

    return trendLines
      .map((tl) => ({
        id: tl.gender,
        label: t(tl.gender),
        value: selectByRange(tl.streams.accountable, dates).reduce(
          sumTrendValues,
          0
        ),
      }))
      .sort((a, b) => b.value - a.value);
  }, [dates, trendLines]);

  const genderColorScale = useMemo(
    () => ({
      domain: genderData.map((g) => g.id),
      range: ['#009688', '#80CBC4', '#f2f2f2'],
    }),
    [genderData]
  );

  console.log(genderData, trendLines);

  const disabled =
    !isPremium || request.loading || (request.loaded && !genderData.length);

  return (
    <DonutChartCard ref={ref} loading={request.loading}>
      <ChartHeader
        title={t('gender')}
        shops={isPremium ? shops : undefined}
        selectedShop={selectedShop}
        onChangeShop={setSelectedShop}
      />
      <DonutChart
        disabled={disabled}
        caption={`${t('non-specified-group-explanation')}`}
        data={genderData}
        colorScaleConfig={genderColorScale}
        width={112}
        height={112}
      />
    </DonutChartCard>
  );
};
const TopEntriesCard: React.FC<CardProps> = ({ isPremium }) => {
  const { dates, shops, country, entriesIds } = useContext(ChartsFilterContext);
  const { t } = useTranslation();
  const [selectedShop, setSelectedShop] = useState<undefined | string>(
    () => undefined
  );
  const interval =
    dates.endDate && dates.startDate
      ? `${dates.startDate.format('YYYY-MM-DD')},${dates.endDate.format(
          'YYYY-MM-DD'
        )}`
      : null;
  const { trendLines, request } = useTrends<TrackTrendData>({
    passive: !isPremium || !interval,
    entity: 'trendsTopTracks',
    query: {
      interval,
      'filter.provider': selectedShop,
      'filter.country_id': country,
      'filter.track_id': entriesIds?.join(','),
    },
  });
  const tracks = useMemo(() => {
    if (!trendLines) return [];
    return trendLines.map(({ trackId, streams, trackName, artistName }) => {
      const accountable = selectByRange(streams.accountable, dates).reduce(
        sumTrendValues,
        0
      );
      const nonAccountable = streams.nonAccountable
        ? selectByRange(streams.nonAccountable, dates).reduce(sumTrendValues, 0)
        : 0;

      return {
        displayArtist: artistName,
        title: trackName,
        trackId,
        streams: accountable,
        skipRate: nonAccountable / (accountable + nonAccountable),
      };
    });
  }, [trendLines]);

  const [ref] = useChartTracking({ selectedShop, chart: 'top_tracks' });

  const total = tracks.reduce((acc, { streams }) => streams + acc, 0);
  const { streamsFormatter } = useContext(ChartsContext);
  return (
    <EntriesTableCard ref={ref} loading={request.loading}>
      <ChartHeader
        caption={
          isPremium ? `${streamsFormatter.format(total)} ${t('streams')}` : ''
        }
        title={t('tracks')}
        shops={shops}
        selectedShop={selectedShop}
        onChangeShop={setSelectedShop}
      />
      <EntriesTable
        disabled={!isPremium || !tracks.length || request.loading}
        data={tracks}
      />
    </EntriesTableCard>
  );
};

const TopCountriesCard: React.FC<CardProps & { maxBarCount: number }> = ({
  maxBarCount,
  isPremium,
}) => {
  const { t } = useTranslation();
  const { dates, shops, country, entriesIds } = useContext(ChartsFilterContext);

  const [selectedShop, setSelectedShop] = useState<undefined | string>(
    () => undefined
  );

  const [ref] = useChartTracking({ selectedShop, chart: 'top_countries' });
  const { trendLines, request } = useTrends<CountryTrendData>({
    passive: !isPremium,
    entity: 'trendsCountries',
    query: {
      'filter.provider': selectedShop,
      'filter.track_id': entriesIds?.join(','),
    },
  });

  const countriesRanged = useMemo(() => {
    if (!trendLines) return [];

    return trendLines
      .map((tl) => ({
        id: `${tl.countryId}`,
        label: t(tl.countryName),
        value: selectByRange(tl.streams.accountable, dates).reduce(
          sumTrendValues,
          0
        ),
      }))
      .sort((a, b) => b.value - a.value);
  }, [dates, trendLines]);

  const topCountries = useMemo(() => {
    return splitBarChartData(
      countriesRanged,
      Math.min(6, maxBarCount),
      country,
      t('other')
    );
  }, [country, maxBarCount, countriesRanged]);

  const disabled =
    !isPremium ||
    request.loading ||
    (request.loaded && !countriesRanged.length);

  return (
    <WideBarChartCard ref={ref} loading={request.loading}>
      <ChartHeader
        title={t('top-countries')}
        shops={isPremium ? shops : undefined}
        selectedShop={selectedShop}
        onChangeShop={setSelectedShop}
      />
      <BarChart
        disabled={disabled}
        selected={country ? `${country}` : null}
        fill="#DD6892"
        data={topCountries}
      />
    </WideBarChartCard>
  );
};

const DevicesCard: React.FC<CardProps> = ({ isPremium }) => {
  const { dates, shops, country, entriesIds } = useContext(ChartsFilterContext);
  const { t } = useTranslation();
  const [selectedShop, setSelectedShop] = useState<undefined | string>(
    () => undefined
  );

  const { trendLines, request } = useTrends<{ device: string }>({
    passive: !isPremium,
    entity: 'trendsDevices',
    query: {
      'filter.provider': selectedShop,
      'filter.country_id': country,
      'filter.track_id': entriesIds?.join(','),
    },
  });

  const devicesData = useMemo(() => {
    if (!trendLines) return [];

    return trendLines
      .map((tl) => ({
        id: tl.device,
        label: t(tl.device),
        value: selectByRange(tl.streams.accountable, dates).reduce(
          sumTrendValues,
          0
        ),
      }))
      .sort((a, b) => b.value - a.value);
  }, [dates, trendLines]);

  const devicesColorScale = useMemo(
    () => ({
      domain: devicesData.map((g) => g.id),
      range: [
        '#607D8B',
        // 'rgba(96,125,139,0.78)',
        // 'rgba(96,125,139,0.68)',
        '#B0BEC5',
        '#f2f2f2',
      ],
    }),
    [devicesData]
  );

  const disabled =
    !isPremium || request.loading || (request.loaded && !devicesData.length);

  return (
    <DonutChartCard loading={request.loading}>
      <ChartHeader
        title={t('stream-devices')}
        selectedShop={selectedShop}
        onChangeShop={setSelectedShop}
        shops={isPremium ? shops : undefined}
      />
      <DonutChart
        disabled={disabled}
        caption={`${t('stream-devices-caption')}`}
        data={devicesData}
        colorScaleConfig={devicesColorScale}
        width={112}
        height={112}
      />
    </DonutChartCard>
  );
};

const ShopsCard: React.FC<CardProps & { maxBarCount: number }> = ({
  maxBarCount,
  isPremium,
}) => {
  const { t } = useTranslation();
  const { dates, country, entriesIds } = useContext(ChartsFilterContext);

  const { trendLines, request } = useTrends<ShopTrendData>({
    entity: 'trendsShops',
    passive: !isPremium,
    query: {
      'filter.country_id': country,
      'filter.track_id': entriesIds.join(','),
    },
  });

  const [ref] = useChartTracking({ chart: 'shops' });

  const shopsData = useMemo(() => {
    if (!trendLines) return [];

    return trendLines
      .map((tl) => ({
        id: tl.shop,
        label: t(tl.shop),
        value: selectByRange(tl.streams.accountable, dates).reduce(
          sumTrendValues,
          0
        ),
      }))
      .sort((a, b) => b.value - a.value);
  }, [dates, trendLines]);

  const topShops = useMemo(() => {
    return splitBarChartData(
      shopsData,
      Math.min(5, maxBarCount),
      undefined,
      t('other')
    );
  }, [maxBarCount, shopsData]);

  const disabled =
    !isPremium || request.loading || (request.loaded && !shopsData.length);
  return (
    <BarChartCard ref={ref} loading={request.loading}>
      <ChartHeader title={t('shops')} />
      <BarChart disabled={disabled} fill="#6981CE" data={topShops} />
    </BarChartCard>
  );
};

const SourcesCard: React.FC<CardProps & { maxBarCount: number }> = ({
  isPremium,
  maxBarCount,
}) => {
  const showHelpWindow = useContext(HelpWindowContext);
  const { t } = useTranslation();
  const { dates, country, entriesIds, shops } = useContext(ChartsFilterContext);
  const sourcesShops = useMemo(
    () => shops.filter((s) => s.value !== undefined),
    [shops]
  );
  const [selectedShop, setSelectedShop] = useState<undefined | string>(
    () => undefined
  );

  const [ref] = useChartTracking({ selectedShop, chart: 'sources' });
  useEffect(() => {
    if (sourcesShops.length) {
      setSelectedShop(sourcesShops[0].value);
    }
  }, [sourcesShops.length]);

  const { trendLines, request } = useTrends<{
    source: string;
  }>({
    passive: !isPremium || !selectedShop,
    entity: 'trendsSources',
    query: {
      'filter.provider': selectedShop,
      'filter.country_id': country,
      'filter.track_id': entriesIds?.join(','),
    },
  });

  const sourcesRanged = useMemo(() => {
    if (!trendLines) return [];

    return trendLines
      .map((tl) => ({
        id: `${tl.source}`,
        label: t(tl.source),
        value: selectByRange(tl.streams.accountable, dates).reduce(
          sumTrendValues,
          0
        ),
      }))
      .sort((a, b) => b.value - a.value);
  }, [dates, trendLines]);

  const topSources = useMemo(() => {
    return splitBarChartData(
      sourcesRanged,
      Math.min(6, maxBarCount),
      undefined,
      t('other')
    );
  }, [maxBarCount, sourcesRanged]);

  const disabled = !isPremium || request.loading || !sourcesShops.length; // use shops for disabled, because wont fetch without one

  return (
    <BarChartCard loading={request.loading} ref={ref}>
      <ChartHeader
        title={
          <>
            {t('stream-sources')}{' '}
            <IconButton
              style={{
                position: 'absolute',
                top: '-8px',
                right: '-40px',
              }}
              onClick={() => {
                showHelpWindow(
                  t('stream-sources'),
                  t('stream-sources-helper-text')
                );
              }}
            >
              <QuestionIcon />
            </IconButton>
          </>
        }
        shops={isPremium ? sourcesShops : undefined}
        selectedShop={selectedShop}
        onChangeShop={setSelectedShop}
      />
      <BarChart disabled={disabled} fill="#A49DB9" data={topSources} />
    </BarChartCard>
  );
};

type GetTrendLineId<T> = (l: T) => string | number;
type GetTrendLineLabel<T> = (l: T) => string;

function getTop5Lines<T>(
  lines: Array<TrendLine<T>>,
  dates: Range,
  getId: GetTrendLineId<T>,
  getLabel: GetTrendLineLabel<T>
) {
  const [top] = splitAt(
    5,
    lines
      .map((line) => ({
        id: getId(line),
        values: fillRange(
          selectByRange(line.streams.accountable, dates),
          dates
        ).map((d) => ({
          ...d,
          id: getId(line),
          label: getLabel(line),
        })),
      }))
      .sort(
        (a, b) =>
          b.values.reduce(sumTrendValues, 0) -
          a.values.reduce(sumTrendValues, 0)
      )
  );

  return top.reduce((acc, line) => ({ ...acc, [line.id]: line.values }), {});
}

type TrendOption = 'shops' | 'countries' | 'genders';

const TrendCard: React.FC<CardProps> = ({ isPremium }) => {
  const { t } = useTranslation();
  const { dates, country, entriesIds } = useContext(ChartsFilterContext);

  const [selectedTab, setSelectedTab] = useState<TrendOption>('shops');

  const [ref] = useChartTracking({ chart: 'trends' });
  const tabOptions = useMemo<Array<[TrendOption, string]>>(
    () => [
      ['shops', t('shops')],
      ['countries', t('top-countries')],
      ['genders', t('genders')],
    ],
    []
  );
  const trendTabs = useMemo(
    () =>
      tabOptions.map(([id, label]) => (
        <TabItem
          onClick={() => {
            setSelectedTab(id);
          }}
          disabled={!isPremium}
          key={id}
          selected={id === selectedTab}
          text={label}
        />
      )),
    [selectedTab]
  );

  const { trendLines: shopLines, request: shopsRequest } =
    useTrends<ShopTrendData>({
      entity: 'trendsShops',
      passive: !isPremium || selectedTab !== 'shops',
      query: {
        'filter.country_id': country,
        'filter.track_id': entriesIds.join(','),
      },
    });

  const { trendLines: countriesLines, request: countriesRequest } =
    useTrends<CountryTrendData>({
      entity: 'trendsCountries',
      passive: !isPremium || selectedTab !== 'countries',
      query: {
        'filter.track_id': entriesIds.join(','),
      },
    });

  const { trendLines: genderLines, request: genderRequest } =
    useTrends<GenderTrendData>({
      passive: !isPremium || selectedTab !== 'genders',
      entity: 'trendsGenders',
      query: {
        'filter.country_id': country,
        'filter.track_id': entriesIds?.join(','),
      },
    });

  const trendLines = useMemo(() => {
    switch (selectedTab) {
      case 'countries': {
        if (!countriesLines || !countriesLines.length) return {};
        return getTop5Lines(
          countriesLines,
          dates,
          (l) => l.countryId,
          (l) => l.countryName
        );
      }
      case 'shops': {
        if (!shopLines || !shopLines.length) return {};
        return getTop5Lines(
          shopLines,
          dates,
          (l) => l.shop,
          (l) => t(l.shop)
        );
      }
      case 'genders': {
        if (!genderLines || !genderLines.length) return {};
        return getTop5Lines(
          genderLines,
          dates,
          (l) => l.gender,
          (l) => t(l.gender)
        );
      }
      default:
        return {};
    }
  }, [dates, selectedTab, shopLines]);

  const { loading, loaded } = useMemo(() => {
    switch (selectedTab) {
      case 'countries':
        return countriesRequest;
      case 'shops':
        return shopsRequest;
      case 'genders':
        return genderRequest;
      default:
        return { loading: false, loaded: false };
    }
  }, [selectedTab, countriesRequest, shopsRequest, genderRequest]);

  const disabled =
    !isPremium || loading || (loaded && !Object.keys(trendLines).length);

  return (
    <TrendLineChartCard loading={loading} ref={ref}>
      <TrendChart
        disabled={disabled}
        tabs={trendTabs}
        data={trendLines}
        title={t('trend')}
      />
    </TrendLineChartCard>
  );
};

const useAvailableShops = ({ passive }: { passive?: boolean }) => {
  const { t } = useTranslation();
  const { trendLines, request } = useTrends<ShopTrendData>({
    passive,
    entity: 'trendsShops',
  });

  const availableShops = useMemo(() => {
    if (!trendLines) return [{ value: undefined, label: t('all-shops') }];

    return [
      { value: undefined, label: t('all-shops') },
      ...trendLines
        .map((tl) => ({
          id: tl.shop,
          label: t(tl.shop),
          value: tl.streams.accountable.reduce(sumTrendValues, 0),
        }))
        .sort((a, b) => b.value - a.value)
        .map(({ id, label }) => ({ label, value: id })),
    ];
  }, [trendLines]);

  return { availableShops, trendLines, request };
};

const useAvailableCountries = ({ passive }: { passive?: boolean }) => {
  const { t } = useTranslation();
  const { trendLines, request } = useTrends<CountryTrendData>({
    passive,
    entity: 'trendsCountries',
  });

  const availableCountries = useMemo(() => {
    if (!trendLines) return [{ value: undefined, label: t('all-countries') }];

    return [
      { value: undefined, label: t('all-countries') },
      ...trendLines
        .map((tl) => ({
          id: tl.countryId,
          label: tl.countryName,
          value: tl.streams.accountable.reduce(sumTrendValues, 0),
        }))
        .sort((a, b) => b.value - a.value)
        .map(({ id, label }) => ({ label, value: id })),
    ];
  }, [trendLines]);

  return { availableCountries, request };
};

const iconStyle = css`
  width: 32px !important;
  height: 32px !important;
  --on-surface: var(--accent);
`;

const EnhancedTrends: React.FC<{
  mode: 'basic' | 'premium';
  displayUpsell: boolean;
}> = ({ mode = 'premium', displayUpsell }) => {
  const { t } = useTranslation();
  const isPremium = mode === 'premium';

  const [selectedTracks, setSelectedTracks] = useState<Array<number>>([]);
  const [entriesSelectionOpen, setEntriesSelectionOpen] = useState(false);
  const {
    availableCountries,
    request: { loaded: countriesLoaded },
  } = useAvailableCountries({ passive: !isPremium });

  const { availableShops, trendLines: premiumShopLines } = useAvailableShops({
    passive: !isPremium,
  });

  const [country, setCountry] = useState(undefined);
  const onChangeEntries = setSelectedTracks;

  const { trendLine } = useTrends({
    entity: 'trendsBasic',
    passive: isPremium,
  });

  const availableRange = useAvailableRange(
    isPremium,
    trendLine,
    premiumShopLines
  );

  const [dates, setDates] = useState<Range>(() => DEFAULT_DATE_RANGE);

  useEffect(() => {
    if (availableRange?.endDate) {
      setDates(createLastDaysOptions(availableRange.endDate)[2]);
    }
  }, [!!availableRange?.endDate]);

  const { width, ref } = useResizeDetector({ handleHeight: false });

  const maxBarCount = width && width < 600 ? 4 : 8;

  const chartFiltersValue = useMemo(
    () => ({
      country,
      dates,
      entriesIds: selectedTracks,
      onChangeEntries,
      shops: availableShops,
    }),
    [availableShops, onChangeEntries, selectedTracks, dates, country]
  );

  const contents = useMemo(() => {
    return [
      {
        title: t('trend-lines-title'),
        description: t('trend-lines-description'),
        icon: <CompassIcon css={iconStyle} />,
      },
      {
        title: t('trend-bars-title'),
        description: t('trend-bars-description'),
        icon: <TrendsFilteredIcon css={iconStyle} />,
      },
      {
        title: t('trend-pie-title'),
        description: t('trend-pie-description'),
        icon: <UserIcon css={iconStyle} />,
      },
      {
        title: t('trend-calendar-title'),
        description: t('trend-calendar-description'),
        icon: <TrendsIcon css={iconStyle} />,
      },
    ];
  }, [t]);

  useEffect(() => {
    if (isPremium && window.analytics) {
      window.analytics.track('FT Enhanced Analytics visited');
    }
  }, []);

  const handelCountryChange = useCallback(
    (v) => {
      setCountry(v);
      if (window.analytics) {
        window.analytics.track('FT Enhanced Analytics Refined Search', {
          scope: 'global',
          changed_field: 'country',
          country: v,
          track_selection_active: selectedTracks.length > 0,
          selected_days_length:
            dates && dates.endDate && dates.startDate
              ? dates.endDate?.diff(dates.startDate, 'days')
              : 'undef',
        });
      }
    },
    [selectedTracks]
  );

  const handleDateRange = useCallback(
    (r: Range) => {
      setDates(r);

      if (window.analytics) {
        window.analytics.track('FT Enhanced Analytics Refined Search', {
          scope: 'global',
          changed_field: 'date_range',
          country,
          track_selection_active: selectedTracks.length > 0,
          selected_days_length:
            r && r.endDate && r.startDate
              ? dates.endDate?.diff(dates.startDate, 'days')
              : 'undef',
        });
      }
    },
    [selectedTracks, country]
  );

  const handleTrackSelection = useCallback(
    (ids) => {
      setSelectedTracks(ids);
      setEntriesSelectionOpen(false);

      if (window.analytics) {
        window.analytics.track('FT Enhanced Analytics Refined Search', {
          scope: 'global',
          changed_field: 'track_selection',
          country,
          track_selection_active: ids.length > 0,
          selected_days_length:
            dates && dates.endDate && dates.startDate
              ? dates.endDate?.diff(dates.startDate, 'days')
              : 'undef',
        });
      }
    },
    [dates, country]
  );

  const [milestonesOpen, setMilestonesOpen] = useState(false);
  return (
    <>
      <MilestonesWindow
        isOpen={milestonesOpen}
        onClose={() => setMilestonesOpen(false)}
      />
      <ChartsFilterContext.Provider value={chartFiltersValue}>
        <ChartsProvider>
          <TracksBrowserDialog
            selectedTracks={selectedTracks}
            onTracksSelected={handleTrackSelection}
            defaultSearchQuery={queryWithTrends}
            noGrouping={true}
            isOpen={entriesSelectionOpen}
            onClose={() => {
              setEntriesSelectionOpen(false);
            }}
          />
          <Header>
            <HeaderFirstRow>
              <Headline>{t('analytics')}</Headline>
            </HeaderFirstRow>

            <HeaderFilters>
              <MilestoneSection>
                <DevComponent>
                  <TouchTarget
                    onClick={() => {
                      setMilestonesOpen(true);
                    }}
                  >
                    <MilestoneIndicatorLevelBadge value={1} />
                  </TouchTarget>
                </DevComponent>
                <HeaderFilterSection>
                  <ContentS>
                    {t('period', { defaultValue: 'period' })}:
                  </ContentS>
                  <DateRangeButton
                    availableRange={availableRange}
                    size="small"
                    disabled={!isPremium || !availableRange}
                    value={dates}
                    onChange={handleDateRange}
                  />
                </HeaderFilterSection>
              </MilestoneSection>
              <HeaderFilterSection>
                <ContentS>{t('filter', { defaultValue: 'filter' })}:</ContentS>
                {/* @ts-ignore */}
                <ButtonDropdown
                  value={country}
                  size="small"
                  disabled={!isPremium || !countriesLoaded}
                  onChange={handelCountryChange}
                  data={availableCountries}
                />
                <Button
                  onClick={() => {
                    setEntriesSelectionOpen(true);
                  }}
                  size="small"
                  disabled={!isPremium}
                  text={
                    selectedTracks?.length
                      ? t('selected-tracks')
                      : t('all-tracks')
                  }
                />
              </HeaderFilterSection>
            </HeaderFilters>
          </Header>
          <CenterWrapper>
            <ChartsGrid data-test-id={`EnhancedTrends-${mode}`} ref={ref}>
              {displayUpsell && (
                <SubscribeCardStyled
                  title={t('activate-enhanced-trends')}
                  description={t('activate-enhanced-trends-description', {
                    defaultValue:
                      'Subscribe now to get powerful analytics & reporting tools and so, so, so much more',
                  })}
                  data={contents}
                />
              )}
              <StreamsChartCardStyled isPremium={isPremium} />
              <>
                <TopEntriesCard isPremium={isPremium} />
                <GenderCard isPremium={isPremium} />
                <TopCountriesCard
                  maxBarCount={maxBarCount}
                  isPremium={isPremium}
                />

                <DevicesCard isPremium={isPremium} />
                <ShopsCard maxBarCount={maxBarCount} isPremium={isPremium} />
                <SourcesCard maxBarCount={maxBarCount} isPremium={isPremium} />
                <TrendCard isPremium={isPremium} />
              </>
            </ChartsGrid>
          </CenterWrapper>
        </ChartsProvider>
      </ChartsFilterContext.Provider>
    </>
  );
};

export default function Trends() {
  const isPremium = useCustomerFeature('music-analytics');

  return (
    <EnhancedTrends
      displayUpsell={!isPremium}
      mode={isPremium ? 'premium' : 'basic'}
    />
  );
}
