import { set, lensPath } from 'ramda';
import { entities } from '../../actionTypes';
import {
  INVALIDATE_AUTH,
  actionTypes as authActionTypes,
} from '../../actionTypes/auth';
import { actionTypes as userActionTypes } from '../../actionTypes/users';

// Initial state
export const initialState = {
  isRequested: false,
  data: null,
  auth: '',
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.loggedUser.fetch.REQUEST:
      return { ...state, isLoading: true };

    case entities.users.types.update.SUCCESS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          locale: action.rawResponse.item.locale,
        },
        data: {
          ...state.data,
          user: action.rawResponse.item,
          locale: action.rawResponse.item.locale,
        },
      };
    }
    case userActionTypes.loggedUser.fetch.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isRequested: true,
        profile: action.response.result,
        data: action.rawResponse.item,
      };
    }

    case authActionTypes.optimizelyUpdate:
      return set(
        lensPath(['profile', 'extra', 'optimizely', 'url']),
        action.payload.url,
        state
      );

    case userActionTypes.loggedUser.fetch.FAILURE:
    case INVALIDATE_AUTH:
      return {
        ...state,
        failedToFetch: action.error?.failedToFetch,
        isLoading: false,
        isRequested: true,
        data: null,
      };

    default:
      return state;
  }
};
