//
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Body, Checkbox } from 'imdui';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding-left: 32px;
  padding-right: ${({ disableDrag }) => (disableDrag ? '108px' : '148px')};
  box-shadow: inset 0 -1px 0 0 var(--outline);
`;

const Number = styled(Body)`
  display: block;
  width: 20px;
  text-align: center;
  margin-left: 50px;
  margin-right: 20px;
`;

const CheckboxStyled = styled(Checkbox)`
  margin-right: 10px;
`;

const Columns = styled.div`
  flex: 1;
  display: flex;
  ${Body} {
    display: block;
    flex: 1;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  @media (max-width: 600px) {
    & > ${Body}:not(:first-of-type) {
      display: none;
    }
  }
`;

const Header = ({
  checked,
  subchecked,
  disableCheckbox,
  disableDrag,
  onCheck,
  columns,
  hideCheckbox,
}) => {
  const { t } = useTranslation();

  return (
    <Container disableDrag={disableDrag}>
      {!hideCheckbox && (
        <CheckboxStyled
          checked={checked}
          subchecked={subchecked}
          disabled={disableCheckbox}
          onCheck={() => {
            onCheck(!checked);
          }}
        />
      )}
      {hideCheckbox && <div style={{ padding: 30 }} />}

      <Columns>
        {columns.number && <Number>#</Number>}
        <Body secondary>{t('title')}</Body>

        <Body secondary>{t('artist')}</Body>

        {columns.cidToggle && (
          <Number secondary style={{ marginLeft: 0, marginRight: 12 }}>
            CID
          </Number>
        )}
        {columns.isrc && <Body secondary>{t('isrc')}</Body>}
      </Columns>
    </Container>
  );
};

export default Header;
