//
import React, { useRef, memo, useState, useEffect, createContext } from 'react';
import { Icons, ComponentIcons, IconButton } from 'imdui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import DataRowColumn from '../DataRowColumn';
import DataRow from '../DataRow';
import PlayPauseButton from '../PlayPauseButton';

const iconStyles = css`
  margin-left: 20px;
`;

const NumberColumn = styled(DataRowColumn)`
  flex: 20px;
  flex-grow: 0;
`;

const NameColumn = styled(DataRowColumn)``;

const DataColumns = styled.div`
  flex: 1 1 auto;
  display: flex;
  min-width: 0;

  @media screen and (max-width: 600px) {
    & * {
      display: none;
    }
    & > ${NumberColumn}, & > ${NameColumn} {
      display: flex;
    }
  }
`;

const MessageOverlayStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  & > div {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 3px;
    padding: 0 10px 0 20px;
    margin: 10px 40px;
  }
`;

const MessageOverlay = ({
  children,
  onRequestClose,
}: {
  onRequestClose: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}) => {
  return (
    <MessageOverlayStyled>
      <div>
        {children}
        <IconButton icon={Icons.actions.close} onClick={onRequestClose} />
      </div>
    </MessageOverlayStyled>
  );
};

export type Props = {
  testId?: string;
  trackId: number | string;
  number?: number;
  trackName?: string;
  showIsrc?: boolean;
  version?: string;
  artistName?: string;
  releaseName?: string;
  valid: boolean;
  verifying?: boolean;
  hideCheckbox?: boolean;
  progressUploading?: number;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onCheck: React.MouseEventHandler<HTMLInputElement>;
  errorMessage?: string;
  className?: string;
  style?: React.CSSProperties;
  checked?: boolean;
  hasAudio?: boolean;
  disabled?: boolean;
  hideReleaseName?: boolean;
  isInContentId?: boolean;
  isMastered?: boolean;
  children?: React.ReactNode;
  isrc?: string;
};

export const TrackContext = createContext<number | string | undefined>(
  undefined
);

function Track({
  testId,
  trackId,
  number,
  trackName,
  version,
  artistName,
  releaseName,
  valid = true,
  verifying,
  hideCheckbox,
  progressUploading,
  onClick,
  errorMessage,
  onCheck,
  className,
  style,
  showIsrc = false,
  checked = false,
  disabled = false,
  hideReleaseName,
  isInContentId,
  isMastered,
  isrc,
  children,
}: Props) {
  // state = {
  //   errorMessage: null,
  //   showMessage: false,
  // };

  const [showMessage, setShowMessage] = useState(false);

  const lastErrorMessage = useRef<string>();

  useEffect(() => {
    if (errorMessage !== lastErrorMessage.current) {
      if (!errorMessage) {
        setShowMessage(false);
      } else {
        setShowMessage(true);
      }
      lastErrorMessage.current = errorMessage;
    }
  }, [errorMessage]);

  return (
    <DataRow
      testId={testId}
      disabled={disabled}
      checked={checked}
      className={className}
      hideCheckbox={hideCheckbox}
      style={style}
      onClick={onClick}
      onCheck={onCheck}
      progressUploading={progressUploading}
      valid={valid}
      verifying={verifying}
      controls={<PlayPauseButton trackToBePlayed={trackId} />}
      data={
        <>
          {showMessage && (
            <MessageOverlay
              onRequestClose={(event) => {
                event.stopPropagation();
                setShowMessage(false);
              }}
            >
              {errorMessage}
            </MessageOverlay>
          )}

          <DataColumns>
            {number && <NumberColumn text={number} />}
            <NameColumn
              key="trackName"
              text={
                trackName || version
                  ? `${trackName}${version ? ` (${version})` : ''}`
                  : null
              }
            />

            <DataRowColumn key="artistName" text={artistName} />

            <TrackContext.Provider value={trackId}>
              {children}
            </TrackContext.Provider>

            {showIsrc && <DataRowColumn key="isrc" text={isrc} />}

            {!hideReleaseName && (
              <DataRowColumn key="releaseName" text={releaseName} />
            )}
          </DataColumns>

          <ComponentIcons.Mastering
            css={iconStyles}
            style={{ visibility: isMastered ? 'visible' : 'hidden' }}
          />

          <ComponentIcons.ContentId
            css={iconStyles}
            style={{ opacity: isInContentId ? 1 : '0.3' }}
          />
        </>
      }
    />
  );
}

export default memo(Track);
