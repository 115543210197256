//
import { takeLatest, fork } from 'redux-saga/effects';
import { LOAD_LOGGED_USER } from 'imddata/actionTypes/users';
import { api } from 'imddata';

// load user unless it is cached
function* loadLoggedUser() {
  yield fork(api.users.getCurrentProfile);
}

function* initAuth() {
  yield takeLatest(LOAD_LOGGED_USER, loadLoggedUser);
}

export default function* runAuth() {
  yield fork(initAuth);
}
