import styled from '@emotion/styled';

export const Level = styled.span`
  line-height: 16px;
  display: block;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;
  font-family: Barlow;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
`;

// const getLevel = (v: number) => {
//   switch (v) {
//     case 1:
//       return 'I';
//     case 2:
//       return 'II';
//     case 3:
//       return 'III';
//     case 4:
//       return 'IV';
//     case 5:
//       return 'V';
//     case 6:
//       return 'VI';
//     case 7:
//       return 'VII';
//     case 8:
//       return 'VIII';
//     case 9:
//       return 'IX';
//     case 10:
//       return 'X';
//     default:
//       return '';
//   }
// };

export const LevelBadge = ({
  value,
  style,
  active,
}: {
  value: number;
  style?: React.CSSProperties;
  active?: boolean;
}) => {
  const fill = active ? 'var(--accent)' : 'var(--state-active-on-surface)';
  const color = active ? 'var(--on-accent)' : 'var(--accent)';
  return (
    <div
      style={{
        position: 'relative',
        height: '32px',
        width: '24px',
        ...(style || {}),
      }}
    >
      <svg
        width="24"
        height="32"
        viewBox="0 0 24 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8 0C21.9201 0 22.4802 0 22.908 0.217987C23.2843 0.409734 23.5903 0.715695 23.782 1.09202C24 1.51984 24 2.0799 24 3.2L24 22.2874C24 22.9136 24 23.2266 23.9137 23.5108C23.8373 23.7624 23.712 23.9964 23.5451 24.1996C23.3565 24.429 23.096 24.6027 22.575 24.95L13.775 30.8166C13.1335 31.2443 12.8128 31.4582 12.4661 31.5412C12.1597 31.6147 11.8403 31.6147 11.5339 31.5412C11.1872 31.4582 10.8665 31.2443 10.225 30.8166L1.42496 24.95C0.903978 24.6027 0.643487 24.429 0.454909 24.1996C0.287962 23.9964 0.162704 23.7624 0.0863018 23.5108C2.07435e-07 23.2266 2.91408e-07 22.9136 4.59352e-07 22.2874L5.579e-06 3.2C5.87943e-06 2.07989 6.02965e-06 1.51984 0.217993 1.09202C0.40974 0.715695 0.715701 0.409734 1.09203 0.217987C1.51985 0 2.0799 0 3.20001 0H20.8Z"
          fill={fill}
        />
      </svg>
      <Level
        style={{
          color,
          top: '6px',
          position: 'absolute',
          width: '24px',
          textAlign: 'center',
        }}
      >
        {value}
      </Level>
    </div>
  );
};
