import React, { useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import type { RouteComponentProps } from 'react-router-dom';
import { useHistory, Redirect } from 'react-router-dom';
import moment from 'moment';
import qs from 'qs';
import { camelizeKeys } from 'humps';
import type { EntityModels } from 'imddata';
import { useEntries, useInvoice, useQuery } from 'imddata';
import {
  Content,
  Headline,
  Body,
  LinkButton,
  Currency,
  IconsCollection,
  LoadingIndicator,
  Card,
} from 'imdshared';
import { integrations, mapEcommerceProperties } from 'helpers/tracking';

import { loadLoggedUser } from 'imddata/actionTypes/users';

import { useDispatch } from 'react-redux';
import {
  PaymentCard,
  columnStyle,
  PaymentHeader,
  hrStyle,
  detailsStyle,
  DetailsRow,
  footerStyle,
  StyledIcon,
  usePaymentSteps,
} from '../../shared';
import { PageTemplate } from 'components';

type PageProps = {
  testId?: string;
  title?: string;
  description?: string; // for overrides (e.g. error)
  invoiceId?: number | string;
  failed?: boolean;
};

const headlineStyle = css`
  margin-top: 12px;
`;

const descriptionStyle = css`
  display: block;
  text-align: center;
  margin-bottom: 12px;
`;

const getDefaultDescription = (
  t: TFunction,
  failed: boolean | undefined,
  steps: string[],
  context?: string
) => {
  if (failed) {
    return t('order-description-failed', { context });
  }
  return steps.length > 2
    ? t('thank-you-order-description-preparing', { context })
    : t('thank-you-order-description-completed', { context });
};

const ThankYouForOrder: React.FC<PageProps> = ({
  testId,
  title,
  description,
  invoiceId,
  failed,
}) => {
  const { t } = useTranslation();
  const {
    entry: invoice,
    request: { loaded },
  } = useInvoice({ id: invoiceId, passive: !invoiceId });
  const { query, queryHash } = useQuery({
    query: { 'filter.invoices': invoiceId },
  });

  const {
    entries: orders,
    request: { loaded: loadedOrders },
  } = useEntries<EntityModels.Order>({ entity: 'orders', query, queryHash });

  const steps = usePaymentSteps(invoice);

  const dispatch = useDispatch();
  const hasSub = invoice?.items?.find((i) => i.subscriptionId !== null);

  useEffect(() => {
    if (hasSub) {
      dispatch(loadLoggedUser());
    }
  }, [hasSub]);
  const history = useHistory();

  useEffect(() => {
    if (
      orders &&
      orders.length === 1 &&
      orders[0].handlersData?.deliveryBundleId
    ) {
      history.push(`/order/md/${orders[0].handlersData.deliveryBundleId}`);
    }
  }, [orders]);

  if (!loaded || !loadedOrders) return null;

  return (
    <PageTemplate>
      <Card data-test-id={testId}>
        <PaymentCard>
          <PaymentHeader>
            <StyledIcon
              size={40}
              d={failed ? IconsCollection.cross : IconsCollection.success}
              failed={failed}
            />

            <Headline css={headlineStyle}>{title}</Headline>
          </PaymentHeader>

          <div css={columnStyle}>
            <Body css={descriptionStyle}>
              {description ||
                getDefaultDescription(
                  t,
                  failed,
                  steps,
                  hasSub ? 'subscription' : undefined
                )}
            </Body>

            {!loaded && <LoadingIndicator size="large" />}

            {invoice && loaded && (
              <div css={detailsStyle}>
                <hr css={hrStyle} />

                <DetailsRow>
                  <Content>{t('order')}</Content>
                  <Content>{invoice.id}</Content>
                </DetailsRow>

                {invoice.items?.map((item) => {
                  if (item.type === 'payment_fee') return null;
                  return (
                    <DetailsRow key={item.id}>
                      <Content> </Content>
                      <Content>{item.name}</Content>
                    </DetailsRow>
                  );
                })}

                {invoice.paidAt && (
                  <DetailsRow>
                    <Content>{t('date')}</Content>
                    <Content>{moment(invoice.paidAt).format('lll')}</Content>
                  </DetailsRow>
                )}

                <hr css={hrStyle} />

                <DetailsRow>
                  <Content>{t('total')}</Content>
                  <Currency
                    value={invoice.valuePaid}
                    format={invoice.currency.format}
                  />
                </DetailsRow>
              </div>
            )}

            <div css={footerStyle}>
              {invoiceId && (
                <LinkButton
                  to={`/account/invoices/${invoiceId}`}
                  text={t('return-to-route', {
                    route: t('invoice'),
                    invoiceId,
                    id: invoiceId,
                  })}
                />
              )}
              {!failed && (
                <LinkButton
                  to="/dashboard"
                  text={t('dashboard')}
                  primary={true}
                />
              )}
            </div>
          </div>
        </PaymentCard>
      </Card>
    </PageTemplate>
  );
};

const useOrderQuery = (search = {}) => {
  const { invoiceId, success } = useMemo(
    () =>
      camelizeKeys(
        qs.parse(search, {
          ignoreQueryPrefix: true,
        })
      ) as { invoiceId: string; success: string },
    [search]
  );
  return { invoiceId, success };
};

const useTrackingInvoice = ({ invoiceId }: { invoiceId?: number }) => {
  const { entry: invoice } = useInvoice({
    id: invoiceId,
    passive: invoiceId === undefined,
  });
  const status = invoice?.status;

  // const [entry] = performance?.getEntriesByType('navigation') || [];
  //
  // const url = new URL(entry.name);
  // const isRefreshed =
  //   entry?.type === 'reload' &&
  //   (url.pathname === '/order/complete' || url.pathname === '/order/failed');
  //
  useEffect(() => {
    if (status && window?.analytics) {
      // const id = window.analytics.user().id();
      if (status === 'paid' || status === 'finalized') {
        window.analytics.track(
          'FT Invoice paid',
          {
            ...mapEcommerceProperties(invoice, 'invoiceId'),
          },
          {
            integrations,
          }
        );
        return;
      }
      window.analytics.track(
        'FT Invoice payment failed',
        {
          ...mapEcommerceProperties(invoice, 'invoiceId'),
        },
        {
          integrations,
        }
      );
    }
  }, [status]);
};

type Props = RouteComponentProps<
  never,
  never,
  {
    invoiceId?: number;
    orderPaid?: boolean;
    message?: string;
    description?: string;
  }
>;
export const CompletedOrder = ({ location: { state = {}, search } }: Props) => {
  const { t } = useTranslation();
  const { invoiceId, success } = useOrderQuery(search);

  useTrackingInvoice({
    invoiceId: state?.invoiceId,
  });

  const orderPaid = state?.orderPaid;

  if (invoiceId) {
    if (success === '1') {
      return (
        <Redirect
          to={{
            pathname: '/order/complete',
            state: { invoiceId },
          }}
        />
      );
    }

    return (
      <Redirect
        to={{
          pathname: '/order/failed',
          state: { invoiceId, message: t('payment-error') },
        }}
      />
    );
  }

  if (!state?.invoiceId && !orderPaid) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <ThankYouForOrder
      testId="ThankYouPage"
      title={t('thank-you-order')}
      invoiceId={state?.invoiceId}
    />
  );
};

export const FailedOrder = ({ location: { state = {}, search } }: Props) => {
  useTrackingInvoice({
    invoiceId: state?.invoiceId,
  });
  const { invoiceId } = useOrderQuery(search);

  if (!invoiceId && !state.invoiceId) return <Redirect to="/dashboard" />;
  return (
    <ThankYouForOrder
      title={state && state.message}
      description={state && state.description}
      invoiceId={(state && state.invoiceId) || invoiceId}
      failed={true}
    />
  );
};
