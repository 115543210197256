import { css } from '@emotion/react';
import { accentThemes } from '@imus/base-ui';

export const BackgroundA = () => {
  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;

        @media (max-width: 640px) {
          display: none;
        }
      `}
    >
      <svg
        css={css`
          position: absolute;
          top: 0;
          filter: blur(100px);
          left: 0;
          ${accentThemes.tertiary}
        `}
        width="876"
        height="685"
        viewBox="0 0 876 685"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.1" filter="url(#filter0_f_1115_6123)">
          <path
            d="M-64.2232 419.56C-273.44 322.386 162.251 44.6389 315.918 -26.4361C469.584 -97.5111 628.403 -83.946 670.649 3.86241C712.895 91.6708 495.825 48.6612 342.159 119.736C188.492 190.811 439.239 653.404 -64.2232 419.56Z"
            fill="var(--accent)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1115_6123"
            x="-320"
            y="-272"
            width="1196"
            height="957"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_1115_6123"
            />
          </filter>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1060"
        height="638"
        viewBox="0 0 1060 638"
        fill="none"
        css={css`
          position: absolute;
          bottom: 0;
          filter: blur(150px);
          right: 0;
          ${accentThemes.primary}
        `}
      >
        <g opacity="0.1" filter="url(#filter0_f_1115_6136)">
          <path
            d="M1145.93 476.75C1292.94 655.675 777.783 726 607.794 726C437.804 726 300 645.715 300 546.679C300 447.643 438.304 537.689 608.293 537.689C778.283 537.689 792.17 46.18 1145.93 476.75Z"
            fill="var(--accent)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1115_6136"
            x="0"
            y="0"
            width="1472"
            height="1026"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="150"
              result="effect1_foregroundBlur_1115_6136"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export const BackgroundB = () => {
  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;

        @media (max-width: 640px) {
          display: none;
        }
      `}
    >
      <svg
        css={css`
          position: absolute;
          top: 0;
          filter: blur(100px);
          right: 0;
          ${accentThemes.tertiary}
        `}
        xmlns="http://www.w3.org/2000/svg"
        width="956"
        height="799"
        viewBox="0 0 956 799"
        fill="none"
      >
        <g opacity="0.1" filter="url(#filter0_f_1115_6501)">
          <path
            d="M233.648 101.021C426.088 -64.8044 648.956 387.586 937.434 261.826C1329.2 91.0385 1019.78 496.408 823.149 564.287C560.421 654.984 474.312 563.17 469.315 344.678C464.237 122.643 87.5452 226.917 233.648 101.021Z"
            fill="var(--accent)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1115_6501"
            x="0"
            y="-136"
            width="1326"
            height="935"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="100"
              result="effect1_foregroundBlur_1115_6501"
            />
          </filter>
        </defs>
      </svg>

      <svg
        css={css`
          position: absolute;
          bottom: 0;
          filter: blur(150px);
          left: 0;
          ${accentThemes.primary}
        `}
        xmlns="http://www.w3.org/2000/svg"
        width="1094"
        height="684"
        viewBox="0 0 1094 684"
        fill="none"
      >
        <g opacity="0.1" filter="url(#filter0_f_1115_6111)">
          <path
            d="M793.951 550.589C796.211 420.949 719.629 554.443 543.117 548.47C198.3 536.802 369.804 181.125 170.007 341.446C-86.9783 547.656 188.264 503.479 313.525 625.902C428.595 738.365 789.748 791.797 793.951 550.589Z"
            fill="var(--accent)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1115_6111"
            x="-236"
            y="0"
            width="1330"
            height="1021"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="150"
              result="effect1_foregroundBlur_1115_6111"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
