import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isValidArtist } from 'imdshared';
import { identity, without, includes, keys, reduce, filter } from 'ramda';
import type { ReduxState, EntityModels, TrackNested } from 'imddata';
import {
  useUpdateEntity,
  useEntityFileRequestProvider,
  useRelease,
  createReleaseNest,
  useIsUploadingTracks,
  useBundleTracks,
} from 'imddata';
import {
  useProductPageActions,
  useProductPage,
} from '../../shared/ProductWizard';

type ChangeStep = ReturnType<typeof useProductPageActions>['changeStep'];
type Release = ReturnType<typeof useRelease>['entry'];

export const RELEASE_DETAILS = 'release-details';
export const TRACKS_DETAILS = 'tracks-details';
export const DELIVERY = 'delivery';
export const ADDITIONAL_FEATRUES = 'extra-features';

const filterReleaseTargetConditions = without([
  'hasTracksWithAllMetadata',
  'hasTracks',
  'hasGenre',
  'hasNoEmptyVolumes',
  'hasActiveLabel',
  'releaseTypeSet',
  'hasNameReadyForDelivery',
  'originalReleaseDateSet',
  'hasCoverUploaded',
  'isCoverCorrect',
  'hasTracks',
  'hasTracksReadyForDelivery',
]);

const nest = createReleaseNest({ tracks: true, covers: false });

const hasTracksWithVariousArtistsFilter = (
  hasTracksWithVariousArtists: boolean
) =>
  hasTracksWithVariousArtists
    ? without(['hasArtists', 'hasArtistsReadyForDelivery', 'hasMainArtist'])
    : identity;

const isFileReady = <T extends { uploadStatus: EntityModels.UploadStatus }>(
  uploadableEntity: T
) =>
  uploadableEntity.uploadStatus === 'finished' ||
  uploadableEntity.uploadStatus === 'confirmed';

const getReleaseError = (release: Release): string => {
  if (!release) return '';
  const coverError = !isFileReady(release) ? ':file-no-ready:' : '';

  const { conditions } = release.targets.addToDelivery;
  const selectedConditions = hasTracksWithVariousArtistsFilter(
    release.hasTracksWithVariousArtists
  )(filterReleaseTargetConditions(keys(conditions)));

  const hasInvalidArtist = release.artists?.find(
    ({ artist }) => !isValidArtist(artist)
  )
    ? ':invalid-artist:'
    : '';

  const targetError = selectedConditions.reduce<string>((acc, conditionKey) => {
    // @ts-ignore
    return conditions[conditionKey].status === 'success'
      ? acc
      : `${conditionKey}:${acc}`;
  }, '');

  return coverError + hasInvalidArtist + targetError;
};

const createValidateTrack =
  (
    performerRequired: boolean,
    bundleTracks?: { trackId: number; addToContentId: boolean }[]
  ) =>
    (track?: TrackNested, uploading?: boolean) => {
      if (!track || !track.targets) return true;

      if (
        performerRequired &&
        (!track.performers || track.performers.length === 0)
      )
        return false;

      const audioValid =
        uploading || track.uploadStatus === 'confirmed' || track.isAudioCorrect;

      const hasArtistWithoutArtistId =
        track.artists?.find(({ artist }) => !isValidArtist(artist)) || false;

      const isInContentId = !!bundleTracks?.find(
        (bt) => bt.trackId === track.id && bt.addToContentId
      );

      const validContentId = isInContentId
        ? track.targets.addToContentId.isMet
        : true;

      return (
        validContentId &&
        track.targets.hasAllMetadata.isMet &&
        audioValid &&
        !hasArtistWithoutArtistId
      );
    };

export const trackRequiredFields = [
  'genreId',
  'artists',
  'contributors',
  'uploadStatus',
  'names[0].title',
];

export const useTrackValidator = ({
  bundleId,
}: {
  bundleId: number | string;
}) => {
  const deliveryBundleTracks = useBundleTracks({ id: bundleId });
  const enableYouTubeContentId = useSelector(
    (state: ReduxState) =>
      !!state.entities.deliveryBundles.entities[bundleId]
        ?.enableYouTubeContentId
  );

  const performerRequired = useSelector(
    (state: ReduxState) =>
      !!state.entities.deliveryBundles.entities[bundleId]?.shops.find(
        (bundleShopId) => {
          return (
            state.entities.deliveryBundleShops.entities[bundleShopId]
              ?.shopId === 'apple_music' ||
            state.entities.deliveryBundleShops.entities[bundleShopId]
              ?.shopId === 'i_tunes'
          );
        }
      )
  );

  const requiredFields = useMemo(
    () =>
      !performerRequired
        ? trackRequiredFields
        : [...trackRequiredFields, 'performers'],
    [performerRequired]
  );
  const validator = useMemo(
    () =>
      createValidateTrack(
        performerRequired,
        enableYouTubeContentId ? deliveryBundleTracks : undefined
      ),
    [performerRequired, enableYouTubeContentId, deliveryBundleTracks]
  );

  return { validator, requiredFields };
};

const useTrackStepManager = ({
  acceptedCidTerms,
  acceptedAiTerms,
  bundleId,
  changeStep,
  release,
}: {
  acceptedCidTerms: boolean;
  acceptedAiTerms: boolean;
  changeStep: ChangeStep;
  release: Release;
  bundleId: number | string;
}) => {
  const enableYouTubeContentId = useSelector(
    (state: ReduxState) =>
      !!state.entities.deliveryBundles.entities[bundleId]
        ?.enableYouTubeContentId
  );

  const { validator } = useTrackValidator({ bundleId });

  const volumes = release?.volumes;
  const trackIds = useMemo(
    () =>
      volumes && volumes.length
        ? volumes.reduce<number[]>((acc, volume) => {
          if (!volume) return acc;
          return [
            ...acc,
            ...volume.reduce<number[]>((volumeAcc, track) => {
              if (!track) return volumeAcc;
              return [...volumeAcc, track.trackId];
            }, []),
          ];
        }, [])
        : [],
    [volumes]
  );

  const hasTracks = volumes?.reduce(
    (acc, volume) => acc || !!volume.length,
    false
  );
  const isTracksValid = volumes?.reduce((acc, volume) => {
    return (
      acc &&
      volume.reduce((trackAcc, { track }) => trackAcc && validator(track), true)
    );
  }, hasTracks);
  const verifying = volumes?.reduce((acc, volume) => {
    return (
      acc ||
      volume.reduce(
        (trackAcc, { track }) =>
          trackAcc || (track && track.uploadStatus === 'confirmed'),
        false
      )
    );
  }, false);

  const updating = useIsUploadingTracks({ ids: trackIds });

  const hasCid = enableYouTubeContentId;

  const valid =
    hasTracks &&
    isTracksValid &&
    (!hasCid || acceptedCidTerms) &&
    acceptedAiTerms;

  useEffect(() => {
    changeStep({
      stepId: TRACKS_DETAILS,
      payload: {
        valid,
        updating,
        verifying,
      },
    });
  }, [valid, updating, verifying]);
};

const REQUIRED_DELIVERY_STEP_CONDITIONS = [
  'hasShops',
  'hasCountries',
  'hasDeliveryDateInFuture',
  'hasPricePlan',
  'hasDeliveryType',
  'beatportWithCorrectGenre',
  'traxsourceWithCorrectGenre',
];

const hasAllConditionsMet = (conditions: EntityModels.Targets): boolean =>
  reduce(
    (acc, key: string) => acc && conditions[key] && conditions[key].isMet,
    true,
    filter(
      (key) => includes(key, REQUIRED_DELIVERY_STEP_CONDITIONS),
      keys(conditions)
    )
  );

const LABEL_CONDITIONS = [
  'labelIsEligibleForBeatport',
  'labelIsEligibleForTraxsource',
];

const hasLabelConditionsMet = (conditions: EntityModels.Targets): boolean =>
  reduce(
    (acc, key: string) => acc && conditions[key] && conditions[key].isMet,
    true,
    filter((key) => includes(key, LABEL_CONDITIONS), keys(conditions))
  );

const useValidateDeliveryStep = ({
  bundleId,
  changeStep,
  releaseId,
}: {
  changeStep: ChangeStep;
  bundleId: number | string;
  releaseId?: number | string;
}) => {
  // Validate Delivery
  const deliveryValid = useSelector((state: ReduxState) => {
    const {
      entities: { deliveryBundles },
    } = state;
    const {
      entities: { releases },
    } = state;
    const bundle = deliveryBundles.entities[bundleId];
    if (!releaseId) return true;
    const release = releases.entities[releaseId];

    if (!bundle || !release) {
      return true;
    }

    const {
      targets: {
        order: { conditions },
      },
    } = bundle;
    return hasAllConditionsMet(conditions) && release.genreId;
  });

  const {
    request: { updating },
  } = useUpdateEntity({
    entity: 'deliveryBundles',
    id: bundleId,
  });

  useEffect(() => {
    changeStep({
      stepId: DELIVERY,
      payload: {
        valid: deliveryValid,
        updating,
      },
    });
  }, [deliveryValid, updating]);
};

export const useReleaseStepManager = () => {
  const { changeStep } = useProductPageActions();
  const {
    id: bundleId,
    releaseId,
    state: {
      data: { acceptedAiTerms, acceptedCidTerms } = {
        acceptedAiTerms: false,
        acceptedCidTerms: false,
      },
    },
  } = useProductPage();
  const { entry: release } = useRelease({ nest, id: releaseId });
  const fileUpload = useEntityFileRequestProvider({
    entity: 'releases',
    id: releaseId,
  });
  const { request: updateRequest } = useUpdateEntity({
    entity: 'releases',
    id: releaseId,
  });

  const updating = updateRequest.updating || fileUpload?.uploading;
  const verifying =
    release?.uploadStatus === 'confirmed' ||
    (release &&
      Object.keys(release?.availableCovers).length === 0 &&
      release.uploadStatus === 'finished');

  const labelValid = useSelector((state: ReduxState) => {
    const {
      entities: { deliveryBundles },
    } = state;

    const bundle = deliveryBundles.entities[bundleId];
    if (!bundle) return true;

    const {
      targets: {
        order: { conditions },
      },
    } = bundle;
    return hasLabelConditionsMet(conditions);
  });

  const error = useMemo(() => getReleaseError(release), [release]);

  useEffect(() => {
    changeStep(
      {
        stepId: RELEASE_DETAILS,
        payload: {
          valid: error.length === 0 && labelValid,
          updating,
          verifying,
        },
      },
      {
        error,
      }
    );
  }, [error, updating, labelValid, verifying]);

  useEffect(() => {
    changeStep(
      {
        stepId: ADDITIONAL_FEATRUES,
        payload: {
          valid: true,
        },
      },
      {
        error,
      }
    );
  }, []);

  useTrackStepManager({
    bundleId,
    release,
    changeStep,
    acceptedCidTerms: acceptedCidTerms as boolean,
    acceptedAiTerms: acceptedAiTerms as boolean,
  });
  useValidateDeliveryStep({ bundleId, releaseId, changeStep });
};
