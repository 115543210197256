import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxField, CustomerPaymentMethod } from 'components';
import { NewPaymentOption, PaymentOption } from './PaymentOption';
import type { GatewayProps } from './types';
import type { EntityModels } from 'imddata';

export const LinkGateway: React.FC<
  GatewayProps & {
    methodScheme: 'new-paypal' | 'new-apple-pay' | 'new-google-pay';
    paymentMethodId: EntityModels.CustomerPaymentMethod['paymentMethodId'];
  }
> = ({
  onPaymentChange,
  paymentData,
  children,
  formData,
  methodScheme,
  paymentMethodId,
}) => {
  const { t } = useTranslation();

  const [storePaymentMethod, setStoreMethod] = useState(
    () => !!formData?.enforceStorePaymentMethod
  );

  useEffect(() => {
    onPaymentChange({
      id: methodScheme,
      isReady: true,
      paymentMethod: paymentMethodId,
      data: {
        paymentMethodId,
        data: {
          storePaymentMethod,
        },
      },
    });
  }, [storePaymentMethod]);

  return (
    <>
      <NewPaymentOption selected={paymentData.id === methodScheme}>
        <PaymentOption
          checked={paymentData.id === methodScheme}
          onCheck={() =>
            onPaymentChange({
              id: methodScheme,
              isReady: true,
              paymentMethod: paymentMethodId,
              data: {
                paymentMethodId,
                data: {
                  storePaymentMethod,
                },
              },
            })
          }
          formChildren={
            <>
              {!formData?.enforceStorePaymentMethod && (
                <div>
                  <CheckboxField
                    // @ts-ignore
                    meta={{}}
                    name="save-method"
                    text={t('save-payment-method')}
                    // @ts-ignore
                    input={{
                      value: storePaymentMethod,
                      onChange: (value) => {
                        setStoreMethod(value);
                      },
                    }}
                  />
                </div>
              )}

              {children ? (
                <div style={{ marginTop: 16 }}>{children}</div>
              ) : null}
            </>
          }
        >
          <CustomerPaymentMethod
            scheme={methodScheme}
            caption={formData?.fee ? formData?.fee + ' ' + t('fee') : undefined}
            data={{}}
          />
        </PaymentOption>
      </NewPaymentOption>
    </>
  );
};
