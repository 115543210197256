import styled from '@emotion/styled';
import IconButton from './IconButton';
import { ChevronLeftIcon, CrossIcon } from '@imus/base-ui';

const IconButtonStyled = styled(IconButton)`
  width: 32px;
  height: 32px;
  background: var(--on-surface-semi);
`;

export const CloseButton = (props: React.ComponentProps<typeof IconButton>) => (
  <IconButtonStyled
    {...props}
    icon={(props: any) => <CrossIcon {...props} width={24} height={24} />}
  />
);

export const BackButton = (props: React.ComponentProps<typeof IconButton>) => (
  <IconButtonStyled {...props} icon={ChevronLeftIcon} />
);
