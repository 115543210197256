import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body } from '../../../text';
import { appear, pulse } from '../../shared/keyframes';
import { CheckIcon, MinusIcon } from '@imus/base-ui';

const focusAnim = ({ focused }: { focused: boolean }) => {
  if (focused) {
    return css`
      ${appear} ease-in-out 0.2s forwards,
        ${pulse} 1.5s 0.2s ease-in-out infinite;
    `;
  }
  return 'none';
};

export const Wrapper = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > ${Body} {
    margin-left: 16px;
  }
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;

  ::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    animation: ${focusAnim};
  }
`;

export const StyledSubCheckedIcon = styled(MinusIcon)`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  --on-surface: var(--on-accent);
`;
export const StyledCheckedIcon = styled(CheckIcon)`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  --on-surface: var(--on-accent);
`;

export const StyledInput = styled.input<{
  checked: boolean;
  subchecked?: boolean;
  tint?: string;
}>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px
    ${({ checked, subchecked }): string => {
      if (checked || subchecked) {
        return 'var(--accent)';
      }
      return 'var(--outline-var)';
    }};
  background-color: ${({ checked, subchecked }): string => {
    if (checked || subchecked) {
      return 'var(--accent)';
    }
    return 'var(--surface-container-low)';
  }};
  transition: all 0.1s ease-in-out;
  cursor: pointer;
`;
