import styled from '@emotion/styled';
import { Body } from '../../../text';
import IconButton from '../IconButton';

export const ChipContainer = styled.div<{
  state?: 'error' | 'primary';
  size?: 'large' | 'small';
  clickable: boolean;
}>`
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: center;
  height: ${({ size }) => (size === 'large' ? '32px' : '24px')};
  border-radius: 16px;
  background: var(--surface-container-high, #fff);

  box-shadow: inset 0 0 0 1px
    ${({ state }): string => {
    if (state === 'primary') {
      return 'var(--accent)';
    }
    if (state === 'error') {
      return 'var(--error-error)';
    }
    return 'var(--fg-4)';
  }};
  cursor: ${({ clickable }): string => (clickable ? 'pointer' : 'default')};

  ::after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition: background-color 0.1s ease-in-out;

    ${({ state }): string => {
    if (state === 'primary') {
      return `
    opacity: 0.1;
    background: var(--accent, #4FC100);`;
    }
    if (state === 'error') {
      return `
    opacity: 0.1;
    background: var(--error-error, #4FC100);`;
    }
    return '';
  }}
  }

  :hover::after {
    background-color: ${({ clickable }) =>
    clickable ? 'rgba(0, 0, 0, 0.1)' : null};
  }

  :active::after {
    background-color: ${({ clickable }) =>
    clickable ? 'rgba(0, 0, 0, 0.2)' : null};
  }

  > span,
  > ${Body} {
    gap: 4px;
    margin: 0 16px;
    display: inline-flex;
    align-items: center;
    text-align: center;
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: -8px;
  width: 32px;
  height: 32px;
`;
