export * from './providers/contexts/index';

export * from './providers/hooks';

export * from './types';

export {
  default as ProcessFieldMeta,
  ShowErrorContext,
} from './providers/ProcessFieldMeta';
export { nil } from './base';
export type { Nil } from './base/nil';
export * as reducers from './reducers';
export * as api from './api';
export { default as runDataSagas } from './sagas';
export { default as action } from './base/action';
export * as callAuthApi from './api/authApiCalls';
export { getReleaseCover } from './selectors/release';

export const noop = (): void => {
  return undefined;
};

export const canCrossellTracks = (/* plan: string */) => true;

export const modelSelectors = {
  invoice: {
    canBePaid: (invoice: any) => {
      if (!invoice) return false;
      const { status, isPayable } = invoice;
      return (
        isPayable ||
        status === 'unpaid' ||
        status === 'pending' ||
        status === 'legacy_unpaid' ||
        status === 'legacy_pending'
      );
    },
  },
};

/**
 * This helper simplifies the linear progress of delivery
 *   by hiding some of the statuses that user doesn't need
 *   to be concerned about,
 *   and changes formatting a bit (changes underscores
 *   to dashes).
 */
export const simplifyBundleStatus = (
  status = '',
  isFieldUpdateRequested: boolean,
  deliveryStatus: string
) => {
  if (!deliveryStatus) return '';
  if (typeof status !== 'string') return '';

  const statusWithDashes = status.replace('_', '-');
  const deliveryStatusWithDashes = deliveryStatus.replace('_', '-');
  if (isFieldUpdateRequested) return 'feedback-required';

  if (statusWithDashes === 'accepted') {
    switch (deliveryStatusWithDashes) {
      case 'confirmed':
      case 'take-down-cancelled':
        return 'delivered';
      case 'failed':
        return 'in-delivery';
      default:
        return deliveryStatusWithDashes;
    }
  }
  return statusWithDashes;
};
