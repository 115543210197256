import styled from '@emotion/styled';
import { EnabledField } from 'imdshared';
import { Card, H3, TextFormatted } from 'imdui';

const BarrierCard = styled(Card)`
  padding: 24px 32px;
  margin-top: 32px;
  box-shadow: inset 0 0 0 2px var(--accent);
  ${H3} {
    margin-bottom: 16px;
  }
`;
const BarrierFieldBase = styled.div`
  max-width: 512px;
  margin-top: 16px;
`;

export const BarrierField = ({
  title,
  description,
  label,
  fieldDescription,
  input,
  testId,
  meta = {},
}: any) => {
  return (
    <BarrierCard>
      <H3>{title}</H3>
      <TextFormatted text={description} />
      <BarrierFieldBase>
        <EnabledField
          testId={testId}
          label={label}
          description={fieldDescription}
          // @ts-ignore
          meta={meta}
          // @ts-ignore
          input={input}
        />
      </BarrierFieldBase>
    </BarrierCard>
  );
};
