//
import React from 'react';
import styled from '@emotion/styled';
import DragBox from '../DragBox';

const Tracker = styled.div`
  display: none;
`;

const DragBoxStyled = styled(DragBox)`
  width: 100%;
`;

const ProgressBarLine = styled.div`
  width: 100%;
  height: 4px;
  overflow: hidden;
  border-radius: ${({ disabled }) => (disabled ? '3px' : 0)};
  transform-origin: top left;
  background-color: ${({ disabled }) =>
    disabled ? 'var(--outline-var)' : 'var(--on-surface)'};
  transform: ${({ xPos = 0 }) => `scaleX(${xPos || 0})`};
`;

const ProgressBar = ({ offset, xPos = 0, disabled, ...props }) => (
  <ProgressBarLine {...props} xPos={1} disabled={true}>
    {!disabled && <ProgressBarLine xPos={xPos} />}
  </ProgressBarLine>
);

const Position = ({ disabled, xPos = 0 }) =>
  !disabled ? <Tracker xPos={xPos} /> : null;

const renderProgress = (props) => <ProgressBar {...props} />;

export default function Slider({
  min,
  disabled,
  length,
  onChange,
  offset,
  label,
  className,
  useFloor,
  style,
}) {
  return (
    <DragBoxStyled
      min={min}
      style={style}
      className={className}
      disabled={disabled}
      useFloor={useFloor}
      length={length}
      offset={offset}
      label={label}
      renderProgress={renderProgress}
      renderPosition={Position}
      onChange={onChange}
    />
  );
}
