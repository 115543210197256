import React, { useContext } from 'react';
import { BodyXS, Content } from 'imdui';
import { ApplicationSettingsContext } from 'imdshared';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import { DetailsRow } from '../../../../shared';
import styled from '@emotion/styled';
import { OverviewGroupLabel } from 'components';
import { css } from '@emotion/react';

const Details = styled.div`
  display: grid;
  gap: 24px;
`;

const Items = styled.div`
  display: grid;
  gap: 16px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--outline-var, #cdcbc9);
  background: var(--outline-var, color(display-p3 0.8039 0.7961 0.7882));
`;

export default function PaymentOverviewDetails({
  overview,
  children,
}: {
  children: React.ReactNode;
  overview: EntityModels.InvoiceOverview;
}) {
  const { t } = useTranslation();

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const currency = overview && overview.currency.id;

  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency,
  });

  // const paymentMethodData =
  //   paymentMethod === 'card' || paymentMethod === 'paypal'
  //     ? overview?.availablePaymentMethods[paymentMethod]
  //     : null;

  return (
    <Details data-test-id="PaymentGate-to-pay">
      <Items>
        {overview?.groups &&
          Object.values(overview.groups).map((g, idx) => {
            return (
              <DetailsRow key={idx}>
                <Content secondary>
                  <OverviewGroupLabel overviewGroup={g} />
                </Content>
                {g.value ? (
                  <Content secondary>
                    {priceFormatter.format(Number(g.value))}
                  </Content>
                ) : (
                  <Content secondary>-</Content>
                )}
              </DetailsRow>
            );
          })}
        {overview?.payments &&
          overview.payments.map(({ type, name, value }, i) => (
            <DetailsRow key={i}>
              <Content secondary>{t(type, { defaultValue: name })}</Content>

              <Content secondary>
                {priceFormatter.format(Number(-value))}
              </Content>
            </DetailsRow>
          ))}

        {overview?.discounts &&
          overview.discounts.map(({ type, name, appliedDiscount }, i) => (
            <DetailsRow key={i}>
              <Content secondary>{t(type, { defaultValue: name })}</Content>
              <Content secondary>
                {priceFormatter.format(Number(-appliedDiscount))}
              </Content>
            </DetailsRow>
          ))}

        {/* <DetailsRow> */}
        {/*   <Content>{t('payment-fee')}</Content> */}
        {/*   {paymentMethod && overview ? ( */}
        {/*     <Content> */}
        {/*       {priceFormatter.format(Number(paymentMethodData?.fee || 0))} */}
        {/*     </Content> */}
        {/*   ) : ( */}
        {/*     <Content>-</Content> */}
        {/*   )} */}
        {/* </DetailsRow> */}
        {children}
      </Items>
      <Divider />

      <div
        css={css(
          `
        display: grid;
        gap:8px;
        `
        )}
      >
        <DetailsRow>
          <Content style={{ fontWeight: 700 }}>{t('amount-to-pay')}</Content>
          <Content style={{ fontWeight: 700 }}>
            {priceFormatter.format(
              Number(overview.total) - Number(overview.totalPayments)
            )}
          </Content>
        </DetailsRow>
        <BodyXS secondary>{t('payment-fees-not-included')}</BodyXS>
      </div>
    </Details>
  );
}
