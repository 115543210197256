import styled from '@emotion/styled';
import config from 'imdconfig';
import { useCurrentLocale } from 'components';
import { useEntries, useQuery } from 'imddata';
import { BodySmall, Card, H4, LinkButton, TextFormatted } from 'imdui';

const AnnouncementCard = styled(Card)`
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  background: var(--surface-container-low, #e9e7e5);
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.05),
    0px 0px 4px 0px rgba(0, 0, 0, 0.05);
`;

const AnnouncementLink = styled(LinkButton)`
  display: flex;
  display: inline-flex;
  width: fit-content;
  margin-top: auto;
`;

const AnnouncementImage = styled.div<{ src: string }>`
  width: 100%;
  aspect-ratio: 320/180;
  background: url(${({ src }) => src});
  background-size: cover;
  overflow: hidden;
  max-width: 100%;
  margin: 0 0 16px 0;
`;

type NewsExceprt = {
  title: string;
  id: string;
  image?: { filenameDisk: string };
  type: string;
  url: string;
  buttonText: string;
  body: string;
};

const AnnouncementText = styled(TextFormatted)<{ truncate: boolean }>`
  text-overflow: ellipsis;
  margin-top: 4px;
  margin-bottom: 16px;

  ${({ truncate }) =>
    truncate
      ? `
  max-height: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

`
      : ''}
`;

const AnnouncementContents = styled.div`
  padding: 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${H4} {
    display: block;
    margin-bottom: 8px;
  }
`;

const TipNewsCard = ({
  imgSrc,
  title,
  description,
  actionText,
  onClick,
}: {
  onClick: () => void;
  imgSrc?: string;
  title: string;
  description: string;
  actionText: string;
}) => {
  return (
    <AnnouncementCard secondary={true}>
      {imgSrc ? (
        <AnnouncementImage src={`${config.contentUploads}/${imgSrc}`} />
      ) : null}
      <AnnouncementContents>
        <H4>{title}</H4>
        <BodySmall>
          <AnnouncementText truncate={!!imgSrc} text={description} />
        </BodySmall>
        {actionText ? (
          <AnnouncementLink
            to="#"
            onClick={onClick}
            size="small"
            appearance="fill"
            text={actionText}
          ></AnnouncementLink>
        ) : null}
      </AnnouncementContents>
    </AnnouncementCard>
  );
};

export const NewsRow = () => {
  const locale = useCurrentLocale();
  const { query: newsQuery, queryHash: newsQueryHash } = useQuery({
    query: { locale },
  });
  const { entries: news } = useEntries<NewsExceprt>({
    entity: 'newsExceprts',
    query: newsQuery,
    queryHash: newsQueryHash,
  });
  return (
    <>
      {news.map((n) => (
        <TipNewsCard
          key={n.id}
          onClick={() => {
            if (n.url) {
              window.open(n.url, '_blank');
            }
          }}
          imgSrc={n.image?.filenameDisk}
          title={n.title}
          description={n.body}
          actionText={n.buttonText}
        />
      ))}
    </>
  );
};

export const TipsRow = () => {
  const locale = useCurrentLocale();
  const { query: tipsQuery, queryHash: tipsQueryHash } = useQuery({
    query: { locale },
  });
  const { entries: tips } = useEntries<NewsExceprt & { articleId: string }>({
    entity: 'platformTips',
    query: tipsQuery,
    queryHash: tipsQueryHash,
  });
  return (
    <>
      {tips.map((tip) => {
        return (
          <TipNewsCard
            key={tip.id}
            onClick={() => {
              if (window.Beacon) {
                window.Beacon('article', tip.articleId, { type: 'modal' });
              }
            }}
            title={tip.title}
            description={tip.body}
            actionText={tip.buttonText}
          />
        );
      })}
    </>
  );
};
