import type { WrappedFieldProps } from 'redux-form';
import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { Button, Card, HelperText } from 'imdui';
import { useSingelFileUploadManager } from 'imdshared';
import { useTranslation } from 'react-i18next';
import type { UploadableHandlers } from 'imddata';

const AvatarFieldCard = styled(Card)`
  width: 100%;
  padding: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  box-shadow: none;
  justify-content: center;
`;

const Avatar = styled.div`
  cursor: pointer;
  overflow: hidden;
  background-color: #f2f2f2;
  display: flex;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
`;

const ImageStyled = styled.img`
  max-height: 112px;
  object-fit: cover;
  width: auto;
  height: 112px;
`;

const defaultImageComponent = ({ url }: { url?: string }) =>
  url ? (
    <ImageStyled alt="img" src={url} />
  ) : (
    <div style={{ width: '200px', height: '80px' }} />
  );

export const ImageField = ({
  input,
  actionText,
  style,
  handler = 'artistProfileImage',
  imageComponent: ImageComponent = defaultImageComponent,
  handlerId: id,
}: WrappedFieldProps & {
  style?: React.CSSProperties;
  actionText: string;
  imageComponent?: React.FC<{ url?: string }>;
  handlerId: number | string;
  handler: UploadableHandlers;
}) => {
  const [droppedAvatar, setDroppedAvatar] = useState<File>();
  const {
    preview: result,
    upload,
    request,
  } = useSingelFileUploadManager({
    handlerData: { id },
    handler,
  });
  useEffect(() => {
    if (droppedAvatar) {
      upload(droppedAvatar);
    }
  }, [droppedAvatar]);

  const [error, setError] = useState('');
  const handleDrop: DropzoneOptions['onDrop'] = useCallback(
    (acceptedFiles, failedFiles) => {
      if (acceptedFiles[0]) {
        setDroppedAvatar(acceptedFiles[0]);
        setError('');
      }
      if (failedFiles[0]) {
        setError(failedFiles[0].errors[0]?.code);
      }
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 3145728,
    onDrop: handleDrop,
    accept: { 'image/jpeg': [], 'image/png': [] },
  });

  useEffect(() => {
    if (result && result !== input.value) {
      input.onChange({
        url: result.downloadUrl,
        filename: result.fileName,
        extension: result.extension,
      });
    }
  }, [result]);
  const { t } = useTranslation();

  return (
    <AvatarFieldCard secondary>
      <input {...getInputProps()} />
      <Avatar style={style} {...getRootProps()}>
        <ImageComponent url={input.value?.url} />
      </Avatar>
      <Button
        {...getRootProps()}
        size="small"
        position="center"
        disabled={request.uploading}
        showLoading={request.uploading}
        text={input.value?.url ? t('change') : actionText}
      />
      {error ? <HelperText errorText={t(error)} /> : null}
    </AvatarFieldCard>
  );
};
