import styled from '@emotion/styled';
import { BodySmall, Subheading } from '../../../text';
import { optionClickStyle } from '../../base/OptionGrid';

export const OptionHeader = styled.div`
  ${Subheading} {
    align-items: center;
  }
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;
`;

// @ts-ignore
const OptionBase = styled.div<{
  disabled?: boolean;
  selected?: boolean;
  error?: boolean;
}>`
  ${BodySmall} {
    display: inline-block;
    margin-top: 4px;
  }
  & * {
    min-width: 0;
  }
  ul {
    list-style-position: inside;
    font-size: 16px;
    line-height: 24px;
    li {
      margin-bottom: 8px;
    }
  }
  svg {
    width: 100%;
  }
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--surface-container-high);
  position: relative;
  overflow: hidden;
  ${optionClickStyle as any}
`;
const OptionContent = styled.div`
  width: 100%;
  padding: 24px 32px;
`;

export const OptionDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Option = ({
  children,
  header,
  ...props
}: {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  header?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  error?: boolean;
  onClick?: React.MouseEventHandler;
}) => (
  <OptionBase {...props}>
    {header}
    <OptionContent>{children}</OptionContent>
  </OptionBase>
);
