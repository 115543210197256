//
import type { ChangeEvent } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Body } from 'imdshared';
import { createInputWithStyles } from 'imdui';
import { css } from '@emotion/react';

const Wrapper = styled.div`
  display: flex;
  margin-right: 32px;

  ${Body} {
    white-space: nowrap;
    margin-top: 12px;
    margin-right: 16px;
  }
`;

const StyledInput = createInputWithStyles('input');

const UnitsNumber = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Body>{t('number-of-units')}</Body>

      <StyledInput
        value={value}
        onChange={onChange}
        css={css(`
  width: 56px;
  min-height: 36px;
  height: 36px;
  margin-top: 4px;
        `)}
      />
    </Wrapper>
  );
};

export default UnitsNumber;
