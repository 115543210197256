export const Radio = ({ selected }: { selected: boolean }) => {
  return (
    <>
      {selected && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="var(--accent)"
            strokeWidth="2"
          />
          <circle cx="12" cy="12" r="4" fill="var(--accent)" />
        </svg>
      )}
      {!selected && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="var(--on-surface)"
            strokeWidth="2"
          />
        </svg>
      )}
    </>
  );
};
