//
import * as R from 'ramda';
import { schema } from 'normalizr';
import { camelize } from 'humps';
import { processNames } from './strategies';

export {
  normalizeWithSales,
  defaultGetSchema,
  defaultNormalizeResponse,
  normalizeGrouped,
} from './responseNormalizers';

/*
 * Simple schemas
 */

export const translation = new schema.Entity('translations');
export const coverArtworkTemplate = new schema.Entity('coverArtworkTemplates');

export const userNotification = new schema.Entity('userNotifications', {});

export const fieldUpdateRequestItem = new schema.Entity(
  'fieldUpdateRequestItems'
);
export const fieldUpdateRequest = new schema.Entity('fieldUpdateRequests', {
  fields: [fieldUpdateRequestItem],
});

export const deliveryBundleUpdate = [
  new schema.Entity(
    'deliveryBundleUpdates',
    {},
    {
      idAttribute: 'deliveryBundleId',
    }
  ),
];
export const dateFormat = new schema.Entity('dateFormats');

export const instantMasteringPreview = new schema.Entity(
  'instantMasteringPreviews'
);
export const instantMasteringTrack = new schema.Entity(
  'instantMasteringTracks',
  {
    currentPreview: instantMasteringPreview,
  }
);
export const instantMastering = new schema.Entity('instantMasterings', {
  tracks: [instantMasteringTrack],
});
export const trackAudioFingerprintMatch = new schema.Entity(
  'trackAudioFingerprintMatches'
);
export const trackAudioFingerprint = new schema.Entity(
  'trackAudioFingerprints'
);
export const handler = new schema.Entity('handlers');
export const trackName = new schema.Entity('trackNames');
trackName.define({ revisions: [trackName] });
export const releaseName = new schema.Entity('releaseNames');
releaseName.define({ revisions: [releaseName] });
export const artistName = new schema.Entity('artistNames');
artistName.define({ revisions: [artistName] });
export const contributorName = new schema.Entity('contributorNames');
contributorName.define({ revisions: [contributorName] });
export const notification = new schema.Entity('notifications');
export const file = new schema.Entity('files');
export const disk = new schema.Entity('disks');
export const deliveryFile = new schema.Entity('deliveryFiles');
export const trackAudio = new schema.Entity('trackAudios');
export const releaseCover = new schema.Entity('releaseCovers');
export const fileLocation = new schema.Entity('fileLocations');
export const blacklistItem = new schema.Entity('blacklistItems');
export const download = new schema.Entity('downloads');
export const failedJob = new schema.Entity('failedJobs');
export const indexConfigurator = new schema.Entity('indexConfigurators');
export const batchProcess = new schema.Entity('batchProcesses');
export const pitching = new schema.Entity('pitching');
export const customerPaymentMethod = new schema.Entity(
  'customerPaymentMethods',
  {},
  {
    idAttribute: (v) => {
      if (v) {
        if (!v.id) {
          if (v.type && v.originKey) return 'card';
        }
        return v.id;
      }
      return 'undefined';
    },
  }
);
export const trendFile = new schema.Entity('trendFiles');
export const trendItem = new schema.Entity('trendItems');
export const bank = new schema.Entity('payoutAddresses');
export const barcode = new schema.Entity('barcodes');
export const admin = new schema.Entity('admins');
export const channel = new schema.Entity('channels');
export const video = new schema.Entity('videos');
export const isrc = new schema.Entity('isrcs');
export const currencyRate = new schema.Entity('currencyRates');
export const currency = new schema.Entity('currencies');
export const salesItem = new schema.Entity('salesItems');
export const salesDistribution = new schema.Entity('salesDistributions');
export const salesCustomerRevenue = new schema.Entity('salesCustomerRevenues');
export const salesItemRevenue = new schema.Entity('salesItemRevenues');
export const salesImport = new schema.Entity('salesImports');
export const country = new schema.Entity('countries');
export const label = new schema.Entity('labels');
export const language = new schema.Entity('languages');
export const holiday = new schema.Entity('holidays');
export const subscription = new schema.Entity('subscriptions', {
  translations: [translation],
});
export const publisher = new schema.Entity('publishers');
export const shop = new schema.Entity('shops');
export const salesShop = new schema.Entity('salesShops');
export const salesStatementYear = new schema.Entity(
  'salesStatementYears',
  {},
  {
    idAttribute: (value, parent, key) => {
      return key;
    },
  }
);
export const salesStatement = new schema.Array(salesStatementYear);
export const salesShopsGroup = new schema.Entity('salesShopsGroups');
export const transaction = new schema.Entity('transactions');
export const orderItem = new schema.Entity('orderItems');
export const order = new schema.Entity('orders');
export const invoiceDiscount = new schema.Entity('invoiceDiscounts');
export const invoiceItem = new schema.Entity('invoiceItems');
export const invoiceTransaction = new schema.Entity('invoiceTransactions', {
  transaction,
});
export const invoice = new schema.Entity('invoices', {
  items: [invoiceItem],
  transactions: [invoiceTransaction],
});
export const payout = new schema.Entity('payouts', {
  payoutAddress: bank,
});
export const log = new schema.Entity('logs');
export const customerSubscription = new schema.Entity('customerSubscriptions', {
  subscription,
});
export const customer = new schema.Entity('customers');
export const user = new schema.Entity('users', { customer });
customer.define({
  country,
  users: [user],
  subscriptions: [customerSubscription],
  subscription: customerSubscription,
  latestSubscription: customerSubscription,
  defaultBank: bank,
});
export const genre = new schema.Entity('genres');
genre.define({
  subGenres: new schema.Array(genre),
});
export const contributor = new schema.Entity(
  'contributors',
  {
    defaultNameNormalized: contributorName,
    namesNormalized: [contributorName],
  },
  {
    processStrategy: processNames,
  }
);

export const artistsMetadata = new schema.Entity(
  'artistsMetadata',
  {},
  {
    idAttribute: (value) => `${value.shop}:${value.id}`,
  }
);

export const artistPage = new schema.Entity('artistPages');
export const artistFile = new schema.Entity('artistFiles');
export const artistImage = new schema.Entity('artistImages');
export const artistGallery = new schema.Entity('artistGalleries');

export const artist = new schema.Entity(
  'artists',
  {
    defaultNameNormalized: artistName,
    namesNormalized: [artistName],
  },
  {
    processStrategy: (v) => processNames(v),
  }
);

/*
 * Track
 */
export const trackArtist = new schema.Entity('trackArtists', {
  artist,
});
export const trackContributor = new schema.Entity('trackContributors', {
  contributor,
});
export const trackPublisher = new schema.Entity('trackPublishers', {
  publisher,
});
export const trackRelease = new schema.Entity('trackReleases');
export const track = new schema.Entity(
  'tracks',
  {
    file,
    audios: [trackAudio],
    releases: [trackRelease],
    artists: [trackArtist],
    contributors: [trackContributor],
    nonPerformingContributors: [trackContributor],
    performers: [trackContributor],
    publishers: [trackPublisher],
    namesNormalized: [trackName],
    defaultNameNormalized: trackName,
  },
  {
    processStrategy: processNames,
  }
);

/*
 * Release
 */
export const releasePage = new schema.Entity('releasePages');
export const releasePagesShop = new schema.Entity('releasePagesShops');
export const releasePagesPressFile = new schema.Entity(
  'releasePagesPressFiles'
);
export const releaseArtist = new schema.Entity('releaseArtists', {
  artist,
});
export const releaseTrack = new schema.Entity('releaseTracks', {
  track,
});
export const releaseVolume = new schema.Values([track]);
export const releaseVolumes = new schema.Array([releaseTrack]);

const removeLabelArray = (v) => {
  if (Array.isArray(v.label)) {
    return {
      ...v,
      label: null,
    };
  }
  return v;
};

export const release = new schema.Entity(
  'releases',
  {
    // Defined later
  },
  {
    processStrategy: (v) => removeLabelArray(processNames(v)),
  }
);
// trackRelease.define({ release });
/*
 * Delivery
 */
export const delivery = new schema.Entity('deliveries', {
  manifestFile: file,
  reportFile: file,
});
export const deliveryBundleDelivery = new schema.Entity(
  'deliveryBundleDeliveries',
  {
    delivery,
  }
);
export const deliveryBundleTrack = new schema.Entity(
  'deliveryBundleTracks',
  {},
  {
    processStrategy: (value) =>
      value
        ? {
          ...value,
          track: undefined,
        }
        : value,
  }
);
export const deliveryBundleCountry = new schema.Entity(
  'deliveryBundleCountries',
  {
    country,
  }
);
export const deliveryBundleShop = new schema.Entity('deliveryBundleShops', {
  shop,
});
export const deliveryBundleRelease = new schema.Entity(
  'deliveryBundleReleases',
  {},
  {
    processStrategy: (value) =>
      value
        ? {
          ...value,
          release: undefined,
        }
        : value,
  }
);

export const deliveryBundle = new schema.Entity('deliveryBundles', {
  customer,
  deliveries: [delivery],
  tracks: [deliveryBundleTrack],
  releases: [deliveryBundleRelease],
  // countries: [deliveryBundleCountry],
  shops: [deliveryBundleShop],
});

export const releaseDeliveryBundle = new schema.Entity(
  'releaseDeliveryBundles',
  {
    deliveryBundle,
  }
);
export const releaseDelivery = new schema.Entity('releaseDeliveries', {
  delivery,
});

release.define({
  covers: [releaseCover],
  artists: [releaseArtist],
  tracks: [releaseTrack],
  volumes: releaseVolumes,
  namesNormalized: [releaseName],
  defaultNameNormalized: releaseName,
  label,
  user,
  customer,
  currentDeliveryBundle: deliveryBundle,
  deliveryBundles: [releaseDeliveryBundle],
  deliveries: [releaseDelivery],
});

/*
 * Products Schemas
 */

export const payoutMethodPrice = new schema.Entity('prices');
export const payoutMethod = new schema.Entity('payoutMethods', {
  translations: [translation],
});

export const promotion = new schema.Entity('promotions');
export const paymentMethodPrice = new schema.Entity('prices');
export const paymentMethod = new schema.Entity('paymentMethods', {
  translations: [translation],
});

export const releaseTypePrice = new schema.Entity('prices');
export const releaseType = new schema.Entity('releaseTypes', {
  translations: [translation],
});
export const product = new schema.Entity('products', {
  translations: [translation],
});
export const price = new schema.Entity('prices');
export const productPrice = new schema.Entity('prices');
export const subscriptionPrice = new schema.Entity('prices');
export const pricePlan = new schema.Entity('pricePlans');
export const priceRegion = new schema.Entity('priceRegions', {
  countries: [country],
});

export const deliveryRegionCountry = new schema.Entity(
  'deliveryRegionCountries'
);
export const deliveryRegion = new schema.Entity('deliveryRegions', {
  countries: [deliveryRegionCountry],
});

export const voucherValue = new schema.Entity('voucherValues');
export const voucherProduct = new schema.Entity('voucherProducts', { product });
export const voucherSubscription = new schema.Entity('voucherSubscriptions', {
  subscription,
});
export const voucherReleaseType = new schema.Entity('voucherReleaseTypes', {
  releaseType,
});
export const voucher = new schema.Entity('vouchers', {
  values: [voucherValue],
  products: [voucherProduct],
  subscriptions: [voucherSubscription],
  releaseTypes: [voucherReleaseType],
  translations: [translation],
});
export const customerVoucher = new schema.Entity('customerVouchers', {
  customer,
  voucher,
});

export const userSocialAccount = new schema.Entity('userSocialAccounts', {});

export const payoutRequest = new schema.Object({
  payoutAddresses: [bank],
});

export const orderOverview = new schema.Object({
  pricePlan,
  customerVoucher,
});

order.define({
  items: [orderItem],
  voucher: customerVoucher,
});

const getGroupPriceId = (priceObj) => {
  if (!R.isNil(priceObj.releaseTypePriceId)) return priceObj.releaseTypePriceId;
  if (!R.isNil(priceObj.subscriptionPriceId))
    return priceObj.subscriptionPriceId;
  if (!R.isNil(priceObj.productPriceId)) return priceObj.productPriceId;
  if (!R.isNil(priceObj.deliveryTypePriceId))
    return priceObj.deliveryTypePriceId;

  return undefined;
};

export const productItems = new schema.Entity('productItems');
export const priceGroup = new schema.Entity(
  'products',
  {
    items: [productItems],
  },
  {
    idAttribute: (value) => camelize(value.name),
    // processStrategy: (value) => {
    //   return {
    //     ...value,
    //     items: value.items.filter((item) => {
    //       const isAvailable = item.availability
    //         ? Object.values(item.availability).reduce(
    //             (acc, isPlanAvailable) => acc || isPlanAvailable,
    //             false
    //           )
    //         : false;
    //       return isAvailable;
    //     }),
    //   };
    // },
  }
);

export const pricesCustomer = new schema.Object({
  groups: [priceGroup],
  defaultPricePlan: new schema.Entity('defaultPricePlan'),
  priceRegion,
  pricePlans: [pricePlan],
});

export const trendsSummary = new schema.Object({});

export const newPricesOverview = new schema.Object({
  groups: [
    new schema.Object(
      {
        items: [
          new schema.Object({
            prices: new schema.Values(
              new schema.Entity(
                'prices',
                {},
                {
                  idAttribute: getGroupPriceId,
                }
              )
            ),
          }),
        ],
      },
      {
        idAttribute: (value) => camelize(value.name),
      }
    ),
  ],
  defaultPricePlan: new schema.Entity('defaultPricePlan'),
  priceRegion,
  pricePlans: [pricePlan],
});

export const pricesOverview = new schema.Entity(
  'prices',
  {
    priceRegion,
    pricePlans: [pricePlan],
    prices: new schema.Entity(
      'combined',
      {},
      {
        idAttribute: () => 'fixKey',
      }
    ),
  },
  {
    idAttribute: (value) => value.priceRegion.id,
    processStrategy: (value) => {
      // TODO this has to be rewritten, I have no freaking idea how it works  (Nick)
      const prices = value.groups.reduce((groupAcc, group) => {
        const name = camelize(group.name);
        return {
          ...groupAcc,
          [name]: group.items.reduce((itemAcc, item) => {
            if (!item.prices) return itemAcc;
            return {
              ...itemAcc,
              ...Object.values(item.prices).reduce((priceAcc, priceObj) => {
                // $FlowFixMe
                const id = getGroupPriceId(priceObj);
                if (!id) return priceAcc;
                return {
                  ...priceAcc,
                  [id]: {
                    id,
                    // $FlowFixMe
                    ...priceObj,
                  },
                };
              }, {}),
            };
          }, {}),
        };
      }, {});
      const groups = value.groups.reduce((groupAcc, group) => {
        const name = camelize(group.name);
        return [
          ...groupAcc,
          {
            ...group,
            name,
            items: group.items.reduce((itemAcc, item) => {
              return [
                ...itemAcc,
                {
                  ...item,
                  prices: Object.keys(item.prices).reduce(
                    (acc, pricePlanId) => ({
                      ...acc,
                      [pricePlanId]: getGroupPriceId(item.prices[pricePlanId]),
                    }),
                    {}
                  ),
                },
              ];
            }, []),
          },
        ];
      }, []);
      return {
        ...value,
        groups,
        prices,
      };
    },
  }
);

export const salesStatements = new schema.Array(
  new schema.Entity(
    'salesStatements',
    {
      months: new schema.Array(
        new schema.Entity(
          'salesStatements',
          {},
          {
            idAttribute: (value, parent) => `${parent.year}-${value.month}`,
            processStrategy: (value, parent) => ({
              ...value,
              year: parent.year,
            }),
          }
        )
      ),
    },
    {
      idAttribute: 'year',
    }
  )
);

export const salesStatementsSplit = new schema.Entity(
  'salesStatementsSplits',
  {},
  {
    idAttribute: (value) => value.id + '----' + value.revenue,
  }
);

export const customerSubscriptionState = new schema.Object({});

export const salesStatementsSummary = new schema.Object({
  summary: salesStatements,
});

export const newsExceprt = new schema.Entity('newsExceprts');
export const releaseNote = new schema.Entity('releaseNotes');
export const platformTip = new schema.Entity('platformTips');
export const referralReward = new schema.Entity('referralRewards');
export const copyrightClaim = new schema.Entity('copyrightClaims', {});
export const shortLink = new schema.Entity('shortLinks', {});
export const revenueSplitTarget = new schema.Entity('revenueSplitTargets');
