import React from 'react';
import { LinkButton } from 'imdshared';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ChevronLeftIcon, ChevronRightIcon } from '@imus/base-ui';

type Props = {
  next?: string;
  nextText?: string;
  previousText?: string;
  previous?: string;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 24px 0;
`;

const NavBar: React.FC<Props> = ({
  next,
  nextText,
  previousText,
  previous,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div id="previous-product-step">
        {previous && (
          <LinkButton
            to={previous}
            size="large"
            text={
              previousText ? t('back-to', { step: t(previousText) }) : t('back')
            }
            iconLeft={ChevronLeftIcon}
            disabled={!previous}
            style={{
              visibility: previous ? 'initial' : 'hidden',
              marginRight: '24px',
            }}
          />
        )}
      </div>

      <div id="next-product-step">
        {next && (
          <LinkButton
            to={next}
            testId="wizard-next"
            size="large"
            primary={true}
            appearance="fill"
            text={nextText ? t(nextText) || t('next') : t('next')}
            icon={ChevronRightIcon}
            disabled={!next}
            style={{
              visibility: next ? 'initial' : 'hidden',
            }}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default NavBar;
