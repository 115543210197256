import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Card } from '../base';
import { FieldGroup } from '../field';
import { ModalWindowTitle } from '@imus/base-ui';

export const AnimatedDim = styled(motion.div)`
  z-index: 103;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a7a7a780;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const WindowCard = styled(Card.withComponent(motion.div))`
  border-radius: 16px;
  border: 1px solid var(--outline-var, #cdcbc9);
  background: var(--surface-container-medium, #f6f1f0);
  box-shadow:
    0px 0px 20px 0px rgba(0, 0, 0, 0.05),
    0px 4px 10px -2px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 576px;
  max-height: 90%;
  margin: 24px;
  cursor: default;
  overflow: auto;
  ${FieldGroup} {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 0;
  }
  margin-bottom: 32px;
`;

export const WindowHeader = styled.div<{
  headerAlign: 'left' | 'center';
}>`
  z-index: 1;
  position: sticky;
  flex-direction: row-reverse;
  top: 0;
  display: flex;
  align-items: center;
  padding: 24px 32px 24px 32px;

  > ${ModalWindowTitle} {
    width: 100%;
    text-align: center;
    margin: 0 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const WindowChildren = styled.div<{
  hideTitleBar: boolean;
}>`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
