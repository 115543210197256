import React from 'react';
import styled from '@emotion/styled';
import { Content, H4, BodyXS } from 'imdui';
import type { EntityModels } from 'imddata';
import { useTranslation } from 'react-i18next';

const RightSection = styled.span`
  align-items: baseline;
  flex-direction: column;
  display: flex;
  ${BodyXS} {
    margin-top: 8px;
  }
`;

const Wrapper = styled.div`
  ${Content} {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-word;
  }
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) auto;
  justify-content: center;
  align-self: center;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-shrink: 1;
  }
`;

type NewPaypalScheme = {
  scheme: 'new-paypal';
  caption?: string;
  data: { name?: string };
};

type PaypalScheme = {
  scheme: EntityModels.CustomerPaymentMethodPaypal['paymentMethodId'];
  caption?: string;
  data: EntityModels.CustomerPaymentMethodPaypal['data'];
};

type CardScheme = {
  scheme: EntityModels.CustomerPaymentMethodCard['paymentMethodId'];
  caption?: string;
  data: EntityModels.CustomerPaymentMethodCard['data'];
};

type NewCardScheme = {
  scheme: 'new-card';
  caption?: string;
  data: { name?: string };
};

type ApplePayScheme = {
  scheme: EntityModels.CustomerPaymentMethodApplePay['paymentMethodId'];
  caption?: string;
  data: EntityModels.CustomerPaymentMethodApplePay['data'];
};

type NewApplePayScheme = {
  scheme: 'new-apple-pay';
  caption?: string;
  description?: string;
  data: { name?: string };
};

type GooglePayScheme = {
  scheme: EntityModels.CustomerPaymentMethodGooglePay['paymentMethodId'];
  caption?: string;
  data: EntityModels.CustomerPaymentMethodGooglePay['data'];
};

type NewGooglePayScheme = {
  scheme: 'new-google-pay';
  caption?: string;
  data: { name?: string };
};
type SepaDirectDebitScheme = {
  scheme: EntityModels.CustomerPaymentMethodSepaDirectDebit['paymentMethodId'];
  caption?: string;
  data: EntityModels.CustomerPaymentMethodSepaDirectDebit['data'];
};

type NewSepaDirectDebitScheme = {
  scheme: 'new-sepa-direct-debit';
  caption?: string;
  data: { iban?: string; ownerName?: string };
};

type Props =
  | NewSepaDirectDebitScheme
  | SepaDirectDebitScheme
  | NewPaypalScheme
  | PaypalScheme
  | CardScheme
  | NewCardScheme
  | NewApplePayScheme
  | ApplePayScheme
  | NewGooglePayScheme
  | GooglePayScheme;

// const formatDate = (expiryMonth: number, expiryYear: number) => {
//   return `${expiryMonth < 10 ? `0${expiryMonth}` : expiryMonth}/${expiryYear
//     .toString()
//     .slice(-2)}`;
// };

const supportedCards = [
  'paypal',
  'diners',
  'discover',
  'visa',
  'mir',
  'maestro',
  'mc',
  'amex',
];

export const getBrandIconUrl = (brand = '') => {
  let mappedname = brand?.toLowerCase();
  switch (mappedname) {
    case 'mastercard':
      mappedname = 'mc';
      break;
    case 'diners club':
      mappedname = 'diners';
      break;
    default:
      break;
  }

  if (supportedCards.indexOf(mappedname) >= 0) {
    return `/images/payment-methods/${mappedname}.svg`;
  }
  console.log('Brand Image Not Found: ' + brand);
  return `/images/payment-methods/card.svg`;
};

export const CustomerPaymentMethod = (p: Props) => {
  const { t } = useTranslation();
  switch (p.scheme) {
    case 'new-paypal':
    case 'new-apple-pay':
    case 'apple_pay':
    case 'google_pay':
    case 'new-google-pay':
    case 'new-sepa-direct-debit':
    case 'sepa_direct_debit':
    case 'paypal': {
      let text: React.ReactNode = '';
      switch (p.scheme) {
        case 'new-sepa-direct-debit':
          text = t('sepa-direct-debit');
          break;
        case 'sepa_direct_debit':
          text = (
            <>
              {t('sepa-direct-debit')} (
              {`${p.data.ownerName} ••••${p.data.iban.slice(12, -1)}`})
            </>
          );
          break;
        case 'apple_pay':
        case 'new-apple-pay':
          text = t('apple-pay');
          break;
        case 'google_pay':
          text = (
            <>
              {t('google-pay')} ({`•••• ${p.data.lastFour}`})
            </>
          );
          break;
        case 'new-google-pay':
          text = t('google-pay');
          break;
        case 'new-paypal':
          text = t('paypal');
          break;
        case 'paypal':
          text = p.data.shopperEmail;
      }
      let iconUrl = '';
      switch (p.scheme) {
        case 'new-paypal':
        case 'paypal':
          iconUrl = getBrandIconUrl('paypal');
          break;
        case 'new-apple-pay':
        case 'apple_pay':
          iconUrl = '/images/payment-methods/apple-pay.svg';
          break;
        case 'new-google-pay':
        case 'google_pay':
          iconUrl = '/images/payment-methods/google-pay.svg';
          break;
        case 'new-sepa-direct-debit':
        case 'sepa_direct_debit':
          iconUrl = '/images/payment-methods/sepa.svg';
          break;
      }

      const height =
        p.scheme === 'google_pay' || p.scheme === 'new-google-pay' ? 48 : 24;
      const style =
        p.scheme === 'google_pay' || p.scheme === 'new-google-pay'
          ? {
            marginLeft: '-12px',
          }
          : undefined;

      return (
        <Wrapper>
          <RightSection>
            <H4>{text}</H4>
            {p.caption ? <BodyXS secondary>{p.caption}</BodyXS> : null}
          </RightSection>
          <div>
            <img src={iconUrl} alt={p.scheme} height={height} style={style} />
          </div>
        </Wrapper>
      );
    }

    case 'card': {
      const { data } = p;
      if (!data) return null;
      const brandUrlSvg = getBrandIconUrl(data?.brand || data?.name);
      return (
        <Wrapper>
          <RightSection>
            <H4>
              {t('credit-card')} ({`•••• ${data.lastFour}`})
            </H4>
            {p.caption ? <BodyXS secondary>{p.caption}</BodyXS> : null}
          </RightSection>
          <div>
            <img src={brandUrlSvg} alt={data.name} height={24} />
          </div>
        </Wrapper>
      );
    }
    case 'new-card': {
      const { data } = p;
      const brandUrlSvg = getBrandIconUrl(data?.name);
      return (
        <Wrapper>
          <RightSection>
            <H4>{t('credit-card')}</H4>
            {p.caption ? <BodyXS secondary>{p.caption}</BodyXS> : null}
          </RightSection>

          <div>
            {brandUrlSvg === '/images/payment-methods/card.svg' ? (
              <>
                <img
                  src="/images/payment-methods/visa.svg"
                  alt={data?.name}
                  height={24}
                  style={{ marginRight: 4 }}
                />
                <img
                  src="/images/payment-methods/mc.svg"
                  alt={data?.name}
                  height={24}
                />
              </>
            ) : (
              <img src={brandUrlSvg} alt={data?.name} height={24} />
            )}
          </div>
        </Wrapper>
      );
    }
  }
};
