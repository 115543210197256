import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Card, Content, Button } from 'imdui';
import { useSubscriptionUpsell } from 'imdshared';
import { accentThemes, BodyS, ContentM } from '@imus/base-ui';

const ShowcaseGrid = styled.div`
  background: var(--state-hover-on-surface, rgba(27, 28, 27, 0.05));
  display: grid;
  padding: 24px 32px 32px 32px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 16px;
  & > div {
    display: grid;
    gap: 16px;
  }
  ${Content} {
    display: block;
    font-weight: 700;
    line-height: 16px; /* 106.667% */
    letter-spacing: 0.225px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
`;

type ShowcaseProps = {
  className?: string;
  style?: React.CSSProperties;
  data: Array<{
    title: string;
    icon: React.ReactNode;
    description: string;
  }>;
  title: React.ReactNode;
  children?: React.ReactNode;
};

const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  display: flex;

  padding: 32px 32px 24px 32px;
  align-items: center;
  align-content: center;
  gap: 16px;
  align-self: stretch;
`;

const ShowcaseCardContent: React.FC<ShowcaseProps> = ({
  className,
  style,
  data,
  children,
  title,
}) => {
  return (
    <Card className={className} style={style}>
      <Title>{title}</Title>
      {data && (
        <ShowcaseGrid>
          {data.map((d, idx) => (
            <div key={idx}>
              {d.icon}
              <div>
                <Content>{d.title}</Content>
                <BodyS secondary>{d.description}</BodyS>
              </div>
            </div>
          ))}
        </ShowcaseGrid>
      )}
      {children}
    </Card>
  );
};

const ShowcaseCard = styled(ShowcaseCardContent)`
  ${accentThemes.tertiary}
  border-radius: 40px;
  border: 1px solid var(--accent, #ff00a3);
  background: var(--accent-surface-container, #fffbff);
`;

const Heading = styled.h2`
  color: var(--on-surface, #1b1c1b);
  color: var(--on-surface, color(display-p3 0.1059 0.1098 0.1059));
  font-feature-settings:
    'ss01' on,
    'cv11' on;
  font-family: Barlow;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 32px */
  text-transform: uppercase;
`;

const HeadingBlock = styled.div`
  display: grid;
  gap: 8px;
  ${ContentM} {
    display: block;
    max-width: 400px;
    align-self: stretch;
  }
`;

export const SubscribeCard: React.FC<{
  className?: string;
  style?: React.CSSProperties;
  title: string;
  description: string;
  data: React.ComponentProps<typeof ShowcaseCard>['data'];
}> = ({ className, title, data, description, style }) => {
  const { t } = useTranslation();

  const { open: openUpsell } = useSubscriptionUpsell();

  return (
    <ShowcaseCard
      title={
        <>
          <HeadingBlock>
            <Heading>{title}</Heading>
            <ContentM secondary>{description}</ContentM>
          </HeadingBlock>
          <Button
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              openUpsell({
                section: 'music-analytics',
                analytics: { detail: 'music-analytics' },
              });
            }}
            primary={true}
            appearance="fill"
            text={t('upgrade')}
          />
        </>
      }
      className={className}
      style={style}
      data={data}
    ></ShowcaseCard>
  );
};
