import React, { useEffect, useReducer, useCallback, useMemo } from 'react';
import { ValidationErrorsIBAN, validateIBAN } from 'ibantools';
import { useTranslation } from 'react-i18next';
import { CheckboxField } from 'imdshared';
import { NewInput } from 'imdui';
import styled from '@emotion/styled';
import { CustomerPaymentMethod } from 'components';
import { NewPaymentOption, PaymentOption } from '../PaymentOption';
import type { GatewayProps } from '../types';

const CardForm = styled.div`
  position: relative;
`;

const GatewayStyled = styled.form``;

type State = {
  values: {
    storePaymentMethod: boolean;
    ownerName?: string;
    ibanNumber?: string;
  };
  ownerName: { isValid: boolean; blurred?: boolean };
  ibanNumber: { isValid: boolean; blurred?: boolean; errorText?: string };
  storePaymentMethod: { isValid: boolean; blurred?: boolean };
  brand: undefined | string;
};

const getInitialCardState = (formData: {
  enforceStorePaymentMethod?: boolean;
  originKey?: string;
  allowSocialSecurityNumber?: boolean;
}): State => ({
  ownerName: { isValid: false },
  ibanNumber: { isValid: false },
  storePaymentMethod: { isValid: true },
  brand: undefined,
  values: { storePaymentMethod: !!formData.enforceStorePaymentMethod },
});

const CHANGE_OWNER_NAME = 'CHANGE_OWNER_NAME';
const CHANGE_IBAN_NUMBER = 'CHANGE_IBAN_NUMBER';
const CHANGE_STORE_METHOD = 'CHANGE_STORE_METHOD';
const BLUR_FIELD = 'BLUR_FIELD';

type Action =
  | {
    type: typeof BLUR_FIELD;
    payload: {
      field: 'ibanNumber' | 'ownerName';
    };
  }
  | {
    type: typeof CHANGE_STORE_METHOD;
    payload: {
      value: boolean;
    };
  }
  | {
    type: typeof CHANGE_IBAN_NUMBER;
    payload: {
      value: string;
    };
  }
  | {
    type: typeof CHANGE_OWNER_NAME;
    payload: {
      value: string;
    };
  };

type Reducer = (s: State, a: Action) => State;

const cardReducer: Reducer = (state, action) => {
  switch (action.type) {
    case BLUR_FIELD: {
      const {
        payload: { field },
      } = action;
      return {
        ...state,
        [field]: {
          ...(state[field] || {}),
          blurred: true,
        },
      };
    }
    case CHANGE_STORE_METHOD: {
      const {
        payload: { value },
      } = action;
      return {
        ...state,
        values: {
          ...state.values,
          storePaymentMethod: value,
        },
      };
    }
    case CHANGE_IBAN_NUMBER: {
      const {
        payload: { value },
      } = action;

      const { valid, errorCodes } = validateIBAN(value);
      let errorText = '';

      if (!valid && errorCodes.length) {
        const [firstErr] = errorCodes;
        switch (firstErr) {
          case ValidationErrorsIBAN.NoIBANCountry:
            errorText = 'iban-missing-country-code';
            break;
          default:
            console.log(errorCodes);
            errorText = 'invalid-iban';
        }
      }
      return {
        ...state,
        ibanNumber: {
          ...state.ownerName,
          isValid: !errorText,
          errorText,
        },
        values: {
          ...state.values,
          ibanNumber: value,
        },
      };
    }
    case CHANGE_OWNER_NAME: {
      const {
        payload: { value },
      } = action;
      return {
        ...state,
        ownerName: {
          ...state.ownerName,
          isValid: !!value,
        },
        values: {
          ...state.values,
          ownerName: value,
        },
      };
    }
    default:
      return state;
  }
};

type Props = {
  checked: boolean;
  children?: React.ReactNode;
  formData: {
    fee?: string;
    enforceStorePaymentMethod?: boolean;
    originKey?: string;
    allowSocialSecurityNumber?: boolean;
  };
  onPaymentChange: GatewayProps['onPaymentChange'];
};

function AdyenCardForm({
  checked,
  children,
  onPaymentChange,
  formData,
}: Props): React.ReactElement {
  const { enforceStorePaymentMethod } = formData;

  const { t } = useTranslation();
  const initialCardState = useMemo(
    () => getInitialCardState(formData),
    [formData]
  );
  const [{ ownerName, ibanNumber, values }, dispatchCardEvent] =
    useReducer<Reducer>(cardReducer, initialCardState);

  const handleChangeOwnerNameField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatchCardEvent({
        type: CHANGE_OWNER_NAME,
        payload: { value: event.target.value },
      });
    },
    []
  );
  const handleChangeIbanNumberField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatchCardEvent({
        type: CHANGE_IBAN_NUMBER,
        payload: { value: event.target.value.toUpperCase() },
      });
    },
    []
  );

  const paymentNotReady = !ownerName.isValid || !ibanNumber.isValid;

  const handleChange = useCallback(() => {
    onPaymentChange({
      id: 'new-sepa-direct-debit',
      paymentMethod: 'sepa_direct_debit',
      isReady: !paymentNotReady,
      data: {
        paymentMethodId: 'sepa_direct_debit',
        data: values,
      },
    });
  }, [paymentNotReady, values]);

  useEffect(() => {
    if (checked) handleChange();
  }, [paymentNotReady, values]);

  return (
    <NewPaymentOption selected={checked} data-test-id="new-payment-option-card">
      <PaymentOption
        checked={checked}
        onCheck={checked ? undefined : handleChange}
        formChildren={
          <GatewayStyled key="form" id="adyen-card">
            <div>
              <div>
                <CardForm className=".js-chckt-pm__pm-holder">
                  <NewInput
                    name="ibanNumber"
                    testId="ibanNumber"
                    placeholder={t('iban-number')}
                    onBlur={() =>
                      dispatchCardEvent({
                        type: BLUR_FIELD,
                        payload: { field: 'ibanNumber' },
                      })
                    }
                    style={{ marginBottom: '16px' }}
                    errorText={
                      !ibanNumber.isValid && ibanNumber.blurred
                        ? ibanNumber.errorText
                        : ''
                    }
                    value={values.ibanNumber}
                    onChange={handleChangeIbanNumberField}
                  />
                  <NewInput
                    name="ownerName"
                    testId="ownerName"
                    placeholder={t('owner-name')}
                    onBlur={() =>
                      dispatchCardEvent({
                        type: BLUR_FIELD,
                        payload: { field: 'ownerName' },
                      })
                    }
                    style={{ marginBottom: '16px' }}
                    errorText={!ownerName.isValid && ownerName.blurred}
                    value={values.ownerName}
                    onChange={handleChangeOwnerNameField}
                  />
                  {!enforceStorePaymentMethod && (
                    <CheckboxField
                      // @ts-ignore
                      meta={{}}
                      name="save-method"
                      text={t('save-payment-method')}
                      // @ts-ignore
                      input={{
                        value: values.storePaymentMethod,
                        onChange: (value) => {
                          dispatchCardEvent({
                            type: CHANGE_STORE_METHOD,
                            payload: {
                              value,
                            },
                          });
                        },
                      }}
                    />
                  )}
                </CardForm>
              </div>
            </div>

            {children ? <div style={{ marginTop: 16 }}>{children}</div> : null}
          </GatewayStyled>
        }
      >
        <CustomerPaymentMethod
          scheme="new-sepa-direct-debit"
          caption={formData?.fee ? formData?.fee + ' ' + t('fee') : undefined}
          data={{
            iban: '',
            ownerName: '',
          }}
        />
      </PaymentOption>
    </NewPaymentOption>
  );
}

export default function SepaDirectGateway({
  paymentData,
  onPaymentChange,
  formData,
  children,
}: GatewayProps) {
  if (!formData) return null;

  return (
    <>
      <AdyenCardForm
        checked={!paymentData.id || paymentData.id === 'new-sepa-direct-debit'}
        onPaymentChange={onPaymentChange}
        formData={formData}
      >
        {children}
      </AdyenCardForm>
    </>
  );
}
