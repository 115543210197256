import React, { useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { nil, type EntityModels } from 'imddata';

import { Content } from 'imdui';
import AdyenCardGateway from './AdyenCardGateway';
import type {
  PaymentMethodData,
  PaymentMethod,
  GatewayProps,
  PaymentData,
  OnPaymentChange,
} from './types';
import { LinkGateway } from './LinkGateway';
import SepaDirectGateway from './SepaDirectGateway';

const CreditGateway = ({ onPaymentChange, children }: GatewayProps) => {
  useEffect(() => {
    onPaymentChange({
      paymentMethod: nil,
      id: nil,
      isReady: true,
    });
  }, []);
  const { t } = useTranslation();
  return (
    <div>
      <Content style={{ marginBottom: '16px', display: 'block' }}>
        {t('credit')}
      </Content>
      {children}
    </div>
  );
};

const PaypalGateway = (props: GatewayProps) => {
  return (
    <LinkGateway
      {...props}
      methodScheme="new-paypal"
      paymentMethodId="paypal"
    />
  );
};

const GooglePayGateway = (props: GatewayProps) => {
  return (
    <LinkGateway
      {...props}
      methodScheme="new-google-pay"
      paymentMethodId="google_pay"
    />
  );
};
const ApplePayGateway = (props: GatewayProps) => {
  return (
    <LinkGateway
      {...props}
      methodScheme="new-apple-pay"
      paymentMethodId="apple_pay"
    />
  );
};

const getPaymentProcessorComponent = (
  paymentMethod: PaymentMethod
): React.FC<GatewayProps> => {
  switch (paymentMethod) {
    case nil:
      return CreditGateway;
    case 'card':
      return AdyenCardGateway;
    case 'paypal':
      return PaypalGateway;
    case 'apple_pay':
      return ApplePayGateway;
    case 'google_pay':
      return GooglePayGateway;
    case 'sepa_direct_debit':
      return SepaDirectGateway;
    default: {
      throw new Error('not supported payment gate');
    }
  }
};
export const PaymentProcessor = ({
  paymentData,
  paymentMethod,
  onChange,
  children,
  paymentMethodData = {
    data: { originKey: undefined, allowSocialSecurityNumber: false },
  },
  customerPaymentMethods = [],
}: {
  children?: React.ReactNode;
  customerPaymentMethods?: Array<EntityModels.CustomerPaymentMethod>;
  paymentData: PaymentData;
  paymentMethodData: PaymentMethodData;
  paymentMethod: PaymentMethod;
  onChange: (data: PaymentData) => void;
}) => {
  const Processor = useMemo(
    () => getPaymentProcessorComponent(paymentMethod),
    [paymentMethod]
  );

  const onPaymentChange = useCallback<OnPaymentChange>(
    (data) => {
      if (data) {
        onChange(data);
      }
    },
    [onChange]
  );

  const {
    fee,
    data: { originKey, allowSocialSecurityNumber, enforceStorePaymentMethod },
  } = paymentMethodData;

  const formData = {
    enforceStorePaymentMethod,
    originKey,
    allowSocialSecurityNumber,
    fee,
  };

  return (
    <Processor
      customerPaymentMethods={customerPaymentMethods}
      paymentData={paymentData}
      formData={formData}
      onPaymentChange={onPaymentChange}
    >
      {children}
    </Processor>
  );
};
