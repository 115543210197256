//
import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  Icon,
  Checkbox,
  Content,
  Clickable,
  IconsCollection,
  FieldCard,
} from 'imdui';
import { useTranslation } from 'react-i18next';
import type * as Types from '../types';

const CountryWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
  padding: 24px 36px;
`;

const RegionWrap = styled(FieldCard)`
  padding: 8px;
`;

const RegionHeader = styled.div<{ expanded: boolean }>`
  display: flex;
  width: 100%;
  padding: 4px 0 4px 16px;
  box-shadow: ${({ expanded }) =>
    expanded ? ' 0px 1px 0px var(--outline-var)' : 'none'};
`;

const CountryCheck = styled(Checkbox)`
  box-sizing: border-box;
`;

const ItemRow = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 8px;
  justify-content: space-between;
  ${Content} {
    margin-left: 10px;
  }
`;

const Country = ({
  country,
  disabled,
  handleCountryCheck,
}: {
  country: Types.Country;
  disabled?: boolean;
  handleCountryCheck: (c: Types.Country, v: boolean) => void;
}) => {
  const { t } = useTranslation();

  const onCheck = useCallback(
    (event, isChecked) => {
      if (disabled) return;
      handleCountryCheck(country, isChecked);
    },
    [country, disabled, handleCountryCheck]
  );

  return (
    <CountryCheck
      name={`DeliveryRegion-country-checkbox-${country.name}`}
      disabled={disabled}
      testId={`DeliveryRegion-country-checkbox-${country.name}`}
      text={t(country.name)}
      checked={country.isChecked}
      onCheck={onCheck}
    />
  );
};

const CountryMemoized = React.memo(
  Country,
  (prevProps, nextProps) =>
    prevProps.country.isChecked === nextProps.country.isChecked &&
    prevProps.country.id === nextProps.country.id
);

const clickableStyle = {
  flex: 1,
  marginLeft: '8px',
};

function Region({
  region,
  onRegionCheck,
  onCountryCheck,
  loading,
  disabled,
}: {
  region: Types.Region;
  onRegionCheck: (r: Types.Region, v: boolean) => void;
  onCountryCheck: (r: Types.Country, v: boolean) => void;
  loading?: boolean;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const handleRegionCheck = useCallback(
    (event: any, isChecked: boolean) => {
      if (onRegionCheck) {
        onRegionCheck(region, isChecked);
      }
    },
    [onRegionCheck, region]
  );

  const handleCountryCheck = useCallback(
    (country: Types.Country, isChecked: boolean) => {
      if (onCountryCheck) {
        onCountryCheck(country, isChecked);
      }
    },
    [onCountryCheck]
  );

  const [expanded, setExpanded] = useState(false);
  const handleRegionExpand = useMemo(
    () =>
      !loading
        ? () => {
          setExpanded(!expanded);
        }
        : undefined,
    [expanded, loading]
  );

  return (
    <RegionWrap>
      <RegionHeader expanded={expanded}>
        <Checkbox
          name={region.name}
          disabled={disabled || loading}
          testId={
            !loading
              ? `DeliveryRegion-${region.name}-checkbox-${region.isChecked
                ? 'checked'
                : region.hasCheckedItems
                  ? 'subchecked'
                  : 'unchecked'
              }`
              : undefined
          }
          data-test-checked={region.isChecked && 'true'}
          key={`region[${region.id}]`}
          checked={region.isChecked}
          subchecked={region.hasCheckedItems}
          onCheck={handleRegionCheck}
        />

        <Clickable
          style={clickableStyle}
          testId={`DeliveryRegion-${region.name}-open`}
          onClick={handleRegionExpand}
        >
          <ItemRow>
            <Content>{t(region.name)}</Content>

            <Icon
              d={
                expanded
                  ? IconsCollection.chevronUp
                  : IconsCollection.chevronDown
              }
            />
          </ItemRow>
        </Clickable>
      </RegionHeader>

      {expanded && (
        <CountryWrap>
          {region.countries.map((country) => (
            <CountryMemoized
              disabled={disabled || loading}
              handleCountryCheck={handleCountryCheck}
              key={country.id}
              country={country}
            />
          ))}
        </CountryWrap>
      )}
    </RegionWrap>
  );
}

export default Region;
