export type FormData = {
  id: number;
  email: string;
  name: string;
  vatNumber: string;
  firstName: string;
  userfirstName: string;
  userlastName: string;
  lastName: string;
  address: string;
  address2: string;
  city: string;
  zip: string;
  countryId: number;
  cashCreditAutoDeduction: boolean;
};
export function validateUserFields(fields: Partial<FormData>) {
  const errors: Partial<Record<keyof FormData, string>> = {};

  if (!fields.firstName) {
    errors.firstName = 'Required';
  }
  if (!fields.lastName) {
    errors.lastName = 'Required';
  }

  return errors;
}
