import React, {
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { remove, adjust, compose, map } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useRelease, useUpdateEntity } from 'imddata';

import {
  H3,
  ComponentIcons,
  Card,
  Body,
  Window,
  OptionTab,
  Button,
  OverlayController,
  OverlayContext,
  TabStripe,
  FormToolbar,
  Content,
  IconButton,
  HelpWindowContext,
  BodySmall,
  OptionTabsWrapper,
} from 'imdui';
import DraggableTracksList from '../DraggableTracksList';
import { Toolbar, NoTracks } from './components';
import { AppContext } from '../../logic';
const TopRow = styled.div`
  padding: 24px 24px 24px 32px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: baseline;
  ${H3} {
    font-weight: 700;
  }
  div {
    gap: 4px;
    display: flex;
    align-items: center;
  }
`;

const ContentIdInfoCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 16px;
  margin-top: 8px;
  border: 1px solid var(--fg-4);
  background: var(--bg-3, #f4f4f4);
`;

export const Wrapper = styled(Card)`
  overflow: initial;
  position: relative;

  ${TabStripe} {
    padding-bottom: 24px;
    box-shadow: inset 0 -1px 0 var(--outline);
  }
`;

const windowTextStyles = css`
  display: block;
  margin: 24px;
`;

const buttonStyles = (theme) => css`
  background-color: ${theme.state.error};
  > span {
    color: white;
  }
`;

const mapTrackToData = map(compose(map((trackId) => ({ trackId }))));

const DEFAULT_VOLUMES = [[]];

const formatVolumes = (apiVolumes) =>
  !apiVolumes || !apiVolumes.length
    ? DEFAULT_VOLUMES
    : apiVolumes.map((volume) => volume.map(({ trackId }) => trackId));

function ReleaseTracksList({
  trackChildren,
  className,
  style,
  disabled,
  disabledCreate,
  children,
  toolbarActions,
  isValid,
  isTrackInContentId,
  releaseId,
  hideReleaseName,
  targetName,
  selectedTracks,
  renderAddItem,
  additionalValidation,
  requiredFields,
  renderTracksUploader,
  hasCidToggle,
  onUpdateSelection,
}) {
  const { t } = useTranslation();
  const [selectedVolume, setSelectedVolume] = useState(0);
  const [showTracksBrowser, setShowTracksBrowser] = useState(false);
  const {
    editableFields,
    updateLocalEntry: updateLocalRelease,
    entry: release,
    request: { loading },
  } = useRelease({ id: releaseId });

  const { updateEntry } = useUpdateEntity({
    id: releaseId,
    entity: 'releases',
  });

  const releaseVolumes = release ? release.volumes : null;

  const volumes = useMemo(() => {
    if (!releaseVolumes) return DEFAULT_VOLUMES;
    return formatVolumes(releaseVolumes);
  }, [releaseVolumes]);

  const genreId = release?.genreId;
  const releaseType = release?.releaseTypeId;

  const openTracksBrowser = useCallback(() => {
    setShowTracksBrowser(true);
  }, [showTracksBrowser]);

  const hideTracksBrowser = useCallback(() => {
    setShowTracksBrowser(false);
  }, [showTracksBrowser]);

  const handleSelectionChanged = useCallback(
    (selected) => {
      onUpdateSelection({
        entity: 'tracks',
        selected,
      });
    },
    [onUpdateSelection]
  );

  const handleVolumeChange = useCallback(
    (volumeNumber) => {
      setSelectedVolume(volumeNumber);
    },
    [selectedVolume]
  );

  const clearSelection = useCallback(() => {
    onUpdateSelection({ entity: 'tracks', selected: [] });
    onUpdateSelection({ entity: 'contributors', selected: [] });
    onUpdateSelection({ entity: 'publishers', selected: [] });
  }, [onUpdateSelection]);

  const onCloseWindow = useCallback(() => {
    clearSelection();
  }, [clearSelection]);

  const handleTabTransition = useCallback(
    (volumeNumber) => {
      clearSelection();
      if (volumeNumber === -1) {
        updateLocalRelease({
          id: releaseId,
          data: {
            volumes:
              releaseVolumes && releaseVolumes.length
                ? [
                  ...releaseVolumes.map((volume) =>
                    volume.map(({ id }) => id)
                  ),
                  [],
                ]
                : [[]],
          },
        });
        handleVolumeChange(volumes.length);
      } else {
        handleVolumeChange(volumeNumber);
      }
    },
    [releaseVolumes]
  );

  const { close, open } = useContext(OverlayContext);

  const handleDeleteVolume = useCallback(() => {
    onCloseWindow();
    open();
  });

  const handleTrackOrderChange = useCallback(
    (trackIds) => {
      updateEntry({
        query: {
          with: 'tracks',
        },
        data: {
          volumes: mapTrackToData(
            adjust(selectedVolume, () => trackIds, volumes)
          ),
        },
      });
    },
    [volumes, selectedVolume]
  );

  const nestingPath = useMemo(
    () => ({
      path: ['volumes', selectedVolume],
      wrapperEntity: 'releaseTracks',
      wrapperData: {
        volume: selectedVolume,
      },
      entity: 'releases',
      entityId: releaseId,
      entityIdKey: 'releaseId',
    }),
    [releaseId, selectedVolume]
  );

  useEffect(() => {
    if (volumes && volumes.length === 0 && selectedVolume === 1) {
      handleVolumeChange(0);
    }
  }, [volumes, selectedVolume, handleVolumeChange]);

  const app = useContext(AppContext);

  const showHelp = useContext(HelpWindowContext);

  const canModifyTracks = app === 'admin' || !release?.registeredAt;

  const AddVolume = renderAddItem || OptionTab;

  return (
    <Wrapper
      className={className}
      style={style}
      data-test-id="release-track-list"
    >
      <TopRow>
        <Header>
          <H3>{t('tracks')}</H3>
          <div>
            <Content>{t(releaseType)}</Content>
            <IconButton
              onClick={() => {
                showHelp(
                  t('release-type-item-name'),
                  t('release-type-price-short-helper-text')
                );
              }}
              iconProps={{ style: { marginLeft: 'auto' } }}
            >
              <ComponentIcons.HelpIcon />
            </IconButton>
          </div>
        </Header>
        {hasCidToggle && (
          <ContentIdInfoCard>
            <div>
              <Content>{t('youtube-content-id-is-available')}</Content>
              <br />
              <BodySmall>{t('youtube-content-id-is-available-text')}</BodySmall>
            </div>
            <Button
              size="small"
              position="center"
              text={t('learn-more')}
              onClick={() => {
                showHelp(
                  t('youtube-content-id'),
                  t('youtube-content-id-helpertext')
                );
              }}
            />
          </ContentIdInfoCard>
        )}
      </TopRow>

      <OptionTabsWrapper style={{ margin: '0 32px 24px' }}>
        {volumes.map((_volume, idx) => {
          const volumeNum = idx;
          return (
            <OptionTab
              testId={`volume-${volumeNum}`}
              key={`volume ${volumeNum}`}
              id={volumeNum}
              selected={volumeNum === selectedVolume}
              onChange={() => {
                handleTabTransition(volumeNum);
              }}
              label={`Vol. ${volumeNum + 1}`}
            ></OptionTab>
          );
        })}

        {!disabled && editableFields.tracks && (
          <AddVolume
            disabled={!volumes[volumes.length - 1].length}
            onClick={() => {
              handleTabTransition(-1);
            }}
            id="-1"
          />
        )}
      </OptionTabsWrapper>
      <div>
        <div
          style={{
            position: 'relative',

            boxShadow: 'inset 0px 1px 0px var(--outline)',
          }}
        >
          <DraggableTracksList
            virtualized={false}
            loading={loading}
            disableDrag={disabled || !editableFields.tracks}
            items={volumes[selectedVolume] || []}
            trackChildren={trackChildren}
            hideReleaseName={hideReleaseName}
            targetName={targetName}
            isValid={isValid}
            isTrackInContentId={isTrackInContentId}
            selectedTracks={selectedTracks}
            columns={{ number: true, cidToggle: hasCidToggle, album: false }}
            noRowsRenderer={NoTracks}
            onChangeSelection={handleSelectionChanged}
            onChangeTracksOrder={handleTrackOrderChange}
          />
        </div>

        {renderTracksUploader &&
          !disabledCreate &&
          !disabled &&
          canModifyTracks &&
          renderTracksUploader({
            nestingPath,
            genreId,
            artists: release?.artists,
          })}
      </div>
      <Toolbar
        canModifyTracks={canModifyTracks}
        selectedVolume={selectedVolume}
        nestingPath={nestingPath}
        toolbarActions={toolbarActions}
        genreId={genreId}
        releaseId={releaseId}
        disabledCreate={disabledCreate}
        disabled={disabled || !canModifyTracks || !editableFields.tracks}
        volumes={volumes}
        artists={release?.artists}
        revenueSplits={release?.revenueSplits}
        onClickTrackLibrary={openTracksBrowser}
        selectedTracks={selectedTracks}
        onClickDeleteVolume={handleDeleteVolume}
      />
      <Window title="Delete volume">
        <Body css={windowTextStyles}>
          {t('delete-volume-confirmation-text')}
        </Body>

        <FormToolbar
          rightChildren={
            <Button
              css={buttonStyles}
              text={t('confirm')}
              onClick={() => {
                updateEntry({
                  query: { with: 'tracks' },
                  data: {
                    volumes:
                      mapTrackToData(remove(selectedVolume, 1, volumes)) || [],
                  },
                });
                handleVolumeChange(0);
                close();
              }}
            />
          }
        />
      </Window>
      {children
        ? children({
          showTracksBrowser,
          onHideTracksBrowser: hideTracksBrowser,
          onChangeSelection: handleSelectionChanged,
          volumes,
          canModifyTracks,
          onCloseWindow,
          selectedVolume,
          disabled,
          selectedTracks,
          releaseId,
          additionalValidation,
          requiredFields,
        })
        : null}
    </Wrapper>
  );
}

export default (props) => (
  <OverlayController>
    <ReleaseTracksList {...props} />
  </OverlayController>
);
