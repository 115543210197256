import { reduxForm, Field } from 'redux-form';
import type { WrappedFieldProps, WrappedFieldArrayProps } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Theme } from 'imdui';
import { BaseInput, Button, Content, Window } from 'imdui';
import {
  JoinTagItem,
  JoinTagListWrapper,
} from '../../Next/JoinTags/JoinTagsList';
import {
  JoinTagAddButton,
  JoinTagsDropdown,
} from 'imdshared/src/Next/JoinTags';
import { css } from '@emotion/react';
import InputField from '../InputField';
import type { EntityModels } from 'imddata';
import {
  useCreateEntity,
  useCurrentCustomer,
  useEntries,
  useUpdateEntity,
} from 'imddata';
import styled from '@emotion/styled';
import { JoinTagItemWrapper } from '../../Next/JoinTags/JoinTagsList/JoinTagItem';

const JoinTagsDropdownStyled = styled(JoinTagsDropdown)`
  height: 100%;
  & > div {
    margin: 0;
    box-shadow: none;
  }
`;

type RevenueSplitTarget = {
  id: string;
  name: string;
};

const RevenueSplitTargetForm = reduxForm<
  RevenueSplitTarget,
  { onSubmit: (p: { id: string }) => void; submitText: string }
>({ form: 'RevenueSplitTarget', submitAsSideEffect: true })(({
  handleSubmit,
  submitting,
  submitText,
  onSubmit,
  form,
  submitSucceeded,
  initialValues,
}) => {
  const { t } = useTranslation();
  const { createEntry, createdId } = useCreateEntity({
    entity: 'revenueSplitTargets',
  });
  const { updateEntry } = useUpdateEntity({
    entity: 'revenueSplitTargets',
  });

  const submit = useCallback(
    (values: RevenueSplitTarget) => {
      if (initialValues.id) {
        updateEntry({
          formId: form,
          id: initialValues.id,
          data: {
            name: values.name,
          },
        });
      } else {
        createEntry({
          formId: form,
          data: {
            name: values.name,
          },
        });
      }
    },
    [initialValues.id]
  );

  useEffect(() => {
    if (initialValues.id && submitSucceeded) {
      onSubmit({ id: initialValues.id });
    }
  }, [submitSucceeded, initialValues.id]);

  useEffect(() => {
    if (createdId) {
      onSubmit({ id: createdId as string });
    }
  }, [createdId]);

  return (
    <div style={{ padding: '0 24px 24px' }}>
      <Field
        name="name"
        component={InputField}
        label={t('revenue-split-target-name')}
        placeholder={t('revenue-split-target-name-placeholder')}
      />
      <Button
        showLoading={submitting}
        disabled={submitting}
        text={submitText}
        onClick={handleSubmit(submit)}
      />
    </div>
  );
});

export type RevenueSplitsEditorProps<T> = {
  disabled?: boolean;
  sort?: (a: T, b: T) => number;
  isDisabled?: (v: T) => boolean;
  dragType?: string;
};

const renderInput = ({
  inputRef,
  getStyles,
  cx: hideCx,
  testId,
  isDisabled,
  hasValue,
  isHidden,
  // innerRef,
  theme,
  // showArrow,
  // showUnderline,
  required,
  // hideHelperText,
  errorText,
  warningText,
  // floatingLabelText,
  // acceptValidation,
  helperText,
  onClickHelp,

  selectProps,
  clearValue,
  getValue,
  isMulti,
  selectOption,
  setValue,
  isRtl,

  ...other
}: any) => (
  <input
    data-test-id="JoinTags-input"
    ref={(node) => {
      if (inputRef) {
        inputRef(node);
      }
    }}
    {...other}
  />
);

const buttonWrapperStyle = css`
  padding: 8px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & input {
    width: 100%;
    height: 40px;
    font-size: 15px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    padding: 0 20px;
    color: var(--on-surface);
  }

  & input::placeholder {
    font-weight: 400;
  }
`;

type RevenueSplit = {
  revenueSplitTargetId: string;
  share?: number;
};

const ShareInput = styled(BaseInput) <{ value: string }>`
  min-width: ${({ value }) => (value?.length >= 3 ? '60px' : '50px')};
  max-width: ${({ value }) => (value?.length >= 3 ? '60px' : '50px')};
  height: 40px;
  min-height: 40px;
  align-self: center;
  margin-right: 8px;
`;

const ShareInputField = ({ meta, input }: WrappedFieldProps) => {
  return <ShareInput status={meta.error ? 'error' : undefined} {...input} />;
};

const onlyNums = (
  value: string,
  prevValue: string,
  allValues: { revenueSplits: RevenueSplit[] }
) => {
  const leftOver = allValues.revenueSplits.reduce(
    (acc, { share }) => acc - (Number(share) || 0),
    100
  );
  if (/\.|,/.test(value)) return prevValue;

  if (value.length > 3) return prevValue;
  try {
    const v = Number(value);
    if (leftOver < 0) {
      return v + leftOver;
    }
    if (v < 0 || !v) {
      return '';
    }
    return value;
  } catch (err) {
    return prevValue;
  }
};

const actionsWrapperStyle = (theme: Theme) => css`
  padding: 8px;
  box-shadow: inset 0 -1px 0 0 ${theme.component.border};

  > button {
    width: 100%;
    justify-content: flex-start;
  }

  > button:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const TotalValueLeft = styled(Content)`
  align-items: center;
  display: flex;
  padding: 0 16px;
`;

const validateShare = (v: any) => (v > 0 && v < 101 ? null : 'required');

export const RevenueSplitsField = ({
  dragType,
  disabled,
  fields,
  isDisabled,
}: WrappedFieldArrayProps<RevenueSplit> &
  RevenueSplitsEditorProps<RevenueSplit>) => {
  const [formState, setFormState] = useState<null | 'add' | number>(null);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const editing = typeof formState === 'number';
  const { t } = useTranslation();
  const addText = editing ? t('edit') : t('add');
  const createLabel = t('create');

  const values = fields.getAll();
  const {
    entries,
    request: { loaded },
  } = useEntries<EntityModels.RevenueSplitTarget>({
    entity: 'revenueSplitTargets',
  });

  const loading = !loaded || !values;

  const renderActions = useMemo(
    () =>
      function DropdownActions({ isLoading, onlyHiddenOption, options }: any) {
        if (!inputValue) return null;

        const disabled = isLoading || !options;

        return (
          <div css={actionsWrapperStyle}>
            <Button
              disabled={disabled}
              appearance="fill"
              testId={`option-add-entry-${disabled ? 'disabled' : 'enabled'}`}
              style={{ width: '100%', justifyContent: 'center' }}
              focused={onlyHiddenOption}
              onClick={() => {
                setFormState('add');
              }}
              text={
                !disabled && inputValue
                  ? `${createLabel}: ${inputValue}`
                  : createLabel
              }
            />
          </div>
        );
      },
    [inputValue]
  );
  const totalLeft = values?.reduce((acc, { share }) => acc - (share || 0), 100);
  const { entry: customer } = useCurrentCustomer();
  const customerName = customer?.name;
  const editingEntry =
    typeof formState === 'number'
      ? entries.find(
        (e) => e.id === fields.get(formState)?.revenueSplitTargetId
      )
      : null;

  if (!values) return;
  return (
    <>
      <JoinTagListWrapper>
        <JoinTagItem
          index={-1}
          style={{
            minHeight: '56px',
          }}
          value={{
            label: `${t('you')}${customerName ? `(${customerName})` : ''}`,
          }}
        >
          <TotalValueLeft>{totalLeft}%</TotalValueLeft>
        </JoinTagItem>
        {!loading &&
          fields.map((f, index, fields) => {
            const v = fields.get(index);
            const disabled = isDisabled ? isDisabled(v) : false;
            const entry = entries.find((e) => e.id === v.revenueSplitTargetId);
            const value = { label: entry?.name || '' };
            return (
              <JoinTagItem
                style={{
                  minHeight: '56px',
                }}
                key={index}
                index={index}
                value={value}
                onClickItem={
                  disabled
                    ? undefined
                    : () => {
                      setFormState(index);
                    }
                }
                type={dragType}
                onClickDelete={() => fields.remove(index)}
                onDropItem={
                  dragType ? ({ from, to }) => fields.swap(from, to) : undefined
                }
              >
                <Field
                  name={`${f}.share`}
                  validate={validateShare}
                  component={ShareInputField}
                  normalize={onlyNums}
                />
              </JoinTagItem>
            );
          })}
        <JoinTagItemWrapper css={buttonWrapperStyle} isOver={false}>
          <JoinTagsDropdownStyled
            hasAddForm={true}
            disabled={disabled}
            allowDuplicates={false}
            label={'test'}
            shouldRefresh={true}
            addLabel={t('add-split-target')}
            placeholder={''}
            entity={'revenueSplitTargets'}
            entityIds={values.map((f) => f.revenueSplitTargetId)}
            renderActions={renderActions}
            renderInput={renderInput}
            onFocus={() => {
              setShowInput(true);
            }}
            onBlur={() => {
              setShowInput(false);
            }}
            // @ts-ignore
            onChange={(id: string, option: any) => {
              if (option.type === 'hidden') {
                setFormState('add');
                return;
              }

              fields.push({ revenueSplitTargetId: id, share: 1 });
            }}
            onInputChange={(searchString: string) => {
              if (searchString) {
                setInputValue(searchString);
              }
            }}
            showInput={showInput}
          />
          {!showInput && (
            <JoinTagAddButton
              showLoading={loading}
              disabled={disabled || loading || totalLeft <= 0}
              icon={undefined}
              text={addText}
              onClick={() => {
                setShowInput(true);
              }}
            />
          )}
        </JoinTagItemWrapper>
      </JoinTagListWrapper>
      <Window
        lockClickOutside={true}
        title={addText}
        isOpen={formState !== null}
        close={() => setFormState(null)}
      >
        <RevenueSplitTargetForm
          submitText={editing ? t('save') : t('add')}
          initialValues={{
            id: editingEntry?.id,
            name: editingEntry ? editingEntry.name : inputValue,
          }}
          onSubmit={(values: { id: string }) => {
            if (editingEntry) {
              setFormState(null);
            } else {
              fields.push({ revenueSplitTargetId: values.id, share: 1 });
            }
            setFormState(null);
          }}
        />
      </Window>
    </>
  );
};
