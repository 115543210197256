//
import React from 'react';
import { Translation } from 'react-i18next';
import { Subheading, Caption, Checkbox } from 'imdui';
import styled from '@emotion/styled';

const GroupHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 -1px 0 var(--outline-var);
`;

const HeaderRow = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 48px 32px 16px 32px;
`;

const FooterRow = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0 120px 10px 120px;

  & > span {
    flex: 1;
  }
  & > span:not(:last-child) {
    margin-right: 10px;
  }
  @media (max-width: 600px) {
    & span:not(:first-child) {
      display: none;
    }
  }
`;

const Title = styled(Subheading)`
  display: block;
  margin-left: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const GroupHeader = ({
  title,
  checked,
  subchecked,
  disabled,
  onCheck,
  style,
  testId,
}: {
  title: string;
  checked: boolean;
  subchecked: boolean;
  disabled: boolean;
  onCheck: (e: any) => void;
  style?: React.CSSProperties;
  testId?: string;
}) => (
  <GroupHeaderContainer style={style}>
    <HeaderRow>
      <Checkbox
        testId={`GroupHeader-Checkbox-${testId}`}
        name={`GroupHeader-Checkbox-${testId}`}
        checked={checked}
        subchecked={subchecked}
        onCheck={(e) => {
          e.stopPropagation();
          onCheck(e);
        }}
        disabled={disabled}
      />

      <Title>{title}</Title>
    </HeaderRow>

    <Translation>
      {(t) => (
        <FooterRow>
          <Caption secondary>{t('title')}</Caption>

          <Caption secondary>{t('artist')}</Caption>

          <Caption secondary>{t('isrc')}</Caption>

          <Caption secondary>{t('release')}</Caption>
        </FooterRow>
      )}
    </Translation>
  </GroupHeaderContainer>
);

export default GroupHeader;
