// @ts-nocheck
import React, { useMemo, useContext, Component, useCallback } from 'react';
import { css } from '@emotion/react';
import {
  find,
  view,
  lensProp,
  // findLast,
  sort,
  append,
  unless,
  isNil,
  compose,
  map,
  without,
  remove,
} from 'ramda';
import {
  ImpersonateContext,
  useQuery,
  useFetchEntity,
  useEntries,
} from 'imddata';
import { getName } from 'imddata/selectors';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  Button,
  HelperText,
  Window,
  OverlayController,
  OverlayContext,
  IssueListContext,
} from 'imdui';
import styled from '@emotion/styled';
import type { InputProps } from '../../fields/DropdownMenu';
import DropdownMenu from '../../fields/DropdownMenu';
import VirtualizedDropdownList from '../VirtualizedDropdownList';
import JoinTagsList, { JoinTagItem } from './JoinTagsList';
import type { JoinTagValue, JoinTagData, JoinTagDataTagOption } from './types';

function defaultMove<T>(list: T[], from: number, to: number) {
  const copy = [...list];

  [copy[from], copy[to]] = [copy[to], copy[from]];
  return copy;
}

const AddButton = styled(Button) <{ error: boolean }>`
  flex: none;
  flex: 1;
  box-shadow: ${({ error }) => (error ? `0 0 0 1px var(--error-error)` : '')};
`;

/* Copy pasted from imdshared/Input  */
const InputWrapper = styled.div`
  position: relative;
  min-height: 40px;
  & input {
    color: var(--on-surface);
    width: 100%;
    height: 40px;
    font-size: 15px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
    padding: 0 20px;
  }

  & input::placeholder {
    font-weight: 400;
  }
`;
const Container = styled.div`
  .container {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const DropdownMenuStyled = styled(DropdownMenu)`
  width: 100% !important;
  height: 100%;
  border-radius: ${({ theme }) => theme.component.radius} !important;
  padding: 0 !important;
  background: var(--background);
  box-shadow: inset 0 0 0 1px var(--outline-var);
  cursor: pointer;
  margin-bottom: 10px;
  color: var(--on-surface);
`;

type IdKey = 'contributorId' | 'artistId' | 'publisherId';

type SelectValue = {
  label: string;
  value: string | number;
};

type FormProps = any; // TODO

type JoinTagChangeAction =
  | {
    action: 'add';
    option: JoinTagDataTagOption;
  }
  | {
    action: 'remove';
    index: number;
  }
  | {
    action: 'move';
    fromIndex: number;
    toIndex: number;
  }
  | {
    action: 'change';
  };

export type Props = {
  floatingLabelText?: string; // TODO figure out where it is used here at all
  style?: React.CSSProperties;
  placeholder?: string;
  onBlur?: (e: Event) => void;
  allowDuplicates?: boolean;
  addLabel?: string;
  createLabel?: string;
  label?: string;
  entity: 'artists' | 'contributors' | 'publishers' | 'revenueSplitTargets';
  renderForm: React.FC<FormProps> | null;
  onChange: (values: Array<JoinTagValue>, meta: JoinTagChangeAction) => void;
  data: JoinTagData;
  disabled?: boolean;
  idKey: IdKey;
  mapEntry?: (value: JoinTagValue, entry: any) => JoinTagValue;

  onClickHelperButton?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  errorText?: string;
  warningText?: string;
  onClickItem?: (event: Event, value: JoinTagValue) => void;
  testId?: string;
  allowedIds?: Array<number>;
  value: Array<JoinTagValue>;
  draggable: boolean;
  renderError?: React.FC<{ values: Array<JoinTagValue> }>;
  query?: Record<string, unknown>;
};

export type JoinTagProps = Props & {
  t: TFunction<any>;
  open: () => void;
  close: () => void;
};

type EntryMapper = (
  entityIds: Readonly<Array<string | number>>
) => Readonly<Array<SelectValue>>;

const mapValues = (data: JoinTagData, idKey: IdKey) => (val: JoinTagValue) => {
  return {
    ...val,
    id: val.id || val[idKey],
    static:
      data.roles &&
        data.roles.options &&
        val.role &&
        data.roles.options[val.role]
        ? data.roles.options[val.role]?.static
        : false,
  };
};

const preventIssues = { issues: null };

export const mapValuesForJoinTags =
  (
    entries: Array<unknown>,
    idKey: IdKey,
    data: JoinTagData,
    mapEntry: (result: JoinTagValue, entry?: any) => JoinTagValue = (result) =>
      result
  ) =>
    (val: JoinTagValue) => {
      if (val.custom) {
        return val;
      }

      const id = val[idKey] || val.id;

      const entry = entries.find((value) => value.id === id);

      const { role } = val;

      const optionMeta = role ? data?.roles?.options[role] || {} : {};

      if (isNil(entry)) {
        return {
          id,
          role,
          label: '',
        };
      }

      return mapEntry(
        {
          id,
          role,
          label: getName(entry),
          static: optionMeta.static,
          ...val,
        },
        entry
      );
    };

const emptyIds: Array<number> = [];

const extractIds = (ids: Array<number>, idKey: IdKey) =>
  compose(
    without(isNil),
    map(view(lensProp(idKey)))
    // filter(value => !value.custom)
  )(ids);

const sortValues = compose(
  sort((x: JoinTagValue, y: JoinTagValue) =>
    x.static === y.static ? 0 : x.static ? 1 : -1
  ),
  sort((x: JoinTagValue, y: JoinTagValue) =>
    x.custom === y.custom ? 0 : y.custom ? -1 : 1
  )
);

const addOption = (values, option, idKey, role, data) => {
  const roleValue = !isNil(option.role) ? option.role : role;
  const optionMeta =
    data.roles && data.roles.options ? data.roles.options[role] : {};
  return !isNil(values)
    ? sortValues(
      append(
        {
          [idKey]: option.value,
          role: roleValue,
          static: optionMeta.static,
        },
        values
      )
    )
    : [
      {
        [idKey]: option.value,
        role: roleValue,
        static: optionMeta.static,
      },
    ];
};

const renderItem = (props: React.ComponentProps<typeof JoinTagItem>) => (
  <JoinTagItem {...props} />
);

const emptyValues: Readonly<Array<JoinTagValue>> = [];

function JoinTagsWithDisplay({
  data,
  entityIds,
  sortedValues,
  entity,
  disabled,
  draggable,
  idKey,
  joinTagsValues,
  onDelete,
  onChange,
  onDropItem,
  onClickItem,
  mapEntry,
  renderError,
}: React.ComponentProps<typeof JoinTagsList>) {
  const { entries } = useEntries({
    ids: entityIds,
    passive: !entityIds,
    entity,
  });

  const values = useMemo(
    () =>
      joinTagsValues
        ? map(mapValuesForJoinTags(entries, idKey, data, mapEntry))(
          sortedValues
        )
        : emptyValues,
    [sortedValues, entries, idKey, data]
  );
  return (
    <div style={values.length > 0 ? { marginBottom: '10px' } : undefined}>
      {renderError ? renderError({ values }) : null}

      <JoinTagsList
        draggable={draggable}
        key="jointaglist"
        disabled={disabled}
        type={entity}
        renderItem={renderItem}
        onClickItem={onClickItem}
        onDropItem={onDropItem}
        values={values}
        onDelete={onDelete}
        onChange={onChange}
        data={data}
      />
    </div>
  );
}

function ConnectedJoinTagsList(props: Props) {
  const onChange = useCallback(
    (index, key, value) => {
      const optionMeta = props.data?.roles?.options?.[value]
        ? props.data.roles.options[value]
        : {};

      const newValues = props.sortedValues.map((jtv, idx) => {
        if (idx === index) {
          return {
            ...jtv,
            [key]: value,
            static: optionMeta.static,
          };
        }
        return jtv;
      });
      props.onChange(sortValues(newValues), { action: 'change' });
      props.onBlur();
    },
    [props.data, props.sortedValues, props.onChange, props.onBlur]
  );

  const onDelete = useCallback(
    (index) => {
      props.onChange(sortValues(remove(index, 1, props.sortedValues)), {
        action: 'remove',
        index,
      });
    },
    [props.onChange, props.sortedValues]
  );

  const onDropItem = useCallback(
    ({ from, to }) => {
      const newValues = defaultMove(props.joinTagsValues, from, to);
      props.onChange(newValues, {
        action: 'move',
        fromIndex: from,
        toIndex: to,
      });
    },
    [props.joinTagsValues, props.idKey, props.onChange]
  );

  return (
    <JoinTagsWithDisplay
      {...props}
      onDropItem={onDropItem}
      onDelete={onDelete}
      onChange={onChange}
    />
  );
}

function ConnectedJoinTagsForm({
  data,
  renderForm: Form,
  createLabel,
  initialFormValues,
  entity,
  sortedValues,
  onChange,
  idKey,
  getNextAvailableRole,
  close,
}: any) {
  const impersonateQuery = useContext(ImpersonateContext);
  const { query, queryHash } = useQuery({ query: impersonateQuery });
  const { refresh } = useFetchEntity({
    query,
    queryHash,
    passive: true,
    entity,
  });
  if (!Form) return null;
  return (
    <Window title={createLabel}>
      <Form
        impersonate={impersonateQuery}
        initialValues={{
          ...initialFormValues,
        }}
        onClose={close}
        onSaved={(response) => {
          const result = {
            value: response.result,
          };
          refresh();
          onChange(
            addOption(
              sortedValues,
              result,
              idKey,
              getNextAvailableRole(result),
              data
            )
          );
          close();
        }}
      />
    </Window>
  );
}

const mapEntriesForSelect = ({
  mapEntry,
  allowDuplicates,
  selectedIds,
  getNextAvailableRole,
}): EntryMapper =>
  unless(
    isNil,
    map((entry) => {
      const role = getNextAvailableRole ? getNextAvailableRole(entry) : true;
      const label = mapEntry
        ? mapEntry({}, entry)?.label || getName(entry)
        : getName(entry);
      return {
        value: entry.id,
        label,
        role,
        disabled:
          !role || (!allowDuplicates && selectedIds.indexOf(entry.id) >= 0),
      };
    })
  );

const SingleValue = () => null;

export const JoinTagsDropdown = ({
  query: propsQuery,
  className,
  entity,
  getNextAvailableRole,
  hasAddForm,
  allowDuplicates,
  entityIds,
  addLabel,
  shouldRefresh,
  showInput,
  allowedIds,
  placeholder,
  renderActions,
  renderInput,
  onInputChange,
  onChange,
  onBlur,
  mapEntry,
  style,
  disabled,
}: Omit<Props, 'renderForm'> & { hasAddForm?: boolean }) => {
  const impersonateQuery = useContext(ImpersonateContext);
  const finalQuery = useMemo(
    () => ({ ...(impersonateQuery || {}), ...(propsQuery || {}) }),
    [impersonateQuery, propsQuery]
  );
  const { query, queryHash, updateQuery } = useQuery({
    query: finalQuery,
  });
  const {
    entries,
    loadMore,
    refresh,
    request: { hasMorePages, loading },
  } = useEntries(
    allowedIds ? { entity, ids: allowedIds } : { entity, query, queryHash }
  );

  const inputValue = query?.query;
  const options = useMemo(
    () =>
      mapEntriesForSelect({
        mapEntry,
        getNextAvailableRole,
        allowDuplicates,
        selectedIds: entityIds,
      })(entries),
    [entries, entityIds, allowDuplicates, getNextAvailableRole]
  );

  const hiddenOption = useMemo(
    () =>
      hasAddForm
        ? [
          {
            inputValue,
            label: `${addLabel}${inputValue ? `: ${inputValue}` : ''}`,
            value: 'add-entry',
            type: 'hidden',
          },
        ]
        : [],
    [addLabel, hasAddForm, inputValue]
  );
  return (
    <div
      className={className}
      style={{
        height: showInput ? 'auto' : '0',
        position: 'absolute',
        width: '100%',
        overflow: 'hidden',
        ...(style || {}),
      }}
    >
      <DropdownMenuStyled
        placeholder={placeholder}
        menuIsOpen={showInput}
        tabSelectsValue={false}
        isLoading={loading}
        hasNextPage={hasMorePages}
        loadNextPage={loadMore}
        disabled={disabled}
        backspaceRemovesValue={false}
        components={{
          SingleValue,
          MenuList: VirtualizedDropdownList,
          Input: renderInput,
          DropdownActions: renderActions,
        }}
        onFocus={() => {
          if (shouldRefresh) {
            refresh();
          }
        }}
        onBlur={onBlur}
        onChange={onChange}
        data={options.length > 0 ? options : hiddenOption}
        onInputChange={(searchString) => {
          updateQuery({ query: searchString });
          // this.setState({ inputValue: searchString });

          onInputChange(searchString);
          return searchString;
        }}
      />
    </div>
  );
};

const actionsWrapperStyle = (theme) => css`
  padding: 8px;
  box-shadow: inset 0 -1px 0 0 ${theme.component.border};

  > button {
    width: 100%;
    justify-content: flex-start;
  }

  > button:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 8px;
  height: 32px;
  padding-bottom: 8px;
  & > * {
    flex: 1;
  }
`;

export const JoinTagAddButton = ({
  onClick,
  error,
  ...props
}: {
  disabled?: boolean;
  onClick: React.ComponentProps<typeof Button>['onClick'];
  icon?: React.ComponentProps<typeof Button>['icon'];
  showLoading?: React.ComponentProps<typeof Button>['showLoading'];
  text?: React.ComponentProps<typeof Button>['text'];
  size?: React.ComponentProps<typeof Button>['size'];
  error?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <AddButton
      text={t('add')}
      testId="JoinTags-Open"
      appearance="fill"
      onClick={onClick}
      error={error}
      {...props}
    />
  );
};

class ConnectedJoinTags extends Component<JoinTagProps, any> {
  static defaultProps = {
    entity: 'artists',
    idKey: 'artistId',
    addLabel: 'add-artist',
    defaultRole: 'featuring',
    placeholder: 'Add',
    label: 'Artist...',
    draggable: true,
    mapSearchStringToFormValues: (searchString: string) => ({
      name: searchString,
    }),
    data: {
      tags: ['roles'],
      roles: {
        label: 'Role',
        key: 'role',
        order: [],
        options: {},
      },
    },
  };

  static getDerivedStateFromProps(props, state) {
    if (state.value !== props.value) {
      const { data, value: joinTagsValues, idKey } = props;
      const updater = compose(sortValues, map(mapValues(data, idKey)));

      const sortedValues = updater(joinTagsValues);

      const entityIds =
        (joinTagsValues && extractIds(joinTagsValues, idKey)) || emptyIds;

      return {
        sortedValues,
        entityIds,
      };
    }
    return null;
  }

  state = {
    initialFormValues: {},
    showInput: false,
    sortedValues: [],
    entityIds: [],
    inputValue: '',
  };

  handlePlusButton = () => {
    const { sortedValues } = this.state;
    if (sortedValues.length === 1 && sortedValues[0].type === 'hidden') {
      this.openForm();
      return;
    }

    this.setState({
      showInput: true,
    });
  };

  handleFocus = () => {
    this.setState({
      showInput: true,
    });
  };

  handleBlur = () => {
    this.setState({
      showInput: false,
    });
  };

  openForm(inputValue) {
    const { open, mapSearchStringToFormValues } = this.props;
    open();
    this.setState({
      initialFormValues: mapSearchStringToFormValues(inputValue),
    });
  }

  change = (v, ...props) => {
    const { onChange } = this.props;
    onChange(v && v.map(({ id, ...values }) => values), ...props);
  };

  handleChange = (value, option) => {
    const { data, idKey } = this.props;
    const { sortedValues } = this.state;

    if (option.disabled) return;

    if (option.type === 'hidden') {
      this.openForm(option.inputValue);
      return;
    }

    this.change(
      addOption(
        sortedValues,
        option,
        idKey,
        this.getNextAvailableRole(option),
        data
      ),
      { action: 'add', option }
    );
  };

  getNextAvailableRole = (value) => {
    const { sortedValues } = this.state;
    const { data, defaultRole } = this.props;

    if (!data.roles) return true;

    if (!data.roles.isDisabled) {
      // const lastActiveRole = findLast((entry) => !entry.static)(sortedValues);
      // return lastActiveRole ? lastActiveRole.role : defaultRole;
      return defaultRole;
    }
    const role = find(
      (option) =>
        !data.roles.isDisabled({
          option: data.roles.options[option],
          values: sortedValues,
          value,
        }),
      data.roles.order
    );
    return role;
  };

  renderInput = ({
    inputRef,
    getStyles,
    cx: hideCx,
    testId,
    isDisabled,
    labelSize,
    hasValue,
    isHidden,
    // innerRef,
    theme,
    // showArrow,
    // showUnderline,
    required,
    // hideHelperText,
    errorText,
    warningText,
    // floatingLabelText,
    // acceptValidation,
    helperText,
    onClickHelp,

    selectProps,
    clearValue,
    getValue,
    isMulti,
    selectOption,
    setValue,
    isRtl,

    ...other
  }: InputProps) => (
    <input
      data-test-id="JoinTags-input"
      disabled={this.props.disabled}
      ref={(node) => {
        if (inputRef) {
          inputRef(node);
        }
      }}
      {...other}
    />
  );

  renderActions = ({ isLoading, onlyHiddenOption, options }) => {
    const { createLabel } = this.props;

    const { inputValue } = this.state;

    if (!inputValue) return null;

    const disabled = isLoading || !options;

    //   ||
    // (options && options[0] && options[0].type !== 'hidden' && !inputValue);
    return (
      <div css={actionsWrapperStyle}>
        <Button
          disabled={disabled}
          appearance="fill"
          testId={`option-add-entry-${disabled ? 'disabled' : 'enabled'}`}
          style={{ width: '100%', justifyContent: 'center' }}
          focused={onlyHiddenOption}
          onClick={() => {
            this.openForm(inputValue);
          }}
          text={
            !disabled && inputValue
              ? `${createLabel}: ${inputValue}`
              : createLabel
          }
        />
      </div>
    );
  };

  render() {
    const {
      extraButton,
      children,
      placeholder,
      onBlur,
      allowDuplicates,
      addLabel,
      createLabel,
      label,
      entity,
      renderForm,
      data,
      disabled,
      idKey,
      mapEntry,
      onClickHelperButton,
      errorText,
      warningText,
      helperText,
      onClickItem,
      testId,
      allowedIds,
      value: joinTagsValues,
      close,
      draggable,
      t,
      renderError,
      query,
      style,
    } = this.props;

    const { initialFormValues, showInput, sortedValues, entityIds } =
      this.state;

    return (
      <Container data-test-id={testId} style={style}>
        <IssueListContext.Provider value={preventIssues}>
          <ConnectedJoinTagsForm
            data={data}
            createLabel={createLabel}
            initialFormValues={initialFormValues}
            entity={entity}
            sortedValues={sortedValues}
            onChange={this.change}
            renderForm={renderForm}
            idKey={idKey}
            close={close}
            getNextAvailableRole={this.getNextAvailableRole}
          />

          <ConnectedJoinTagsList
            renderError={renderError}
            mapEntry={mapEntry}
            t={t}
            draggable={draggable}
            data={data}
            onBlur={onBlur}
            disabled={disabled}
            idKey={idKey}
            sortedValues={sortedValues}
            onChange={this.change}
            entity={entity}
            joinTagsValues={joinTagsValues}
            onClickItem={onClickItem}
            entityIds={entityIds}
          />

          <InputWrapper className={disabled ? 'disabled' : ''}>
            <>
              <JoinTagsDropdown
                mapEntry={mapEntry}
                hasAddForm={!!renderForm}
                allowedIds={allowedIds}
                query={query}
                disabled={disabled}
                allowDuplicates={allowDuplicates}
                label={label}
                addLabel={addLabel}
                placeholder={placeholder}
                entity={entity}
                entityIds={entityIds}
                renderInput={this.renderInput}
                renderActions={this.renderActions}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onInputChange={(searchString) => {
                  this.setState({ inputValue: searchString });
                }}
                getNextAvailableRole={this.getNextAvailableRole}
                showInput={showInput}
              />

              <Buttons>
                {!showInput && (
                  <>
                    <JoinTagAddButton
                      error={errorText === 'required'}
                      onClick={this.handlePlusButton}
                    />
                    {extraButton}
                  </>
                )}
              </Buttons>
            </>
          </InputWrapper>
        </IssueListContext.Provider>
        {children}
        <HelperText
          text={helperText}
          warningText={warningText}
          errorText={errorText}
          onClickHelperButton={onClickHelperButton}
        />
      </Container>
    );
  }
}

export default function JoinTagsConnected(props: Props) {
  const { t } = useTranslation();

  return (
    <OverlayController>
      <OverlayContext.Consumer>
        {({ open, close }) => (
          <ConnectedJoinTags t={t} open={open} close={close} {...props} />
        )}
      </OverlayContext.Consumer>
    </OverlayController>
  );
}
