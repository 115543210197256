import React from 'react';
import { Caption, Content } from 'imdui';
import styled from '@emotion/styled';
import { formatOffset } from '../../helpers';
import PlayPauseButton from '../PlayPauseButton';
import { usePlayer } from '../PlayerProvider';
import Slider from '../Slider';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-bottom: 8px;
  gap: 16px;
  background: var(--bg-3);

  border: 1px solid var(--outline-var);
  border-radius: 8px;
`;

const PlayPauseButtonStyled = styled(PlayPauseButton)`
  padding: 0;
  width: 40px;
  background: var(--on-surface-semi);
  height: 40px;
`;

const TopRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  align-items: center;
  gap: 4px;
  & > div {
    display: flex;
  }
`;

const TimelineRow = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: auto 1fr auto;
`;

function MiniPlayer({
  trackToBePlayed,
  disabled,
  rightChild,
  leftChild,
  duration,
}: {
  trackToBePlayed: number;
  disabled: boolean;
  leftChild?: React.ReactChild;
  rightChild?: React.ReactChild;
  duration?: number;
}) {
  const { seekTo, progress, fileName, playingTrackId } =
    usePlayer(trackToBePlayed);
  const matchedTrack = trackToBePlayed === playingTrackId;
  return (
    <Wrapper>
      {!disabled && (
        <>
          <div>
            <Content>{fileName || ' '}</Content>
          </div>
          <TimelineRow>
            <Caption secondary>
              {formatOffset(matchedTrack ? progress : '00:00')}
            </Caption>
            {/* @ts-ignore */}
            <Slider
              onChange={(_e: any, value: number) => seekTo(value)}
              disabled={disabled || !matchedTrack}
              length={duration}
              offset={matchedTrack ? progress : 0}
            />
            <Caption secondary>{formatOffset(duration)}</Caption>
          </TimelineRow>
        </>
      )}

      <TopRow>
        <div>{leftChild}</div>
        <PlayPauseButtonStyled
          trackToBePlayed={trackToBePlayed}
          disabled={disabled}
        />
        <div>{disabled ? null : rightChild}</div>
      </TopRow>
    </Wrapper>
  );
}

export default MiniPlayer;
