import styled from '@emotion/styled';
import { BodyS, ContentM, ContentS, H4 } from '@imus/services-ui/src/Text';
import { SideEditor } from 'components/molecules';
import type { EntityModels, ReduxState } from 'imddata';
import { useCustomerFeature, useEntryProvider } from 'imddata';
import { BuyOrSubOffer, ShopsField, useProductPriceFormatted } from 'imdshared';
import DeliveryRegionsField, {
  useCountryRegionsValue,
} from 'imdshared/src/fields/DeliveryRegionsField';
import {
  getShopTipText,
  getShopTranslationKey,
} from 'imdshared/src/fields/ShopsField';
import { Button, FieldCardAction, OverlineText } from 'imdui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

const SaveCancelWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  margin-top: auto;
  padding-top: 24px;
  & > * {
    flex: 1;
  }
`;

export const SideEditorSaveCancelButtons = ({
  onCancel,
  onSave,
}: {
  onCancel: () => void;
  onSave?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <SaveCancelWrapper>
      <Button text={t('cancel')} onClick={onCancel} />
      <Button
        testId="SideEditorSaveButton"
        text={t('save')}
        primary={true}
        onClick={onSave}
        disabled={!onSave}
      />
    </SaveCancelWrapper>
  );
};

const SideEditorWrapper = styled.div`
  flex-direction: column;
  display: flex;
  height: auto;
  flex: 1;
  padding: 24px;
`;

export const CountryFieldCard = ({ input, disabled, meta }: any) => {
  const { regions, onCheckAll } = useCountryRegionsValue(
    input.value,
    input.onChange
  );
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const noCountriesChecked = regions.reduce(
    (acc, region) => acc && !region.isChecked,
    true
  );

  const worldwideDelivery = regions.reduce(
    (acc, region) => acc && !!region.isChecked,
    true
  );

  const [opened, setOpened] = useState(false);
  const selector = useMemo(() => formValueSelector(meta.form), [meta.form]);

  const enableYouTubeContentId = useSelector((state: ReduxState) =>
    selector(state, 'enableYouTubeContentId')
  );

  const decideBuy = useSelector((state: ReduxState) =>
    selector(state, 'allowPaymentForCountriesCustomization')
  );
  const setDecideBuy = useCallback(
    (v) => {
      dispatch(change(meta.form, 'allowPaymentForCountriesCustomization', v));
    },
    [decideBuy]
  );
  const feature = useCustomerFeature('delivery-regions-customization');
  const price = useProductPriceFormatted(
    'delivery-bundle-delivery-regions-customization'
  );

  useEffect(() => {
    if (!decideBuy && !feature) {
      onCheckAll(null, true);
    }
  }, [decideBuy, feature]);

  return (
    <>
      <OverlineText error={t(meta.error)} label={t('countries')} />
      <FieldCardAction
        action={
          <Button
            disabled={enableYouTubeContentId}
            text={disabled ? t('manage') : t('change')}
            testId="CountryFieldCard-change"
            onClick={() => {
              setOpened(!opened);
            }}
          />
        }
      >
        <ContentM>
          {t('release-countries-delivery', {
            context: enableYouTubeContentId
              ? 'content-id'
              : worldwideDelivery
                ? 'worldwide'
                : noCountriesChecked
                  ? undefined
                  : 'selected',
          })}
        </ContentM>
      </FieldCardAction>
      <SideEditor
        form={meta.form}
        label={t('countries')}
        isSelectionActive={opened}
        onClearSelection={() => setOpened(false)}
      >
        <SideEditorWrapper>
          {!feature && (
            <BuyOrSubOffer
              subscribeContext={{ analytics: { detail: 'regions' } }}
              price={price}
              title={t('unlock-countries-change')}
              description={t('unlock-countries-change-description')}
              onDecideSubscribe={() => {
                setOpened(false);
              }}
              onDecideBuy={setDecideBuy}
              decideBuy={decideBuy}
            />
          )}
          <DeliveryRegionsField
            input={input}
            disabled={disabled || (!feature && !decideBuy)}
            meta={meta}
          />
          <SideEditorSaveCancelButtons
            onCancel={() => {
              setOpened(false);
            }}
            onSave={
              !meta.error
                ? () => {
                  setOpened(false);
                }
                : undefined
            }
          />
        </SideEditorWrapper>
      </SideEditor>
    </>
  );
};

const SelectedShopsGrid = styled.div`
  display: grid;
  grid-template-columns: 144px 1fr;
  row-gap: 16px;
  width: 100%;
`;

export const ShopsFieldCard = ({
  input,
  disabled,
  meta,
  ...props
}: React.ComponentProps<typeof ShopsField>) => {
  const [opened, setOpened] = useState(false);
  // const selector = useMemo(() => formValueSelector(meta.form), [meta.form]);
  // const enableyoutubecontentid = useselector((state: reduxstate) =>
  //   selector(state, 'enableyoutubecontentid')
  // );
  const { t, i18n } = useTranslation();

  // const { beatport, traxsource } = useReleaseLabelLimitations({
  //   releaseId,
  // });
  //

  const selector = useMemo(() => formValueSelector(meta.form), [meta.form]);

  const genreId = useSelector((state: ReduxState) =>
    selector(state, 'genreId')
  );
  const genre = useEntryProvider<EntityModels.Genre>({
    entity: 'genres',
    id: genreId,
  });

  const isShopDisabled = useCallback<
    Exclude<React.ComponentProps<typeof ShopsField>['isDisabled'], undefined>
  >(
    ({ shopId }) => {
      let disabled: string | false = false;

      switch (shopId) {
        case 'apple-music-classical':
        case 'idagio':
          return genre?.handler !== 'classical' ? 'idagio-genre-error' : false;
        case 'beatport': {
          disabled =
            ['electronic', 'funk_soul'].indexOf(genre?.handler || '') < 0
              ? 'traxsource-beatport-genre-error'
              : false;
          break;
        }
        case 'traxsource':
          disabled =
            ['electronic', 'funk_soul'].indexOf(genre?.handler || '') < 0
              ? 'traxsource-beatport-genre-error'
              : false;
          break;

        default:
          return false;
      }

      return disabled;
    },
    [genre?.handler]
  );

  const tipCollection = input.value?.shops
    ?.map((s) => {
      const tipText = getShopTipText(
        i18n,
        s.shopId,
        input.value,
        isShopDisabled
      );
      return tipText ? (
        <>
          <ContentS>{t(getShopTranslationKey(s.shopId))}</ContentS>
          <BodyS>{tipText}</BodyS>
        </>
      ) : null;
    })
    .filter(Boolean);
  return (
    <>
      <OverlineText label={t('shops')} />
      <FieldCardAction
        status={!!meta.error && meta.touched ? 'error' : undefined}
        action={
          <Button
            testId="ShopsFieldCard-change"
            style={{ marginLeft: 'auto' }}
            text={disabled ? t('manage') : t('change')}
            onClick={() => {
              setOpened(!opened);
            }}
          />
        }
      >
        <ContentM>
          {meta?.error === 'required'
            ? t('required')
            : t('shops', {
              count: input.value?.shops.length,
            })}
        </ContentM>
      </FieldCardAction>
      <SideEditor
        form={meta.form}
        label={t('shops')}
        isSelectionActive={opened}
        onClearSelection={() => setOpened(false)}
      >
        <SideEditorWrapper>
          <ShopsField
            disabled={disabled}
            input={input}
            isDisabled={isShopDisabled}
            meta={meta}
            {...props}
          />
          {tipCollection &&
            tipCollection.length &&
            tipCollection?.length > 0 ? (
            <>
              <H4 style={{ padding: '32px 0 24px' }}>{t('selected-shops')}</H4>
              <SelectedShopsGrid>{tipCollection}</SelectedShopsGrid>
            </>
          ) : null}
          <SideEditorSaveCancelButtons
            onCancel={() => {
              setOpened(false);
            }}
            onSave={
              !meta.error
                ? () => {
                  setOpened(false);
                }
                : undefined
            }
          />
        </SideEditorWrapper>
      </SideEditor>
    </>
  );
};
