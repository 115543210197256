import styled from '@emotion/styled';
import { MilestoneIndicator, LevelBadge } from 'components/atoms';

const MilestoneIndicatorLevelBadgeContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const MilestoneIndicatorLevelBadge = ({ value }: { value: number }) => (
  <MilestoneIndicatorLevelBadgeContainer>
    <MilestoneIndicator size={40} />
    <LevelBadge style={{ marginLeft: '-3px' }} value={value} />
  </MilestoneIndicatorLevelBadgeContainer>
);
