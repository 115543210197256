import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReferralSvg from './referral.svg';
import { Card, Window, OverlineText, H1, BodySmall, Button } from 'imdui';
import { ErrorBoundary } from 'components';
import { useCurrentCustomer } from 'imddata';
import styled from '@emotion/styled';
import { BodyL } from '@imus/services-ui/src/Text';
import { useHistory } from 'react-router-dom';
import { secondaryTheme } from '@imus/base-ui';

const ReferralCardWrapper = styled(Card)`
  ${secondaryTheme}
  padding: 24px;
  ${BodySmall} {
    color: var(--on-surface-var, #353534);
    font-feature-settings: 'ss01' on;
  }
`;

const ReferralWindowContent = styled.div`
  ${H1} {
    margin-bottom: 16px;
  }
  background: var(--bg-1);
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;

export default function ReferralCard() {
  const [openReferralWindow, setOpenReferralWindow] = useState(false);
  const { t } = useTranslation();
  const { entry: customer } = useCurrentCustomer();
  // TODO: create useReferallLink to avoid duplication
  if (!customer) return null;
  const { referralReward } = customer;
  if (!referralReward) return null;

  const history = useHistory();

  return (
    <>
      <ReferralCardWrapper style={{ padding: '24px' }}>
        <OverlineText size="large" label={t('referral-card-title')} />
        <ErrorBoundary>
          <div
            style={{
              marginTop: 'auto',
              display: 'flex',
              gap: '16px',
              justifyContent: 'space-between',
            }}
          >
            <BodySmall>{t('referral-card-subtitle')}</BodySmall>

            <div
              style={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}
            >
              <Button
                appearance="fill"
                size="small"
                text={t('referral-card-button-text', {
                  defaultValue: 'view',
                })}
                onClick={() => {
                  setOpenReferralWindow(true);
                }}
              />
            </div>
          </div>
        </ErrorBoundary>
      </ReferralCardWrapper>

      <Window
        isOpen={openReferralWindow}
        close={() => {
          setOpenReferralWindow(false);

          if (window && window.analytics) {
            window.analytics.track('FT referral card closed modal');
          }
        }}
        style={{ maxWidth: '512px' }}
      >
        <ReferralSvg
          style={{
            backgroundColor: 'color(display-p3 1 0.4353 0.1333)',
            flex: 1,
            width: '100%',
            height: 'auto',
          }}
        />
        <ReferralWindowContent>
          <div>
            <H1>{t('refer-and-earn')}</H1>
            <BodyL>{t('refer-and-earn-text')}</BodyL>
          </div>
          <Button
            primary
            appearance="fill"
            onClick={() => {
              if (window && window.analytics) {
                window.analytics.track(
                  'FT referral card modal clicked to open referral dashboard'
                );
              }
              history.push('/account/referral');
            }}
            text={t('start-referring')}
          />
        </ReferralWindowContent>
      </Window>
    </>
  );
}
