import styled from '@emotion/styled';
import { Body } from '../../../text';

export const Wrapper = styled.div<{
  tintColor?: string;
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  min-height: 40px;
  cursor: pointer;

  ${Body} {
    margin-left: 8px;
    font-weight: 400;
  }
`;
