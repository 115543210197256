import styled from '@emotion/styled';
import cx from 'classnames';
import React, { useCallback } from 'react';
import { Body } from '../../../text';
import { IconsCollection } from '../../icon';

import { ChipContainer, StyledIconButton } from './styles';

const ChipText = styled.span`
  color: var(--fg-1, rgba(0, 0, 0, 0.95));

  color: var(--fg-1, color(display-p3 0 0 0 / 0.95));
  font-feature-settings:
    'cv11' on,
    'cpsp' on;
  font-family: Barlow, Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  letter-spacing: 0.15px;
`;

type Props = {
  text?: React.ReactNode;
  state?: 'error' | 'primary';
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  onClickDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  showLoading?: boolean;
  disabled?: boolean;
  className?: string;
  size?: 'large' | 'small';
  style?: React.CSSProperties;
  testId?: string;
};

export const Chip: React.FC<Props> = ({
  text,
  state,
  children,
  onClick,
  onClickDelete,
  showLoading,
  disabled,
  testId,
  className,
  style,
  size = 'large',
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  return (
    <ChipContainer
      size={size}
      data-test-id={testId}
      onClick={handleClick}
      clickable={!!onClick}
      role="button"
      state={state}
      className={cx(className, disabled && 'disabled')}
      style={style}
    >
      {children}

      {text && size === 'large' && <ChipText>{text}</ChipText>}
      {text && size === 'small' && <Body>{text}</Body>}

      {!!onClickDelete && (
        <StyledIconButton
          onClick={onClickDelete}
          newIcon={IconsCollection.remove}
          showLoading={showLoading}
        />
      )}
    </ChipContainer>
  );
};
