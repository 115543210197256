import styled from '@emotion/styled';
import {
  ArtistPagePromoCard,
  PageTemplate,
  ReleasePagePromoCard,
  ReturnNavigation,
} from 'components';
import { Card, H3 } from 'imdui';
import { useTranslation } from 'react-i18next';

const StyledCard = styled(Card)`
  padding: 32px;
  display: grid;
  gap: 24px;
  margin-bottom: 32px;
`;

export const ArtistHubProduct = () => {
  const { t } = useTranslation();
  return (
    <PageTemplate title={t('products')}>
      <ReturnNavigation to={`/products`} text={`${t('artist-hub')}`} />
      <StyledCard secondary>
        <H3>{t('artist-pages')}</H3>
        <ArtistPagePromoCard linkTo="/library/artists/new/artist-page" />
      </StyledCard>
      <StyledCard secondary>
        <H3>{t('release-pages')}</H3>
        <ReleasePagePromoCard linkTo="/order/release-page/new" />
      </StyledCard>
    </PageTemplate>
  );
};
