import styled from '@emotion/styled';

const SecondaryButton = styled.button<{
  active?: boolean;
}>`
  border-radius: 4px;
  height: 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${({ active }): number => (active ? 500 : 400)};
  text-decoration: ${({ active }): string => (active ? 'none' : 'underline')};
  color: ${({ active }): string =>
    active ? 'var(--accent)' : 'var(--on-surface-var)'};
  transition: color 0.1s ease-in-out;
`;

export default SecondaryButton;
